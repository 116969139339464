/**
 *
 *
 */

import { Fragment, useCallback, useContext } from 'react'
import BingoGame from '../types/bingogame'
import { Box, Grid } from '@mui/material'
import { BingoContext } from '../contexs/bingo-context'
import BingoCardCell from './bingo-card-cell'
import centerBingo from '../assets/bingo-default-center-square.png'

export interface BingoCardSquaresProps {
  cardId: string | number | undefined
}

export const BingoCardSquares: React.FC<BingoCardSquaresProps> = (props) => {
  const { cardId } = props

  const { bingo } = useContext(BingoContext)

  // match the square number with a bingo number object
  const matchNumber = useCallback(
    (number: keyof BingoGame['numbers']) => {
      if (bingo && number in bingo.numbers) {
        return bingo.numbers[number]
      }
      // fallback to number if no text/artist/number is set
      return {
        number,
      }
    },
    [bingo]
  )

  if (!bingo || !cardId) {
    return null
  }

  return (
    <>
      <Grid
        container
        sx={{
          width: '100%',
        }}
        alignItems={'flex-start'}
      >
        <Grid item xs={12} container spacing={0} sx={{ aspectRatio: '1 / 1' }}>
          {Object.keys(bingo.cards[cardId].squares).map((squareKey) => {
            // lookup data
            const square = parseInt(squareKey)
            const cardData = matchNumber(bingo.cards[cardId].squares[square])

            // add middle square
            if (
              (bingo.variant === '5x5' && square === 12) ||
              (bingo.variant === '3x3' && square === 4)
            ) {
              return (
                <Fragment key={square}>
                  <BingoCardCell
                    numberData={cardData}
                    variant={bingo.variant}
                  />
                  <BingoCardCell checked={true} variant={bingo.variant}>
                    <Box
                      component="img"
                      src={centerBingo}
                      alt="ballon"
                      sx={{
                        maxWidth: '80%',
                      }}
                    />
                  </BingoCardCell>
                </Fragment>
              )
            }

            // normal square
            else {
              return (
                <Fragment key={square}>
                  <BingoCardCell
                    numberData={cardData}
                    variant={bingo.variant}
                  />
                </Fragment>
              )
            }
          })}
        </Grid>
      </Grid>
    </>
  )
}
export default BingoCardSquares
