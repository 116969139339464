/**
 *
 *
 *
 */

import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import eazisLogo from '../assets/logo.webp'
import { useState } from 'react'
import StartBingo from '../components/start-bingo'
import Settings from '../components/bingo-settings'
import { Error, Home } from '@mui/icons-material'
import { BingoContextProvider } from '../contexs/bingo-context'
import Cards from '../components/bingo-cards'
import Play from '../components/bingo-play'
import useValidateToken from '../hooks/use-validate-token'

export type Views = 'home' | 'new' | 'settings' | 'cards' | 'play'

export const Bingo: React.FC = (props) => {
  const [view, setView] = useState<Views>('home')

  const { loading, validToken } = useValidateToken()

  return (
    <Box>
      {/* loader and not authenticated message */}
      <Backdrop open={loading || validToken === false} sx={{ zIndex: '9999' }}>
        {loading && <CircularProgress color="inherit" size="inherit" />}
        {validToken === false && <Box></Box>}
        <Box
          sx={{
            background: '#333',
            padding: 8,
            borderRadius: 3,
          }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Error
              color="secondary"
              sx={{ fontSize: '6rem', marginBottom: 1 }}
            />
            <br />
            <Typography variant="h4">Geen licentie</Typography>
            <br />
            <Typography>
              Uw apparaat heeft geen toegang tot de bingo, neem contact op met
              Eazis
            </Typography>
          </Box>
        </Box>
      </Backdrop>

      {/* header */}
      <Box flexGrow={1}>
        <AppBar position="fixed" sx={{ zIndex: 99999 }}>
          <Toolbar>
            <Box flexGrow={1}>
              <IconButton
                edge="start"
                color="secondary"
                onClick={() => setView('home')}
              >
                <Home color="inherit" />
              </IconButton>
            </Box>
            <Box component={'img'} src={eazisLogo} alt={'Logo'} />
            <Box flexGrow={1} />
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>

      {/* main body / content */}
      <Box
        sx={{
          padding: 2,
        }}
      >
        <BingoContextProvider>
          {view === 'home' && <StartBingo setView={setView} />}
          {view === 'new' && <Settings setView={setView} />}
          {view === 'settings' && <Settings setView={setView} />}
          {view === 'cards' && <Cards setView={setView} />}
          {view === 'play' && <Play setView={setView} />}
        </BingoContextProvider>
      </Box>
    </Box>
  )
}
export default Bingo
