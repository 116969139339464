/**
 *
 *
 *
 */

import { CheckBox, Delete } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { useCallback, useEffect } from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import useFiles from '../hooks/use-files'
import FileUploadButton from './file-upload-button'
import { useParams } from 'react-router-dom'
import BingoGame from '../types/bingogame'

export interface AttachmentsDialogProps {
  open: boolean
  onClose: () => void
  onSelect: (selectedItem: string | undefined) => void
  selectedItem: string | undefined
  bingoId: BingoGame['id']
}

// content app
export const AttachmentsDialogContent: React.FC<AttachmentsDialogProps> = (
  props
) => {
  const { selectedItem, onSelect, onClose, bingoId } = props
  const { deviceHash } = useParams()

  const { loading, files, listFiles, deleteFile } = useFiles()

  const handleSelect = useCallback(
    (newSelectedItem: string) => {
      if (newSelectedItem !== selectedItem) {
        onSelect(newSelectedItem)
      } else {
        onSelect(undefined)
      }
    },
    [onSelect, selectedItem]
  )

  const handleDelete = useCallback(
    async (event: any, filename: string) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (!filename) {
        return
      }

      if (filename === selectedItem) {
        onSelect(undefined)
      }

      await deleteFile(filename)
      await listFiles()
    },
    [deleteFile, listFiles, selectedItem, onSelect]
  )

  useEffect(() => {
    if (!files) {
      listFiles()
    }
  }, [listFiles, files])

  return (
    <>
      <DialogTitle>Mijn Afbeeldingen</DialogTitle>
      <DialogContent>
        <List sx={{ maxHeight: 350, position: 'relative' }}>
          <Backdrop
            open={loading}
            sx={{
              position: 'absolute',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Box display="flex" flexDirection={'column'} alignItems={'center'}>
              <CircularProgress color="inherit" />
              <Typography variant="caption" sx={{ marginTop: 2 }}>
                Mediabestanden Laden...
              </Typography>
            </Box>
          </Backdrop>
          {files &&
            files.length > 0 &&
            files.map((file) => (
              <ListItemButton
                key={file}
                onClick={() => handleSelect(file)}
                href={'##'}
                selected={file === selectedItem}
              >
                <ListItemIcon>
                  {file === selectedItem && <CheckBox />}
                  {file !== selectedItem && <CheckBoxOutlineBlankIcon />}
                </ListItemIcon>
                <ListItemText>{file}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={(ev) => handleDelete(ev, file)}>
                    <Delete color="inherit" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          {(!files || files.length < 1) && !loading && (
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              sx={{ padding: 4, outline: '3px dashed white' }}
            >
              <Typography variant="caption" sx={{ marginBottom: 2 }}>
                U heeft nog geen bestanden ge-upload
              </Typography>
              <FileUploadButton
                onError={console.error}
                onSuccess={() => listFiles()}
                uploadUrl={`https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingoId}/attachments`}
                buttonLabel={'Upload bestand'}
              />
            </Box>
          )}
        </List>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Box display={'flex'} width={'100%'}>
          <FileUploadButton
            onError={console.error}
            onSuccess={() => listFiles()}
            uploadUrl={`https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingoId}/attachments`}
            buttonLabel={'Upload bestand'}
          />

          <Box flexGrow={2} />

          <Button
            variant="contained"
            onClick={() => {
              // onSelect(undefined)
              onClose()
            }}
            sx={{ marginRight: 2 }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onClose()}
          >
            Select
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}

// dialog container
export const AttachmentsDialog: React.FC<AttachmentsDialogProps> = (props) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <AttachmentsDialogContent {...props} />
    </Dialog>
  )
}
export default AttachmentsDialog
