/**
 *
 *
 *
 */

import { useEffect, useState } from 'react'
import BingoGame from '../types/bingogame'
import { Box, Typography } from '@mui/material'
import FigureDisplay from '../components/figure-display'
import QRCode from '../components/QR-code'
import bgPub from '../assets/pub-background.jpg'
import NumberHistory from '../components/bingo-number-history'
import { useParams } from 'react-router-dom'

export interface InfoScreenProp {}

export const InfoScreen: React.FC<InfoScreenProp> = (props) => {
  const [bingo, setBingo] = useState<BingoGame>(
    JSON.parse(localStorage.getItem('eazis-bingo') || '{}')
  )

  const { deviceHash } = useParams()

  // update screen if bingo object changes
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea != localStorage) return
      if (event.key === 'eazis-bingo') {
        const bingo: BingoGame | null = JSON.parse(event.newValue || '{}')
        if (bingo) {
          setBingo(bingo)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  if (!bingo) {
    return (
      <>
        <Typography>Laden</Typography>
      </>
    )
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${bingo.infoScreenDesign?.backgroundImage || bgPub})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        textAlign: 'center',
        paddingTop: '3vh',
      }}
    >
      <Box sx={{ marginBottom: '3vh', background: 'rgba(0,0,0,0.5)' }}>
        <Typography variant="h2">{bingo.name || 'Bingo'}</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box
          sx={{
            width: 500,
            height: 500,
            border: 5,
            borderRadius: 1,
            marginRight: 1,
            aspectRatio: '1 / 1',
          }}
        >
          <FigureDisplay
            squares={bingo.figures[bingo.currentFigure].squares}
            noMargin
            bingo={bingo}
            normalCenterSquare
          />
        </Box>
        <Box
          sx={{
            width: 500,
            height: 500,
            backgroundColor: 'black',
            boxShadow: 15,
            border: 5,
            borderRadius: 1,
            marginLeft: 1,
          }}
        >
          <NumberHistory
            bingo={bingo}
            hideLast={bingo.type === 'music' ? 8 : undefined}
            hidePlaybackButton
          />
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h4"
          sx={{ background: 'rgba(0,0,0,0.5)', marginTop: '3vh', p: 1 }}
        >
          Wij spelen voor het figuur:{' '}
          {bingo?.figures[bingo?.currentFigure || 1]?.name || ''}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ marginTop: 3 }}>
          Speel mee via
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <QRCode
            text={
              window.location.protocol +
              '//' +
              window.location.host +
              `/${deviceHash}/play/${bingo.id}`
            }
          />
        </Box>
      </Box>
    </Box>
  )
}
export default InfoScreen
