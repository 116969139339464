/**
 *
 *
 *
 */

import React, { createContext } from 'react'
import useBingo from '../hooks/use-bingo'
import BingoGame, {
  BingoCard,
  BingoCards,
  WinningCard,
} from '../types/bingogame'
import BingoShowCardDialog from '../components/bingo-show-card-dialog'

export const BingoContext = createContext<ReturnType<typeof useBingo>>({
  bingo: undefined,
  loading: false,
  createBingo: () => {},
  updateBingo: () => {},
  saveBingo: async () => false,
  loadBingo: async () => {},
  deleteBingo: async () => false,
  loadDefaultBingo: () => {},
  resetGame: async () => {},
  addFigure: () => {},
  removeFigure: () => {},
  moveFigure: () => {},
  addCards: async (
    amount?: number,
    owner?: BingoCard['owner'],
    variant?: BingoGame['variant']
  ) => {},
  removeAllCards: async () => {},
  updateCard: (id: keyof BingoCards, owner: BingoCard['owner']) => {},
  removeCard: (id: keyof BingoCards) => {},
  drawNumber: async (number?: number[]) => {},
  nextFigure: async () => {},
  possibleWinningCards: [],
  addToWinningCards: async (newCard: WinningCard) => {},
  showCardId: undefined,
  setShowCardId: (value) => {},
  bingoInLocalstorage: false,
  clearBingoFromLocalstorage: () => {},
  loadBingoFromLocalstorage: () => false,
  saveBingoToLocalstorage: () => false,
})

export const BingoContextProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { children } = props

  const bingoHook = useBingo()

  return (
    <BingoContext.Provider value={bingoHook}>
      {children}
      {bingoHook.showCardId && (
        <BingoShowCardDialog
          open={Boolean(bingoHook.showCardId)}
          onClose={() => bingoHook.setShowCardId(undefined)}
          cardId={bingoHook.showCardId}
        />
      )}
    </BingoContext.Provider>
  )
}
