/**
 *
 *
 *
 */

import { useCallback, useContext } from 'react'
import { FixedSizeList } from 'react-window'
import { BingoContext } from '../contexs/bingo-context'
import {
  Button,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  capitalize,
} from '@mui/material'
import FigureDisplay from './figure-display'
import { BingoCard, WinningCard } from '../types/bingogame'

export interface BingoPossibleWinningCardsProps {
  onWinnerSelect: (wc: WinningCard) => void
  height?: number
}

export const BingoPossibleWinningCards: React.FC<
  BingoPossibleWinningCardsProps
> = (props) => {
  const { possibleWinningCards, bingo, setShowCardId, showCardId } =
    useContext(BingoContext)

  const { onWinnerSelect, height } = props

  // handlers
  const handleWinner = useCallback(
    (
      e: any,
      winningCard: { amountDrawn: number; card: BingoCard; figure: number }
    ) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (winningCard) {
        onWinnerSelect(winningCard)
      }
    },
    [onWinnerSelect]
  )

  // const handleShowCard = useCallback(() => {}, [])

  // render a bingocard row
  const RenderBingoCardRow: React.FC<{
    index: number
    style: any
    data: {
      amountDrawn: number
      card: BingoCard
      figure: number
    }[]
  }> = (props) => {
    const { index, style, data } = props
    const item = data[index]

    if (!bingo) {
      return null
    }

    return (
      <ListItemButton
        key={index}
        style={style}
        sx={{
          borderBottom: 1,
          borderColor: 'primary.light',
          paddingLeft: 0,
          paddingRight: 1,
        }}
        onClick={() => setShowCardId(item.card.id.toString())}
        selected={showCardId === item.card.id}
      >
        <ListItemAvatar>
          <FigureDisplay
            squares={bingo.figures[item.figure]?.squares}
            maxWidth={48}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{ paddingRight: 0 }}
          primary={`Kaart: ${item.card.id}`}
          secondary={
            item.card.owner
              ? capitalize(item.card.owner)
              : 'Figuur: ' + bingo.figures[item.figure].name
          }
        />
        <ListItemSecondaryAction>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => handleWinner(e, item)}
          >
            Winnaar
          </Button>
        </ListItemSecondaryAction>
      </ListItemButton>
    )
  }

  const calcHeight = () => {
    let newHeight = height || 382
    if (possibleWinningCards.length < 1) {
      newHeight = newHeight - 32
    }
    return newHeight
  }

  if (!bingo) {
    return null
  }

  return (
    <>
      {possibleWinningCards.length < 1 && (
        <Typography variant="overline">
          Er zijn nog geen mogelijke winnaars
        </Typography>
      )}
      <FixedSizeList
        height={calcHeight()}
        itemCount={possibleWinningCards.length}
        itemData={possibleWinningCards}
        itemSize={56}
        itemKey={(index, data) => data[index].card.id}
        width={'100%'}
      >
        {RenderBingoCardRow}
      </FixedSizeList>
    </>
  )
}
export default BingoPossibleWinningCards
