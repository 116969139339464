/**
 *
 *
 *
 */

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import StyledBox from '../theme/styled-box'
import { ArrowBack } from '@mui/icons-material'
import { Views } from '../routes/bingo'
import { useCallback, useContext, useEffect, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import FigureDisplay from './figure-display'
import NumberHistory from './bingo-number-history'
import NextBingoNumber from './bingo-next-number'
import BingoPossibleWinningCards from './bingo-possible-winning-cards'
import BingoWinnersDialog from './bingo-winners-dialog'
import BingoAddWinnerDialog from './bingo-add-winner-dialog'
import BingoGame, { WinningCard } from '../types/bingogame'
import PlaylistAudioPlayer from './playlist-audio-player'
import ConfirmDialog from './confirm-dialog'
import SaveBingoButton from './save-bingo-button'

const mapUrls = (numbersDrawn?: number[], numbers?: BingoGame['numbers']) => {
  const newNumbers: string[] = []
  if (!numbersDrawn || !numbers) {
    return newNumbers
  }
  numbersDrawn.forEach((number) => {
    const data = numbers[number]
    if (data.fileUrl) {
      newNumbers.push(`/music/playlist-01/${data.fileUrl}`)
    }
  })
  return newNumbers
}

export interface PlayProps {
  setView: (view: Views) => void
}

export const Play: React.FC<PlayProps> = (props) => {
  const { setView } = props

  const { bingo, resetGame } = useContext(BingoContext)

  const [winnerDialogOpen, setWinnerDialogOpen] = useState(false)

  const [playlist, setPlaylist] = useState<string[]>([])
  const [alreadyPlayed, setAlreadyPlayed] = useState<string[]>(() =>
    mapUrls(bingo?.numbersDrawn, bingo?.numbers)
  )

  const [addWinnerDialogOpen, setAddWinnerDialogOpen] = useState<
    WinningCard | undefined
  >(undefined)

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

  const handleResetGame = useCallback(() => {
    resetGame()
    setPlaylist([])
    setAlreadyPlayed([])
    setConfirmDialogOpen(false)
  }, [resetGame])

  // add new drawn bingonumbers to player
  useEffect(() => {
    if (
      bingo &&
      bingo.type === 'music' &&
      bingo.numbersDrawn.length > alreadyPlayed.length
    ) {
      const mapNew: string[] = []
      bingo.numbersDrawn.forEach((number) => {
        const url = `/music/playlist-01/${bingo.numbers[number].fileUrl}`
        if (url && alreadyPlayed.includes(url) === false) {
          mapNew.push(url)
        }
      })
      setPlaylist(mapNew)
      setAlreadyPlayed(() => mapUrls(bingo.numbersDrawn, bingo.numbers))
    }
  }, [bingo, alreadyPlayed])

  if (!bingo) {
    setView('settings')
    return null
  }

  return (
    <Grid
      container
      alignItems={'stretch'}
      justifyContent={'center'}
      spacing={2}
    >
      {/* navigation */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <StyledBox>
          <Grid container alignItems={'center'}>
            <Grid item>
              {/* back button */}
              <IconButton color="secondary" onClick={() => setView('cards')}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              {/* header */}
              <Typography
                variant="h5"
                alignSelf={'center'}
                onClick={() => setView('cards')}
                sx={{ cursor: 'pointer' }}
              >
                Bingokaarten Beheren
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <SaveBingoButton />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirmDialogOpen(true)}
                sx={{ marginRight: 1 }}
              >
                Herstart Spel
              </Button>
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={12} md={12} lg={5}>
        <StyledBox sx={{ height: '100%' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="secondary" variant="caption">
                Figuur
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
            </Grid>
            {Object.values(bingo.figures).map((figure, index) => (
              <Grid item xs={3} key={index} flexGrow={1}>
                <Tooltip title={figure.name}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      border:
                        figure.order === bingo.currentFigure
                          ? '4px solid #FFFFFF'
                          : '4px solid transparent',
                      p: 1,
                      maxWidth: 125,
                      boxShadow:
                        figure.order === bingo.currentFigure ? 5 : undefined,
                      aspectRatio: '1 / 1',
                    }}
                  >
                    <FigureDisplay
                      squares={figure.squares}
                      noMargin
                      normalCenterSquare={true}
                    />
                  </Box>
                </Tooltip>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ position: 'relative', marginTop: 2 }}>
            <Typography color="secondary" variant="caption">
              Getrokken Nummers
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: 1 }} />
          <NumberHistory showHideArtistsSwitch />
        </StyledBox>
      </Grid>

      <Grid item xs={12} md={12} lg={5}>
        <StyledBox sx={{ height: '100%' }}>
          <Box sx={{ width: '100%' }} />
          <Typography color="secondary" variant="caption">
            Bediening
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <NextBingoNumber />

          {bingo.type === 'music' && (
            <>
              <Box sx={{ marginTop: 3 }} />
              <PlaylistAudioPlayer playlist={playlist} />
            </>
          )}

          <Box sx={{ width: '100%', marginTop: 2 }} />
          <Typography
            color="secondary"
            variant="caption"
            sx={{ display: 'block' }}
          >
            Geselecteerde winnaars bekijken
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => setWinnerDialogOpen(true)}
            sx={{ margin: 1 }}
          >
            Bekijk Winnaars
          </Button>
          <Typography
            color="secondary"
            variant="caption"
            sx={{ display: 'block', marginTop: 2 }}
          >
            Mogelijke kaarten met Bingo
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <BingoPossibleWinningCards
            onWinnerSelect={(wc: WinningCard) => setAddWinnerDialogOpen(wc)}
          />
        </StyledBox>
      </Grid>

      <BingoWinnersDialog
        open={winnerDialogOpen}
        onClose={() => setWinnerDialogOpen(false)}
      />

      <BingoAddWinnerDialog
        open={Boolean(addWinnerDialogOpen)}
        onClose={() => setAddWinnerDialogOpen(undefined)}
        winningCard={addWinnerDialogOpen}
      />

      <ConfirmDialog
        text={
          'Dit reset de bingo: winnaars/resultaten/getrokken nummers worden geleegd. De kaarten, figuren en nummers blijven ongewijzigd.'
        }
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={() => handleResetGame()}
        title={'Herstart Spel'}
        open={confirmDialogOpen}
      />
    </Grid>
  )
}
export default Play
