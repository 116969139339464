
export type Odds = {
  ul3: number;
  ul2: number;
  ul1: number;
  mean: number;
  median: number;
  mostFrequent: number;
  ur1: number;
  ur2: number;
  ur3: number;
  standardDeviation: number;
  lowest: number;
  highest: number;
}

export type PresetFigure = {
  name: string
  squares: number[];
  odds?: {
    [key: string]: Odds;
  };
}

export const presetFigures: PresetFigure[] = [
  {
    name: 'Middelste Rij',
    squares: [11,12,13,14],
    odds: {
      "25": {
        "ul3": 6.794613156539771,
        "ul2": 15.195435463734846,
        "ul1": 23.596257770929924,
        "mean": 31.997080078125,
        "median": 32,
        "mostFrequent": 34,
        "ur1": 40.39790238532007,
        "ur2": 48.79872469251515,
        "ur3": 57.19954699971022,
        "standardDeviation": 8.400822307195076,
        "lowest": 4,
        "highest": 63
      },
      "50": {
        "ul3": 5.7806449666566735,
        "ul2": 12.961370732979448,
        "ul1": 20.142096499302223,
        "mean": 27.322822265625,
        "median": 27,
        "mostFrequent": 29,
        "ur1": 34.50354803194777,
        "ur2": 41.68427379827055,
        "ur3": 48.864999564593326,
        "standardDeviation": 7.180725766322776,
        "lowest": 4,
        "highest": 52
      },
      "75": {
        "ul3": 5.337268744817379,
        "ul2": 11.902144006961587,
        "ul1": 18.467019269105794,
        "mean": 25.03189453125,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 31.596769793394206,
        "ur2": 38.161645055538415,
        "ur3": 44.72652031768262,
        "standardDeviation": 6.564875262144207,
        "lowest": 4,
        "highest": 53
      },
      "100": {
        "ul3": 4.953728106752795,
        "ul2": 11.141069388876863,
        "ul1": 17.32841067100093,
        "mean": 23.515751953125,
        "median": 24,
        "mostFrequent": 24,
        "ur1": 29.70309323524907,
        "ur2": 35.89043451737314,
        "ur3": 42.0777757994972,
        "standardDeviation": 6.187341282124069,
        "lowest": 4,
        "highest": 50
      },
      "125": {
        "ul3": 4.70228341797819,
        "ul2": 10.599823059902127,
        "ul1": 16.497362701826063,
        "mean": 22.39490234375,
        "median": 22,
        "mostFrequent": 24,
        "ur1": 28.29244198567394,
        "ur2": 34.189981627597874,
        "ur3": 40.087521269521815,
        "standardDeviation": 5.897539641923937,
        "lowest": 4,
        "highest": 47
      },
      "150": {
        "ul3": 4.578821052805381,
        "ul2": 10.230864425828587,
        "ul1": 15.882907798851793,
        "mean": 21.534951171875,
        "median": 22,
        "mostFrequent": 21,
        "ur1": 27.18699454489821,
        "ur2": 32.83903791792142,
        "ur3": 38.49108129094462,
        "standardDeviation": 5.652043373023207,
        "lowest": 4,
        "highest": 45
      },
      "200": {
        "ul3": 4.22893382962884,
        "ul2": 9.565662917669227,
        "ul1": 14.902392005709615,
        "mean": 20.23912109375,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 25.575850181790386,
        "ur2": 30.912579269830772,
        "ur3": 36.24930835787116,
        "standardDeviation": 5.336729088040387,
        "lowest": 4,
        "highest": 42
      },
      "250": {
        "ul3": 4.0100698701109785,
        "ul2": 9.106133819657318,
        "ul1": 14.202197769203657,
        "mean": 19.29826171875,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 24.39432566829634,
        "ur2": 29.49038961784268,
        "ur3": 34.586453567389015,
        "standardDeviation": 5.09606394954634,
        "lowest": 4,
        "highest": 44
      },
      "300": {
        "ul3": 3.832730226724358,
        "ul2": 8.759437338649573,
        "ul1": 13.686144450574787,
        "mean": 18.6128515625,
        "median": 19,
        "mostFrequent": 18,
        "ur1": 23.539558674425216,
        "ur2": 28.46626578635043,
        "ur3": 33.392972898275644,
        "standardDeviation": 4.926707111925214,
        "lowest": 4,
        "highest": 39
      },
      "400": {
        "ul3": 3.6058395366633,
        "ul2": 8.261933389025534,
        "ul1": 12.918027241387769,
        "mean": 17.57412109375,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 22.230214946112234,
        "ur2": 26.886308798474467,
        "ur3": 31.542402650836703,
        "standardDeviation": 4.6560938523622335,
        "lowest": 4,
        "highest": 41
      },
      "500": {
        "ul3": 3.3847826543225032,
        "ul2": 7.852944295590001,
        "ul1": 12.3211059368575,
        "mean": 16.789267578125,
        "median": 17,
        "mostFrequent": 17,
        "ur1": 21.2574292193925,
        "ur2": 25.72559086066,
        "ur3": 30.193752501927495,
        "standardDeviation": 4.468161641267499,
        "lowest": 4,
        "highest": 38
      },
      "750": {
        "ul3": 2.9908575675514335,
        "ul2": 7.157612727325956,
        "ul1": 11.324367887100479,
        "mean": 15.491123046875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 19.65787820664952,
        "ur2": 23.824633366424045,
        "ur3": 27.991388526198566,
        "standardDeviation": 4.166755159774522,
        "lowest": 4,
        "highest": 41
      },
      "1000": {
        "ul3": 2.6954689800474636,
        "ul2": 6.692239736698308,
        "ul1": 10.689010493349155,
        "mean": 14.68578125,
        "median": 15,
        "mostFrequent": 14,
        "ur1": 18.682552006650845,
        "ur2": 22.67932276330169,
        "ur3": 26.676093519952538,
        "standardDeviation": 3.9967707566508457,
        "lowest": 4,
        "highest": 39
      },
      "2000": {
        "ul3": 1.9262472510941997,
        "ul2": 5.6227683496878,
        "ul1": 9.3192894482814,
        "mean": 13.015810546875,
        "median": 13,
        "mostFrequent": 13,
        "ur1": 16.712331645468602,
        "ur2": 20.4088527440622,
        "ur3": 24.1053738426558,
        "standardDeviation": 3.6965210985936,
        "lowest": 4,
        "highest": 38
      },
      "5000": {
        "ul3": 0.5696050559298769,
        "ul2": 4.189320037286585,
        "ul1": 7.809035018643293,
        "mean": 11.42875,
        "median": 11,
        "mostFrequent": 11,
        "ur1": 15.048464981356709,
        "ur2": 18.668179962713417,
        "ur3": 22.287894944070125,
        "standardDeviation": 3.619714981356708,
        "lowest": 4,
        "highest": 39
      },
      "10000": {
        "ul3": -0.5762971832015307,
        "ul2": 3.1512934143239795,
        "ul1": 6.87888401184949,
        "mean": 10.606474609375,
        "median": 10,
        "mostFrequent": 9,
        "ur1": 14.334065206900512,
        "ur2": 18.06165580442602,
        "ur3": 21.78924640195153,
        "standardDeviation": 3.7275905975255106,
        "lowest": 4,
        "highest": 43
      }
    },
  },
  {
    name: 'Schuine Streep',
    squares: [20, 16, 9, 5],
    odds: {
      "25": {
        "ul3": 6.763155237448888,
        "ul2": 15.161055314549259,
        "ul1": 23.55895539164963,
        "mean": 31.95685546875,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 40.35475554585037,
        "ur2": 48.75265562295074,
        "ur3": 57.150555700051115,
        "standardDeviation": 8.39790007710037,
        "lowest": 4,
        "highest": 60
      },
      "50": {
        "ul3": 5.883086225900183,
        "ul2": 13.042513213100122,
        "ul1": 20.20194020030006,
        "mean": 27.3613671875,
        "median": 27,
        "mostFrequent": 28,
        "ur1": 34.520794174699944,
        "ur2": 41.68022116189988,
        "ur3": 48.839648149099816,
        "standardDeviation": 7.159426987199939,
        "lowest": 4,
        "highest": 55
      },
      "75": {
        "ul3": 5.23481502966073,
        "ul2": 11.824114967690488,
        "ul1": 18.413414905720245,
        "mean": 25.00271484375,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 31.592014781779753,
        "ur2": 38.18131471980951,
        "ur3": 44.77061465783927,
        "standardDeviation": 6.589299938029756,
        "lowest": 4,
        "highest": 52
      },
      "100": {
        "ul3": 4.861559881612926,
        "ul2": 11.067397342950283,
        "ul1": 17.27323480428764,
        "mean": 23.479072265625,
        "median": 24,
        "mostFrequent": 23,
        "ur1": 29.68490972696236,
        "ur2": 35.89074718829971,
        "ur3": 42.09658464963707,
        "standardDeviation": 6.205837461337358,
        "lowest": 4,
        "highest": 47
      },
      "125": {
        "ul3": 4.6807217741655975,
        "ul2": 10.577372458818733,
        "ul1": 16.474023143471868,
        "mean": 22.370673828125,
        "median": 22,
        "mostFrequent": 24,
        "ur1": 28.26732451277813,
        "ur2": 34.16397519743127,
        "ur3": 40.0606258820844,
        "standardDeviation": 5.8966506846531335,
        "lowest": 4,
        "highest": 45
      },
      "150": {
        "ul3": 4.500585001094343,
        "ul2": 10.173241563229562,
        "ul1": 15.84589812536478,
        "mean": 21.5185546875,
        "median": 22,
        "mostFrequent": 21,
        "ur1": 27.19121124963522,
        "ur2": 32.86386781177044,
        "ur3": 38.53652437390566,
        "standardDeviation": 5.672656562135219,
        "lowest": 4,
        "highest": 44
      },
      "200": {
        "ul3": 4.185587499605088,
        "ul2": 9.53158307265339,
        "ul1": 14.877578645701695,
        "mean": 20.22357421875,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 25.5695697917983,
        "ur2": 30.915565364846607,
        "ur3": 36.26156093789491,
        "standardDeviation": 5.345995573048304,
        "lowest": 4,
        "highest": 42
      },
      "250": {
        "ul3": 4.007328233067867,
        "ul2": 9.11860619183691,
        "ul1": 14.229884150605955,
        "mean": 19.341162109375,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 24.452440068144043,
        "ur2": 29.563718026913087,
        "ur3": 34.67499598568213,
        "standardDeviation": 5.111277958769044,
        "lowest": 4,
        "highest": 41
      },
      "300": {
        "ul3": 3.86999826877795,
        "ul2": 8.775444809393633,
        "ul1": 13.680891350009317,
        "mean": 18.586337890625,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 23.49178443124068,
        "ur2": 28.397230971856366,
        "ur3": 33.30267751247205,
        "standardDeviation": 4.905446540615683,
        "lowest": 4,
        "highest": 42
      },
      "400": {
        "ul3": 3.6178345748687732,
        "ul2": 8.266456773870848,
        "ul1": 12.915078972872923,
        "mean": 17.563701171875,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 22.212323370877076,
        "ur2": 26.86094556987915,
        "ur3": 31.509567768881226,
        "standardDeviation": 4.648622199002076,
        "lowest": 4,
        "highest": 43
      },
      "500": {
        "ul3": 3.4405842542817133,
        "ul2": 7.888621924729476,
        "ul1": 12.336659595177238,
        "mean": 16.784697265625,
        "median": 17,
        "mostFrequent": 17,
        "ur1": 21.23273493607276,
        "ur2": 25.68077260652052,
        "ur3": 30.128810276968284,
        "standardDeviation": 4.4480376704477615,
        "lowest": 4,
        "highest": 38
      },
      "750": {
        "ul3": 2.983509014704925,
        "ul2": 7.151662259803283,
        "ul1": 11.31981550490164,
        "mean": 15.48796875,
        "median": 15,
        "mostFrequent": 16,
        "ur1": 19.65612199509836,
        "ur2": 23.82427524019672,
        "ur3": 27.992428485295076,
        "standardDeviation": 4.168153245098359,
        "lowest": 4,
        "highest": 39
      },
      "1000": {
        "ul3": 2.7071935719879683,
        "ul2": 6.704831521950313,
        "ul1": 10.702469471912657,
        "mean": 14.700107421875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 18.697745371837343,
        "ur2": 22.69538332179969,
        "ur3": 26.69302127176203,
        "standardDeviation": 3.9976379499623436,
        "lowest": 4,
        "highest": 39
      },
      "2000": {
        "ul3": 1.937347785850827,
        "ul2": 5.634878992650552,
        "ul1": 9.332410199450276,
        "mean": 13.02994140625,
        "median": 13,
        "mostFrequent": 12,
        "ur1": 16.727472613049724,
        "ur2": 20.425003819849447,
        "ur3": 24.122535026649174,
        "standardDeviation": 3.697531206799724,
        "lowest": 4,
        "highest": 38
      },
      "5000": {
        "ul3": 0.569690073908875,
        "ul2": 4.184363695105916,
        "ul1": 7.799037316302957,
        "mean": 11.4137109375,
        "median": 11,
        "mostFrequent": 10,
        "ur1": 15.028384558697041,
        "ur2": 18.643058179894084,
        "ur3": 22.257731801091126,
        "standardDeviation": 3.6146736211970416,
        "lowest": 4,
        "highest": 41
      },
      "10000": {
        "ul3": -0.5537549548536571,
        "ul2": 3.1642935717642287,
        "ul1": 6.882342098382114,
        "mean": 10.600390625,
        "median": 10,
        "mostFrequent": 9,
        "ur1": 14.318439151617884,
        "ur2": 18.03648767823577,
        "ur3": 21.754536204853657,
        "standardDeviation": 3.7180485266178853,
        "lowest": 4,
        "highest": 42
      }
    }
  },
  {
    name: 'Schuine Streep (voorwaarts)',
    squares: [1, 7, 18, 24],
    odds: {
      "25": {
        "ul3": 6.829020381037168,
        "ul2": 15.174844967566447,
        "ul1": 23.520669554095726,
        "mean": 31.866494140625,
        "median": 32,
        "mostFrequent": 33,
        "ur1": 40.212318727154276,
        "ur2": 48.55814331368356,
        "ur3": 56.903967900212834,
        "standardDeviation": 8.345824586529277,
        "lowest": 4,
        "highest": 60
      },
      "50": {
        "ul3": 5.737664559900296,
        "ul2": 12.93621647743353,
        "ul1": 20.134768394966766,
        "mean": 27.3333203125,
        "median": 27,
        "mostFrequent": 28,
        "ur1": 34.53187223003324,
        "ur2": 41.73042414756647,
        "ur3": 48.928976065099704,
        "standardDeviation": 7.198551917533234,
        "lowest": 4,
        "highest": 55
      },
      "75": {
        "ul3": 5.179294282794796,
        "ul2": 11.794584860404864,
        "ul1": 18.409875438014932,
        "mean": 25.025166015625,
        "median": 25,
        "mostFrequent": 26,
        "ur1": 31.64045659323507,
        "ur2": 38.25574717084514,
        "ur3": 44.87103774845521,
        "standardDeviation": 6.615290577610068,
        "lowest": 4,
        "highest": 53
      },
      "100": {
        "ul3": 4.9494315478691995,
        "ul2": 11.122944599621134,
        "ul1": 17.296457651373068,
        "mean": 23.469970703125,
        "median": 24,
        "mostFrequent": 25,
        "ur1": 29.643483754876932,
        "ur2": 35.816996806628865,
        "ur3": 41.990509858380804,
        "standardDeviation": 6.173513051751933,
        "lowest": 4,
        "highest": 47
      },
      "125": {
        "ul3": 4.706558674857035,
        "ul2": 10.610899142613025,
        "ul1": 16.515239610369015,
        "mean": 22.419580078125,
        "median": 22,
        "mostFrequent": 23,
        "ur1": 28.323920545880988,
        "ur2": 34.22826101363698,
        "ur3": 40.13260148139297,
        "standardDeviation": 5.904340467755988,
        "lowest": 4,
        "highest": 45
      },
      "150": {
        "ul3": 4.5454791195577045,
        "ul2": 10.211396886996804,
        "ul1": 15.877314654435903,
        "mean": 21.543232421875,
        "median": 22,
        "mostFrequent": 21,
        "ur1": 27.209150189314098,
        "ur2": 32.8750679567532,
        "ur3": 38.540985724192296,
        "standardDeviation": 5.665917767439098,
        "lowest": 4,
        "highest": 46
      },
      "200": {
        "ul3": 4.221288481091264,
        "ul2": 9.571288674894175,
        "ul1": 14.921288868697086,
        "mean": 20.2712890625,
        "median": 20,
        "mostFrequent": 21,
        "ur1": 25.621289256302912,
        "ur2": 30.97128945010582,
        "ur3": 36.321289643908734,
        "standardDeviation": 5.350000193802912,
        "lowest": 4,
        "highest": 41
      },
      "250": {
        "ul3": 4.051357624019861,
        "ul2": 9.150563285804907,
        "ul1": 14.249768947589953,
        "mean": 19.348974609375,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 24.448180271160044,
        "ur2": 29.54738593294509,
        "ur3": 34.64659159473014,
        "standardDeviation": 5.099205661785046,
        "lowest": 4,
        "highest": 40
      },
      "300": {
        "ul3": 3.876909538494399,
        "ul2": 8.779160395454598,
        "ul1": 13.681411252414797,
        "mean": 18.583662109375,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 23.4859129663352,
        "ur2": 28.3881638232954,
        "ur3": 33.290414680255594,
        "standardDeviation": 4.9022508569602,
        "lowest": 4,
        "highest": 42
      },
      "400": {
        "ul3": 3.5705030242453013,
        "ul2": 8.229085349496868,
        "ul1": 12.887667674748435,
        "mean": 17.54625,
        "median": 17,
        "mostFrequent": 18,
        "ur1": 22.204832325251566,
        "ur2": 26.86341465050313,
        "ur3": 31.5219969757547,
        "standardDeviation": 4.658582325251566,
        "lowest": 4,
        "highest": 41
      },
      "500": {
        "ul3": 3.404796483345244,
        "ul2": 7.859906639938496,
        "ul1": 12.315016796531747,
        "mean": 16.770126953125,
        "median": 17,
        "mostFrequent": 17,
        "ur1": 21.225237109718254,
        "ur2": 25.680347266311507,
        "ur3": 30.135457422904757,
        "standardDeviation": 4.4551101565932525,
        "lowest": 4,
        "highest": 42
      },
      "750": {
        "ul3": 3.04038816936977,
        "ul2": 7.200607086871512,
        "ul1": 11.360826004373255,
        "mean": 15.521044921875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 19.681263839376744,
        "ur2": 23.841482756878484,
        "ur3": 28.00170167438023,
        "standardDeviation": 4.160218917501743,
        "lowest": 4,
        "highest": 41
      },
      "1000": {
        "ul3": 2.6921975118714947,
        "ul2": 6.697926596455996,
        "ul1": 10.703655681040498,
        "mean": 14.709384765625,
        "median": 15,
        "mostFrequent": 14,
        "ur1": 18.7151138502095,
        "ur2": 22.720842934794003,
        "ur3": 26.726572019378505,
        "standardDeviation": 4.005729084584502,
        "lowest": 4,
        "highest": 39
      },
      "2000": {
        "ul3": 1.9164984372940452,
        "ul2": 5.6232124998626976,
        "ul1": 9.32992656243135,
        "mean": 13.036640625,
        "median": 13,
        "mostFrequent": 13,
        "ur1": 16.74335468756865,
        "ur2": 20.450068750137305,
        "ur3": 24.156782812705956,
        "standardDeviation": 3.7067140625686514,
        "lowest": 4,
        "highest": 39
      },
      "5000": {
        "ul3": 0.5702179732122872,
        "ul2": 4.182537893599858,
        "ul1": 7.794857813987429,
        "mean": 11.407177734375,
        "median": 11,
        "mostFrequent": 10,
        "ur1": 15.019497654762572,
        "ur2": 18.631817575150144,
        "ur3": 22.244137495537714,
        "standardDeviation": 3.6123199203875713,
        "lowest": 4,
        "highest": 47
      },
      "10000": {
        "ul3": -0.5555920454493517,
        "ul2": 3.162606605117099,
        "ul1": 6.8808052556835495,
        "mean": 10.59900390625,
        "median": 10,
        "mostFrequent": 9,
        "ur1": 14.317202556816449,
        "ur2": 18.0354012073829,
        "ur3": 21.75359985794935,
        "standardDeviation": 3.71819865056645,
        "lowest": 4,
        "highest": 40
      }
    },
  },
  {
    name: 'Kleine Plus',
    squares: [8,12,13,17],
    odds: {
      "25": {
        "ul3": 6.055599889760543,
        "ul2": 14.91889602025703,
        "ul1": 23.782192150753517,
        "mean": 32.64548828125,
        "median": 33,
        "mostFrequent": 33,
        "ur1": 41.50878441174649,
        "ur2": 50.372080542242976,
        "ur3": 59.23537667273946,
        "standardDeviation": 8.863296130496487,
        "lowest": 4,
        "highest": 65
      },
      "50": {
        "ul3": 4.796232332469305,
        "ul2": 12.578292258104538,
        "ul1": 20.36035218373977,
        "mean": 28.142412109375,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 35.92447203501023,
        "ur2": 43.706531960645464,
        "ur3": 51.4885918862807,
        "standardDeviation": 7.782059925635232,
        "lowest": 4,
        "highest": 58
      },
      "75": {
        "ul3": 4.143113905610676,
        "ul2": 11.378771900615451,
        "ul1": 18.614429895620226,
        "mean": 25.850087890625,
        "median": 26,
        "mostFrequent": 26,
        "ur1": 33.08574588562978,
        "ur2": 40.32140388063455,
        "ur3": 47.55706187563933,
        "standardDeviation": 7.235657995004775,
        "lowest": 4,
        "highest": 56
      },
      "100": {
        "ul3": 3.7966569811918873,
        "ul2": 10.66024202391959,
        "ul1": 17.523827066647293,
        "mean": 24.387412109375,
        "median": 24,
        "mostFrequent": 23,
        "ur1": 31.250997152102705,
        "ur2": 38.114582194830405,
        "ur3": 44.97816723755811,
        "standardDeviation": 6.8635850427277045,
        "lowest": 4,
        "highest": 56
      },
      "125": {
        "ul3": 3.4125530600854432,
        "ul2": 10.03490190984863,
        "ul1": 16.657250759611816,
        "mean": 23.279599609375,
        "median": 23,
        "mostFrequent": 24,
        "ur1": 29.901948459138186,
        "ur2": 36.524297308901374,
        "ur3": 43.14664615866456,
        "standardDeviation": 6.622348849763186,
        "lowest": 4,
        "highest": 52
      },
      "150": {
        "ul3": 3.2060418065338077,
        "ul2": 9.600821490814205,
        "ul1": 15.995601175094603,
        "mean": 22.390380859375,
        "median": 22,
        "mostFrequent": 22,
        "ur1": 28.785160543655397,
        "ur2": 35.179940227935795,
        "ur3": 41.574719912216196,
        "standardDeviation": 6.3947796842803974,
        "lowest": 4,
        "highest": 49
      },
      "200": {
        "ul3": 2.9210947275496544,
        "ul2": 9.018259115241435,
        "ul1": 15.115423502933217,
        "mean": 21.212587890625,
        "median": 21,
        "mostFrequent": 20,
        "ur1": 27.30975227831678,
        "ur2": 33.40691666600856,
        "ur3": 39.50408105370035,
        "standardDeviation": 6.097164387691782,
        "lowest": 4,
        "highest": 51
      },
      "250": {
        "ul3": 2.591554706395403,
        "ul2": 8.516039726138601,
        "ul1": 14.4405247458818,
        "mean": 20.365009765625,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 26.2894947853682,
        "ur2": 32.2139798051114,
        "ur3": 38.138464824854594,
        "standardDeviation": 5.9244850197432,
        "lowest": 4,
        "highest": 47
      },
      "300": {
        "ul3": 2.3867287877053123,
        "ul2": 8.139397967845207,
        "ul1": 13.892067147985102,
        "mean": 19.644736328125,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 25.397405508264896,
        "ur2": 31.150074688404793,
        "ur3": 36.90274386854469,
        "standardDeviation": 5.752669180139896,
        "lowest": 4,
        "highest": 47
      },
      "400": {
        "ul3": 2.0585973781814317,
        "ul2": 7.576880673995953,
        "ul1": 13.095163969810475,
        "mean": 18.613447265625,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 24.13173056143952,
        "ur2": 29.650013857254045,
        "ur3": 35.168297153068565,
        "standardDeviation": 5.5182832958145225,
        "lowest": 4,
        "highest": 50
      },
      "500": {
        "ul3": 1.8064338891523057,
        "ul2": 7.153993035476539,
        "ul1": 12.50155218180077,
        "mean": 17.849111328125,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 23.196670474449235,
        "ur2": 28.544229620773464,
        "ur3": 33.891788767097694,
        "standardDeviation": 5.347559146324231,
        "lowest": 4,
        "highest": 45
      },
      "750": {
        "ul3": 1.3938283505163849,
        "ul2": 6.47386082742759,
        "ul1": 11.553893304338795,
        "mean": 16.63392578125,
        "median": 16,
        "mostFrequent": 15,
        "ur1": 21.713958258161206,
        "ur2": 26.79399073507241,
        "ur3": 31.874023211983612,
        "standardDeviation": 5.080032476911205,
        "lowest": 4,
        "highest": 44
      },
      "1000": {
        "ul3": 1.1185516459319285,
        "ul2": 6.034760342079618,
        "ul1": 10.95096903822731,
        "mean": 15.867177734375,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 20.78338643052269,
        "ur2": 25.69959512667038,
        "ur3": 30.615803822818073,
        "standardDeviation": 4.916208696147691,
        "lowest": 4,
        "highest": 44
      },
      "2000": {
        "ul3": 0.36020459248847914,
        "ul2": 4.99065397311732,
        "ul1": 9.621103353746161,
        "mean": 14.251552734375,
        "median": 14,
        "mostFrequent": 13,
        "ur1": 18.88200211500384,
        "ur2": 23.512451495632682,
        "ur3": 28.14290087626152,
        "standardDeviation": 4.63044938062884,
        "lowest": 4,
        "highest": 45
      },
      "5000": {
        "ul3": -0.8740164648287543,
        "ul2": 3.675545013030831,
        "ul1": 8.225106490890415,
        "mean": 12.77466796875,
        "median": 12,
        "mostFrequent": 11,
        "ur1": 17.324229446609586,
        "ur2": 21.87379092446917,
        "ur3": 26.423352402328753,
        "standardDeviation": 4.5495614778595845,
        "lowest": 4,
        "highest": 46
      },
      "10000": {
        "ul3": -1.8230486170015752,
        "ul2": 2.797254698040616,
        "ul1": 7.417558013082808,
        "mean": 12.037861328125,
        "median": 11,
        "mostFrequent": 10,
        "ur1": 16.658164643167193,
        "ur2": 21.278467958209383,
        "ur3": 25.898771273251576,
        "standardDeviation": 4.620303315042192,
        "lowest": 4,
        "highest": 43
      }
    },
  },
  {
    name: 'Kruishaar',
    squares: [3,11,14,22],
    odds: {
      "25": {
        "ul3": 6.067860642140555,
        "ul2": 14.89982701663537,
        "ul1": 23.731793391130186,
        "mean": 32.563759765625,
        "median": 33,
        "mostFrequent": 32,
        "ur1": 41.39572614011982,
        "ur2": 50.22769251461463,
        "ur3": 59.05965888910944,
        "standardDeviation": 8.831966374494815,
        "lowest": 4,
        "highest": 63
      },
      "50": {
        "ul3": 4.71022291995218,
        "ul2": 12.531265149759786,
        "ul1": 20.352307379567392,
        "mean": 28.173349609375,
        "median": 28,
        "mostFrequent": 29,
        "ur1": 35.994391839182605,
        "ur2": 43.815434068990214,
        "ur3": 51.63647629879782,
        "standardDeviation": 7.821042229807606,
        "lowest": 4,
        "highest": 58
      },
      "75": {
        "ul3": 4.097863391049774,
        "ul2": 11.352979890908182,
        "ul1": 18.60809639076659,
        "mean": 25.863212890625,
        "median": 26,
        "mostFrequent": 25,
        "ur1": 33.11832939048341,
        "ur2": 40.37344589034182,
        "ur3": 47.62856239020023,
        "standardDeviation": 7.255116499858409,
        "lowest": 4,
        "highest": 55
      },
      "100": {
        "ul3": 3.6695553778443823,
        "ul2": 10.566721814396255,
        "ul1": 17.463888250948127,
        "mean": 24.3610546875,
        "median": 24,
        "mostFrequent": 24,
        "ur1": 31.258221124051875,
        "ur2": 38.15538756060375,
        "ur3": 45.05255399715562,
        "standardDeviation": 6.897166436551873,
        "lowest": 4,
        "highest": 52
      },
      "125": {
        "ul3": 3.454830516450624,
        "ul2": 10.072946906800416,
        "ul1": 16.691063297150208,
        "mean": 23.3091796875,
        "median": 23,
        "mostFrequent": 24,
        "ur1": 29.92729607784979,
        "ur2": 36.54541246819958,
        "ur3": 43.16352885854937,
        "standardDeviation": 6.618116390349791,
        "lowest": 4,
        "highest": 54
      },
      "150": {
        "ul3": 3.24896825004404,
        "ul2": 9.66245018752936,
        "ul1": 16.07593212501468,
        "mean": 22.4894140625,
        "median": 22,
        "mostFrequent": 22,
        "ur1": 28.90289599998532,
        "ur2": 35.31637793747064,
        "ur3": 41.72985987495596,
        "standardDeviation": 6.41348193748532,
        "lowest": 4,
        "highest": 51
      },
      "200": {
        "ul3": 2.9351652230187604,
        "ul2": 9.042743612220839,
        "ul1": 15.15032200142292,
        "mean": 21.257900390625,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 27.36547877982708,
        "ur2": 33.47305716902916,
        "ur3": 39.580635558231236,
        "standardDeviation": 6.1075783892020805,
        "lowest": 4,
        "highest": 49
      },
      "250": {
        "ul3": 2.5926740292467443,
        "ul2": 8.503029430956163,
        "ul1": 14.413384832665582,
        "mean": 20.323740234375,
        "median": 20,
        "mostFrequent": 19,
        "ur1": 26.234095636084415,
        "ur2": 32.144451037793836,
        "ur3": 38.05480643950325,
        "standardDeviation": 5.910355401709418,
        "lowest": 4,
        "highest": 48
      },
      "300": {
        "ul3": 2.408383179614262,
        "ul2": 8.149950765576175,
        "ul1": 13.891518351538087,
        "mean": 19.6330859375,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 25.37465352346191,
        "ur2": 31.116221109423822,
        "ur3": 36.857788695385736,
        "standardDeviation": 5.741567585961912,
        "lowest": 4,
        "highest": 45
      },
      "400": {
        "ul3": 2.0707985042943733,
        "ul2": 7.569340929946248,
        "ul1": 13.067883355598124,
        "mean": 18.56642578125,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 24.064968206901877,
        "ur2": 29.56351063255375,
        "ur3": 35.06205305820563,
        "standardDeviation": 5.498542425651876,
        "lowest": 4,
        "highest": 47
      },
      "500": {
        "ul3": 1.8626750882803762,
        "ul2": 7.198485866145251,
        "ul1": 12.534296644010126,
        "mean": 17.870107421875,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 23.205918199739877,
        "ur2": 28.541728977604752,
        "ur3": 33.87753975546963,
        "standardDeviation": 5.335810777864875,
        "lowest": 4,
        "highest": 45
      },
      "750": {
        "ul3": 1.4362270056151623,
        "ul2": 6.506078420410107,
        "ul1": 11.575929835205052,
        "mean": 16.64578125,
        "median": 16,
        "mostFrequent": 16,
        "ur1": 21.715632664794946,
        "ur2": 26.785484079589892,
        "ur3": 31.855335494384835,
        "standardDeviation": 5.069851414794946,
        "lowest": 4,
        "highest": 45
      },
      "1000": {
        "ul3": 1.086321587114302,
        "ul2": 6.0050412143262015,
        "ul1": 10.923760841538101,
        "mean": 15.84248046875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 20.7612000959619,
        "ur2": 25.6799197231738,
        "ur3": 30.5986393503857,
        "standardDeviation": 4.9187196272119,
        "lowest": 4,
        "highest": 43
      },
      "2000": {
        "ul3": 0.35366397133883076,
        "ul2": 4.983656840267553,
        "ul1": 9.613649709196277,
        "mean": 14.243642578125,
        "median": 14,
        "mostFrequent": 13,
        "ur1": 18.873635447053722,
        "ur2": 23.503628315982446,
        "ur3": 28.13362118491117,
        "standardDeviation": 4.629992868928723,
        "lowest": 4,
        "highest": 48
      },
      "5000": {
        "ul3": -0.8964902066000011,
        "ul2": 3.6539121278916653,
        "ul1": 8.204314462383334,
        "mean": 12.754716796875,
        "median": 12,
        "mostFrequent": 11,
        "ur1": 17.305119131366666,
        "ur2": 21.855521465858335,
        "ur3": 26.405923800350003,
        "standardDeviation": 4.550402334491667,
        "lowest": 4,
        "highest": 48
      },
      "10000": {
        "ul3": -1.9335457584433104,
        "ul2": 2.7295567339544604,
        "ul1": 7.39265922635223,
        "mean": 12.05576171875,
        "median": 11,
        "mostFrequent": 10,
        "ur1": 16.71886421114777,
        "ur2": 21.38196670354554,
        "ur3": 26.045069195943313,
        "standardDeviation": 4.66310249239777,
        "lowest": 4,
        "highest": 44
      }
    }
  },
  {
    name: 'Hoeken',
    squares: [1, 5, 20, 24],
    odds: {
      "25": {
        "ul3": 5.573153204278409,
        "ul2": 14.792629479935606,
        "ul1": 24.012105755592803,
        "mean": 33.23158203125,
        "median": 33,
        "mostFrequent": 33,
        "ur1": 42.4510583069072,
        "ur2": 51.67053458256439,
        "ur3": 60.890010858221586,
        "standardDeviation": 9.219476275657197,
        "lowest": 4,
        "highest": 65
      },
      "50": {
        "ul3": 4.156404103397843,
        "ul2": 12.398865756431896,
        "ul1": 20.641327409465948,
        "mean": 28.8837890625,
        "median": 29,
        "mostFrequent": 27,
        "ur1": 37.126250715534056,
        "ur2": 45.368712368568104,
        "ur3": 53.61117402160215,
        "standardDeviation": 8.242461653034052,
        "lowest": 4,
        "highest": 61
      },
      "75": {
        "ul3": 3.4337043079927128,
        "ul2": 11.184038549078474,
        "ul1": 18.934372790164236,
        "mean": 26.68470703125,
        "median": 27,
        "mostFrequent": 26,
        "ur1": 34.43504127233576,
        "ur2": 42.185375513421526,
        "ur3": 49.935709754507286,
        "standardDeviation": 7.7503342410857625,
        "lowest": 4,
        "highest": 57
      },
      "100": {
        "ul3": 3.014645653489712,
        "ul2": 10.426876399201475,
        "ul1": 17.839107144913235,
        "mean": 25.251337890625,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 32.66356863633676,
        "ur2": 40.07579938204852,
        "ur3": 47.48803012776028,
        "standardDeviation": 7.412230745711762,
        "lowest": 4,
        "highest": 55
      },
      "125": {
        "ul3": 2.672802440734422,
        "ul2": 9.83448873653128,
        "ul1": 16.99617503232814,
        "mean": 24.157861328125,
        "median": 24,
        "mostFrequent": 23,
        "ur1": 31.319547623921864,
        "ur2": 38.48123391971872,
        "ur3": 45.64292021551558,
        "standardDeviation": 7.16168629579686,
        "lowest": 4,
        "highest": 54
      },
      "150": {
        "ul3": 2.4403833908577184,
        "ul2": 9.419637104321813,
        "ul1": 16.398890817785908,
        "mean": 23.37814453125,
        "median": 23,
        "mostFrequent": 22,
        "ur1": 30.357398244714094,
        "ur2": 37.33665195817819,
        "ur3": 44.315905671642284,
        "standardDeviation": 6.979253713464094,
        "lowest": 4,
        "highest": 54
      },
      "200": {
        "ul3": 2.038391927105671,
        "ul2": 8.749435763903781,
        "ul1": 15.460479600701891,
        "mean": 22.1715234375,
        "median": 22,
        "mostFrequent": 21,
        "ur1": 28.882567274298108,
        "ur2": 35.593611111096216,
        "ur3": 42.30465494789433,
        "standardDeviation": 6.711043836798109,
        "lowest": 4,
        "highest": 51
      },
      "250": {
        "ul3": 1.8004667498663771,
        "ul2": 8.287068979077585,
        "ul1": 14.773671208288793,
        "mean": 21.2602734375,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 27.746875666711208,
        "ur2": 34.23347789592242,
        "ur3": 40.72008012513362,
        "standardDeviation": 6.486602229211208,
        "lowest": 4,
        "highest": 51
      },
      "300": {
        "ul3": 1.5576921046437349,
        "ul2": 7.905219215595823,
        "ul1": 14.25274632654791,
        "mean": 20.6002734375,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 26.94780054845209,
        "ur2": 33.29532765940418,
        "ur3": 39.642854770356266,
        "standardDeviation": 6.347527110952089,
        "lowest": 4,
        "highest": 49
      },
      "400": {
        "ul3": 1.3367059498453209,
        "ul2": 7.4195064405218805,
        "ul1": 13.50230693119844,
        "mean": 19.585107421875,
        "median": 19,
        "mostFrequent": 18,
        "ur1": 25.667907912551563,
        "ur2": 31.750708403228124,
        "ur3": 37.83350889390468,
        "standardDeviation": 6.0828004906765605,
        "lowest": 4,
        "highest": 51
      },
      "500": {
        "ul3": 1.1115473360457457,
        "ul2": 7.0397522604888305,
        "ul1": 12.967957184931915,
        "mean": 18.896162109375,
        "median": 19,
        "mostFrequent": 17,
        "ur1": 24.82436703381808,
        "ur2": 30.752571958261164,
        "ur3": 36.68077688270425,
        "standardDeviation": 5.928204924443084,
        "lowest": 4,
        "highest": 44
      },
      "750": {
        "ul3": 0.6745821982461919,
        "ul2": 6.346684356122463,
        "ul1": 12.018786513998732,
        "mean": 17.690888671875,
        "median": 17,
        "mostFrequent": 16,
        "ur1": 23.36299082975127,
        "ur2": 29.03509298762754,
        "ur3": 34.70719514550381,
        "standardDeviation": 5.672102157876269,
        "lowest": 4,
        "highest": 45
      },
      "1000": {
        "ul3": 0.3629758456919383,
        "ul2": 5.88720850650296,
        "ul1": 11.411441167313981,
        "mean": 16.935673828125,
        "median": 16,
        "mostFrequent": 15,
        "ur1": 22.45990648893602,
        "ur2": 27.984139149747044,
        "ur3": 33.50837181055806,
        "standardDeviation": 5.524232660811021,
        "lowest": 4,
        "highest": 44
      },
      "2000": {
        "ul3": -0.2689194814477709,
        "ul2": 4.957819955076486,
        "ul1": 10.184559391600743,
        "mean": 15.411298828125,
        "median": 15,
        "mostFrequent": 14,
        "ur1": 20.638038264649257,
        "ur2": 25.864777701173516,
        "ur3": 31.09151713769777,
        "standardDeviation": 5.226739436524257,
        "lowest": 4,
        "highest": 44
      },
      "5000": {
        "ul3": -1.2577667981187854,
        "ul2": 3.8247342439624763,
        "ul1": 8.907235286043738,
        "mean": 13.989736328125,
        "median": 13,
        "mostFrequent": 12,
        "ur1": 19.07223737020626,
        "ur2": 24.15473841228752,
        "ur3": 29.237239454368783,
        "standardDeviation": 5.082501042081262,
        "lowest": 4,
        "highest": 44
      },
      "10000": {
        "ul3": -1.9386388483886687,
        "ul2": 3.1585441531575533,
        "ul1": 8.255727154703777,
        "mean": 13.35291015625,
        "median": 13,
        "mostFrequent": 11,
        "ur1": 18.45009315779622,
        "ur2": 23.547276159342445,
        "ur3": 28.644459160888665,
        "standardDeviation": 5.097183001546223,
        "lowest": 4,
        "highest": 43
      }
    },
  },
  {
    name: 'Kleine Hoeken',
    squares: [7, 9, 16, 18],
    odds: {
      "25": {
        "ul3": 5.510687419615056,
        "ul2": 14.72901296724337,
        "ul1": 23.947338514871685,
        "mean": 33.1656640625,
        "median": 33,
        "mostFrequent": 32,
        "ur1": 42.38398961012831,
        "ur2": 51.60231515775663,
        "ur3": 60.820640705384946,
        "standardDeviation": 9.218325547628314,
        "lowest": 4,
        "highest": 67
      },
      "50": {
        "ul3": 4.147375796426019,
        "ul2": 12.387160036159013,
        "ul1": 20.626944275892008,
        "mean": 28.866728515625,
        "median": 29,
        "mostFrequent": 29,
        "ur1": 37.10651275535799,
        "ur2": 45.34629699509098,
        "ur3": 53.58608123482398,
        "standardDeviation": 8.239784239732993,
        "lowest": 4,
        "highest": 61
      },
      "75": {
        "ul3": 3.3914410074604078,
        "ul2": 11.12693397893194,
        "ul1": 18.86242695040347,
        "mean": 26.597919921875,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 34.333412893346534,
        "ur2": 42.06890586481806,
        "ur3": 49.80439883628959,
        "standardDeviation": 7.735492971471531,
        "lowest": 4,
        "highest": 57
      },
      "100": {
        "ul3": 3.0490402899572473,
        "ul2": 10.431144047471498,
        "ul1": 17.81324780498575,
        "mean": 25.1953515625,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 32.57745532001425,
        "ur2": 39.9595590775285,
        "ur3": 47.341662835042754,
        "standardDeviation": 7.382103757514251,
        "lowest": 4,
        "highest": 55
      },
      "125": {
        "ul3": 2.6027821716331765,
        "ul2": 9.792463505047117,
        "ul1": 16.98214483846106,
        "mean": 24.171826171875,
        "median": 24,
        "mostFrequent": 24,
        "ur1": 31.36150750528894,
        "ur2": 38.551188838702885,
        "ur3": 45.74087017211683,
        "standardDeviation": 7.189681333413942,
        "lowest": 4,
        "highest": 54
      },
      "150": {
        "ul3": 2.482520703843079,
        "ul2": 9.437093880687053,
        "ul1": 16.391667057531027,
        "mean": 23.346240234375,
        "median": 23,
        "mostFrequent": 22,
        "ur1": 30.30081341121897,
        "ur2": 37.25538658806295,
        "ur3": 44.20995976490692,
        "standardDeviation": 6.954573176843973,
        "lowest": 4,
        "highest": 53
      },
      "200": {
        "ul3": 2.0981122218139774,
        "ul2": 8.766593043709317,
        "ul1": 15.435073865604659,
        "mean": 22.1035546875,
        "median": 22,
        "mostFrequent": 22,
        "ur1": 28.772035509395344,
        "ur2": 35.44051633129068,
        "ur3": 42.108997153186024,
        "standardDeviation": 6.668480821895342,
        "lowest": 4,
        "highest": 50
      },
      "250": {
        "ul3": 1.7823990045695162,
        "ul2": 8.27110454471301,
        "ul1": 14.759810084856504,
        "mean": 21.248515625,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 27.737221165143495,
        "ur2": 34.22592670528699,
        "ur3": 40.71463224543048,
        "standardDeviation": 6.488705540143495,
        "lowest": 4,
        "highest": 52
      },
      "300": {
        "ul3": 1.5445497771500456,
        "ul2": 7.891164695183363,
        "ul1": 14.23777961321668,
        "mean": 20.58439453125,
        "median": 20,
        "mostFrequent": 19,
        "ur1": 26.931009449283316,
        "ur2": 33.27762436731663,
        "ur3": 39.62423928534995,
        "standardDeviation": 6.346614918033318,
        "lowest": 4,
        "highest": 49
      },
      "400": {
        "ul3": 1.3205383721368484,
        "ul2": 7.408975451216232,
        "ul1": 13.497412530295616,
        "mean": 19.585849609375,
        "median": 19,
        "mostFrequent": 19,
        "ur1": 25.674286688454384,
        "ur2": 31.762723767533767,
        "ur3": 37.851160846613155,
        "standardDeviation": 6.088437079079384,
        "lowest": 4,
        "highest": 47
      },
      "500": {
        "ul3": 1.0643292810708402,
        "ul2": 6.985952593630559,
        "ul1": 12.907575906190278,
        "mean": 18.82919921875,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 24.75082253130972,
        "ur2": 30.67244584386944,
        "ur3": 36.59406915642916,
        "standardDeviation": 5.92162331255972,
        "lowest": 4,
        "highest": 47
      },
      "750": {
        "ul3": 0.6809068697777256,
        "ul2": 6.347066168393484,
        "ul1": 12.013225467009242,
        "mean": 17.679384765625,
        "median": 17,
        "mostFrequent": 17,
        "ur1": 23.345544064240755,
        "ur2": 29.011703362856515,
        "ur3": 34.677862661472275,
        "standardDeviation": 5.666159298615757,
        "lowest": 4,
        "highest": 46
      },
      "1000": {
        "ul3": 0.4239023620443163,
        "ul2": 5.938174491362878,
        "ul1": 11.452446620681439,
        "mean": 16.96671875,
        "median": 17,
        "mostFrequent": 16,
        "ur1": 22.480990879318558,
        "ur2": 27.995263008637117,
        "ur3": 33.50953513795568,
        "standardDeviation": 5.51427212931856,
        "lowest": 4,
        "highest": 44
      },
      "2000": {
        "ul3": -0.275460038394149,
        "ul2": 4.951594349403901,
        "ul1": 10.17864873720195,
        "mean": 15.405703125,
        "median": 15,
        "mostFrequent": 14,
        "ur1": 20.63275751279805,
        "ur2": 25.8598119005961,
        "ur3": 31.08686628839415,
        "standardDeviation": 5.2270543877980495,
        "lowest": 4,
        "highest": 48
      },
      "5000": {
        "ul3": -1.314104335280117,
        "ul2": 3.7909291410632555,
        "ul1": 8.895962617406628,
        "mean": 14.00099609375,
        "median": 13,
        "mostFrequent": 12,
        "ur1": 19.106029570093373,
        "ur2": 24.211063046436745,
        "ur3": 29.316096522780118,
        "standardDeviation": 5.1050334763433725,
        "lowest": 4,
        "highest": 46
      },
      "10000": {
        "ul3": -1.9370199059525604,
        "ul2": 3.151443109573293,
        "ul1": 8.239906125099147,
        "mean": 13.328369140625,
        "median": 13,
        "mostFrequent": 11,
        "ur1": 18.416832156150853,
        "ur2": 23.505295171676707,
        "ur3": 28.59375818720256,
        "standardDeviation": 5.0884630155258535,
        "lowest": 4,
        "highest": 44
      }
    },
  },
  {
    name: 'Rechthoek Hoeken (liggend)',
    squares: [6, 10, 15, 19],
    odds: {
      "25": {
        "ul3": 5.545044059294895,
        "ul2": 14.773841221821595,
        "ul1": 24.002638384348295,
        "mean": 33.231435546875,
        "median": 33,
        "mostFrequent": 34,
        "ur1": 42.4602327094017,
        "ur2": 51.6890298719284,
        "ur3": 60.9178270344551,
        "standardDeviation": 9.228797162526702,
        "lowest": 5,
        "highest": 67
      },
      "50": {
        "ul3": 4.175210116237253,
        "ul2": 12.419521587908168,
        "ul1": 20.663833059579083,
        "mean": 28.90814453125,
        "median": 29,
        "mostFrequent": 28,
        "ur1": 37.152456002920914,
        "ur2": 45.396767474591826,
        "ur3": 53.641078946262745,
        "standardDeviation": 8.244311471670915,
        "lowest": 4,
        "highest": 62
      },
      "75": {
        "ul3": 3.37619148031483,
        "ul2": 11.15817062229322,
        "ul1": 18.94014976427161,
        "mean": 26.72212890625,
        "median": 27,
        "mostFrequent": 26,
        "ur1": 34.50410804822839,
        "ur2": 42.28608719020678,
        "ur3": 50.06806633218517,
        "standardDeviation": 7.78197914197839,
        "lowest": 4,
        "highest": 58
      },
      "100": {
        "ul3": 2.97689885516251,
        "ul2": 10.396783481566674,
        "ul1": 17.816668107970838,
        "mean": 25.236552734375,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 32.656437360779165,
        "ur2": 40.07632198718333,
        "ur3": 47.49620661358749,
        "standardDeviation": 7.419884626404163,
        "lowest": 4,
        "highest": 57
      },
      "125": {
        "ul3": 2.685597136031273,
        "ul2": 9.841576476104182,
        "ul1": 16.99755581617709,
        "mean": 24.15353515625,
        "median": 24,
        "mostFrequent": 24,
        "ur1": 31.30951449632291,
        "ur2": 38.46549383639582,
        "ur3": 45.621473176468726,
        "standardDeviation": 7.15597934007291,
        "lowest": 4,
        "highest": 53
      },
      "150": {
        "ul3": 2.418909393503096,
        "ul2": 9.39199428316873,
        "ul1": 16.365079172834363,
        "mean": 23.3381640625,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 30.311248952165634,
        "ur2": 37.28433384183127,
        "ur3": 44.2574187314969,
        "standardDeviation": 6.973084889665635,
        "lowest": 4,
        "highest": 52
      },
      "200": {
        "ul3": 2.131949798195304,
        "ul2": 8.80446718317187,
        "ul1": 15.476984568148435,
        "mean": 22.149501953125,
        "median": 22,
        "mostFrequent": 22,
        "ur1": 28.822019338101565,
        "ur2": 35.49453672307813,
        "ur3": 42.1670541080547,
        "standardDeviation": 6.672517384976565,
        "lowest": 4,
        "highest": 53
      },
      "250": {
        "ul3": 1.831866274968725,
        "ul2": 8.305573610395816,
        "ul1": 14.779280945822908,
        "mean": 21.25298828125,
        "median": 21,
        "mostFrequent": 20,
        "ur1": 27.726695616677095,
        "ur2": 34.200402952104184,
        "ur3": 40.67411028753128,
        "standardDeviation": 6.473707335427092,
        "lowest": 4,
        "highest": 50
      },
      "300": {
        "ul3": 1.5691797240855507,
        "ul2": 7.9276692952237,
        "ul1": 14.286158866361848,
        "mean": 20.6446484375,
        "median": 20,
        "mostFrequent": 19,
        "ur1": 27.00313800863815,
        "ur2": 33.3616275797763,
        "ur3": 39.72011715091445,
        "standardDeviation": 6.35848957113815,
        "lowest": 4,
        "highest": 48
      },
      "400": {
        "ul3": 1.2770839728010124,
        "ul2": 7.3795891849923425,
        "ul1": 13.482094397183673,
        "mean": 19.584599609375,
        "median": 19,
        "mostFrequent": 20,
        "ur1": 25.68710482156633,
        "ur2": 31.78961003375766,
        "ur3": 37.892115245948986,
        "standardDeviation": 6.102505212191329,
        "lowest": 4,
        "highest": 48
      },
      "500": {
        "ul3": 1.0790930468837736,
        "ul2": 7.014076354172516,
        "ul1": 12.949059661461257,
        "mean": 18.88404296875,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 24.81902627603874,
        "ur2": 30.75400958332748,
        "ur3": 36.68899289061622,
        "standardDeviation": 5.934983307288741,
        "lowest": 4,
        "highest": 47
      },
      "750": {
        "ul3": 0.6536979508285974,
        "ul2": 6.319076628677399,
        "ul1": 11.9844553065262,
        "mean": 17.649833984375,
        "median": 17,
        "mostFrequent": 16,
        "ur1": 23.3152126622238,
        "ur2": 28.9805913400726,
        "ur3": 34.6459700179214,
        "standardDeviation": 5.665378677848801,
        "lowest": 4,
        "highest": 48
      },
      "1000": {
        "ul3": 0.3817329240366689,
        "ul2": 5.8907744233161115,
        "ul1": 11.399815922595554,
        "mean": 16.908857421875,
        "median": 16,
        "mostFrequent": 15,
        "ur1": 22.417898921154443,
        "ur2": 27.926940420433887,
        "ur3": 33.43598191971333,
        "standardDeviation": 5.509041499279443,
        "lowest": 4,
        "highest": 45
      },
      "2000": {
        "ul3": -0.279465331643701,
        "ul2": 4.944913737237533,
        "ul1": 10.169292806118767,
        "mean": 15.393671875,
        "median": 15,
        "mostFrequent": 13,
        "ur1": 20.618050943881236,
        "ur2": 25.84243001276247,
        "ur3": 31.066809081643704,
        "standardDeviation": 5.224379068881234,
        "lowest": 4,
        "highest": 43
      },
      "5000": {
        "ul3": -1.251331801081875,
        "ul2": 3.8240372628204167,
        "ul1": 8.899406326722708,
        "mean": 13.974775390625,
        "median": 13,
        "mostFrequent": 11,
        "ur1": 19.05014445452729,
        "ur2": 24.125513518429585,
        "ur3": 29.200882582331875,
        "standardDeviation": 5.075369063902292,
        "lowest": 4,
        "highest": 43
      },
      "10000": {
        "ul3": -1.9611163912985035,
        "ul2": 3.137826702675998,
        "ul1": 8.2367697966505,
        "mean": 13.335712890625,
        "median": 13,
        "mostFrequent": 11,
        "ur1": 18.4346559845995,
        "ur2": 23.533599078574003,
        "ur3": 28.632542172548504,
        "standardDeviation": 5.098943093974501,
        "lowest": 4,
        "highest": 45
      }
    },
  },
  {
    name: 'Rechthoek Hoeken (staand)',
    squares: [2, 4, 21, 23],
    odds: {
      "25": {
        "ul3": 5.367816351461755,
        "ul2": 14.672076135349503,
        "ul1": 23.97633591923725,
        "mean": 33.280595703125,
        "median": 33,
        "mostFrequent": 32,
        "ur1": 42.58485548701275,
        "ur2": 51.889115270900504,
        "ur3": 61.19337505478825,
        "standardDeviation": 9.30425978388775,
        "lowest": 4,
        "highest": 64
      },
      "50": {
        "ul3": 4.143602966701874,
        "ul2": 12.41403283717625,
        "ul1": 20.684462707650624,
        "mean": 28.954892578125,
        "median": 29,
        "mostFrequent": 29,
        "ur1": 37.22532244859938,
        "ur2": 45.49575231907375,
        "ur3": 53.76618218954813,
        "standardDeviation": 8.270429870474375,
        "lowest": 4,
        "highest": 60
      },
      "75": {
        "ul3": 3.4923631315559724,
        "ul2": 11.210484926245648,
        "ul1": 18.928606720935324,
        "mean": 26.646728515625,
        "median": 26,
        "mostFrequent": 26,
        "ur1": 34.36485031031468,
        "ur2": 42.08297210500435,
        "ur3": 49.801093899694024,
        "standardDeviation": 7.718121794689676,
        "lowest": 4,
        "highest": 59
      },
      "100": {
        "ul3": 3.073777690869882,
        "ul2": 10.44724502307992,
        "ul1": 17.82071235528996,
        "mean": 25.1941796875,
        "median": 25,
        "mostFrequent": 24,
        "ur1": 32.56764701971004,
        "ur2": 39.94111435192008,
        "ur3": 47.31458168413012,
        "standardDeviation": 7.37346733221004,
        "lowest": 4,
        "highest": 54
      },
      "125": {
        "ul3": 2.7243855873968137,
        "ul2": 9.871777891597876,
        "ul1": 17.01917019579894,
        "mean": 24.1665625,
        "median": 24,
        "mostFrequent": 24,
        "ur1": 31.313954804201064,
        "ur2": 38.46134710840212,
        "ur3": 45.608739412603185,
        "standardDeviation": 7.1473923042010625,
        "lowest": 4,
        "highest": 56
      },
      "150": {
        "ul3": 2.4344965772012017,
        "ul2": 9.400979488967469,
        "ul1": 16.367462400733736,
        "mean": 23.3339453125,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 30.300428224266263,
        "ur2": 37.26691113603253,
        "ur3": 44.2333940477988,
        "standardDeviation": 6.966482911766265,
        "lowest": 4,
        "highest": 53
      },
      "200": {
        "ul3": 2.2077610248465334,
        "ul2": 8.862175969689357,
        "ul1": 15.516590914532179,
        "mean": 22.171005859375,
        "median": 22,
        "mostFrequent": 20,
        "ur1": 28.82542080421782,
        "ur2": 35.47983574906064,
        "ur3": 42.13425069390347,
        "standardDeviation": 6.654414944842822,
        "lowest": 4,
        "highest": 50
      },
      "250": {
        "ul3": 1.763443838541825,
        "ul2": 8.257442376736218,
        "ul1": 14.75144091493061,
        "mean": 21.245439453125,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 27.73943799131939,
        "ur2": 34.23343652951378,
        "ur3": 40.72743506770818,
        "standardDeviation": 6.493998538194392,
        "lowest": 4,
        "highest": 51
      },
      "300": {
        "ul3": 1.6010786289258938,
        "ul2": 7.934426768242263,
        "ul1": 14.267774907558632,
        "mean": 20.601123046875,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 26.934471186191367,
        "ur2": 33.26781932550774,
        "ur3": 39.6011674648241,
        "standardDeviation": 6.333348139316368,
        "lowest": 4,
        "highest": 48
      },
      "400": {
        "ul3": 1.3163031283008806,
        "ul2": 7.418717059492254,
        "ul1": 13.521130990683627,
        "mean": 19.623544921875,
        "median": 19,
        "mostFrequent": 18,
        "ur1": 25.72595885306637,
        "ur2": 31.828372784257745,
        "ur3": 37.930786715449116,
        "standardDeviation": 6.102413931191372,
        "lowest": 4,
        "highest": 48
      },
      "500": {
        "ul3": 1.131909593203872,
        "ul2": 7.042425405885915,
        "ul1": 12.952941218567958,
        "mean": 18.86345703125,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 24.773972843932043,
        "ur2": 30.684488656614086,
        "ur3": 36.59500446929613,
        "standardDeviation": 5.910515812682043,
        "lowest": 4,
        "highest": 47
      },
      "750": {
        "ul3": 0.6573153640367764,
        "ul2": 6.333906857274519,
        "ul1": 12.010498350512261,
        "mean": 17.68708984375,
        "median": 17,
        "mostFrequent": 17,
        "ur1": 23.363681336987742,
        "ur2": 29.040272830225483,
        "ur3": 34.71686432346323,
        "standardDeviation": 5.6765914932377415,
        "lowest": 4,
        "highest": 46
      },
      "1000": {
        "ul3": 0.36913495166505683,
        "ul2": 5.885227337568372,
        "ul1": 11.401319723471687,
        "mean": 16.917412109375,
        "median": 16,
        "mostFrequent": 16,
        "ur1": 22.433504495278314,
        "ur2": 27.949596881181627,
        "ur3": 33.46568926708494,
        "standardDeviation": 5.516092385903314,
        "lowest": 4,
        "highest": 46
      },
      "2000": {
        "ul3": -0.33160956162987176,
        "ul2": 4.908585162038419,
        "ul1": 10.148779885706709,
        "mean": 15.388974609375,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 20.62916933304329,
        "ur2": 25.869364056711582,
        "ur3": 31.10955878037987,
        "standardDeviation": 5.24019472366829,
        "lowest": 4,
        "highest": 50
      },
      "5000": {
        "ul3": -1.3441547465608927,
        "ul2": 3.759290715834405,
        "ul1": 8.862736178229703,
        "mean": 13.966181640625,
        "median": 13,
        "mostFrequent": 12,
        "ur1": 19.0696271030203,
        "ur2": 24.173072565415595,
        "ur3": 29.276518027810894,
        "standardDeviation": 5.103445462395298,
        "lowest": 4,
        "highest": 48
      },
      "10000": {
        "ul3": -1.9296078419374076,
        "ul2": 3.1568564907917285,
        "ul1": 8.243320823520865,
        "mean": 13.32978515625,
        "median": 13,
        "mostFrequent": 11,
        "ur1": 18.416249488979137,
        "ur2": 23.502713821708273,
        "ur3": 28.58917815443741,
        "standardDeviation": 5.086464332729136,
        "lowest": 4,
        "highest": 42
      }
    },
  },
  {
    name: 'Bovenste Rij',
    squares: [1, 2, 3, 4, 5],
    odds: {
      "25": {
        "ul3": 13.950994292509574,
        "ul2": 21.813546976256383,
        "ul1": 29.676099660003192,
        "mean": 37.53865234375,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 45.401205027496815,
        "ur2": 53.26375771124362,
        "ur3": 61.126310394990426,
        "standardDeviation": 7.862552683746809,
        "lowest": 6,
        "highest": 63
      },
      "50": {
        "ul3": 12.252447162479388,
        "ul2": 19.212891207277927,
        "ul1": 26.173335252076463,
        "mean": 33.133779296875,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 40.09422334167353,
        "ur2": 47.05466738647207,
        "ur3": 54.01511143127061,
        "standardDeviation": 6.960444044798536,
        "lowest": 7,
        "highest": 56
      },
      "75": {
        "ul3": 11.430780732497233,
        "ul2": 17.881858378956487,
        "ul1": 24.332936025415744,
        "mean": 30.784013671875,
        "median": 31,
        "mostFrequent": 32,
        "ur1": 37.235091318334256,
        "ur2": 43.68616896479352,
        "ur3": 50.13724661125277,
        "standardDeviation": 6.4510776464592565,
        "lowest": 6,
        "highest": 55
      },
      "100": {
        "ul3": 10.866209286250676,
        "ul2": 16.996974810625453,
        "ul1": 23.127740335000226,
        "mean": 29.258505859375,
        "median": 30,
        "mostFrequent": 30,
        "ur1": 35.38927138374977,
        "ur2": 41.520036908124546,
        "ur3": 47.65080243249932,
        "standardDeviation": 6.130765524374774,
        "lowest": 6,
        "highest": 50
      },
      "125": {
        "ul3": 10.495037421157523,
        "ul2": 16.35596570264668,
        "ul1": 22.21689398413584,
        "mean": 28.077822265625,
        "median": 28,
        "mostFrequent": 29,
        "ur1": 33.938750547114154,
        "ur2": 39.79967882860332,
        "ur3": 45.66060711009247,
        "standardDeviation": 5.860928281489159,
        "lowest": 6,
        "highest": 47
      },
      "150": {
        "ul3": 10.236862552291264,
        "ul2": 15.901850425485842,
        "ul1": 21.56683829868042,
        "mean": 27.231826171875,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 32.896814045069576,
        "ur2": 38.56180191826416,
        "ur3": 44.226789791458735,
        "standardDeviation": 5.664987873194579,
        "lowest": 5,
        "highest": 46
      },
      "200": {
        "ul3": 9.802771982542854,
        "ul2": 15.153088222736903,
        "ul1": 20.503404462930952,
        "mean": 25.853720703125,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 31.204036943319046,
        "ur2": 36.55435318351309,
        "ur3": 41.90466942370715,
        "standardDeviation": 5.350316240194048,
        "lowest": 5,
        "highest": 45
      },
      "250": {
        "ul3": 9.499714456459104,
        "ul2": 14.619285549097736,
        "ul1": 19.73885664173637,
        "mean": 24.858427734375,
        "median": 25,
        "mostFrequent": 26,
        "ur1": 29.97799882701363,
        "ur2": 35.09756991965226,
        "ur3": 40.217141012290895,
        "standardDeviation": 5.1195710926386315,
        "lowest": 5,
        "highest": 44
      },
      "300": {
        "ul3": 9.173529227557257,
        "ul2": 14.141649693371503,
        "ul1": 19.10977015918575,
        "mean": 24.077890625,
        "median": 24,
        "mostFrequent": 25,
        "ur1": 29.046011090814247,
        "ur2": 34.01413155662849,
        "ur3": 38.98225202244274,
        "standardDeviation": 4.9681204658142475,
        "lowest": 6,
        "highest": 44
      },
      "400": {
        "ul3": 8.798784367458227,
        "ul2": 13.487285281430484,
        "ul1": 18.175786195402743,
        "mean": 22.864287109375,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 27.552788023347254,
        "ur2": 32.24128893731951,
        "ur3": 36.92978985129177,
        "standardDeviation": 4.688500913972257,
        "lowest": 5,
        "highest": 41
      },
      "500": {
        "ul3": 8.522027485443331,
        "ul2": 13.02703525071222,
        "ul1": 17.53204301598111,
        "mean": 22.03705078125,
        "median": 22,
        "mostFrequent": 23,
        "ur1": 26.542058546518888,
        "ur2": 31.047066311787777,
        "ur3": 35.552074077056666,
        "standardDeviation": 4.505007765268889,
        "lowest": 5,
        "highest": 42
      },
      "750": {
        "ul3": 7.927743407844758,
        "ul2": 12.131474120854838,
        "ul1": 16.335204833864918,
        "mean": 20.538935546875,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 24.74266625988508,
        "ur2": 28.94639697289516,
        "ur3": 33.15012768590524,
        "standardDeviation": 4.2037307130100805,
        "lowest": 5,
        "highest": 41
      },
      "1000": {
        "ul3": 7.557255136672797,
        "ul2": 11.564664231740197,
        "ul1": 15.572073326807597,
        "mean": 19.579482421875,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 23.5868915169424,
        "ur2": 27.594300612009803,
        "ur3": 31.6017097070772,
        "standardDeviation": 4.007409095067401,
        "lowest": 5,
        "highest": 42
      },
      "2000": {
        "ul3": 6.722257561614944,
        "ul2": 10.319418452534961,
        "ul1": 13.91657934345498,
        "mean": 17.513740234375,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 21.11090112529502,
        "ur2": 24.708062016215038,
        "ur3": 28.305222907135054,
        "standardDeviation": 3.597160890920019,
        "lowest": 5,
        "highest": 44
      },
      "5000": {
        "ul3": 5.4291648242513535,
        "ul2": 8.69063462241757,
        "ul1": 11.952104420583785,
        "mean": 15.21357421875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 18.475044016916215,
        "ur2": 21.736513815082432,
        "ur3": 24.99798361324865,
        "standardDeviation": 3.2614697981662157,
        "lowest": 5,
        "highest": 44
      },
      "10000": {
        "ul3": 4.405062512680292,
        "ul2": 7.548365242828528,
        "ul1": 10.691667972976765,
        "mean": 13.834970703125,
        "median": 14,
        "mostFrequent": 14,
        "ur1": 16.978273433273237,
        "ur2": 20.121576163421473,
        "ur3": 23.26487889356971,
        "standardDeviation": 3.143302730148236,
        "lowest": 5,
        "highest": 39
      }
    },
  },
  {
    name: '2de Rij',
    squares: [6,7,8,9,10],
    odds: {
      "25": {
        "ul3": 13.915140702954258,
        "ul2": 21.811493541552835,
        "ul1": 29.707846380151416,
        "mean": 37.60419921875,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 45.50055205734858,
        "ur2": 53.39690489594716,
        "ur3": 61.29325773454573,
        "standardDeviation": 7.89635283859858,
        "lowest": 7,
        "highest": 62
      },
      "50": {
        "ul3": 12.292942576331306,
        "ul2": 19.25148645713754,
        "ul1": 26.21003033794377,
        "mean": 33.16857421875,
        "median": 34,
        "mostFrequent": 34,
        "ur1": 40.127118099556235,
        "ur2": 47.08566198036247,
        "ur3": 54.0442058611687,
        "standardDeviation": 6.958543880806232,
        "lowest": 5,
        "highest": 57
      },
      "75": {
        "ul3": 11.442294496039494,
        "ul2": 17.904687216109664,
        "ul1": 24.36707993617983,
        "mean": 30.82947265625,
        "median": 31,
        "mostFrequent": 33,
        "ur1": 37.29186537632017,
        "ur2": 43.75425809639034,
        "ur3": 50.21665081646051,
        "standardDeviation": 6.462392720070169,
        "lowest": 5,
        "highest": 53
      },
      "100": {
        "ul3": 10.958204527821696,
        "ul2": 17.068526325839464,
        "ul1": 23.178848123857232,
        "mean": 29.289169921875,
        "median": 30,
        "mostFrequent": 31,
        "ur1": 35.399491719892765,
        "ur2": 41.50981351791054,
        "ur3": 47.62013531592831,
        "standardDeviation": 6.110321798017768,
        "lowest": 5,
        "highest": 49
      },
      "125": {
        "ul3": 10.495462453988292,
        "ul2": 16.35460517765886,
        "ul1": 22.21374790132943,
        "mean": 28.072890625,
        "median": 28,
        "mostFrequent": 29,
        "ur1": 33.93203334867057,
        "ur2": 39.79117607234114,
        "ur3": 45.6503187960117,
        "standardDeviation": 5.859142723670569,
        "lowest": 5,
        "highest": 48
      },
      "150": {
        "ul3": 10.25987609222421,
        "ul2": 15.912537837524473,
        "ul1": 21.565199582824736,
        "mean": 27.217861328125,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 32.87052307342526,
        "ur2": 38.523184818725525,
        "ur3": 44.17584656402579,
        "standardDeviation": 5.652661745300263,
        "lowest": 5,
        "highest": 47
      },
      "200": {
        "ul3": 9.750439789186874,
        "ul2": 15.112317932374582,
        "ul1": 20.47419607556229,
        "mean": 25.83607421875,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 31.19795236193771,
        "ur2": 36.55983050512542,
        "ur3": 41.92170864831313,
        "standardDeviation": 5.361878143187709,
        "lowest": 5,
        "highest": 45
      },
      "250": {
        "ul3": 9.412523685401691,
        "ul2": 14.562118654851128,
        "ul1": 19.711713624300565,
        "mean": 24.86130859375,
        "median": 25,
        "mostFrequent": 25,
        "ur1": 30.010903563199435,
        "ur2": 35.16049853264887,
        "ur3": 40.31009350209831,
        "standardDeviation": 5.149594969449436,
        "lowest": 5,
        "highest": 43
      },
      "300": {
        "ul3": 9.18149726185327,
        "ul2": 14.147140752693847,
        "ul1": 19.112784243534424,
        "mean": 24.078427734375,
        "median": 24,
        "mostFrequent": 25,
        "ur1": 29.04407122521558,
        "ur2": 34.00971471605615,
        "ur3": 38.97535820689673,
        "standardDeviation": 4.965643490840577,
        "lowest": 5,
        "highest": 46
      },
      "400": {
        "ul3": 8.81815963106644,
        "ul2": 13.509902644669294,
        "ul1": 18.20164565827215,
        "mean": 22.893388671875,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 27.585131685477855,
        "ur2": 32.27687469908071,
        "ur3": 36.96861771268357,
        "standardDeviation": 4.691743013602854,
        "lowest": 5,
        "highest": 42
      },
      "500": {
        "ul3": 8.42896969568373,
        "ul2": 12.956396463789153,
        "ul1": 17.483823231894576,
        "mean": 22.01125,
        "median": 22,
        "mostFrequent": 23,
        "ur1": 26.538676768105425,
        "ur2": 31.066103536210846,
        "ur3": 35.593530304316275,
        "standardDeviation": 4.527426768105424,
        "lowest": 5,
        "highest": 42
      },
      "750": {
        "ul3": 7.935859276091028,
        "ul2": 12.146308527810685,
        "ul1": 16.35675777953034,
        "mean": 20.56720703125,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 24.77765628296966,
        "ur2": 28.988105534689314,
        "ur3": 33.19855478640897,
        "standardDeviation": 4.210449251719657,
        "lowest": 5,
        "highest": 37
      },
      "1000": {
        "ul3": 7.567109109920338,
        "ul2": 11.57570620348856,
        "ul1": 15.584303297056781,
        "mean": 19.592900390625,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 23.60149748419322,
        "ur2": 27.610094577761444,
        "ur3": 31.618691671329664,
        "standardDeviation": 4.008597093568221,
        "lowest": 5,
        "highest": 41
      },
      "2000": {
        "ul3": 6.721683450355256,
        "ul2": 10.317212795028503,
        "ul1": 13.912742139701752,
        "mean": 17.508271484375,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 21.10380082904825,
        "ur2": 24.6993301737215,
        "ur3": 28.294859518394745,
        "standardDeviation": 3.5955293446732486,
        "lowest": 5,
        "highest": 38
      },
      "5000": {
        "ul3": 5.4652713866807066,
        "ul2": 8.715656184870472,
        "ul1": 11.966040983060235,
        "mean": 15.21642578125,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 18.466810579439766,
        "ur2": 21.71719537762953,
        "ur3": 24.967580175819293,
        "standardDeviation": 3.2503847981897644,
        "lowest": 5,
        "highest": 38
      },
      "10000": {
        "ul3": 4.353673561153146,
        "ul2": 7.516325993893764,
        "ul1": 10.678978426634382,
        "mean": 13.841630859375,
        "median": 14,
        "mostFrequent": 14,
        "ur1": 17.004283292115616,
        "ur2": 20.166935724856238,
        "ur3": 23.329588157596852,
        "standardDeviation": 3.162652432740618,
        "lowest": 5,
        "highest": 40
      }
    }
  },
  {
    name: 'Onderste Rij',
    squares: [20, 21, 22, 23, 24],
    odds: {
      "25": {
        "ul3": 13.90361802005906,
        "ul2": 21.808707586289373,
        "ul1": 29.713797152519685,
        "mean": 37.61888671875,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 45.52397628498031,
        "ur2": 53.42906585121062,
        "ur3": 61.33415541744094,
        "standardDeviation": 7.905089566230313,
        "lowest": 6,
        "highest": 64
      },
      "50": {
        "ul3": 12.254331453669522,
        "ul2": 19.20583359932135,
        "ul1": 26.157335744973174,
        "mean": 33.108837890625,
        "median": 33,
        "mostFrequent": 35,
        "ur1": 40.06034003627682,
        "ur2": 47.01184218192864,
        "ur3": 53.96334432758047,
        "standardDeviation": 6.9515021456518244,
        "lowest": 5,
        "highest": 56
      },
      "75": {
        "ul3": 11.476214171297823,
        "ul2": 17.91059460378188,
        "ul1": 24.344975036265943,
        "mean": 30.77935546875,
        "median": 31,
        "mostFrequent": 32,
        "ur1": 37.21373590123406,
        "ur2": 43.64811633371812,
        "ur3": 50.08249676620218,
        "standardDeviation": 6.434380432484059,
        "lowest": 6,
        "highest": 52
      },
      "100": {
        "ul3": 10.924113945093282,
        "ul2": 17.03854666652052,
        "ul1": 23.15297938794776,
        "mean": 29.267412109375,
        "median": 30,
        "mostFrequent": 30,
        "ur1": 35.381844830802244,
        "ur2": 41.496277552229486,
        "ur3": 47.61071027365672,
        "standardDeviation": 6.1144327214272405,
        "lowest": 5,
        "highest": 52
      },
      "125": {
        "ul3": 10.541244828060773,
        "ul2": 16.399325979123848,
        "ul1": 22.257407130186923,
        "mean": 28.11548828125,
        "median": 28,
        "mostFrequent": 30,
        "ur1": 33.97356943231307,
        "ur2": 39.831650583376145,
        "ur3": 45.68973173443922,
        "standardDeviation": 5.858081151063075,
        "lowest": 6,
        "highest": 48
      },
      "150": {
        "ul3": 10.233338747196587,
        "ul2": 15.87960864396439,
        "ul1": 21.525878540732194,
        "mean": 27.1721484375,
        "median": 27,
        "mostFrequent": 28,
        "ur1": 32.818418334267804,
        "ur2": 38.46468823103561,
        "ur3": 44.110958127803414,
        "standardDeviation": 5.646269896767804,
        "lowest": 6,
        "highest": 46
      },
      "200": {
        "ul3": 9.832429621920891,
        "ul2": 15.175796180238928,
        "ul1": 20.519162738556965,
        "mean": 25.862529296875,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 31.205895855193035,
        "ur2": 36.54926241351107,
        "ur3": 41.89262897182911,
        "standardDeviation": 5.343366558318036,
        "lowest": 5,
        "highest": 44
      },
      "250": {
        "ul3": 9.47184846855315,
        "ul2": 14.604520072785434,
        "ul1": 19.73719167701772,
        "mean": 24.86986328125,
        "median": 25,
        "mostFrequent": 26,
        "ur1": 30.002534885482284,
        "ur2": 35.13520648971457,
        "ur3": 40.267878093946855,
        "standardDeviation": 5.132671604232284,
        "lowest": 5,
        "highest": 42
      },
      "300": {
        "ul3": 9.117944330663336,
        "ul2": 14.096038407942224,
        "ul1": 19.074132485221114,
        "mean": 24.0522265625,
        "median": 24,
        "mostFrequent": 25,
        "ur1": 29.030320639778886,
        "ur2": 34.008414717057775,
        "ur3": 38.986508794336665,
        "standardDeviation": 4.978094077278888,
        "lowest": 5,
        "highest": 43
      },
      "400": {
        "ul3": 8.748682831518405,
        "ul2": 13.46374428351227,
        "ul1": 18.178805735506135,
        "mean": 22.8938671875,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 27.608928639493865,
        "ur2": 32.32399009148773,
        "ur3": 37.03905154348159,
        "standardDeviation": 4.715061451993865,
        "lowest": 5,
        "highest": 43
      },
      "500": {
        "ul3": 8.504681837459778,
        "ul2": 13.018726693723185,
        "ul1": 17.53277154998659,
        "mean": 22.04681640625,
        "median": 22,
        "mostFrequent": 23,
        "ur1": 26.560861262513406,
        "ur2": 31.074906118776813,
        "ur3": 35.58895097504022,
        "standardDeviation": 4.514044856263407,
        "lowest": 5,
        "highest": 41
      },
      "750": {
        "ul3": 7.882964353986912,
        "ul2": 12.101302407866275,
        "ul1": 16.319640461745635,
        "mean": 20.537978515625,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 24.756316569504364,
        "ur2": 28.974654623383724,
        "ur3": 33.192992677263085,
        "standardDeviation": 4.2183380538793624,
        "lowest": 5,
        "highest": 40
      },
      "1000": {
        "ul3": 7.557190124286587,
        "ul2": 11.55967948389939,
        "ul1": 15.562168843512195,
        "mean": 19.564658203125,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 23.5671475627378,
        "ur2": 27.569636922350607,
        "ur3": 31.572126281963413,
        "standardDeviation": 4.002489359612804,
        "lowest": 5,
        "highest": 39
      },
      "2000": {
        "ul3": 6.71890916044172,
        "ul2": 10.31011912779448,
        "ul1": 13.90132909514724,
        "mean": 17.4925390625,
        "median": 18,
        "mostFrequent": 18,
        "ur1": 21.08374902985276,
        "ur2": 24.674958997205522,
        "ur3": 28.266168964558283,
        "standardDeviation": 3.59120996735276,
        "lowest": 5,
        "highest": 46
      },
      "5000": {
        "ul3": 5.4949195631701055,
        "ul2": 8.737400802530072,
        "ul1": 11.979882041890036,
        "mean": 15.22236328125,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 18.464844520609965,
        "ur2": 21.70732575996993,
        "ur3": 24.949806999329894,
        "standardDeviation": 3.242481239359965,
        "lowest": 5,
        "highest": 38
      },
      "10000": {
        "ul3": 4.35697430074196,
        "ul2": 7.51991971611964,
        "ul1": 10.68286513149732,
        "mean": 13.845810546875,
        "median": 14,
        "mostFrequent": 14,
        "ur1": 17.008755962252682,
        "ur2": 20.171701377630363,
        "ur3": 23.33464679300804,
        "standardDeviation": 3.1629454153776804,
        "lowest": 5,
        "highest": 41
      }
    },
  },
  {
    name: 'De Chevron',
    squares: [3,7,9,11,14],
    odds: {
      "25": {
        "ul3": 13.87359951582097,
        "ul2": 21.78346413033898,
        "ul1": 29.69332874485699,
        "mean": 37.603193359375,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 45.513057973893005,
        "ur2": 53.42292258841101,
        "ur3": 61.332787202929026,
        "standardDeviation": 7.909864614518009,
        "lowest": 5,
        "highest": 64
      },
      "50": {
        "ul3": 12.230426510304682,
        "ul2": 19.20703238707812,
        "ul1": 26.183638263851556,
        "mean": 33.160244140625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 40.13685001739844,
        "ur2": 47.11345589417188,
        "ur3": 54.09006177094531,
        "standardDeviation": 6.976605876773439,
        "lowest": 6,
        "highest": 56
      },
      "75": {
        "ul3": 11.413234966520296,
        "ul2": 17.87012213913853,
        "ul1": 24.327009311756765,
        "mean": 30.783896484375,
        "median": 31,
        "mostFrequent": 33,
        "ur1": 37.24078365699323,
        "ur2": 43.69767082961147,
        "ur3": 50.154558002229706,
        "standardDeviation": 6.456887172618234,
        "lowest": 6,
        "highest": 52
      },
      "100": {
        "ul3": 10.930169922124346,
        "ul2": 17.035035156416228,
        "ul1": 23.139900390708114,
        "mean": 29.244765625,
        "median": 30,
        "mostFrequent": 31,
        "ur1": 35.349630859291885,
        "ur2": 41.45449609358377,
        "ur3": 47.55936132787565,
        "standardDeviation": 6.104865234291885,
        "lowest": 5,
        "highest": 49
      },
      "125": {
        "ul3": 10.534252015266595,
        "ul2": 16.39027933830273,
        "ul1": 22.246306661338863,
        "mean": 28.102333984375,
        "median": 28,
        "mostFrequent": 30,
        "ur1": 33.95836130741114,
        "ur2": 39.81438863044727,
        "ur3": 45.670415953483406,
        "standardDeviation": 5.856027323036136,
        "lowest": 6,
        "highest": 49
      },
      "150": {
        "ul3": 10.211188582982004,
        "ul2": 15.870388742821335,
        "ul1": 21.529588902660667,
        "mean": 27.1887890625,
        "median": 27,
        "mostFrequent": 28,
        "ur1": 32.84798922233933,
        "ur2": 38.507189382178666,
        "ur3": 44.166389542017995,
        "standardDeviation": 5.659200159839332,
        "lowest": 6,
        "highest": 48
      },
      "200": {
        "ul3": 9.746694906933254,
        "ul2": 15.117812880663834,
        "ul1": 20.488930854394418,
        "mean": 25.860048828125,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 31.23116680185558,
        "ur2": 36.60228477558616,
        "ur3": 41.97340274931675,
        "standardDeviation": 5.371117973730582,
        "lowest": 5,
        "highest": 44
      },
      "250": {
        "ul3": 9.455643129123862,
        "ul2": 14.583163127749241,
        "ul1": 19.71068312637462,
        "mean": 24.838203125,
        "median": 25,
        "mostFrequent": 26,
        "ur1": 29.96572312362538,
        "ur2": 35.093243122250755,
        "ur3": 40.220763120876136,
        "standardDeviation": 5.127519998625379,
        "lowest": 6,
        "highest": 43
      },
      "300": {
        "ul3": 9.207202880682306,
        "ul2": 14.165218587121538,
        "ul1": 19.12323429356077,
        "mean": 24.08125,
        "median": 24,
        "mostFrequent": 25,
        "ur1": 29.03926570643923,
        "ur2": 33.997281412878465,
        "ur3": 38.95529711931769,
        "standardDeviation": 4.958015706439231,
        "lowest": 5,
        "highest": 44
      },
      "400": {
        "ul3": 8.790629702357236,
        "ul2": 13.497613811988158,
        "ul1": 18.20459792161908,
        "mean": 22.91158203125,
        "median": 23,
        "mostFrequent": 24,
        "ur1": 27.61856614088092,
        "ur2": 32.32555025051184,
        "ur3": 37.03253436014276,
        "standardDeviation": 4.706984109630921,
        "lowest": 5,
        "highest": 41
      },
      "500": {
        "ul3": 8.479692926126429,
        "ul2": 13.001406612209285,
        "ul1": 17.52312029829214,
        "mean": 22.044833984375,
        "median": 22,
        "mostFrequent": 23,
        "ur1": 26.566547670457858,
        "ur2": 31.088261356540713,
        "ur3": 35.609975042623574,
        "standardDeviation": 4.521713686082857,
        "lowest": 5,
        "highest": 41
      },
      "750": {
        "ul3": 7.992884185467794,
        "ul2": 12.181805602811863,
        "ul1": 16.370727020155933,
        "mean": 20.5596484375,
        "median": 21,
        "mostFrequent": 21,
        "ur1": 24.74856985484407,
        "ur2": 28.93749127218814,
        "ur3": 33.12641268953221,
        "standardDeviation": 4.188921417344069,
        "lowest": 5,
        "highest": 42
      },
      "1000": {
        "ul3": 7.644531033812491,
        "ul2": 11.636920428791662,
        "ul1": 15.62930982377083,
        "mean": 19.62169921875,
        "median": 20,
        "mostFrequent": 20,
        "ur1": 23.61408861372917,
        "ur2": 27.606478008708336,
        "ur3": 31.598867403687507,
        "standardDeviation": 3.992389394979169,
        "lowest": 5,
        "highest": 43
      },
      "2000": {
        "ul3": 6.718841339013714,
        "ul2": 10.308501647884142,
        "ul1": 13.89816195675457,
        "mean": 17.487822265625,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 21.077482574495427,
        "ur2": 24.667142883365855,
        "ur3": 28.256803192236283,
        "standardDeviation": 3.589660308870428,
        "lowest": 5,
        "highest": 43
      },
      "5000": {
        "ul3": 5.510218739471917,
        "ul2": 8.749738925272943,
        "ul1": 11.989259111073972,
        "mean": 15.228779296875,
        "median": 15,
        "mostFrequent": 15,
        "ur1": 18.468299482676027,
        "ur2": 21.707819668477057,
        "ur3": 24.947339854278084,
        "standardDeviation": 3.239520185801028,
        "lowest": 5,
        "highest": 39
      },
      "10000": {
        "ul3": 4.292564212162718,
        "ul2": 7.479320151858478,
        "ul1": 10.666076091554238,
        "mean": 13.85283203125,
        "median": 14,
        "mostFrequent": 14,
        "ur1": 17.03958797094576,
        "ur2": 20.22634391064152,
        "ur3": 23.413099850337282,
        "standardDeviation": 3.1867559396957605,
        "lowest": 5,
        "highest": 42
      }
    },
  },
  {
    name: 'De Sixpack',
    squares: [8,9,13,17,18],
    odds: {
      "25": {
        "ul3": 12.191346113175747,
        "ul2": 21.27951069003383,
        "ul1": 30.367675266891915,
        "mean": 39.45583984375,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 48.54400442060808,
        "ur2": 57.63216899746617,
        "ur3": 66.72033357432426,
        "standardDeviation": 9.088164576858084,
        "lowest": 6,
        "highest": 70
      },
      "50": {
        "ul3": 10.247901177761065,
        "ul2": 18.58742565496571,
        "ul1": 26.926950132170354,
        "mean": 35.266474609375,
        "median": 35,
        "mostFrequent": 36,
        "ur1": 43.60599908657965,
        "ur2": 51.94552356378429,
        "ur3": 60.28504804098894,
        "standardDeviation": 8.339524477204646,
        "lowest": 5,
        "highest": 65
      },
      "75": {
        "ul3": 9.073849572222073,
        "ul2": 17.080118464814714,
        "ul1": 25.086387357407354,
        "mean": 33.09265625,
        "median": 33,
        "mostFrequent": 34,
        "ur1": 41.09892514259264,
        "ur2": 49.10519403518528,
        "ur3": 57.11146292777792,
        "standardDeviation": 8.006268892592642,
        "lowest": 5,
        "highest": 64
      },
      "100": {
        "ul3": 8.404534095143777,
        "ul2": 16.16550319884585,
        "ul1": 23.926472302547925,
        "mean": 31.68744140625,
        "median": 32,
        "mostFrequent": 30,
        "ur1": 39.44841050995207,
        "ur2": 47.20937961365415,
        "ur3": 54.97034871735622,
        "standardDeviation": 7.760969103702074,
        "lowest": 5,
        "highest": 60
      },
      "125": {
        "ul3": 7.947354503386215,
        "ul2": 15.513210293924143,
        "ul1": 23.07906608446207,
        "mean": 30.644921875,
        "median": 31,
        "mostFrequent": 31,
        "ur1": 38.21077766553793,
        "ur2": 45.77663345607586,
        "ur3": 53.34248924661379,
        "standardDeviation": 7.565855790537929,
        "lowest": 5,
        "highest": 63
      },
      "150": {
        "ul3": 7.468977763953131,
        "ul2": 14.908169420760421,
        "ul1": 22.34736107756771,
        "mean": 29.786552734375,
        "median": 30,
        "mostFrequent": 28,
        "ur1": 37.22574439118229,
        "ur2": 44.66493604798958,
        "ur3": 52.104127704796866,
        "standardDeviation": 7.4391916568072896,
        "lowest": 5,
        "highest": 58
      },
      "200": {
        "ul3": 6.804292611997756,
        "ul2": 14.039912522581837,
        "ul1": 21.275532433165917,
        "mean": 28.51115234375,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 35.746772254334076,
        "ur2": 42.98239216491816,
        "ur3": 50.218012075502244,
        "standardDeviation": 7.235619910584081,
        "lowest": 5,
        "highest": 57
      },
      "250": {
        "ul3": 6.4148699449191255,
        "ul2": 13.486658088279418,
        "ul1": 20.55844623163971,
        "mean": 27.630234375,
        "median": 27,
        "mostFrequent": 28,
        "ur1": 34.702022518360295,
        "ur2": 41.77381066172058,
        "ur3": 48.845598805080876,
        "standardDeviation": 7.071788143360291,
        "lowest": 5,
        "highest": 59
      },
      "300": {
        "ul3": 6.124557740202015,
        "ul2": 13.050857503884677,
        "ul1": 19.97715726756734,
        "mean": 26.90345703125,
        "median": 27,
        "mostFrequent": 25,
        "ur1": 33.82975679493266,
        "ur2": 40.75605655861532,
        "ur3": 47.682356322297984,
        "standardDeviation": 6.926299763682661,
        "lowest": 5,
        "highest": 57
      },
      "400": {
        "ul3": 5.604571746099634,
        "ul2": 12.348744445316422,
        "ul1": 19.09291714453321,
        "mean": 25.83708984375,
        "median": 26,
        "mostFrequent": 25,
        "ur1": 32.58126254296679,
        "ur2": 39.32543524218358,
        "ur3": 46.06960794140036,
        "standardDeviation": 6.744172699216789,
        "lowest": 5,
        "highest": 54
      },
      "500": {
        "ul3": 5.1945854584528455,
        "ul2": 11.808844732718564,
        "ul1": 18.423104006984282,
        "mean": 25.03736328125,
        "median": 25,
        "mostFrequent": 24,
        "ur1": 31.651622555515715,
        "ur2": 38.265881829781435,
        "ur3": 44.880141104047155,
        "standardDeviation": 6.614259274265717,
        "lowest": 5,
        "highest": 54
      },
      "750": {
        "ul3": 4.571139538951318,
        "ul2": 10.94369589055088,
        "ul1": 17.31625224215044,
        "mean": 23.68880859375,
        "median": 23,
        "mostFrequent": 23,
        "ur1": 30.06136494534956,
        "ur2": 36.43392129694912,
        "ur3": 42.80647764854868,
        "standardDeviation": 6.3725563515995605,
        "lowest": 5,
        "highest": 55
      },
      "1000": {
        "ul3": 4.183534747564462,
        "ul2": 10.41599256608464,
        "ul1": 16.64845038460482,
        "mean": 22.880908203125,
        "median": 23,
        "mostFrequent": 22,
        "ur1": 29.113366021645177,
        "ur2": 35.34582384016536,
        "ur3": 41.57828165868554,
        "standardDeviation": 6.23245781852018,
        "lowest": 5,
        "highest": 52
      },
      "2000": {
        "ul3": 3.2119228823540134,
        "ul2": 9.144403666361008,
        "ul1": 15.076884450368004,
        "mean": 21.009365234375,
        "median": 21,
        "mostFrequent": 20,
        "ur1": 26.941846018381995,
        "ur2": 32.87432680238899,
        "ur3": 38.80680758639599,
        "standardDeviation": 5.932480784006996,
        "lowest": 5,
        "highest": 51
      },
      "5000": {
        "ul3": 2.064362867800366,
        "ul2": 7.702397510825245,
        "ul1": 13.340432153850124,
        "mean": 18.978466796875,
        "median": 18,
        "mostFrequent": 17,
        "ur1": 24.616501439899878,
        "ur2": 30.25453608292476,
        "ur3": 35.89257072594964,
        "standardDeviation": 5.638034643024878,
        "lowest": 5,
        "highest": 51
      },
      "10000": {
        "ul3": 1.2087993069920095,
        "ul2": 6.751217767161339,
        "ul1": 12.29363622733067,
        "mean": 17.8360546875,
        "median": 17,
        "mostFrequent": 16,
        "ur1": 23.37847314766933,
        "ur2": 28.92089160783866,
        "ur3": 34.463310068007985,
        "standardDeviation": 5.54241846016933,
        "lowest": 5,
        "highest": 49
      }
    },
  },
  {
    name: 'De Plus',
    squares: [3, 8, 11, 12, 13, 14, 17, 22],
    odds: { // amount of cards : avg amount of nummers (bingoballs) drawn
      "25": {
        "ul3": 28.46647530853121,
        "ul2": 35.578639789020805,
        "ul1": 42.6908042695104,
        "mean": 49.80296875,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 56.915133230489594,
        "ur2": 64.02729771097918,
        "ur3": 71.1394621914688,
        "standardDeviation": 7.1121644804895965,
        "lowest": 15,
        "highest": 71
      },
      "50": {
        "ul3": 25.93414578487255,
        "ul2": 32.7019826065817,
        "ul1": 39.46981942829085,
        "mean": 46.23765625,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 53.005493071709154,
        "ur2": 59.7733298934183,
        "ur3": 66.54116671512745,
        "standardDeviation": 6.7678368217091505,
        "lowest": 11,
        "highest": 69
      },
      "75": {
        "ul3": 24.55746463379432,
        "ul2": 31.14638592773788,
        "ul1": 37.73530722168144,
        "mean": 44.324228515625,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 50.913149809568566,
        "ur2": 57.50207110351212,
        "ur3": 64.09099239745568,
        "standardDeviation": 6.58892129394356,
        "lowest": 14,
        "highest": 67
      },
      "100": {
        "ul3": 23.703111535756584,
        "ul2": 30.146996232171055,
        "ul1": 36.59088092858553,
        "mean": 43.034765625,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 49.47865032141447,
        "ur2": 55.92253501782894,
        "ur3": 62.36641971424341,
        "standardDeviation": 6.443884696414472,
        "lowest": 12,
        "highest": 66
      },
      "125": {
        "ul3": 23.002096519833916,
        "ul2": 29.35445432051428,
        "ul1": 35.70681212119464,
        "mean": 42.059169921875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 48.411527722555356,
        "ur2": 54.763885523235714,
        "ur3": 61.11624332391608,
        "standardDeviation": 6.35235780068036,
        "lowest": 11,
        "highest": 64
      },
      "150": {
        "ul3": 22.473474171947622,
        "ul2": 28.739298536506745,
        "ul1": 35.005122901065874,
        "mean": 41.270947265625,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 47.53677163018412,
        "ur2": 53.80259599474325,
        "ur3": 60.06842035930237,
        "standardDeviation": 6.265824364559125,
        "lowest": 15,
        "highest": 65
      },
      "200": {
        "ul3": 21.662908980271116,
        "ul2": 27.80384361705574,
        "ul1": 33.94477825384037,
        "mean": 40.085712890625,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 46.226647527409625,
        "ur2": 52.36758216419425,
        "ur3": 58.50851680097888,
        "standardDeviation": 6.140934636784627,
        "lowest": 14,
        "highest": 64
      },
      "250": {
        "ul3": 20.98375460775123,
        "ul2": 27.03656231662582,
        "ul1": 33.08937002550041,
        "mean": 39.142177734375,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 45.194985443249585,
        "ur2": 51.247793152124174,
        "ur3": 57.30060086099876,
        "standardDeviation": 6.052807708874589,
        "lowest": 12,
        "highest": 63
      },
      "300": {
        "ul3": 20.52863711122495,
        "ul2": 26.50528606894163,
        "ul1": 32.48193502665831,
        "mean": 38.458583984375,
        "median": 39,
        "mostFrequent": 38,
        "ur1": 44.43523294209168,
        "ur2": 50.411881899808364,
        "ur3": 56.38853085752504,
        "standardDeviation": 5.976648957716683,
        "lowest": 11,
        "highest": 61
      },
      "400": {
        "ul3": 19.733840486754016,
        "ul2": 25.613263449502675,
        "ul1": 31.492686412251338,
        "mean": 37.372109375,
        "median": 37,
        "mostFrequent": 38,
        "ur1": 43.251532337748664,
        "ur2": 49.13095530049733,
        "ur3": 55.01037826324598,
        "standardDeviation": 5.879422962748662,
        "lowest": 13,
        "highest": 61
      },
      "500": {
        "ul3": 19.188711971246825,
        "ul2": 24.984701209997883,
        "ul1": 30.780690448748942,
        "mean": 36.5766796875,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 42.37266892625105,
        "ur2": 48.168658165002114,
        "ur3": 53.96464740375317,
        "standardDeviation": 5.795989238751057,
        "lowest": 12,
        "highest": 61
      },
      "750": {
        "ul3": 18.207467084652464,
        "ul2": 23.838063993934973,
        "ul1": 29.468660903217486,
        "mean": 35.0992578125,
        "median": 35,
        "mostFrequent": 35,
        "ur1": 40.72985472178251,
        "ur2": 46.360451631065025,
        "ur3": 51.991048540347535,
        "standardDeviation": 5.630596909282512,
        "lowest": 12,
        "highest": 59
      },
      "1000": {
        "ul3": 17.402077060801385,
        "ul2": 22.98486778011759,
        "ul1": 28.567658499433794,
        "mean": 34.15044921875,
        "median": 34,
        "mostFrequent": 34,
        "ur1": 39.733239938066205,
        "ur2": 45.316030657382406,
        "ur3": 50.898821376698606,
        "standardDeviation": 5.582790719316204,
        "lowest": 10,
        "highest": 60
      },
      "2000": {
        "ul3": 15.788370881024957,
        "ul2": 21.181999858183303,
        "ul1": 26.575628835341654,
        "mean": 31.9692578125,
        "median": 32,
        "mostFrequent": 31,
        "ur1": 37.36288678965835,
        "ur2": 42.7565157668167,
        "ur3": 48.15014474397505,
        "standardDeviation": 5.393628977158348,
        "lowest": 11,
        "highest": 58
      },
      "5000": {
        "ul3": 13.83929800916794,
        "ul2": 19.01771559986196,
        "ul1": 24.19613319055598,
        "mean": 29.37455078125,
        "median": 29,
        "mostFrequent": 28,
        "ur1": 34.55296837194402,
        "ur2": 39.73138596263804,
        "ur3": 44.90980355333206,
        "standardDeviation": 5.17841759069402,
        "lowest": 9,
        "highest": 56
      },
      "10000": {
        "ul3": 12.39796779961707,
        "ul2": 17.484696632036382,
        "ul1": 22.57142546445569,
        "mean": 27.658154296875,
        "median": 27,
        "mostFrequent": 27,
        "ur1": 32.74488312929431,
        "ur2": 37.83161196171362,
        "ur3": 42.91834079413293,
        "standardDeviation": 5.08672883241931,
        "lowest": 10,
        "highest": 57
      }
    },
  },
  {
    name: 'Het Kruis',
    squares: [1, 5, 7, 9, 16, 18, 20, 24],
    odds: {
      "25": {
        "ul3": 28.924735334078598,
        "ul2": 35.722668608135734,
        "ul1": 42.520601882192864,
        "mean": 49.31853515625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 56.11646843030714,
        "ur2": 62.914401704364266,
        "ur3": 69.71233497842141,
        "standardDeviation": 6.797933274057135,
        "lowest": 14,
        "highest": 68
      },
      "50": {
        "ul3": 26.728894087101047,
        "ul2": 33.05260256848403,
        "ul1": 39.37631104986701,
        "mean": 45.70001953125,
        "median": 46,
        "mostFrequent": 48,
        "ur1": 52.023728012632986,
        "ur2": 58.347436494015966,
        "ur3": 64.67114497539896,
        "standardDeviation": 6.323708481382985,
        "lowest": 12,
        "highest": 65
      },
      "75": {
        "ul3": 25.435256965185054,
        "ul2": 31.52112834137337,
        "ul1": 37.606999717561685,
        "mean": 43.69287109375,
        "median": 44,
        "mostFrequent": 46,
        "ur1": 49.778742469938315,
        "ur2": 55.86461384612663,
        "ur3": 61.950485222314946,
        "standardDeviation": 6.085871376188315,
        "lowest": 16,
        "highest": 62
      },
      "100": {
        "ul3": 24.660859652606582,
        "ul2": 30.561367372571056,
        "ul1": 36.46187509253553,
        "mean": 42.3623828125,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 48.262890532464475,
        "ur2": 54.16339825242895,
        "ur3": 60.06390597239342,
        "standardDeviation": 5.900507719964473,
        "lowest": 11,
        "highest": 61
      },
      "125": {
        "ul3": 23.960847986417175,
        "ul2": 29.760578345111448,
        "ul1": 35.560308703805724,
        "mean": 41.3600390625,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.15976942119428,
        "ur2": 52.95949977988855,
        "ur3": 58.75923013858282,
        "standardDeviation": 5.7997303586942754,
        "lowest": 12,
        "highest": 61
      },
      "150": {
        "ul3": 23.544662825074415,
        "ul2": 29.20295230004961,
        "ul1": 34.86124177502481,
        "mean": 40.51953125,
        "median": 41,
        "mostFrequent": 42,
        "ur1": 46.17782072497519,
        "ur2": 51.83611019995039,
        "ur3": 57.49439967492559,
        "standardDeviation": 5.658289474975195,
        "lowest": 12,
        "highest": 58
      },
      "200": {
        "ul3": 22.7960861436108,
        "ul2": 28.302315892615535,
        "ul1": 33.80854564162027,
        "mean": 39.314775390625,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 44.82100513962973,
        "ur2": 50.327234888634464,
        "ur3": 55.8334646376392,
        "standardDeviation": 5.506229749004733,
        "lowest": 13,
        "highest": 58
      },
      "250": {
        "ul3": 22.2066769493061,
        "ul2": 27.6015118464124,
        "ul1": 32.9963467435187,
        "mean": 38.391181640625,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 43.7860165377313,
        "ur2": 49.1808514348376,
        "ur3": 54.5756863319439,
        "standardDeviation": 5.3948348971063,
        "lowest": 13,
        "highest": 56
      },
      "300": {
        "ul3": 21.775045496830003,
        "ul2": 27.073881242678333,
        "ul1": 32.37271698852667,
        "mean": 37.671552734375,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 42.97038848022333,
        "ur2": 48.269224226071664,
        "ur3": 53.56805997191999,
        "standardDeviation": 5.298835745848332,
        "lowest": 10,
        "highest": 55
      },
      "400": {
        "ul3": 21.044079664236893,
        "ul2": 26.19925297928293,
        "ul1": 31.354426294328967,
        "mean": 36.509599609375,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 41.66477292442104,
        "ur2": 46.81994623946707,
        "ur3": 51.975119554513114,
        "standardDeviation": 5.155173315046036,
        "lowest": 12,
        "highest": 55
      },
      "500": {
        "ul3": 20.607929792746326,
        "ul2": 25.632060617039215,
        "ul1": 30.65619144133211,
        "mean": 35.680322265625,
        "median": 36,
        "mostFrequent": 36,
        "ur1": 40.70445308991789,
        "ur2": 45.72858391421079,
        "ur3": 50.75271473850368,
        "standardDeviation": 5.024130824292892,
        "lowest": 13,
        "highest": 53
      },
      "750": {
        "ul3": 19.628381557195986,
        "ul2": 24.47313978813066,
        "ul1": 29.31789801906533,
        "mean": 34.16265625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 39.00741448093467,
        "ur2": 43.852172711869336,
        "ur3": 48.69693094280401,
        "standardDeviation": 4.84475823093467,
        "lowest": 12,
        "highest": 51
      },
      "1000": {
        "ul3": 18.986541717470086,
        "ul2": 23.71158119706339,
        "ul1": 28.436620676656695,
        "mean": 33.16166015625,
        "median": 33,
        "mostFrequent": 34,
        "ur1": 37.88669963584331,
        "ur2": 42.61173911543661,
        "ur3": 47.33677859502991,
        "standardDeviation": 4.725039479593305,
        "lowest": 10,
        "highest": 49
      },
      "2000": {
        "ul3": 17.61326482983077,
        "ul2": 22.04130415738718,
        "ul1": 26.46934348494359,
        "mean": 30.8973828125,
        "median": 31,
        "mostFrequent": 32,
        "ur1": 35.32542214005641,
        "ur2": 39.753461467612816,
        "ur3": 44.18150079516923,
        "standardDeviation": 4.42803932755641,
        "lowest": 11,
        "highest": 47
      },
      "5000": {
        "ul3": 15.902457721344959,
        "ul2": 20.007846163188304,
        "ul1": 24.113234605031654,
        "mean": 28.218623046875,
        "median": 28,
        "mostFrequent": 29,
        "ur1": 32.32401148871835,
        "ur2": 36.429399930561694,
        "ur3": 40.53478837240504,
        "standardDeviation": 4.105388441843346,
        "lowest": 10,
        "highest": 47
      },
      "10000": {
        "ul3": 14.67099309621814,
        "ul2": 18.57576297560376,
        "ul1": 22.48053285498938,
        "mean": 26.385302734375,
        "median": 26,
        "mostFrequent": 27,
        "ur1": 30.290072613760618,
        "ur2": 34.19484249314624,
        "ur3": 38.09961237253186,
        "standardDeviation": 3.90476987938562,
        "lowest": 9,
        "highest": 46
      }
    },
  },
  {
    name: 'De Golf',
    squares: [9,10,14,19,21,22,23,24],
    odds: { // amount of cards : avg amount of nummers (bingoballs) drawn
      "25": {
        "ul3": 28.2037110019455,
        "ul2": 35.443668662755336,
        "ul1": 42.683626323565164,
        "mean": 49.923583984375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 57.163541645184836,
        "ur2": 64.40349930599467,
        "ur3": 71.64345696680451,
        "standardDeviation": 7.239957660809833,
        "lowest": 17,
        "highest": 71
      },
      "50": {
        "ul3": 25.860607446590897,
        "ul2": 32.731495459185595,
        "ul1": 39.6023834717803,
        "mean": 46.473271484375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 53.344159496969695,
        "ur2": 60.2150475095644,
        "ur3": 67.0859355221591,
        "standardDeviation": 6.8708880125947,
        "lowest": 11,
        "highest": 68
      },
      "75": {
        "ul3": 24.509167773936213,
        "ul2": 31.193696484707477,
        "ul1": 37.87822519547874,
        "mean": 44.56275390625,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 51.24728261702126,
        "ur2": 57.93181132779253,
        "ur3": 64.61634003856379,
        "standardDeviation": 6.6845287107712625,
        "lowest": 14,
        "highest": 67
      },
      "100": {
        "ul3": 23.59591004211752,
        "ul2": 30.164311121828348,
        "ul1": 36.73271220153917,
        "mean": 43.30111328125,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 49.86951436096082,
        "ur2": 56.437915440671645,
        "ur3": 63.00631652038247,
        "standardDeviation": 6.568401079710825,
        "lowest": 13,
        "highest": 67
      },
      "125": {
        "ul3": 22.905295564222104,
        "ul2": 29.376460063648068,
        "ul1": 35.847624563074035,
        "mean": 42.3187890625,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 48.78995356192597,
        "ur2": 55.26111806135194,
        "ur3": 61.732282560777904,
        "standardDeviation": 6.471164499425966,
        "lowest": 14,
        "highest": 67
      },
      "150": {
        "ul3": 22.330299025839803,
        "ul2": 28.720625782851535,
        "ul1": 35.11095253986326,
        "mean": 41.501279296875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.89160605388673,
        "ur2": 54.28193281089846,
        "ur3": 60.67225956791019,
        "standardDeviation": 6.3903267570117315,
        "lowest": 13,
        "highest": 65
      },
      "200": {
        "ul3": 21.557547771357445,
        "ul2": 27.81407481632163,
        "ul1": 34.070601861285816,
        "mean": 40.32712890625,
        "median": 41,
        "mostFrequent": 40,
        "ur1": 46.58365595121419,
        "ur2": 52.84018299617838,
        "ur3": 59.09671004114256,
        "standardDeviation": 6.256527044964186,
        "lowest": 13,
        "highest": 63
      },
      "250": {
        "ul3": 20.93660124278711,
        "ul2": 27.104391062899737,
        "ul1": 33.27218088301237,
        "mean": 39.439970703125,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 45.60776052323763,
        "ur2": 51.77555034335026,
        "ur3": 57.94334016346289,
        "standardDeviation": 6.16778982011263,
        "lowest": 12,
        "highest": 62
      },
      "300": {
        "ul3": 20.482895770411126,
        "ul2": 26.574642102149085,
        "ul1": 32.66638843388704,
        "mean": 38.758134765625,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 44.84988109736296,
        "ur2": 50.94162742910092,
        "ur3": 57.03337376083888,
        "standardDeviation": 6.0917463317379585,
        "lowest": 13,
        "highest": 62
      },
      "400": {
        "ul3": 19.65333122116896,
        "ul2": 25.658392689112638,
        "ul1": 31.663454157056318,
        "mean": 37.668515625,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 43.673577092943674,
        "ur2": 49.67863856088736,
        "ur3": 55.68370002883104,
        "standardDeviation": 6.00506146794368,
        "lowest": 14,
        "highest": 62
      },
      "500": {
        "ul3": 19.107244062695173,
        "ul2": 25.017108020963448,
        "ul1": 30.926971979231723,
        "mean": 36.8368359375,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 42.74669989576827,
        "ur2": 48.65656385403655,
        "ur3": 54.56642781230482,
        "standardDeviation": 5.909863958268275,
        "lowest": 11,
        "highest": 59
      },
      "750": {
        "ul3": 18.17397319514533,
        "ul2": 23.938573275930217,
        "ul1": 29.70317335671511,
        "mean": 35.4677734375,
        "median": 36,
        "mostFrequent": 35,
        "ur1": 41.23237351828489,
        "ur2": 46.99697359906978,
        "ur3": 52.76157367985467,
        "standardDeviation": 5.7646000807848905,
        "lowest": 12,
        "highest": 59
      },
      "1000": {
        "ul3": 17.467189728423392,
        "ul2": 23.138051615823926,
        "ul1": 28.808913503224463,
        "mean": 34.479775390625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 40.150637278025535,
        "ur2": 45.821499165426076,
        "ur3": 51.49236105282661,
        "standardDeviation": 5.6708618874005365,
        "lowest": 11,
        "highest": 58
      },
      "2000": {
        "ul3": 15.783147107146206,
        "ul2": 21.287172941222472,
        "ul1": 26.791198775298735,
        "mean": 32.295224609375,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 37.799250443451264,
        "ur2": 43.30327627752752,
        "ur3": 48.80730211160379,
        "standardDeviation": 5.504025834076264,
        "lowest": 10,
        "highest": 58
      },
      "5000": {
        "ul3": 13.847627232714231,
        "ul2": 19.15292010826782,
        "ul1": 24.45821298382141,
        "mean": 29.763505859375,
        "median": 30,
        "mostFrequent": 29,
        "ur1": 35.06879873492859,
        "ur2": 40.37409161048218,
        "ur3": 45.67938448603577,
        "standardDeviation": 5.30529287555359,
        "lowest": 10,
        "highest": 55
      },
      "10000": {
        "ul3": 12.529004642114522,
        "ul2": 17.70419319890968,
        "ul1": 22.87938175570484,
        "mean": 28.0545703125,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 33.22975886929516,
        "ur2": 38.40494742609032,
        "ur3": 43.58013598288548,
        "standardDeviation": 5.17518855679516,
        "lowest": 9,
        "highest": 54
      }
    },
  },
  {
    name: 'De Pijl',
    squares: [3,9,11,12,13,14,18,22],
    odds: {
      "25": {
        "ul3": 28.878000031546215,
        "ul2": 35.68852996894748,
        "ul1": 42.499059906348734,
        "mean": 49.30958984375,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 56.12011978115126,
        "ur2": 62.930649718552516,
        "ur3": 69.74117965595377,
        "standardDeviation": 6.810529937401261,
        "lowest": 17,
        "highest": 70
      },
      "50": {
        "ul3": 26.513383268671028,
        "ul2": 32.90399379369735,
        "ul1": 39.29460431872368,
        "mean": 45.68521484375,
        "median": 46,
        "mostFrequent": 48,
        "ur1": 52.07582536877633,
        "ur2": 58.466435893802654,
        "ur3": 64.85704641882899,
        "standardDeviation": 6.390610525026325,
        "lowest": 14,
        "highest": 67
      },
      "75": {
        "ul3": 25.324046526174254,
        "ul2": 31.46315341328284,
        "ul1": 37.60226030039142,
        "mean": 43.7413671875,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 49.880474074608586,
        "ur2": 56.01958096171717,
        "ur3": 62.15868784882575,
        "standardDeviation": 6.139106887108583,
        "lowest": 15,
        "highest": 63
      },
      "100": {
        "ul3": 24.37815458133954,
        "ul2": 30.37550149172636,
        "ul1": 36.37284840211318,
        "mean": 42.3701953125,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 48.367542222886826,
        "ur2": 54.36488913327364,
        "ur3": 60.36223604366046,
        "standardDeviation": 5.99734691038682,
        "lowest": 13,
        "highest": 62
      },
      "125": {
        "ul3": 23.850547345022026,
        "ul2": 29.698486641473018,
        "ul1": 35.54642593792401,
        "mean": 41.394365234375,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.24230453082599,
        "ur2": 53.090243827276986,
        "ur3": 58.93818312372798,
        "standardDeviation": 5.847939296450992,
        "lowest": 13,
        "highest": 62
      },
      "150": {
        "ul3": 23.38006373491165,
        "ul2": 29.1159995211911,
        "ul1": 34.851935307470555,
        "mean": 40.58787109375,
        "median": 41,
        "mostFrequent": 42,
        "ur1": 46.32380688002945,
        "ur2": 52.05974266630891,
        "ur3": 57.795678452588355,
        "standardDeviation": 5.735935786279451,
        "lowest": 13,
        "highest": 60
      },
      "200": {
        "ul3": 22.55998322801864,
        "ul2": 28.162960823887424,
        "ul1": 33.76593841975621,
        "mean": 39.368916015625,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 44.97189361149378,
        "ur2": 50.57487120736257,
        "ur3": 56.177848803231356,
        "standardDeviation": 5.6029775958687855,
        "lowest": 12,
        "highest": 62
      },
      "250": {
        "ul3": 22.010470323498353,
        "ul2": 27.488978913582237,
        "ul1": 32.96748750366611,
        "mean": 38.44599609375,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 43.92450468383388,
        "ur2": 49.40301327391776,
        "ur3": 54.88152186400164,
        "standardDeviation": 5.478508590083881,
        "lowest": 13,
        "highest": 58
      },
      "300": {
        "ul3": 21.54182476897995,
        "ul2": 26.9315778407783,
        "ul1": 32.32133091257665,
        "mean": 37.711083984375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 43.100837056173354,
        "ur2": 48.4905901279717,
        "ur3": 53.88034319977005,
        "standardDeviation": 5.389753071798351,
        "lowest": 11,
        "highest": 58
      },
      "400": {
        "ul3": 20.821478788256503,
        "ul2": 26.069543801546004,
        "ul1": 31.317608814835502,
        "mean": 36.565673828125,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 41.8137388414145,
        "ur2": 47.061803854703996,
        "ur3": 52.3098688679935,
        "standardDeviation": 5.248065013289499,
        "lowest": 11,
        "highest": 56
      },
      "500": {
        "ul3": 20.212548675765515,
        "ul2": 25.38274078384368,
        "ul1": 30.55293289192184,
        "mean": 35.723125,
        "median": 36,
        "mostFrequent": 36,
        "ur1": 40.893317108078165,
        "ur2": 46.063509216156326,
        "ur3": 51.23370132423449,
        "standardDeviation": 5.1701921080781625,
        "lowest": 13,
        "highest": 59
      },
      "750": {
        "ul3": 19.32085034372792,
        "ul2": 24.29611051561028,
        "ul1": 29.27137068749264,
        "mean": 34.246630859375,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 39.221891031257364,
        "ur2": 44.197151203139725,
        "ur3": 49.17241137502209,
        "standardDeviation": 4.975260171882361,
        "lowest": 11,
        "highest": 55
      },
      "1000": {
        "ul3": 18.602638194400413,
        "ul2": 23.48238705147528,
        "ul1": 28.36213590855014,
        "mean": 33.241884765625,
        "median": 33,
        "mostFrequent": 34,
        "ur1": 38.12163362269987,
        "ur2": 43.001382479774726,
        "ur3": 47.88113133684959,
        "standardDeviation": 4.879748857074863,
        "lowest": 12,
        "highest": 54
      },
      "2000": {
        "ul3": 17.266554825830127,
        "ul2": 21.840841238053418,
        "ul1": 26.41512765027671,
        "mean": 30.9894140625,
        "median": 31,
        "mostFrequent": 31,
        "ur1": 35.56370047472329,
        "ur2": 40.13798688694658,
        "ur3": 44.71227329916987,
        "standardDeviation": 4.574286412223291,
        "lowest": 10,
        "highest": 51
      },
      "5000": {
        "ul3": 15.437304659367864,
        "ul2": 19.72127602291191,
        "ul1": 24.005247386455956,
        "mean": 28.28921875,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 32.573190113544044,
        "ur2": 36.85716147708809,
        "ur3": 41.14113284063214,
        "standardDeviation": 4.283971363544045,
        "lowest": 11,
        "highest": 51
      },
      "10000": {
        "ul3": 14.104303641516434,
        "ul2": 18.22706831309429,
        "ul1": 22.349832984672144,
        "mean": 26.47259765625,
        "median": 26,
        "mostFrequent": 26,
        "ur1": 30.595362327827853,
        "ur2": 34.71812699940571,
        "ur3": 38.84089167098357,
        "standardDeviation": 4.122764671577855,
        "lowest": 10,
        "highest": 47
      }
    },
  },
  {
    name: 'Het Hondenbot',
    squares: [6,10,11,12,13,14,15,19],
    odds: {
      "25": {
        "ul3": 28.526346859056495,
        "ul2": 35.57059191645433,
        "ul1": 42.614836973852164,
        "mean": 49.65908203125,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 56.70332708864784,
        "ur2": 63.74757214604567,
        "ur3": 70.79181720344351,
        "standardDeviation": 7.044245057397836,
        "lowest": 15,
        "highest": 70
      },
      "50": {
        "ul3": 26.15460413334933,
        "ul2": 32.827870854524555,
        "ul1": 39.50113757569977,
        "mean": 46.174404296875,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 52.84767101805022,
        "ur2": 59.52093773922544,
        "ur3": 66.19420446040067,
        "standardDeviation": 6.673266721175222,
        "lowest": 13,
        "highest": 66
      },
      "75": {
        "ul3": 24.994361552292517,
        "ul2": 31.428926581736675,
        "ul1": 37.86349161118084,
        "mean": 44.298056640625,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 50.73262167006916,
        "ur2": 57.16718669951332,
        "ur3": 63.60175172895748,
        "standardDeviation": 6.434565029444161,
        "lowest": 12,
        "highest": 68
      },
      "100": {
        "ul3": 24.014501086412974,
        "ul2": 30.330627677400315,
        "ul1": 36.64675426838765,
        "mean": 42.962880859375,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 49.27900745036234,
        "ur2": 55.59513404134968,
        "ur3": 61.91126063233702,
        "standardDeviation": 6.316126590987341,
        "lowest": 12,
        "highest": 63
      },
      "125": {
        "ul3": 23.292487431335914,
        "ul2": 29.50900529276561,
        "ul1": 35.72552315419531,
        "mean": 41.942041015625,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 48.158558877054695,
        "ur2": 54.375076738484395,
        "ur3": 60.59159459991409,
        "standardDeviation": 6.216517861429695,
        "lowest": 13,
        "highest": 62
      },
      "150": {
        "ul3": 22.859757945096547,
        "ul2": 28.971130947772696,
        "ul1": 35.082503950448846,
        "mean": 41.193876953125,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 47.30524995580115,
        "ur2": 53.416622958477305,
        "ur3": 59.52799596115345,
        "standardDeviation": 6.111373002676151,
        "lowest": 14,
        "highest": 63
      },
      "200": {
        "ul3": 21.97671632987555,
        "ul2": 27.975646173042033,
        "ul1": 33.974576016208516,
        "mean": 39.973505859375,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 45.97243570254149,
        "ur2": 51.97136554570797,
        "ur3": 57.970295388874455,
        "standardDeviation": 5.998929843166485,
        "lowest": 15,
        "highest": 61
      },
      "250": {
        "ul3": 21.405826805345974,
        "ul2": 27.290313573355647,
        "ul1": 33.17480034136533,
        "mean": 39.059287109375,
        "median": 39,
        "mostFrequent": 41,
        "ur1": 44.94377387738467,
        "ur2": 50.82826064539435,
        "ur3": 56.712747413404024,
        "standardDeviation": 5.884486768009675,
        "lowest": 13,
        "highest": 59
      },
      "300": {
        "ul3": 20.824969085129997,
        "ul2": 26.657280822378333,
        "ul1": 32.489592559626665,
        "mean": 38.321904296875,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 44.15421603412333,
        "ur2": 49.98652777137166,
        "ur3": 55.81883950862,
        "standardDeviation": 5.832311737248333,
        "lowest": 13,
        "highest": 59
      },
      "400": {
        "ul3": 20.226855235927438,
        "ul2": 25.902135261451626,
        "ul1": 31.577415286975814,
        "mean": 37.2526953125,
        "median": 37,
        "mostFrequent": 38,
        "ur1": 42.92797533802419,
        "ur2": 48.60325536354838,
        "ur3": 54.278535389072566,
        "standardDeviation": 5.675280025524188,
        "lowest": 12,
        "highest": 58
      },
      "500": {
        "ul3": 19.59555293754761,
        "ul2": 25.196595838573405,
        "ul1": 30.7976387395992,
        "mean": 36.398681640625,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 41.9997245416508,
        "ur2": 47.6007674426766,
        "ur3": 53.20181034370239,
        "standardDeviation": 5.601042901025798,
        "lowest": 13,
        "highest": 58
      },
      "750": {
        "ul3": 18.701906687609828,
        "ul2": 24.128683234448218,
        "ul1": 29.555459781286608,
        "mean": 34.982236328125,
        "median": 35,
        "mostFrequent": 36,
        "ur1": 40.40901287496339,
        "ur2": 45.83578942180178,
        "ur3": 51.26256596864017,
        "standardDeviation": 5.42677654683839,
        "lowest": 12,
        "highest": 55
      },
      "1000": {
        "ul3": 18.054225510065397,
        "ul2": 23.380863881710262,
        "ul1": 28.70750225335513,
        "mean": 34.034140625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 39.36077899664487,
        "ur2": 44.687417368289736,
        "ur3": 50.014055739934605,
        "standardDeviation": 5.326638371644868,
        "lowest": 12,
        "highest": 56
      },
      "2000": {
        "ul3": 16.53685462575732,
        "ul2": 21.62759709425488,
        "ul1": 26.71833956275244,
        "mean": 31.80908203125,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 36.89982449974756,
        "ur2": 41.99056696824512,
        "ur3": 47.08130943674268,
        "standardDeviation": 5.0907424684975595,
        "lowest": 11,
        "highest": 53
      },
      "5000": {
        "ul3": 14.683771329930352,
        "ul2": 19.518168516828567,
        "ul1": 24.352565703726786,
        "mean": 29.186962890625,
        "median": 29,
        "mostFrequent": 29,
        "ur1": 34.02136007752322,
        "ur2": 38.855757264421435,
        "ur3": 43.69015445131965,
        "standardDeviation": 4.834397186898216,
        "lowest": 11,
        "highest": 53
      },
      "10000": {
        "ul3": 13.422564860121355,
        "ul2": 18.089213161955904,
        "ul1": 22.755861463790453,
        "mean": 27.422509765625,
        "median": 27,
        "mostFrequent": 27,
        "ur1": 32.08915806745955,
        "ur2": 36.7558063692941,
        "ur3": 41.42245467112865,
        "standardDeviation": 4.666648301834549,
        "lowest": 9,
        "highest": 56
      }
    },
  },
  {
    name: 'Piramide',
    squares: [16,17,18,20,21,22,23,24],
    odds: {
      "25": {
        "ul3": 29.157480199447175,
        "ul2": 35.80113263296478,
        "ul1": 42.44478506648239,
        "mean": 49.0884375,
        "median": 50,
        "mostFrequent": 52,
        "ur1": 55.73208993351761,
        "ur2": 62.375742367035215,
        "ur3": 69.01939480055282,
        "standardDeviation": 6.643652433517608,
        "lowest": 16,
        "highest": 68
      },
      "50": {
        "ul3": 26.967602924128816,
        "ul2": 33.13569036087755,
        "ul1": 39.303777797626275,
        "mean": 45.471865234375,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 51.63995267112373,
        "ur2": 57.80804010787246,
        "ur3": 63.97612754462119,
        "standardDeviation": 6.168087436748729,
        "lowest": 14,
        "highest": 65
      },
      "75": {
        "ul3": 25.79128234186451,
        "ul2": 31.694051509159674,
        "ul1": 37.59682067645484,
        "mean": 43.49958984375,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 49.402359011045164,
        "ur2": 55.305128178340325,
        "ur3": 61.207897345635494,
        "standardDeviation": 5.902769167295164,
        "lowest": 15,
        "highest": 61
      },
      "100": {
        "ul3": 24.949171264618887,
        "ul2": 30.67691495766259,
        "ul1": 36.404658650706295,
        "mean": 42.13240234375,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 47.86014603679371,
        "ur2": 53.587889729837414,
        "ur3": 59.31563342288112,
        "standardDeviation": 5.727743693043705,
        "lowest": 14,
        "highest": 60
      },
      "125": {
        "ul3": 24.333422770686024,
        "ul2": 29.916793565874016,
        "ul1": 35.500164361062005,
        "mean": 41.08353515625,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 46.666905951438,
        "ur2": 52.250276746625985,
        "ur3": 57.833647541813974,
        "standardDeviation": 5.583370795187992,
        "lowest": 15,
        "highest": 61
      },
      "150": {
        "ul3": 23.75155630428854,
        "ul2": 29.247281025775692,
        "ul1": 34.74300574726284,
        "mean": 40.23873046875,
        "median": 41,
        "mostFrequent": 42,
        "ur1": 45.734455190237156,
        "ur2": 51.230179911724306,
        "ur3": 56.72590463321146,
        "standardDeviation": 5.495724721487154,
        "lowest": 11,
        "highest": 57
      },
      "200": {
        "ul3": 23.13354110641398,
        "ul2": 28.428099669900984,
        "ul1": 33.72265823338799,
        "mean": 39.017216796875,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 44.311775360362006,
        "ur2": 49.60633392384901,
        "ur3": 54.900892487336016,
        "standardDeviation": 5.294558563487007,
        "lowest": 12,
        "highest": 57
      },
      "250": {
        "ul3": 22.511784143724643,
        "ul2": 27.69559372602476,
        "ul1": 32.87940330832488,
        "mean": 38.063212890625,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 43.247022472925124,
        "ur2": 48.430832055225245,
        "ur3": 53.61464163752537,
        "standardDeviation": 5.18380958230012,
        "lowest": 9,
        "highest": 55
      },
      "300": {
        "ul3": 21.99006755464996,
        "ul2": 27.106418734349973,
        "ul1": 32.22276991404999,
        "mean": 37.33912109375,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 42.45547227345001,
        "ur2": 47.57182345315003,
        "ur3": 52.68817463285004,
        "standardDeviation": 5.116351179700014,
        "lowest": 11,
        "highest": 53
      },
      "400": {
        "ul3": 21.379948868816697,
        "ul2": 26.316424245877798,
        "ul1": 31.252899622938898,
        "mean": 36.189375,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 41.1258503770611,
        "ur2": 46.0623257541222,
        "ur3": 50.9988011311833,
        "standardDeviation": 4.9364753770611,
        "lowest": 11,
        "highest": 52
      },
      "500": {
        "ul3": 20.90530891707508,
        "ul2": 25.714102429091717,
        "ul1": 30.52289594110836,
        "mean": 35.331689453125,
        "median": 36,
        "mostFrequent": 36,
        "ur1": 40.14048296514164,
        "ur2": 44.949276477158286,
        "ur3": 49.75806998917493,
        "standardDeviation": 4.808793512016641,
        "lowest": 11,
        "highest": 52
      },
      "750": {
        "ul3": 19.923341122942784,
        "ul2": 24.556189003836856,
        "ul1": 29.18903688473093,
        "mean": 33.821884765625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 38.454732646519076,
        "ur2": 43.087580527413145,
        "ur3": 47.720428408307214,
        "standardDeviation": 4.632847880894072,
        "lowest": 12,
        "highest": 50
      },
      "1000": {
        "ul3": 19.391110033306635,
        "ul2": 23.870124787829425,
        "ul1": 28.349139542352212,
        "mean": 32.828154296875,
        "median": 33,
        "mostFrequent": 34,
        "ur1": 37.307169051397786,
        "ur2": 41.78618380592057,
        "ur3": 46.265198560443366,
        "standardDeviation": 4.479014754522788,
        "lowest": 10,
        "highest": 49
      },
      "2000": {
        "ul3": 17.96100971379926,
        "ul2": 22.14298434044951,
        "ul1": 26.324958967099754,
        "mean": 30.50693359375,
        "median": 31,
        "mostFrequent": 31,
        "ur1": 34.68890822040024,
        "ur2": 38.87088284705049,
        "ur3": 43.05285747370074,
        "standardDeviation": 4.181974626650246,
        "lowest": 11,
        "highest": 48
      },
      "5000": {
        "ul3": 16.279302871902235,
        "ul2": 20.11641675835149,
        "ul1": 23.953530644800743,
        "mean": 27.79064453125,
        "median": 28,
        "mostFrequent": 28,
        "ur1": 31.627758417699255,
        "ur2": 35.46487230414851,
        "ur3": 39.30198619059776,
        "standardDeviation": 3.837113886449255,
        "lowest": 10,
        "highest": 46
      },
      "10000": {
        "ul3": 15.06087706568838,
        "ul2": 18.677931715667256,
        "ul1": 22.29498636564613,
        "mean": 25.912041015625,
        "median": 26,
        "mostFrequent": 26,
        "ur1": 29.529095665603872,
        "ur2": 33.146150315582744,
        "ur3": 36.76320496556162,
        "standardDeviation": 3.617054649978873,
        "lowest": 9,
        "highest": 45
      }
    },
  },
  {
    name: 'Klein Vierkant',
    squares: [7,8,9,12,13,16,17,18],
    odds: {
      "25": {
        "ul3": 28.402136527466688,
        "ul2": 35.5556072943528,
        "ul1": 42.7090780612389,
        "mean": 49.862548828125,
        "median": 50,
        "mostFrequent": 52,
        "ur1": 57.0160195950111,
        "ur2": 64.1694903618972,
        "ur3": 71.3229611287833,
        "standardDeviation": 7.1534707668861035,
        "lowest": 15,
        "highest": 70
      },
      "50": {
        "ul3": 25.92648422964172,
        "ul2": 32.73715485101115,
        "ul1": 39.54782547238057,
        "mean": 46.35849609375,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 53.169166715119424,
        "ur2": 59.97983733648885,
        "ur3": 66.79050795785828,
        "standardDeviation": 6.810670621369426,
        "lowest": 14,
        "highest": 67
      },
      "75": {
        "ul3": 24.730480577691385,
        "ul2": 31.313839265335922,
        "ul1": 37.89719795298046,
        "mean": 44.480556640625,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 51.06391532826953,
        "ur2": 57.64727401591407,
        "ur3": 64.23063270355861,
        "standardDeviation": 6.583358687644537,
        "lowest": 14,
        "highest": 65
      },
      "100": {
        "ul3": 23.948755483751945,
        "ul2": 30.37178099958463,
        "ul1": 36.794806515417314,
        "mean": 43.21783203125,
        "median": 44,
        "mostFrequent": 44,
        "ur1": 49.64085754708268,
        "ur2": 56.063883062915366,
        "ur3": 62.48690857874805,
        "standardDeviation": 6.423025515832684,
        "lowest": 14,
        "highest": 66
      },
      "125": {
        "ul3": 23.184433495172748,
        "ul2": 29.528717382198497,
        "ul1": 35.87300126922425,
        "mean": 42.21728515625,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 48.56156904327575,
        "ur2": 54.905852930301506,
        "ur3": 61.25013681732725,
        "standardDeviation": 6.344283887025751,
        "lowest": 13,
        "highest": 62
      },
      "150": {
        "ul3": 22.746586966520894,
        "ul2": 28.983362665180593,
        "ul1": 35.2201383638403,
        "mean": 41.4569140625,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.693689761159696,
        "ur2": 53.9304654598194,
        "ur3": 60.1672411584791,
        "standardDeviation": 6.236775698659701,
        "lowest": 13,
        "highest": 62
      },
      "200": {
        "ul3": 21.90299502924753,
        "ul2": 28.01433067054002,
        "ul1": 34.12566631183251,
        "mean": 40.237001953125,
        "median": 41,
        "mostFrequent": 41,
        "ur1": 46.348337594417494,
        "ur2": 52.459673235709985,
        "ur3": 58.57100887700247,
        "standardDeviation": 6.111335641292491,
        "lowest": 15,
        "highest": 61
      },
      "250": {
        "ul3": 21.2161117103863,
        "ul2": 27.2481304631742,
        "ul1": 33.280149215962105,
        "mean": 39.31216796875,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 45.3441867215379,
        "ur2": 51.37620547432581,
        "ur3": 57.408224227113706,
        "standardDeviation": 6.032018752787901,
        "lowest": 11,
        "highest": 59
      },
      "300": {
        "ul3": 20.821025180853088,
        "ul2": 26.75639959973539,
        "ul1": 32.69177401861769,
        "mean": 38.6271484375,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 44.5625228563823,
        "ur2": 50.497897275264606,
        "ur3": 56.43327169414691,
        "standardDeviation": 5.935374418882303,
        "lowest": 12,
        "highest": 59
      },
      "400": {
        "ul3": 20.054166858955018,
        "ul2": 25.878861890345014,
        "ul1": 31.703556921735007,
        "mean": 37.528251953125,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 43.35294698451499,
        "ur2": 49.177642015904986,
        "ur3": 55.002337047294986,
        "standardDeviation": 5.824695031389994,
        "lowest": 13,
        "highest": 57
      },
      "500": {
        "ul3": 19.601942897321948,
        "ul2": 25.310618181547966,
        "ul1": 31.019293465773984,
        "mean": 36.72796875,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 42.43664403422602,
        "ur2": 48.14531931845204,
        "ur3": 53.85399460267806,
        "standardDeviation": 5.708675284226018,
        "lowest": 12,
        "highest": 57
      },
      "750": {
        "ul3": 18.587828528838752,
        "ul2": 24.158412378600836,
        "ul1": 29.728996228362917,
        "mean": 35.299580078125,
        "median": 35,
        "mostFrequent": 36,
        "ur1": 40.87016392788708,
        "ur2": 46.44074777764916,
        "ur3": 52.01133162741124,
        "standardDeviation": 5.570583849762081,
        "lowest": 11,
        "highest": 57
      },
      "1000": {
        "ul3": 17.85069826718912,
        "ul2": 23.33886069375108,
        "ul1": 28.82702312031304,
        "mean": 34.315185546875,
        "median": 34,
        "mostFrequent": 36,
        "ur1": 39.803347973436956,
        "ur2": 45.29151039999892,
        "ur3": 50.77967282656088,
        "standardDeviation": 5.48816242656196,
        "lowest": 13,
        "highest": 55
      },
      "2000": {
        "ul3": 16.34953914888548,
        "ul2": 21.618442765923653,
        "ul1": 26.887346382961827,
        "mean": 32.15625,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 37.42515361703818,
        "ur2": 42.69405723407635,
        "ur3": 47.96296085111452,
        "standardDeviation": 5.268903617038173,
        "lowest": 12,
        "highest": 54
      },
      "5000": {
        "ul3": 14.595526823906114,
        "ul2": 19.591672830520743,
        "ul1": 24.587818837135373,
        "mean": 29.58396484375,
        "median": 30,
        "mostFrequent": 30,
        "ur1": 34.580110850364626,
        "ur2": 39.576256856979256,
        "ur3": 44.57240286359389,
        "standardDeviation": 4.996146006614628,
        "lowest": 10,
        "highest": 50
      },
      "10000": {
        "ul3": 13.41807890342021,
        "ul2": 18.21642760228014,
        "ul1": 23.01477630114007,
        "mean": 27.813125,
        "median": 28,
        "mostFrequent": 27,
        "ur1": 32.61147369885993,
        "ur2": 37.40982239771986,
        "ur3": 42.20817109657979,
        "standardDeviation": 4.79834869885993,
        "lowest": 10,
        "highest": 50
      }
    },
  },
  {
    name: 'Letter T',
    squares: [1,2,3,4,5,8,17,22],
    odds: {
      "25": {
        "ul3": 28.509850086227495,
        "ul2": 35.60027440644333,
        "ul1": 42.690698726659164,
        "mean": 49.781123046875,
        "median": 50,
        "mostFrequent": 52,
        "ur1": 56.87154736709084,
        "ur2": 63.96197168730667,
        "ur3": 71.0523960075225,
        "standardDeviation": 7.090424320215836,
        "lowest": 16,
        "highest": 70
      },
      "50": {
        "ul3": 25.946908657431877,
        "ul2": 32.714381162246255,
        "ul1": 39.481853667060626,
        "mean": 46.249326171875,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 53.01679867668937,
        "ur2": 59.78427118150374,
        "ur3": 66.55174368631812,
        "standardDeviation": 6.767472504814373,
        "lowest": 15,
        "highest": 67
      },
      "75": {
        "ul3": 24.520258711787847,
        "ul2": 31.107555287025228,
        "ul1": 37.69485186226262,
        "mean": 44.2821484375,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 50.86944501273738,
        "ur2": 57.45674158797477,
        "ur3": 64.04403816321215,
        "standardDeviation": 6.587296575237384,
        "lowest": 13,
        "highest": 66
      },
      "100": {
        "ul3": 23.73659110989768,
        "ul2": 30.16440709409845,
        "ul1": 36.59222307829923,
        "mean": 43.0200390625,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 49.44785504670077,
        "ur2": 55.87567103090154,
        "ur3": 62.30348701510231,
        "standardDeviation": 6.427815984200772,
        "lowest": 13,
        "highest": 66
      },
      "125": {
        "ul3": 22.990723813853187,
        "ul2": 29.327406370693794,
        "ul1": 35.664088927534394,
        "mean": 42.000771484375,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 48.33745404121561,
        "ur2": 54.67413659805621,
        "ur3": 61.010819154896815,
        "standardDeviation": 6.336682556840604,
        "lowest": 14,
        "highest": 65
      },
      "150": {
        "ul3": 22.43733059544138,
        "ul2": 28.71030698550259,
        "ul1": 34.98328337556379,
        "mean": 41.256259765625,
        "median": 41,
        "mostFrequent": 42,
        "ur1": 47.52923615568621,
        "ur2": 53.802212545747416,
        "ur3": 60.075188935808626,
        "standardDeviation": 6.272976390061207,
        "lowest": 13,
        "highest": 64
      },
      "200": {
        "ul3": 21.53976326586067,
        "ul2": 27.712830458490444,
        "ul1": 33.885897651120224,
        "mean": 40.05896484375,
        "median": 40,
        "mostFrequent": 41,
        "ur1": 46.23203203637977,
        "ur2": 52.40509922900955,
        "ur3": 58.578166421639324,
        "standardDeviation": 6.173067192629776,
        "lowest": 12,
        "highest": 64
      },
      "250": {
        "ul3": 21.081194534064863,
        "ul2": 27.12313685083491,
        "ul1": 33.16507916760495,
        "mean": 39.207021484375,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 45.248963801145045,
        "ur2": 51.290906117915085,
        "ur3": 57.33284843468513,
        "standardDeviation": 6.041942316770045,
        "lowest": 12,
        "highest": 62
      },
      "300": {
        "ul3": 20.506526411290544,
        "ul2": 26.483270211693696,
        "ul1": 32.46001401209685,
        "mean": 38.4367578125,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 44.41350161290315,
        "ur2": 50.3902454133063,
        "ur3": 56.36698921370945,
        "standardDeviation": 5.976743800403151,
        "lowest": 13,
        "highest": 62
      },
      "400": {
        "ul3": 19.6631648562314,
        "ul2": 25.557031779154265,
        "ul1": 31.450898702077133,
        "mean": 37.344765625,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 43.23863254792287,
        "ur2": 49.13249947084574,
        "ur3": 55.0263663937686,
        "standardDeviation": 5.893866922922867,
        "lowest": 13,
        "highest": 60
      },
      "500": {
        "ul3": 19.16997773074774,
        "ul2": 24.960717575706827,
        "ul1": 30.751457420665915,
        "mean": 36.542197265625,
        "median": 37,
        "mostFrequent": 36,
        "ur1": 42.33293711058408,
        "ur2": 48.123676955543175,
        "ur3": 53.91441680050226,
        "standardDeviation": 5.790739844959086,
        "lowest": 13,
        "highest": 60
      },
      "750": {
        "ul3": 18.029677538156704,
        "ul2": 23.72398098897947,
        "ul1": 29.418284439802235,
        "mean": 35.112587890625,
        "median": 35,
        "mostFrequent": 35,
        "ur1": 40.806891341447766,
        "ur2": 46.50119479227053,
        "ur3": 52.1954982430933,
        "standardDeviation": 5.6943034508227655,
        "lowest": 12,
        "highest": 60
      },
      "1000": {
        "ul3": 17.46167256949399,
        "ul2": 23.028533666120996,
        "ul1": 28.595394762748,
        "mean": 34.162255859375,
        "median": 34,
        "mostFrequent": 34,
        "ur1": 39.729116956002,
        "ur2": 45.29597805262901,
        "ur3": 50.86283914925601,
        "standardDeviation": 5.566861096627003,
        "lowest": 11,
        "highest": 59
      },
      "2000": {
        "ul3": 15.811301050082655,
        "ul2": 21.197807470888435,
        "ul1": 26.58431389169422,
        "mean": 31.9708203125,
        "median": 32,
        "mostFrequent": 31,
        "ur1": 37.35732673330578,
        "ur2": 42.74383315411156,
        "ur3": 48.13033957491734,
        "standardDeviation": 5.386506420805781,
        "lowest": 10,
        "highest": 59
      },
      "5000": {
        "ul3": 13.845253791175713,
        "ul2": 19.027760339950476,
        "ul1": 24.210266888725236,
        "mean": 29.3927734375,
        "median": 29,
        "mostFrequent": 29,
        "ur1": 34.575279986274765,
        "ur2": 39.757786535049526,
        "ur3": 44.94029308382429,
        "standardDeviation": 5.182506548774763,
        "lowest": 10,
        "highest": 57
      },
      "10000": {
        "ul3": 12.441470441764324,
        "ul2": 17.51480190909288,
        "ul1": 22.588133376421442,
        "mean": 27.66146484375,
        "median": 27,
        "mostFrequent": 27,
        "ur1": 32.73479631107856,
        "ur2": 37.80812777840712,
        "ur3": 42.88145924573568,
        "standardDeviation": 5.0733314673285586,
        "lowest": 8,
        "highest": 55
      }
    },
  },
  {
    name: 'Het Zitbankje',
    squares: [11,12,13,14,16,18,21,23],
    odds: {
      "25": {
        "ul3": 28.516363346770955,
        "ul2": 35.570033246805636,
        "ul1": 42.62370314684031,
        "mean": 49.677373046875,
        "median": 50,
        "mostFrequent": 52,
        "ur1": 56.73104294690968,
        "ur2": 63.78471284694436,
        "ur3": 70.83838274697904,
        "standardDeviation": 7.053669900034681,
        "lowest": 14,
        "highest": 70
      },
      "50": {
        "ul3": 26.12861401906674,
        "ul2": 32.8092745804195,
        "ul1": 39.48993514177225,
        "mean": 46.170595703125,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 52.851256264477755,
        "ur2": 59.53191682583051,
        "ur3": 66.21257738718326,
        "standardDeviation": 6.680660561352753,
        "lowest": 15,
        "highest": 67
      },
      "75": {
        "ul3": 24.825553150121845,
        "ul2": 31.2890601729979,
        "ul1": 37.75256719587395,
        "mean": 44.21607421875,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 50.67958124162605,
        "ur2": 57.143088264502104,
        "ur3": 63.606595287378155,
        "standardDeviation": 6.463507022876052,
        "lowest": 14,
        "highest": 66
      },
      "100": {
        "ul3": 23.978686662940092,
        "ul2": 30.30578459821006,
        "ul1": 36.63288253348003,
        "mean": 42.95998046875,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 49.287078404019965,
        "ur2": 55.61417633928993,
        "ur3": 61.9412742745599,
        "standardDeviation": 6.327097935269968,
        "lowest": 13,
        "highest": 63
      },
      "125": {
        "ul3": 23.302387561175536,
        "ul2": 29.525550040783692,
        "ul1": 35.74871252039184,
        "mean": 41.971875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 48.19503747960815,
        "ur2": 54.4181999592163,
        "ur3": 60.64136243882446,
        "standardDeviation": 6.2231624796081535,
        "lowest": 13,
        "highest": 63
      },
      "150": {
        "ul3": 22.869804248939133,
        "ul2": 28.980038770126086,
        "ul1": 35.090273291313046,
        "mean": 41.2005078125,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.31074233368695,
        "ur2": 53.42097685487391,
        "ur3": 59.53121137606087,
        "standardDeviation": 6.110234521186956,
        "lowest": 15,
        "highest": 63
      },
      "200": {
        "ul3": 22.032621481200387,
        "ul2": 28.01353150830026,
        "ul1": 33.99444153540013,
        "mean": 39.9753515625,
        "median": 40,
        "mostFrequent": 42,
        "ur1": 45.956261589599876,
        "ur2": 51.93717161669974,
        "ur3": 57.91808164379962,
        "standardDeviation": 5.980910027099871,
        "lowest": 13,
        "highest": 61
      },
      "250": {
        "ul3": 21.41175407617751,
        "ul2": 27.310394644535005,
        "ul1": 33.2090352128925,
        "mean": 39.10767578125,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 45.0063163496075,
        "ur2": 50.904956917964995,
        "ur3": 56.803597486322495,
        "standardDeviation": 5.898640568357498,
        "lowest": 12,
        "highest": 61
      },
      "300": {
        "ul3": 20.923725742174135,
        "ul2": 26.73269216144942,
        "ul1": 32.541658580724715,
        "mean": 38.350625,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 44.15959141927529,
        "ur2": 49.96855783855058,
        "ur3": 55.77752425782587,
        "standardDeviation": 5.808966419275289,
        "lowest": 13,
        "highest": 59
      },
      "400": {
        "ul3": 20.174799022617353,
        "ul2": 25.869133593203237,
        "ul1": 31.563468163789118,
        "mean": 37.257802734375,
        "median": 37,
        "mostFrequent": 38,
        "ur1": 42.95213730496088,
        "ur2": 48.64647187554676,
        "ur3": 54.340806446132646,
        "standardDeviation": 5.694334570585881,
        "lowest": 12,
        "highest": 60
      },
      "500": {
        "ul3": 19.666340040924894,
        "ul2": 25.25054505332493,
        "ul1": 30.83475006572497,
        "mean": 36.418955078125,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 42.003160090525036,
        "ur2": 47.58736510292508,
        "ur3": 53.17157011532511,
        "standardDeviation": 5.5842050124000355,
        "lowest": 12,
        "highest": 58
      },
      "750": {
        "ul3": 18.719970734872653,
        "ul2": 24.1361133024151,
        "ul1": 29.55225586995755,
        "mean": 34.9683984375,
        "median": 35,
        "mostFrequent": 36,
        "ur1": 40.38454100504245,
        "ur2": 45.8006835725849,
        "ur3": 51.216826140127345,
        "standardDeviation": 5.416142567542449,
        "lowest": 9,
        "highest": 56
      },
      "1000": {
        "ul3": 18.012033848802,
        "ul2": 23.33653493565967,
        "ul1": 28.661036022517337,
        "mean": 33.985537109375,
        "median": 34,
        "mostFrequent": 34,
        "ur1": 39.31003819623267,
        "ur2": 44.634539283090334,
        "ur3": 49.959040369948,
        "standardDeviation": 5.3245010868576665,
        "lowest": 11,
        "highest": 54
      },
      "2000": {
        "ul3": 16.521521905554962,
        "ul2": 21.615581660994977,
        "ul1": 26.70964141643499,
        "mean": 31.803701171875,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 36.89776092731501,
        "ur2": 41.991820682755026,
        "ur3": 47.08588043819504,
        "standardDeviation": 5.094059755440012,
        "lowest": 11,
        "highest": 54
      },
      "5000": {
        "ul3": 14.67536558268446,
        "ul2": 19.513479398872974,
        "ul1": 24.351593215061484,
        "mean": 29.18970703125,
        "median": 29,
        "mostFrequent": 29,
        "ur1": 34.02782084743851,
        "ur2": 38.86593466362702,
        "ur3": 43.70404847981554,
        "standardDeviation": 4.838113816188513,
        "lowest": 10,
        "highest": 51
      },
      "10000": {
        "ul3": 13.423780254301764,
        "ul2": 18.09626691432618,
        "ul1": 22.76875357435059,
        "mean": 27.441240234375,
        "median": 27,
        "mostFrequent": 27,
        "ur1": 32.11372689439941,
        "ur2": 36.786213554423824,
        "ur3": 41.45870021444824,
        "standardDeviation": 4.672486660024412,
        "lowest": 10,
        "highest": 53
      }
    },
  },
  {
    name: 'De Bijl',
    squares: [3,5,7,8,9,10,14,19,24],
    odds: {
      "25": {
        "ul3": 31.949001064299686,
        "ul2": 38.865916074116456,
        "ul1": 45.78283108393323,
        "mean": 52.69974609375,
        "median": 53,
        "mostFrequent": 53,
        "ur1": 59.61666110356677,
        "ur2": 66.53357611338355,
        "ur3": 73.45049112320032,
        "standardDeviation": 6.916915009816771,
        "lowest": 19,
        "highest": 72
      },
      "50": {
        "ul3": 29.329333822764575,
        "ul2": 36.094217340176385,
        "ul1": 42.85910085758819,
        "mean": 49.623984375,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 56.38886789241181,
        "ur2": 63.15375140982361,
        "ur3": 69.91863492723542,
        "standardDeviation": 6.764883517411809,
        "lowest": 13,
        "highest": 71
      },
      "75": {
        "ul3": 27.983003668708065,
        "ul2": 34.610983565597046,
        "ul1": 41.23896346248602,
        "mean": 47.866943359375,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 54.49492325626398,
        "ur2": 61.122903153152954,
        "ur3": 67.75088305004194,
        "standardDeviation": 6.627979896888978,
        "lowest": 15,
        "highest": 70
      },
      "100": {
        "ul3": 27.034467154016898,
        "ul2": 33.59266885788627,
        "ul1": 40.15087056175563,
        "mean": 46.709072265625,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 53.26727396949436,
        "ur2": 59.82547567336373,
        "ur3": 66.3836773772331,
        "standardDeviation": 6.558201703869366,
        "lowest": 17,
        "highest": 70
      },
      "125": {
        "ul3": 26.27524437660779,
        "ul2": 32.79484390732186,
        "ul1": 39.31444343803593,
        "mean": 45.83404296875,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 52.353642499464065,
        "ur2": 58.87324203017813,
        "ur3": 65.39284156089221,
        "standardDeviation": 6.519599530714069,
        "lowest": 17,
        "highest": 67
      },
      "150": {
        "ul3": 25.770395149719082,
        "ul2": 32.203183355021054,
        "ul1": 38.63597156032303,
        "mean": 45.068759765625,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 51.50154797092698,
        "ur2": 57.93433617622895,
        "ur3": 64.36712438153093,
        "standardDeviation": 6.432788205301974,
        "lowest": 16,
        "highest": 67
      },
      "200": {
        "ul3": 24.869136981270863,
        "ul2": 31.26034913334724,
        "ul1": 37.651561285423625,
        "mean": 44.0427734375,
        "median": 44,
        "mostFrequent": 44,
        "ur1": 50.43398558957638,
        "ur2": 56.825197741652765,
        "ur3": 63.21640989372914,
        "standardDeviation": 6.39121215207638,
        "lowest": 15,
        "highest": 66
      },
      "250": {
        "ul3": 24.20966413230949,
        "ul2": 30.52942778091466,
        "ul1": 36.849191429519834,
        "mean": 43.168955078125,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 49.48871872673017,
        "ur2": 55.808482375335345,
        "ur3": 62.12824602394051,
        "standardDeviation": 6.3197636486051705,
        "lowest": 16,
        "highest": 66
      },
      "300": {
        "ul3": 23.506938687775932,
        "ul2": 29.822018369975623,
        "ul1": 36.13709805217531,
        "mean": 42.452177734375,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 48.76725741657469,
        "ur2": 55.082337098774374,
        "ur3": 61.39741678097407,
        "standardDeviation": 6.315079682199689,
        "lowest": 15,
        "highest": 64
      },
      "400": {
        "ul3": 22.90361025171459,
        "ul2": 29.106593032393057,
        "ul1": 35.30957581307153,
        "mean": 41.51255859375,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 47.71554137442847,
        "ur2": 53.918524155106944,
        "ur3": 60.12150693578541,
        "standardDeviation": 6.202982780678471,
        "lowest": 15,
        "highest": 66
      },
      "500": {
        "ul3": 22.179532846352235,
        "ul2": 28.35122502256816,
        "ul1": 34.52291719878408,
        "mean": 40.694609375,
        "median": 41,
        "mostFrequent": 40,
        "ur1": 46.86630155121592,
        "ur2": 53.03799372743184,
        "ur3": 59.20968590364777,
        "standardDeviation": 6.171692176215921,
        "lowest": 14,
        "highest": 64
      },
      "750": {
        "ul3": 21.05328870476857,
        "ul2": 27.164832443804045,
        "ul1": 33.27637618283953,
        "mean": 39.387919921875,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 45.49946366091048,
        "ur2": 51.61100739994596,
        "ur3": 57.72255113898144,
        "standardDeviation": 6.111543739035478,
        "lowest": 14,
        "highest": 64
      },
      "1000": {
        "ul3": 20.433948103746236,
        "ul2": 26.44794782437249,
        "ul1": 32.46194754499875,
        "mean": 38.475947265625,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 44.489946986251255,
        "ur2": 50.503946706877514,
        "ur3": 56.51794642750377,
        "standardDeviation": 6.013999720626256,
        "lowest": 13,
        "highest": 62
      },
      "2000": {
        "ul3": 18.731435600578276,
        "ul2": 24.630937535802182,
        "ul1": 30.530439471026092,
        "mean": 36.42994140625,
        "median": 36,
        "mostFrequent": 36,
        "ur1": 42.32944334147391,
        "ur2": 48.22894527669782,
        "ur3": 54.128447211921724,
        "standardDeviation": 5.899501935223909,
        "lowest": 11,
        "highest": 61
      },
      "5000": {
        "ul3": 16.693844890019996,
        "ul2": 22.462110786054996,
        "ul1": 28.230376682089997,
        "mean": 33.998642578125,
        "median": 34,
        "mostFrequent": 33,
        "ur1": 39.766908474160005,
        "ur2": 45.53517437019501,
        "ur3": 51.303440266230005,
        "standardDeviation": 5.768265896035002,
        "lowest": 13,
        "highest": 59
      },
      "10000": {
        "ul3": 15.204883462449967,
        "ul2": 20.92104861038331,
        "ul1": 26.637213758316655,
        "mean": 32.35337890625,
        "median": 32,
        "mostFrequent": 32,
        "ur1": 38.069544054183346,
        "ur2": 43.78570920211669,
        "ur3": 49.50187435005003,
        "standardDeviation": 5.716165147933345,
        "lowest": 12,
        "highest": 58
      }
    },
  },
  {
    name: 'Dubbele Lijn',
    squares: [6,7,8,9,10,15,16,17,18,19],
    odds: {
      "25": {
        "ul3": 36.37685053979851,
        "ul2": 42.10860022965734,
        "ul1": 47.84034991951617,
        "mean": 53.572099609375,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 59.30384929923383,
        "ur2": 65.03559898909266,
        "ur3": 70.76734867895149,
        "standardDeviation": 5.73174968985883,
        "lowest": 22,
        "highest": 69
      },
      "50": {
        "ul3": 34.057785396594525,
        "ul2": 39.491554196688014,
        "ul1": 44.92532299678151,
        "mean": 50.359091796875,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.79286059696849,
        "ur2": 61.226629397061984,
        "ur3": 66.66039819715547,
        "standardDeviation": 5.433768800093492,
        "lowest": 19,
        "highest": 65
      },
      "75": {
        "ul3": 33.017333752585884,
        "ul2": 38.21858057464059,
        "ul1": 43.4198273966953,
        "mean": 48.62107421875,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.82232104080471,
        "ur2": 59.023567862859416,
        "ur3": 64.22481468491412,
        "standardDeviation": 5.201246822054707,
        "lowest": 17,
        "highest": 64
      },
      "100": {
        "ul3": 32.17518613555318,
        "ul2": 37.24529987161878,
        "ul1": 42.31541360768439,
        "mean": 47.38552734375,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 52.45564107981561,
        "ur2": 57.525754815881214,
        "ur3": 62.595868551946815,
        "standardDeviation": 5.070113736065606,
        "lowest": 21,
        "highest": 64
      },
      "125": {
        "ul3": 31.446534217332978,
        "ul2": 36.447562525096984,
        "ul1": 41.448590832860994,
        "mean": 46.449619140625,
        "median": 47,
        "mostFrequent": 49,
        "ur1": 51.45064744838901,
        "ur2": 56.45167575615302,
        "ur3": 61.452704063917025,
        "standardDeviation": 5.001028307764009,
        "lowest": 19,
        "highest": 60
      },
      "150": {
        "ul3": 31.102875238104023,
        "ul2": 35.97167268477768,
        "ul1": 40.84047013145134,
        "mean": 45.709267578125,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.57806502479866,
        "ur2": 55.44686247147232,
        "ur3": 60.31565991814598,
        "standardDeviation": 4.86879744667366,
        "lowest": 19,
        "highest": 60
      },
      "200": {
        "ul3": 30.3515165723325,
        "ul2": 35.103328756555,
        "ul1": 39.855140940777495,
        "mean": 44.606953125,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.3587653092225,
        "ur2": 54.11057749344499,
        "ur3": 58.862389677667494,
        "standardDeviation": 4.7518121842225,
        "lowest": 16,
        "highest": 57
      },
      "250": {
        "ul3": 29.73061867810039,
        "ul2": 34.382684587483595,
        "ul1": 39.0347504968668,
        "mean": 43.68681640625,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.33888231563321,
        "ur2": 52.99094822501641,
        "ur3": 57.643014134399614,
        "standardDeviation": 4.652065909383204,
        "lowest": 18,
        "highest": 56
      },
      "300": {
        "ul3": 29.269968984593554,
        "ul2": 33.85713752618737,
        "ul1": 38.444306067781184,
        "mean": 43.031474609375,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.61864315096882,
        "ur2": 52.205811692562634,
        "ur3": 56.79298023415645,
        "standardDeviation": 4.587168541593817,
        "lowest": 20,
        "highest": 56
      },
      "400": {
        "ul3": 28.614281535361805,
        "ul2": 33.06590448711621,
        "ul1": 37.5175274388706,
        "mean": 41.969150390625,
        "median": 42,
        "mostFrequent": 44,
        "ur1": 46.420773342379405,
        "ur2": 50.8723962941338,
        "ur3": 55.3240192458882,
        "standardDeviation": 4.451622951754399,
        "lowest": 18,
        "highest": 55
      },
      "500": {
        "ul3": 28.071745591008423,
        "ul2": 32.434510081505614,
        "ul1": 36.797274572002806,
        "mean": 41.1600390625,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 45.52280355299719,
        "ur2": 49.88556804349438,
        "ur3": 54.24833253399157,
        "standardDeviation": 4.362764490497192,
        "lowest": 18,
        "highest": 54
      },
      "750": {
        "ul3": 27.128477337663448,
        "ul2": 31.34163137615063,
        "ul1": 35.55478541463782,
        "mean": 39.767939453125,
        "median": 40,
        "mostFrequent": 41,
        "ur1": 43.981093491612185,
        "ur2": 48.194247530099375,
        "ur3": 52.40740156858656,
        "standardDeviation": 4.213154038487185,
        "lowest": 18,
        "highest": 54
      },
      "1000": {
        "ul3": 26.48577462733836,
        "ul2": 30.581401834892237,
        "ul1": 34.677029042446115,
        "mean": 38.77265625,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 42.86828345755388,
        "ur2": 46.96391066510776,
        "ur3": 51.059537872661636,
        "standardDeviation": 4.09562720755388,
        "lowest": 17,
        "highest": 51
      },
      "2000": {
        "ul3": 25.047882790782438,
        "ul2": 28.88470831885496,
        "ul1": 32.72153384692748,
        "mean": 36.558359375,
        "median": 37,
        "mostFrequent": 38,
        "ur1": 40.395184903072526,
        "ur2": 44.23201043114504,
        "ur3": 48.068835959217566,
        "standardDeviation": 3.836825528072522,
        "lowest": 12,
        "highest": 50
      },
      "5000": {
        "ul3": 23.2590374400139,
        "ul2": 26.799071835009265,
        "ul1": 30.33910623000463,
        "mean": 33.879140625,
        "median": 34,
        "mostFrequent": 35,
        "ur1": 37.419175019995365,
        "ur2": 40.95920941499073,
        "ur3": 44.4992438099861,
        "standardDeviation": 3.540034394995366,
        "lowest": 13,
        "highest": 48
      },
      "10000": {
        "ul3": 22.018921248932884,
        "ul2": 25.340612212830255,
        "ul1": 28.662303176727626,
        "mean": 31.983994140625,
        "median": 32,
        "mostFrequent": 33,
        "ur1": 35.305685104522375,
        "ur2": 38.62737606841975,
        "ur3": 41.94906703231712,
        "standardDeviation": 3.321690963897373,
        "lowest": 14,
        "highest": 47
      }
    },
  },
  {
    name: 'Vliegtuig',
    squares: [3,6,7,8,9,10,16,17,18,22],
    odds: {
      "25": {
        "ul3": 35.84435734045742,
        "ul2": 41.89216921655495,
        "ul1": 47.939981092652474,
        "mean": 53.98779296875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 60.035604844847526,
        "ur2": 66.08341672094505,
        "ur3": 72.13122859704258,
        "standardDeviation": 6.0478118760975255,
        "lowest": 18,
        "highest": 71
      },
      "50": {
        "ul3": 33.59409841132581,
        "ul2": 39.36011834192554,
        "ul1": 45.12613827252527,
        "mean": 50.892158203125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 56.65817813372473,
        "ur2": 62.42419806432446,
        "ur3": 68.19021799492418,
        "standardDeviation": 5.76601993059973,
        "lowest": 17,
        "highest": 69
      },
      "75": {
        "ul3": 32.24251810214957,
        "ul2": 37.89375230247471,
        "ul1": 43.54498650279986,
        "mean": 49.196220703125,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.84745490345014,
        "ur2": 60.49868910377529,
        "ur3": 66.14992330410044,
        "standardDeviation": 5.651234200325145,
        "lowest": 21,
        "highest": 67
      },
      "100": {
        "ul3": 31.461654537540994,
        "ul2": 36.972076332319,
        "ul1": 42.482498127096996,
        "mean": 47.992919921875,
        "median": 48,
        "mostFrequent": 50,
        "ur1": 53.503341716653004,
        "ur2": 59.013763511431,
        "ur3": 64.524185306209,
        "standardDeviation": 5.510421794778002,
        "lowest": 22,
        "highest": 66
      },
      "125": {
        "ul3": 30.64247943973981,
        "ul2": 36.12908134524321,
        "ul1": 41.6156832507466,
        "mean": 47.10228515625,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 52.5888870617534,
        "ur2": 58.07548896725679,
        "ur3": 63.56209087276019,
        "standardDeviation": 5.486601905503396,
        "lowest": 19,
        "highest": 66
      },
      "150": {
        "ul3": 30.102230469970678,
        "ul2": 35.52642838623045,
        "ul1": 40.95062630249023,
        "mean": 46.37482421875,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.79902213500977,
        "ur2": 57.22322005126955,
        "ur3": 62.64741796752932,
        "standardDeviation": 5.424197916259773,
        "lowest": 18,
        "highest": 65
      },
      "200": {
        "ul3": 29.329310099958334,
        "ul2": 34.64376207184722,
        "ul1": 39.95821404373611,
        "mean": 45.272666015625,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.587117987513885,
        "ur2": 55.90156995940278,
        "ur3": 61.216021931291664,
        "standardDeviation": 5.314451971888888,
        "lowest": 17,
        "highest": 63
      },
      "250": {
        "ul3": 28.854844824661804,
        "ul2": 34.0605475914412,
        "ul1": 39.2662503582206,
        "mean": 44.471953125,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.677655891779395,
        "ur2": 54.8833586585588,
        "ur3": 60.089061425338194,
        "standardDeviation": 5.205702766779399,
        "lowest": 18,
        "highest": 63
      },
      "300": {
        "ul3": 28.263968870275065,
        "ul2": 33.43869409060004,
        "ul1": 38.61341931092502,
        "mean": 43.78814453125,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.96286975157498,
        "ur2": 54.13759497189996,
        "ur3": 59.31232019222493,
        "standardDeviation": 5.174725220324978,
        "lowest": 18,
        "highest": 64
      },
      "400": {
        "ul3": 27.54398706514546,
        "ul2": 32.61502783509697,
        "ul1": 37.68606860504848,
        "mean": 42.757109375,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 47.828150144951515,
        "ur2": 52.89919091490303,
        "ur3": 57.97023168485454,
        "standardDeviation": 5.071040769951514,
        "lowest": 18,
        "highest": 62
      },
      "500": {
        "ul3": 26.996474756547155,
        "ul2": 32.00597666061477,
        "ul1": 37.01547856468238,
        "mean": 42.02498046875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 47.03448237281762,
        "ur2": 52.04398427688523,
        "ur3": 57.053486180952845,
        "standardDeviation": 5.009501904067616,
        "lowest": 15,
        "highest": 63
      },
      "750": {
        "ul3": 25.991007558583103,
        "ul2": 30.86759227863874,
        "ul1": 35.74417699869437,
        "mean": 40.62076171875,
        "median": 41,
        "mostFrequent": 41,
        "ur1": 45.497346438805636,
        "ur2": 50.37393115886127,
        "ur3": 55.2505158789169,
        "standardDeviation": 4.876584720055633,
        "lowest": 17,
        "highest": 60
      },
      "1000": {
        "ul3": 25.213646313111578,
        "ul2": 30.037027229574385,
        "ul1": 34.86040814603719,
        "mean": 39.6837890625,
        "median": 40,
        "mostFrequent": 41,
        "ur1": 44.50716997896281,
        "ur2": 49.33055089542561,
        "ur3": 54.15393181188841,
        "standardDeviation": 4.823380916462806,
        "lowest": 16,
        "highest": 59
      },
      "2000": {
        "ul3": 23.642132773017337,
        "ul2": 28.27637953096989,
        "ul1": 32.910626288922444,
        "mean": 37.544873046875,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 42.17911980482755,
        "ur2": 46.813366562780104,
        "ur3": 51.44761332073266,
        "standardDeviation": 4.634246757952553,
        "lowest": 16,
        "highest": 57
      },
      "5000": {
        "ul3": 21.616987563689626,
        "ul2": 26.064655120584753,
        "ul1": 30.512322677479876,
        "mean": 34.959990234375,
        "median": 35,
        "mostFrequent": 35,
        "ur1": 39.407657791270125,
        "ur2": 43.855325348165245,
        "ur3": 48.30299290506037,
        "standardDeviation": 4.447667556895124,
        "lowest": 15,
        "highest": 56
      },
      "10000": {
        "ul3": 20.17781188658686,
        "ul2": 24.49899373168291,
        "ul1": 28.820175576778954,
        "mean": 33.141357421875,
        "median": 33,
        "mostFrequent": 33,
        "ur1": 37.46253926697105,
        "ur2": 41.78372111206709,
        "ur3": 46.10490295716314,
        "standardDeviation": 4.321181845096047,
        "lowest": 13,
        "highest": 54
      }
    },
  },
  {
    name: 'De Boom',
    squares: [3,7,8,9,11,12,13,14,17,22],
    odds: {
      "25": {
        "ul3": 35.780108746165354,
        "ul2": 41.84653734119357,
        "ul1": 47.91296593622179,
        "mean": 53.97939453125,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 60.045823126278215,
        "ur2": 66.11225172130644,
        "ur3": 72.17868031633465,
        "standardDeviation": 6.066428595028216,
        "lowest": 20,
        "highest": 70
      },
      "50": {
        "ul3": 33.5169010499409,
        "ul2": 39.30806424162727,
        "ul1": 45.09922743331364,
        "mean": 50.890390625,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 56.68155381668637,
        "ur2": 62.47271700837273,
        "ur3": 68.2638802000591,
        "standardDeviation": 5.791163191686366,
        "lowest": 20,
        "highest": 68
      },
      "75": {
        "ul3": 32.142166965546124,
        "ul2": 37.81483331557242,
        "ul1": 43.48749966559871,
        "mean": 49.160166015625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.83283236565129,
        "ur2": 60.50549871567758,
        "ur3": 66.17816506570388,
        "standardDeviation": 5.672666350026291,
        "lowest": 21,
        "highest": 67
      },
      "100": {
        "ul3": 31.39484486155066,
        "ul2": 36.93569084520044,
        "ul1": 42.47653682885022,
        "mean": 48.0173828125,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 53.558228796149784,
        "ur2": 59.099074779799565,
        "ur3": 64.63992076344934,
        "standardDeviation": 5.540845983649781,
        "lowest": 18,
        "highest": 65
      },
      "125": {
        "ul3": 30.670655243941695,
        "ul2": 36.14458396471113,
        "ul1": 41.61851268548057,
        "mean": 47.09244140625,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 52.56637012701944,
        "ur2": 58.040298847788875,
        "ur3": 63.51422756855831,
        "standardDeviation": 5.473928720769436,
        "lowest": 18,
        "highest": 65
      },
      "150": {
        "ul3": 30.209859128855822,
        "ul2": 35.60686246611221,
        "ul1": 41.003865803368605,
        "mean": 46.400869140625,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.79787247788139,
        "ur2": 57.194875815137785,
        "ur3": 62.59187915239417,
        "standardDeviation": 5.397003337256392,
        "lowest": 19,
        "highest": 63
      },
      "200": {
        "ul3": 29.37219515686461,
        "ul2": 34.678009661868074,
        "ul1": 39.983824166871536,
        "mean": 45.289638671875,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 50.59545317687846,
        "ur2": 55.90126768188192,
        "ur3": 61.20708218688539,
        "standardDeviation": 5.305814505003462,
        "lowest": 19,
        "highest": 65
      },
      "250": {
        "ul3": 28.73713581515266,
        "ul2": 33.9817624184351,
        "ul1": 39.22638902171755,
        "mean": 44.471015625,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.71564222828245,
        "ur2": 54.960268831564896,
        "ur3": 60.204895434847344,
        "standardDeviation": 5.244626603282447,
        "lowest": 16,
        "highest": 63
      },
      "300": {
        "ul3": 28.25171909811287,
        "ul2": 33.43386741957524,
        "ul1": 38.61601574103762,
        "mean": 43.7981640625,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.98031238396238,
        "ur2": 54.162460705424756,
        "ur3": 59.34460902688713,
        "standardDeviation": 5.182148321462377,
        "lowest": 19,
        "highest": 63
      },
      "400": {
        "ul3": 27.518188407136748,
        "ul2": 32.59481115163283,
        "ul1": 37.67143389612892,
        "mean": 42.748056640625,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 47.82467938512109,
        "ur2": 52.90130212961717,
        "ur3": 57.977924874113256,
        "standardDeviation": 5.076622744496085,
        "lowest": 16,
        "highest": 62
      },
      "500": {
        "ul3": 26.974330883760413,
        "ul2": 31.973443896465277,
        "ul1": 36.97255690917014,
        "mean": 41.971669921875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 46.97078293457986,
        "ur2": 51.96989594728472,
        "ur3": 56.96900895998958,
        "standardDeviation": 4.9991130127048615,
        "lowest": 17,
        "highest": 63
      },
      "750": {
        "ul3": 25.88107882018292,
        "ul2": 30.786184708246946,
        "ul1": 35.69129059631097,
        "mean": 40.596396484375,
        "median": 41,
        "mostFrequent": 41,
        "ur1": 45.50150237243903,
        "ur2": 50.40660826050305,
        "ur3": 55.31171414856708,
        "standardDeviation": 4.905105888064027,
        "lowest": 15,
        "highest": 60
      },
      "1000": {
        "ul3": 25.232909545150353,
        "ul2": 30.050673420725232,
        "ul1": 34.868437296300115,
        "mean": 39.686201171875,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 44.50396504744988,
        "ur2": 49.321728923024764,
        "ur3": 54.13949279859965,
        "standardDeviation": 4.817763875574882,
        "lowest": 15,
        "highest": 60
      },
      "2000": {
        "ul3": 23.610438968053977,
        "ul2": 28.25170215057765,
        "ul1": 32.89296533310133,
        "mean": 37.534228515625,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 42.175491698148676,
        "ur2": 46.816754880672356,
        "ur3": 51.45801806319603,
        "standardDeviation": 4.641263182523676,
        "lowest": 18,
        "highest": 57
      },
      "5000": {
        "ul3": 21.642106153218798,
        "ul2": 26.080010872979198,
        "ul1": 30.517915592739598,
        "mean": 34.9558203125,
        "median": 35,
        "mostFrequent": 35,
        "ur1": 39.393725032260406,
        "ur2": 43.8316297520208,
        "ur3": 48.269534471781206,
        "standardDeviation": 4.437904719760402,
        "lowest": 14,
        "highest": 56
      },
      "10000": {
        "ul3": 20.237760695489115,
        "ul2": 24.544955698034407,
        "ul1": 28.852150700579703,
        "mean": 33.159345703125,
        "median": 33,
        "mostFrequent": 33,
        "ur1": 37.46654070567029,
        "ur2": 41.77373570821559,
        "ur3": 46.08093071076088,
        "standardDeviation": 4.307195002545295,
        "lowest": 14,
        "highest": 54
      }
    },
  },
  {
    name: 'Letter Z',
    squares: [1,2,3,4,5,9,16,20,21,22,23,24],
    odds: {
      "25": {
        "ul3": 41.67849670519066,
        "ul2": 46.7801566576271,
        "ul1": 51.88181661006355,
        "mean": 56.9834765625,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.08513651493645,
        "ur2": 67.1867964673729,
        "ur3": 72.28845641980935,
        "standardDeviation": 5.101659952436448,
        "lowest": 28,
        "highest": 70
      },
      "50": {
        "ul3": 39.49375132442054,
        "ul2": 44.392823148572035,
        "ul1": 49.29189497272352,
        "mean": 54.190966796875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.09003862102649,
        "ur2": 63.98911044517797,
        "ur3": 68.88818226932946,
        "standardDeviation": 4.899071824151486,
        "lowest": 25,
        "highest": 67
      },
      "75": {
        "ul3": 38.446323556174804,
        "ul2": 43.16861349057487,
        "ul1": 47.89090342497494,
        "mean": 52.613193359375,
        "median": 53,
        "mostFrequent": 55,
        "ur1": 57.33548329377507,
        "ur2": 62.05777322817514,
        "ur3": 66.7800631625752,
        "standardDeviation": 4.7222899344000675,
        "lowest": 25,
        "highest": 65
      },
      "100": {
        "ul3": 37.582893546883696,
        "ul2": 42.238065098964135,
        "ul1": 46.89323665104457,
        "mean": 51.548408203125,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.20357975520543,
        "ur2": 60.858751307285864,
        "ur3": 65.5139228593663,
        "standardDeviation": 4.655171552080433,
        "lowest": 25,
        "highest": 65
      },
      "125": {
        "ul3": 37.098486908826125,
        "ul2": 41.64445676734242,
        "ul1": 46.190426625858706,
        "mean": 50.736396484375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.282366342891294,
        "ur2": 59.82833620140758,
        "ur3": 64.37430605992388,
        "standardDeviation": 4.545969858516292,
        "lowest": 24,
        "highest": 64
      },
      "150": {
        "ul3": 36.5092919793614,
        "ul2": 41.02631835082427,
        "ul1": 45.54334472228713,
        "mean": 50.06037109375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 54.57739746521287,
        "ur2": 59.09442383667573,
        "ur3": 63.6114502081386,
        "standardDeviation": 4.517026371462866,
        "lowest": 22,
        "highest": 63
      },
      "200": {
        "ul3": 35.775015737878576,
        "ul2": 40.198265700252385,
        "ul1": 44.62151566262619,
        "mean": 49.044765625,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 53.468015587373806,
        "ur2": 57.89126554974761,
        "ur3": 62.31451551212142,
        "standardDeviation": 4.423249962373807,
        "lowest": 22,
        "highest": 63
      },
      "250": {
        "ul3": 35.264229048919915,
        "ul2": 39.60817743886328,
        "ul1": 43.95212582880664,
        "mean": 48.29607421875,
        "median": 49,
        "mostFrequent": 49,
        "ur1": 52.64002260869336,
        "ur2": 56.98397099863672,
        "ur3": 61.327919388580085,
        "standardDeviation": 4.34394838994336,
        "lowest": 22,
        "highest": 61
      },
      "300": {
        "ul3": 34.81174759921299,
        "ul2": 39.09258433697533,
        "ul1": 43.373421074737664,
        "mean": 47.6542578125,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.935094550262335,
        "ur2": 56.21593128802467,
        "ur3": 60.496768025787006,
        "standardDeviation": 4.2808367377623355,
        "lowest": 23,
        "highest": 60
      },
      "400": {
        "ul3": 34.106500120005784,
        "ul2": 38.299278074795524,
        "ul1": 42.49205602958526,
        "mean": 46.684833984375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 50.87761193916474,
        "ur2": 55.07038989395447,
        "ur3": 59.26316784874421,
        "standardDeviation": 4.192777954789738,
        "lowest": 23,
        "highest": 59
      },
      "500": {
        "ul3": 33.555747756589376,
        "ul2": 37.686237436684586,
        "ul1": 41.81672711677979,
        "mean": 45.947216796875,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.07770647697021,
        "ur2": 54.20819615706541,
        "ur3": 58.33868583716062,
        "standardDeviation": 4.130489680095207,
        "lowest": 19,
        "highest": 58
      },
      "750": {
        "ul3": 32.63956722688758,
        "ul2": 36.64732216167505,
        "ul1": 40.65507709646253,
        "mean": 44.66283203125,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.67058696603747,
        "ur2": 52.67834190082495,
        "ur3": 56.68609683561242,
        "standardDeviation": 4.0077549347874735,
        "lowest": 20,
        "highest": 58
      },
      "1000": {
        "ul3": 31.93133324842036,
        "ul2": 35.88015315519691,
        "ul1": 39.82897306197345,
        "mean": 43.77779296875,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.72661287552655,
        "ur2": 51.67543278230309,
        "ur3": 55.62425268907964,
        "standardDeviation": 3.948819906776546,
        "lowest": 20,
        "highest": 56
      },
      "2000": {
        "ul3": 30.459732773044735,
        "ul2": 34.20199958307149,
        "ul1": 37.944266393098246,
        "mean": 41.686533203125,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 45.42880001315176,
        "ur2": 49.17106682317851,
        "ur3": 52.91333363320527,
        "standardDeviation": 3.742266810026756,
        "lowest": 22,
        "highest": 54
      },
      "5000": {
        "ul3": 28.612228435189742,
        "ul2": 32.130343045334826,
        "ul1": 35.648457655479916,
        "mean": 39.166572265625,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 42.68468687577008,
        "ur2": 46.202801485915174,
        "ur3": 49.72091609606026,
        "standardDeviation": 3.5181146101450866,
        "lowest": 20,
        "highest": 54
      },
      "10000": {
        "ul3": 27.231001663009913,
        "ul2": 30.60849980658994,
        "ul1": 33.985997950169974,
        "mean": 37.36349609375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 40.74099423733003,
        "ur2": 44.11849238091006,
        "ur3": 47.49599052449009,
        "standardDeviation": 3.3774981435800298,
        "lowest": 18,
        "highest": 51
      }
    }
  },
  {
    name: 'Dambord',
    squares: [1,3,5,7,9,11,14,16,18,20,22,24],
    odds: {
      "25": {
        "ul3": 41.52961747482318,
        "ul2": 46.67538951446545,
        "ul1": 51.821161554107725,
        "mean": 56.96693359375,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.112705633392274,
        "ur2": 67.25847767303455,
        "ur3": 72.40424971267682,
        "standardDeviation": 5.1457720396422735,
        "lowest": 22,
        "highest": 70
      },
      "50": {
        "ul3": 39.50800838756989,
        "ul2": 44.40225624275492,
        "ul1": 49.296504097939966,
        "mean": 54.190751953125,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.08499980831004,
        "ur2": 63.97924766349508,
        "ur3": 68.87349551868012,
        "standardDeviation": 4.894247855185038,
        "lowest": 24,
        "highest": 67
      },
      "75": {
        "ul3": 38.410000151632424,
        "ul2": 43.14550465837995,
        "ul1": 47.88100916512747,
        "mean": 52.616513671875,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.35201817862252,
        "ur2": 62.08752268537005,
        "ur3": 66.82302719211756,
        "standardDeviation": 4.7355045067475245,
        "lowest": 26,
        "highest": 67
      },
      "100": {
        "ul3": 37.62817789924465,
        "ul2": 42.2634630005381,
        "ul1": 46.89874810183155,
        "mean": 51.534033203125,
        "median": 52,
        "mostFrequent": 54,
        "ur1": 56.16931830441845,
        "ur2": 60.804603405711894,
        "ur3": 65.43988850700535,
        "standardDeviation": 4.635285101293449,
        "lowest": 25,
        "highest": 65
      },
      "125": {
        "ul3": 36.94474912535419,
        "ul2": 41.52997272419446,
        "ul1": 46.11519632303473,
        "mean": 50.700419921875,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.285643520715276,
        "ur2": 59.87086711955555,
        "ur3": 64.45609071839581,
        "standardDeviation": 4.585223598840272,
        "lowest": 20,
        "highest": 64
      },
      "150": {
        "ul3": 36.54972212764122,
        "ul2": 41.05354522051081,
        "ul1": 45.55736831338041,
        "mean": 50.06119140625,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 54.5650144991196,
        "ur2": 59.068837591989194,
        "ur3": 63.57266068485879,
        "standardDeviation": 4.503823092869595,
        "lowest": 25,
        "highest": 62
      },
      "200": {
        "ul3": 35.81676525649568,
        "ul2": 40.22218920745545,
        "ul1": 44.62761315841523,
        "mean": 49.033037109375,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.438461060334774,
        "ur2": 57.84388501129455,
        "ur3": 62.249308962254325,
        "standardDeviation": 4.405423950959775,
        "lowest": 25,
        "highest": 63
      },
      "250": {
        "ul3": 35.20473505144835,
        "ul2": 39.555484174923905,
        "ul1": 43.90623329839945,
        "mean": 48.256982421875,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.60773154535055,
        "ur2": 56.9584806688261,
        "ur3": 61.30922979230165,
        "standardDeviation": 4.350749123475549,
        "lowest": 25,
        "highest": 62
      },
      "300": {
        "ul3": 34.777850099276165,
        "ul2": 39.070021810975774,
        "ul1": 43.36219352267538,
        "mean": 47.654365234375,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.946536946074616,
        "ur2": 56.238708657774225,
        "ur3": 60.530880369473834,
        "standardDeviation": 4.292171711699613,
        "lowest": 23,
        "highest": 60
      },
      "400": {
        "ul3": 34.13009039581607,
        "ul2": 38.31452640971071,
        "ul1": 42.498962423605356,
        "mean": 46.6833984375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 50.86783445139465,
        "ur2": 55.0522704652893,
        "ur3": 59.23670647918394,
        "standardDeviation": 4.184436013894646,
        "lowest": 21,
        "highest": 60
      },
      "500": {
        "ul3": 33.59182871729427,
        "ul2": 37.713142972987846,
        "ul1": 41.83445722868142,
        "mean": 45.955771484375,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.077085740068576,
        "ur2": 54.19839999576215,
        "ur3": 58.31971425145573,
        "standardDeviation": 4.1213142556935765,
        "lowest": 21,
        "highest": 58
      },
      "750": {
        "ul3": 32.66344103633372,
        "ul2": 36.66014558672248,
        "ul1": 40.65685013711124,
        "mean": 44.6535546875,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.650259237888754,
        "ur2": 52.64696378827752,
        "ur3": 56.643668338666274,
        "standardDeviation": 3.9967045503887584,
        "lowest": 22,
        "highest": 57
      },
      "1000": {
        "ul3": 31.94299725416648,
        "ul2": 35.87697863819432,
        "ul1": 39.81096002222216,
        "mean": 43.74494140625,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.67892279027784,
        "ur2": 51.612904174305676,
        "ur3": 55.546885558333514,
        "standardDeviation": 3.933981384027839,
        "lowest": 21,
        "highest": 56
      },
      "2000": {
        "ul3": 30.43959354705091,
        "ul2": 34.18942304178394,
        "ul1": 37.939252536516975,
        "mean": 41.68908203125,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 45.43891152598303,
        "ur2": 49.188741020716066,
        "ur3": 52.93857051544909,
        "standardDeviation": 3.7498294947330297,
        "lowest": 20,
        "highest": 56
      },
      "5000": {
        "ul3": 28.618896586745414,
        "ul2": 32.12748704741361,
        "ul1": 35.636077508081804,
        "mean": 39.14466796875,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 42.653258429418194,
        "ur2": 46.16184889008639,
        "ur3": 49.670439350754584,
        "standardDeviation": 3.5085904606681955,
        "lowest": 18,
        "highest": 52
      },
      "10000": {
        "ul3": 27.229268527706203,
        "ul2": 30.60728904451247,
        "ul1": 33.985309561318736,
        "mean": 37.363330078125,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 40.74135059493127,
        "ur2": 44.119371111737536,
        "ur3": 47.4973916285438,
        "standardDeviation": 3.378020516806266,
        "lowest": 20,
        "highest": 52
      }
    },
  },
  {
    name: 'Dambord Binnenste Buiten',
    squares: [2,4,6,8,10,12,13,15,17,19,21,23],
    odds: {
      "25": {
        "ul3": 41.69795732723085,
        "ul2": 46.804504103570565,
        "ul1": 51.911050879910285,
        "mean": 57.01759765625,
        "median": 58,
        "mostFrequent": 58,
        "ur1": 62.12414443258971,
        "ur2": 67.23069120892943,
        "ur3": 72.33723798526914,
        "standardDeviation": 5.106546776339715,
        "lowest": 28,
        "highest": 71
      },
      "50": {
        "ul3": 39.57248716379445,
        "ul2": 44.444753812321295,
        "ul1": 49.31702046084815,
        "mean": 54.189287109375,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.061553757901855,
        "ur2": 63.93382040642871,
        "ur3": 68.80608705495555,
        "standardDeviation": 4.8722666485268515,
        "lowest": 22,
        "highest": 68
      },
      "75": {
        "ul3": 38.416856913743686,
        "ul2": 43.14560643207912,
        "ul1": 47.87435595041456,
        "mean": 52.60310546875,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.33185498708544,
        "ur2": 62.06060450542088,
        "ur3": 66.78935402375632,
        "standardDeviation": 4.72874951833544,
        "lowest": 21,
        "highest": 66
      },
      "100": {
        "ul3": 37.59903642112474,
        "ul2": 42.254185088041496,
        "ul1": 46.90933375495825,
        "mean": 51.564482421875,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.21963108879175,
        "ur2": 60.8747797557085,
        "ur3": 65.52992842262526,
        "standardDeviation": 4.655148666916753,
        "lowest": 25,
        "highest": 65
      },
      "125": {
        "ul3": 37.00476019824757,
        "ul2": 41.57626591341505,
        "ul1": 46.147771628582525,
        "mean": 50.71927734375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.29078305891748,
        "ur2": 59.86228877408495,
        "ur3": 64.43379448925243,
        "standardDeviation": 4.5715057151674765,
        "lowest": 22,
        "highest": 64
      },
      "150": {
        "ul3": 36.51887286455616,
        "ul2": 41.039515503454105,
        "ul1": 45.56015814235205,
        "mean": 50.08080078125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 54.601443420147945,
        "ur2": 59.12208605904589,
        "ur3": 63.64272869794384,
        "standardDeviation": 4.520642638897947,
        "lowest": 23,
        "highest": 63
      },
      "200": {
        "ul3": 35.7973827743084,
        "ul2": 40.21514971412227,
        "ul1": 44.632916653936135,
        "mean": 49.05068359375,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 53.46845053356387,
        "ur2": 57.886217473377734,
        "ur3": 62.3039844131916,
        "standardDeviation": 4.417766939813866,
        "lowest": 23,
        "highest": 62
      },
      "250": {
        "ul3": 35.21543114636388,
        "ul2": 39.559880529867584,
        "ul1": 43.90432991337129,
        "mean": 48.248779296875,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.59322868037871,
        "ur2": 56.937678063882416,
        "ur3": 61.28212744738612,
        "standardDeviation": 4.344449383503706,
        "lowest": 20,
        "highest": 61
      },
      "300": {
        "ul3": 34.82798832803276,
        "ul2": 39.098212921813506,
        "ul1": 43.36843751559425,
        "mean": 47.638662109375,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.908886703155744,
        "ur2": 56.17911129693649,
        "ur3": 60.44933589071724,
        "standardDeviation": 4.270224593780747,
        "lowest": 23,
        "highest": 60
      },
      "400": {
        "ul3": 34.068477268680084,
        "ul2": 38.27455125203672,
        "ul1": 42.48062523539336,
        "mean": 46.68669921875,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 50.89277320210664,
        "ur2": 55.09884718546328,
        "ur3": 59.30492116881992,
        "standardDeviation": 4.206073983356638,
        "lowest": 20,
        "highest": 59
      },
      "500": {
        "ul3": 33.53989512829225,
        "ul2": 37.678146231361495,
        "ul1": 41.81639733443075,
        "mean": 45.9546484375,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.09289954056925,
        "ur2": 54.2311506436385,
        "ur3": 58.369401746707744,
        "standardDeviation": 4.1382511030692495,
        "lowest": 24,
        "highest": 59
      },
      "750": {
        "ul3": 32.5625476614173,
        "ul2": 36.58610924823653,
        "ul1": 40.609670835055766,
        "mean": 44.633232421875,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.656794008694234,
        "ur2": 52.68035559551347,
        "ur3": 56.7039171823327,
        "standardDeviation": 4.023561586819233,
        "lowest": 19,
        "highest": 57
      },
      "1000": {
        "ul3": 31.97781756456499,
        "ul2": 35.904150511793326,
        "ul1": 39.830483459021664,
        "mean": 43.75681640625,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.68314935347834,
        "ur2": 51.60948230070668,
        "ur3": 55.53581524793502,
        "standardDeviation": 3.9263329472283375,
        "lowest": 20,
        "highest": 57
      },
      "2000": {
        "ul3": 30.419544959906037,
        "ul2": 34.17469012952069,
        "ul1": 37.92983529913535,
        "mean": 41.68498046875,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 45.44012563836465,
        "ur2": 49.195270807979306,
        "ur3": 52.95041597759396,
        "standardDeviation": 3.7551451696146536,
        "lowest": 19,
        "highest": 55
      },
      "5000": {
        "ul3": 28.5448719428749,
        "ul2": 32.07528572233326,
        "ul1": 35.605699501791634,
        "mean": 39.13611328125,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 42.66652706070836,
        "ur2": 46.19694084016673,
        "ur3": 49.727354619625096,
        "standardDeviation": 3.530413779458367,
        "lowest": 17,
        "highest": 53
      },
      "10000": {
        "ul3": 27.284128908566736,
        "ul2": 30.64185742341949,
        "ul1": 33.99958593827225,
        "mean": 37.357314453125,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 40.71504296797775,
        "ur2": 44.07277148283051,
        "ur3": 47.43049999768326,
        "standardDeviation": 3.3577285148527545,
        "lowest": 21,
        "highest": 50
      }
    },
  },
  {
    name: 'Bull\'s Eye',
    squares: [2,3,4,6,10,11,14,15,19,21,22,23],
    odds: {
      "25": {
        "ul3": 41.68724591731966,
        "ul2": 46.78429350217144,
        "ul1": 51.88134108702322,
        "mean": 56.978388671875,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.07543625672678,
        "ur2": 67.17248384157857,
        "ur3": 72.26953142643035,
        "standardDeviation": 5.097047584851781,
        "lowest": 27,
        "highest": 71
      },
      "50": {
        "ul3": 39.50188745530191,
        "ul2": 44.40129411082628,
        "ul1": 49.300700766350644,
        "mean": 54.200107421875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.09951407739936,
        "ur2": 63.99892073292373,
        "ur3": 68.8983273884481,
        "standardDeviation": 4.899406655524363,
        "lowest": 25,
        "highest": 68
      },
      "75": {
        "ul3": 38.35952170401461,
        "ul2": 43.114407698509744,
        "ul1": 47.86929369300488,
        "mean": 52.6241796875,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.37906568199513,
        "ur2": 62.13395167649026,
        "ur3": 66.8888376709854,
        "standardDeviation": 4.75488599449513,
        "lowest": 22,
        "highest": 65
      },
      "100": {
        "ul3": 37.636255707729376,
        "ul2": 42.27486708640292,
        "ul1": 46.91347846507646,
        "mean": 51.55208984375,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.190701222423534,
        "ur2": 60.82931260109707,
        "ur3": 65.46792397977062,
        "standardDeviation": 4.63861137867354,
        "lowest": 25,
        "highest": 64
      },
      "125": {
        "ul3": 37.04010034665407,
        "ul2": 41.59854671547771,
        "ul1": 46.156993084301355,
        "mean": 50.715439453125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.273885821948646,
        "ur2": 59.83233219077229,
        "ur3": 64.39077855959593,
        "standardDeviation": 4.558446368823644,
        "lowest": 24,
        "highest": 64
      },
      "150": {
        "ul3": 36.489195931007856,
        "ul2": 41.009616948796904,
        "ul1": 45.53003796658595,
        "mean": 50.050458984375,
        "median": 51,
        "mostFrequent": 51,
        "ur1": 54.570880002164046,
        "ur2": 59.09130101995309,
        "ur3": 63.61172203774214,
        "standardDeviation": 4.520421017789048,
        "lowest": 22,
        "highest": 64
      },
      "200": {
        "ul3": 35.78906798389111,
        "ul2": 40.20505899446907,
        "ul1": 44.62105000504704,
        "mean": 49.037041015625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.45303202620296,
        "ur2": 57.86902303678093,
        "ur3": 62.28501404735889,
        "standardDeviation": 4.415991010577964,
        "lowest": 22,
        "highest": 62
      },
      "250": {
        "ul3": 35.22772936920018,
        "ul2": 39.571363850300116,
        "ul1": 43.91499833140006,
        "mean": 48.2586328125,
        "median": 49,
        "mostFrequent": 49,
        "ur1": 52.60226729359994,
        "ur2": 56.94590177469988,
        "ur3": 61.28953625579981,
        "standardDeviation": 4.343634481099938,
        "lowest": 20,
        "highest": 61
      },
      "300": {
        "ul3": 34.78676150756027,
        "ul2": 39.07508058837351,
        "ul1": 43.363399669186755,
        "mean": 47.65171875,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.940037830813246,
        "ur2": 56.22835691162649,
        "ur3": 60.51667599243973,
        "standardDeviation": 4.2883190808132445,
        "lowest": 23,
        "highest": 60
      },
      "400": {
        "ul3": 34.11773434981789,
        "ul2": 38.30734373321192,
        "ul1": 42.496953116605965,
        "mean": 46.6865625,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 50.876171883394036,
        "ur2": 55.06578126678808,
        "ur3": 59.255390650182115,
        "standardDeviation": 4.189609383394038,
        "lowest": 20,
        "highest": 61
      },
      "500": {
        "ul3": 33.550577094393674,
        "ul2": 37.68968485980412,
        "ul1": 41.828792625214554,
        "mean": 45.967900390625,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.10700815603544,
        "ur2": 54.24611592144588,
        "ur3": 58.38522368685632,
        "standardDeviation": 4.139107765410442,
        "lowest": 24,
        "highest": 58
      },
      "750": {
        "ul3": 32.57892131896152,
        "ul2": 36.59677111368268,
        "ul1": 40.61462090840384,
        "mean": 44.632470703125,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.65032049784616,
        "ur2": 52.668170292567325,
        "ur3": 56.68602008728848,
        "standardDeviation": 4.017849794721161,
        "lowest": 21,
        "highest": 58
      },
      "1000": {
        "ul3": 31.986247759531302,
        "ul2": 35.9081625688542,
        "ul1": 39.8300773781771,
        "mean": 43.7519921875,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.6739069968229,
        "ur2": 51.5958218061458,
        "ur3": 55.5177366154687,
        "standardDeviation": 3.9219148093229,
        "lowest": 21,
        "highest": 58
      },
      "2000": {
        "ul3": 30.49329652475938,
        "ul2": 34.22686239671459,
        "ul1": 37.960428268669794,
        "mean": 41.693994140625,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 45.42756001258021,
        "ur2": 49.161125884535416,
        "ur3": 52.89469175649062,
        "standardDeviation": 3.7335658719552067,
        "lowest": 18,
        "highest": 55
      },
      "5000": {
        "ul3": 28.637125281368874,
        "ul2": 32.143513208412585,
        "ul1": 35.64990113545629,
        "mean": 39.1562890625,
        "median": 39,
        "mostFrequent": 40,
        "ur1": 42.66267698954371,
        "ur2": 46.169064916587416,
        "ur3": 49.67545284363113,
        "standardDeviation": 3.5063879270437086,
        "lowest": 20,
        "highest": 54
      },
      "10000": {
        "ul3": 27.18501189894694,
        "ul2": 30.57072733367296,
        "ul1": 33.95644276839898,
        "mean": 37.342158203125,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 40.727873637851026,
        "ur2": 44.11358907257704,
        "ur3": 47.499304507303066,
        "standardDeviation": 3.385715434726021,
        "lowest": 18,
        "highest": 51
      }
    },
  },
  {
    name: 'Vierkant',
    squares: [1, 2, 3, 4, 5, 6, 10, 11, 14, 15, 19, 20, 21, 22, 23, 24],
    odds: { // amount of cards : avg amount of nummers (bingoballs) drawn
      "25": {
        "ul3": 48.9438733544208,
        "ul2": 53.22234135086387,
        "ul1": 57.50080934730694,
        "mean": 61.77927734375,
        "median": 62,
        "mostFrequent": 63,
        "ur1": 66.05774534019307,
        "ur2": 70.33621333663613,
        "ur3": 74.6146813330792,
        "standardDeviation": 4.278467996443066,
        "lowest": 32,
        "highest": 73
      },
      "50": {
        "ul3": 47.153625907246585,
        "ul2": 51.31356961524772,
        "ul1": 55.473513323248866,
        "mean": 59.63345703125,
        "median": 60,
        "mostFrequent": 61,
        "ur1": 63.79340073925114,
        "ur2": 67.95334444725228,
        "ur3": 72.11328815525343,
        "standardDeviation": 4.15994370800114,
        "lowest": 33,
        "highest": 71
      },
      "75": {
        "ul3": 46.00592512875415,
        "ul2": 50.13868966916944,
        "ul1": 54.27145420958472,
        "mean": 58.40421875,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 62.53698329041528,
        "ur2": 66.66974783083056,
        "ur3": 70.80251237124584,
        "standardDeviation": 4.132764540415282,
        "lowest": 30,
        "highest": 71
      },
      "100": {
        "ul3": 45.37216401863183,
        "ul2": 49.44751038742122,
        "ul1": 53.52285675621061,
        "mean": 57.598203125,
        "median": 58,
        "mostFrequent": 58,
        "ur1": 61.67354949378939,
        "ur2": 65.74889586257878,
        "ur3": 69.82424223136817,
        "standardDeviation": 4.07534636878939,
        "lowest": 31,
        "highest": 69
      },
      "125": {
        "ul3": 44.709635570644444,
        "ul2": 48.78296668251296,
        "ul1": 52.85629779438148,
        "mean": 56.92962890625,
        "median": 57,
        "mostFrequent": 58,
        "ur1": 61.00296001811852,
        "ur2": 65.07629112998704,
        "ur3": 69.14962224185555,
        "standardDeviation": 4.073331111868519,
        "lowest": 33,
        "highest": 70
      },
      "150": {
        "ul3": 44.304336576589975,
        "ul2": 48.34523480105998,
        "ul1": 52.386133025529986,
        "mean": 56.42703125,
        "median": 57,
        "mostFrequent": 57,
        "ur1": 60.46792947447001,
        "ur2": 64.50882769894002,
        "ur3": 68.54972592341002,
        "standardDeviation": 4.040898224470009,
        "lowest": 33,
        "highest": 68
      },
      "200": {
        "ul3": 43.60304132154278,
        "ul2": 47.60564733936185,
        "ul1": 51.60825335718093,
        "mean": 55.610859375,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.613465392819066,
        "ur2": 63.61607141063814,
        "ur3": 67.6186774284572,
        "standardDeviation": 4.002606017819072,
        "lowest": 30,
        "highest": 68
      },
      "250": {
        "ul3": 43.09391354836897,
        "ul2": 47.06442218328765,
        "ul1": 51.03493081820632,
        "mean": 55.005439453125,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 58.97594808804368,
        "ur2": 62.94645672296235,
        "ur3": 66.91696535788104,
        "standardDeviation": 3.9705086349186782,
        "lowest": 31,
        "highest": 67
      },
      "300": {
        "ul3": 42.677343491886475,
        "ul2": 46.625582510215985,
        "ul1": 50.57382152854549,
        "mean": 54.522060546875,
        "median": 55,
        "mostFrequent": 55,
        "ur1": 58.470299565204506,
        "ur2": 62.41853858353401,
        "ur3": 66.36677760186352,
        "standardDeviation": 3.9482390183295073,
        "lowest": 33,
        "highest": 67
      },
      "400": {
        "ul3": 41.91649976693965,
        "ul2": 45.8573930737931,
        "ul1": 49.79828638064655,
        "mean": 53.7391796875,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 57.680072994353445,
        "ur2": 61.6209663012069,
        "ur3": 65.56185960806035,
        "standardDeviation": 3.940893306853449,
        "lowest": 26,
        "highest": 66
      },
      "500": {
        "ul3": 41.39643637158504,
        "ul2": 45.312236877931696,
        "ul1": 49.228037384278345,
        "mean": 53.143837890625,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.05963839697166,
        "ur2": 60.975438903318306,
        "ur3": 64.89123940966496,
        "standardDeviation": 3.9158005063466543,
        "lowest": 29,
        "highest": 66
      },
      "750": {
        "ul3": 40.47133178451591,
        "ul2": 44.3447713198856,
        "ul1": 48.2182108552553,
        "mean": 52.091650390625,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 55.9650899259947,
        "ur2": 59.838529461364395,
        "ur3": 63.711968996734086,
        "standardDeviation": 3.873439535369697,
        "lowest": 30,
        "highest": 65
      },
      "1000": {
        "ul3": 39.87767770813952,
        "ul2": 43.701176414801346,
        "ul1": 47.524675121463176,
        "mean": 51.348173828125,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 55.17167253478682,
        "ur2": 58.99517124144865,
        "ur3": 62.818669948110475,
        "standardDeviation": 3.8234987066618262,
        "lowest": 30,
        "highest": 66
      },
      "2000": {
        "ul3": 38.3854446771234,
        "ul2": 42.14911481079061,
        "ul1": 45.912784944457805,
        "mean": 49.676455078125,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 53.4401252117922,
        "ur2": 57.2037953454594,
        "ur3": 60.9674654791266,
        "standardDeviation": 3.7636701336671985,
        "lowest": 27,
        "highest": 63
      },
      "5000": {
        "ul3": 36.4506502541963,
        "ul2": 40.1441314194642,
        "ul1": 43.8376125847321,
        "mean": 47.53109375,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.224574915267894,
        "ur2": 54.91805608053579,
        "ur3": 58.611537245803696,
        "standardDeviation": 3.693481165267899,
        "lowest": 27,
        "highest": 62
      },
      "10000": {
        "ul3": 35.08365970513165,
        "ul2": 38.72596193883777,
        "ul1": 42.36826417254388,
        "mean": 46.01056640625,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 49.65286863995612,
        "ur2": 53.29517087366223,
        "ur3": 56.93747310736835,
        "standardDeviation": 3.642302233706116,
        "lowest": 28,
        "highest": 60
      }
    },
  },
  {
    name: 'Het Anker',
    squares: [1,2,3,4,5,8,15,17,19,21,22,23],
    odds: {
      "25": {
        "ul3": 41.42135073248788,
        "ul2": 46.63821168624192,
        "ul1": 51.85507263999596,
        "mean": 57.07193359375,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.28879454750405,
        "ur2": 67.50565550125809,
        "ur3": 72.72251645501213,
        "standardDeviation": 5.216860953754042,
        "lowest": 28,
        "highest": 71
      },
      "50": {
        "ul3": 39.305166363975026,
        "ul2": 44.30488304473335,
        "ul1": 49.30459972549168,
        "mean": 54.30431640625,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.30403308700833,
        "ur2": 64.30374976776665,
        "ur3": 69.30346644852497,
        "standardDeviation": 4.9997166807583255,
        "lowest": 27,
        "highest": 69
      },
      "75": {
        "ul3": 38.200524644617225,
        "ul2": 43.06204898182815,
        "ul1": 47.92357331903908,
        "mean": 52.78509765625,
        "median": 53,
        "mostFrequent": 55,
        "ur1": 57.646621993460926,
        "ur2": 62.508146330671856,
        "ur3": 67.36967066788279,
        "standardDeviation": 4.861524337210926,
        "lowest": 26,
        "highest": 67
      },
      "100": {
        "ul3": 37.35806713040235,
        "ul2": 42.1394894150599,
        "ul1": 46.92091169971745,
        "mean": 51.702333984375,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.483756269032554,
        "ur2": 61.265178553690106,
        "ur3": 66.04660083834766,
        "standardDeviation": 4.781422284657551,
        "lowest": 23,
        "highest": 67
      },
      "125": {
        "ul3": 36.720797418765244,
        "ul2": 41.437501013551824,
        "ul1": 46.15420460833841,
        "mean": 50.870908203125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.587611797911585,
        "ur2": 60.30431539269817,
        "ur3": 65.02101898748475,
        "standardDeviation": 4.716703594786585,
        "lowest": 26,
        "highest": 66
      },
      "150": {
        "ul3": 36.24392764736481,
        "ul2": 40.90449017636821,
        "ul1": 45.565052705371606,
        "mean": 50.225615234375,
        "median": 51,
        "mostFrequent": 51,
        "ur1": 54.88617776337839,
        "ur2": 59.546740292381784,
        "ur3": 64.20730282138518,
        "standardDeviation": 4.6605625290033945,
        "lowest": 24,
        "highest": 65
      },
      "200": {
        "ul3": 35.5096800912915,
        "ul2": 40.08093581606933,
        "ul1": 44.65219154084716,
        "mean": 49.223447265625,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 53.79470299040283,
        "ur2": 58.36595871518067,
        "ur3": 62.937214439958495,
        "standardDeviation": 4.571255724777834,
        "lowest": 22,
        "highest": 64
      },
      "250": {
        "ul3": 34.90892206432851,
        "ul2": 39.42215572517733,
        "ul1": 43.935389386026166,
        "mean": 48.448623046875,
        "median": 49,
        "mostFrequent": 49,
        "ur1": 52.96185670772383,
        "ur2": 57.475090368572666,
        "ur3": 61.98832402942149,
        "standardDeviation": 4.513233660848832,
        "lowest": 22,
        "highest": 63
      },
      "300": {
        "ul3": 34.432010269516496,
        "ul2": 38.895689138010994,
        "ul1": 43.3593680065055,
        "mean": 47.823046875,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 52.28672574349451,
        "ur2": 56.75040461198901,
        "ur3": 61.21408348048351,
        "standardDeviation": 4.463678868494504,
        "lowest": 23,
        "highest": 62
      },
      "400": {
        "ul3": 33.74921574859597,
        "ul2": 38.12493940531398,
        "ul1": 42.50066306203199,
        "mean": 46.87638671875,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.25211037546801,
        "ur2": 55.627834032186016,
        "ur3": 60.003557688904024,
        "standardDeviation": 4.375723656718008,
        "lowest": 23,
        "highest": 62
      },
      "500": {
        "ul3": 33.22714227948452,
        "ul2": 37.54041256132301,
        "ul1": 41.85368284316151,
        "mean": 46.166953125,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 50.48022340683849,
        "ur2": 54.79349368867699,
        "ur3": 59.10676397051548,
        "standardDeviation": 4.313270281838493,
        "lowest": 22,
        "highest": 62
      },
      "750": {
        "ul3": 32.225813674543645,
        "ul2": 36.4445984392791,
        "ul1": 40.66338320401454,
        "mean": 44.88216796875,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.10095273348545,
        "ur2": 53.319737498220896,
        "ur3": 57.53852226295635,
        "standardDeviation": 4.21878476473545,
        "lowest": 22,
        "highest": 60
      },
      "1000": {
        "ul3": 31.50765198620853,
        "ul2": 35.66204793872235,
        "ul1": 39.816443891236176,
        "mean": 43.97083984375,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.12523579626382,
        "ur2": 52.279631748777646,
        "ur3": 56.43402770129147,
        "standardDeviation": 4.154395952513823,
        "lowest": 23,
        "highest": 61
      },
      "2000": {
        "ul3": 30.08252843771865,
        "ul2": 34.038388099104104,
        "ul1": 37.99424776048955,
        "mean": 41.950107421875,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 45.90596708326046,
        "ur2": 49.8618267446459,
        "ur3": 53.817686406031356,
        "standardDeviation": 3.9558596613854506,
        "lowest": 20,
        "highest": 57
      },
      "5000": {
        "ul3": 28.065743232094675,
        "ul2": 31.856263717229783,
        "ul1": 35.64678420236489,
        "mean": 39.4373046875,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.227825172635114,
        "ur2": 47.018345657770226,
        "ur3": 50.80886614290533,
        "standardDeviation": 3.7905204851351098,
        "lowest": 17,
        "highest": 57
      },
      "10000": {
        "ul3": 26.664224345369036,
        "ul2": 30.330078600037687,
        "ul1": 33.995932854706346,
        "mean": 37.661787109375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 41.32764136404365,
        "ur2": 44.99349561871231,
        "ur3": 48.65934987338096,
        "standardDeviation": 3.6658542546686546,
        "lowest": 17,
        "highest": 55
      }
    },
  },
  {
    name: 'Het Mandje',
    squares: [3,7,9,11,14,15,16,17,18,19,20,21,22,23,24],
    odds: {
      "25": {
        "ul3": 47.70586241616357,
        "ul2": 51.96308796494238,
        "ul1": 56.220313513721194,
        "mean": 60.4775390625,
        "median": 61,
        "mostFrequent": 62,
        "ur1": 64.7347646112788,
        "ur2": 68.99199016005761,
        "ur3": 73.24921570883643,
        "standardDeviation": 4.257225548778809,
        "lowest": 31,
        "highest": 72
      },
      "50": {
        "ul3": 45.91126332978491,
        "ul2": 49.99747307923161,
        "ul1": 54.083682828678306,
        "mean": 58.169892578125,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 62.256102327571696,
        "ur2": 66.34231207701839,
        "ur3": 70.42852182646509,
        "standardDeviation": 4.086209749446697,
        "lowest": 30,
        "highest": 69
      },
      "75": {
        "ul3": 44.8961336228187,
        "ul2": 48.874479706879136,
        "ul1": 52.85282579093957,
        "mean": 56.831171875,
        "median": 57,
        "mostFrequent": 59,
        "ur1": 60.809517959060436,
        "ur2": 64.78786404312088,
        "ur3": 68.76621012718131,
        "standardDeviation": 3.978346084060434,
        "lowest": 29,
        "highest": 68
      },
      "100": {
        "ul3": 44.14238114224683,
        "ul2": 48.06378143858122,
        "ul1": 51.98518173491561,
        "mean": 55.90658203125,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.82798232758439,
        "ur2": 63.74938262391877,
        "ur3": 67.67078292025316,
        "standardDeviation": 3.921400296334388,
        "lowest": 31,
        "highest": 66
      },
      "125": {
        "ul3": 43.57936294620138,
        "ul2": 47.455216573509254,
        "ul1": 51.33107020081712,
        "mean": 55.206923828125,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.082777455432876,
        "ur2": 62.958631082740744,
        "ur3": 66.83448471004863,
        "standardDeviation": 3.8758536273078734,
        "lowest": 31,
        "highest": 66
      },
      "150": {
        "ul3": 43.081138512443175,
        "ul2": 46.92936252392045,
        "ul1": 50.777586535397724,
        "mean": 54.625810546875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 58.47403455835228,
        "ur2": 62.32225856982955,
        "ur3": 66.17048258130683,
        "standardDeviation": 3.8482240114772757,
        "lowest": 31,
        "highest": 65
      },
      "200": {
        "ul3": 42.411157057435005,
        "ul2": 46.193876840373335,
        "ul1": 49.976596623311664,
        "mean": 53.75931640625,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 57.54203618918834,
        "ur2": 61.32475597212667,
        "ur3": 65.107475755065,
        "standardDeviation": 3.782719782938333,
        "lowest": 30,
        "highest": 64
      },
      "250": {
        "ul3": 41.87415957813532,
        "ul2": 45.60256471875688,
        "ul1": 49.33096985937844,
        "mean": 53.059375,
        "median": 54,
        "mostFrequent": 54,
        "ur1": 56.787780140621564,
        "ur2": 60.516185281243125,
        "ur3": 64.24459042186469,
        "standardDeviation": 3.7284051406215624,
        "lowest": 30,
        "highest": 63
      },
      "300": {
        "ul3": 41.43700583153502,
        "ul2": 45.13447524185668,
        "ul1": 48.831944652178336,
        "mean": 52.5294140625,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 56.22688347282167,
        "ur2": 59.92435288314333,
        "ur3": 63.62182229346499,
        "standardDeviation": 3.6974694103216628,
        "lowest": 28,
        "highest": 63
      },
      "400": {
        "ul3": 40.88671911143143,
        "ul2": 44.486022376370954,
        "ul1": 48.085325641310476,
        "mean": 51.68462890625,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 55.28393217118952,
        "ur2": 58.88323543612904,
        "ur3": 62.48253870106856,
        "standardDeviation": 3.599303264939522,
        "lowest": 28,
        "highest": 62
      },
      "500": {
        "ul3": 40.47667270600134,
        "ul2": 44.01107933004256,
        "ul1": 47.54548595408378,
        "mean": 51.079892578125,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 54.614299202166215,
        "ur2": 58.14870582620743,
        "ur3": 61.68311245024866,
        "standardDeviation": 3.5344066240412184,
        "lowest": 27,
        "highest": 61
      },
      "750": {
        "ul3": 39.51561895889584,
        "ul2": 42.97942305593056,
        "ul1": 46.44322715296528,
        "mean": 49.90703125,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.37083534703473,
        "ur2": 56.834639444069445,
        "ur3": 60.29844354110416,
        "standardDeviation": 3.4638040970347213,
        "lowest": 29,
        "highest": 59
      },
      "1000": {
        "ul3": 38.910703875006746,
        "ul2": 42.314935395837836,
        "ul1": 45.71916691666892,
        "mean": 49.1233984375,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 52.52762995833108,
        "ur2": 55.931861479162166,
        "ur3": 59.336092999993255,
        "standardDeviation": 3.4042315208310834,
        "lowest": 28,
        "highest": 60
      },
      "2000": {
        "ul3": 37.47359489063385,
        "ul2": 40.74133865104756,
        "ul1": 44.009082411461286,
        "mean": 47.276826171875,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 50.54456993228872,
        "ur2": 53.81231369270244,
        "ur3": 57.080057453116154,
        "standardDeviation": 3.2677437604137185,
        "lowest": 26,
        "highest": 57
      },
      "5000": {
        "ul3": 35.725482393054136,
        "ul2": 38.809094381827755,
        "ul1": 41.892706370601374,
        "mean": 44.976318359375,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.059930348148626,
        "ur2": 51.143542336922245,
        "ur3": 54.227154325695864,
        "standardDeviation": 3.0836119887736224,
        "lowest": 25,
        "highest": 54
      },
      "10000": {
        "ul3": 34.44789098411303,
        "ul2": 37.40373070815869,
        "ul1": 40.35957043220434,
        "mean": 43.31541015625,
        "median": 44,
        "mostFrequent": 44,
        "ur1": 46.27124988029566,
        "ur2": 49.227089604341316,
        "ur3": 52.182929328386976,
        "standardDeviation": 2.955839724045658,
        "lowest": 26,
        "highest": 54
      }
    },
  },
  {
    name: 'De Strik',
    squares: [1,5,6,7,9,10,11,12,13,14,15,16,18,19,20,24],
    odds: {
      "25": {
        "ul3": 49.01241421441274,
        "ul2": 53.31380023148349,
        "ul1": 57.615186248554245,
        "mean": 61.916572265625,
        "median": 62,
        "mostFrequent": 63,
        "ur1": 66.21795828269575,
        "ur2": 70.5193442997665,
        "ur3": 74.82073031683726,
        "standardDeviation": 4.301386017070754,
        "lowest": 36,
        "highest": 73
      },
      "50": {
        "ul3": 47.030880453434364,
        "ul2": 51.291622125206246,
        "ul1": 55.55236379697812,
        "mean": 59.81310546875,
        "median": 60,
        "mostFrequent": 61,
        "ur1": 64.07384714052188,
        "ur2": 68.33458881229376,
        "ur3": 72.59533048406564,
        "standardDeviation": 4.260741671771879,
        "lowest": 29,
        "highest": 72
      },
      "75": {
        "ul3": 45.95737374981307,
        "ul2": 50.17738328112539,
        "ul1": 54.397392812437694,
        "mean": 58.61740234375,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 62.83741187506231,
        "ur2": 67.05742140637462,
        "ur3": 71.27743093768693,
        "standardDeviation": 4.220009531312309,
        "lowest": 31,
        "highest": 70
      },
      "100": {
        "ul3": 45.22970219234986,
        "ul2": 49.4181543261499,
        "ul1": 53.60660645994995,
        "mean": 57.79505859375,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 61.98351072755005,
        "ur2": 66.1719628613501,
        "ur3": 70.36041499515014,
        "standardDeviation": 4.188452133800047,
        "lowest": 32,
        "highest": 69
      },
      "125": {
        "ul3": 44.669897611231725,
        "ul2": 48.83153655852948,
        "ul1": 52.99317550582724,
        "mean": 57.154814453125,
        "median": 58,
        "mostFrequent": 58,
        "ur1": 61.31645340042276,
        "ur2": 65.47809234772052,
        "ur3": 69.63973129501828,
        "standardDeviation": 4.161638947297758,
        "lowest": 32,
        "highest": 70
      },
      "150": {
        "ul3": 44.1717182528411,
        "ul2": 48.319071934185736,
        "ul1": 52.46642561553037,
        "mean": 56.613779296875,
        "median": 57,
        "mostFrequent": 58,
        "ur1": 60.761132978219635,
        "ur2": 64.90848665956426,
        "ur3": 69.0558403409089,
        "standardDeviation": 4.147353681344632,
        "lowest": 31,
        "highest": 70
      },
      "200": {
        "ul3": 43.593354019790425,
        "ul2": 47.68894174236028,
        "ul1": 51.78452946493014,
        "mean": 55.8801171875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.97570491006986,
        "ur2": 64.07129263263971,
        "ur3": 68.16688035520957,
        "standardDeviation": 4.095587722569859,
        "lowest": 31,
        "highest": 68
      },
      "250": {
        "ul3": 43.03731941266314,
        "ul2": 47.11153976469209,
        "ul1": 51.18576011672105,
        "mean": 55.25998046875,
        "median": 56,
        "mostFrequent": 56,
        "ur1": 59.33420082077895,
        "ur2": 63.40842117280791,
        "ur3": 67.48264152483686,
        "standardDeviation": 4.074220352028955,
        "lowest": 31,
        "highest": 67
      },
      "300": {
        "ul3": 42.56925429466826,
        "ul2": 46.631101170403845,
        "ul1": 50.69294804613942,
        "mean": 54.754794921875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 58.816641797610586,
        "ur2": 62.87848867334616,
        "ur3": 66.94033554908174,
        "standardDeviation": 4.06184687573558,
        "lowest": 32,
        "highest": 67
      },
      "400": {
        "ul3": 41.93573972067966,
        "ul2": 45.95659340753644,
        "ul1": 49.97744709439322,
        "mean": 53.99830078125,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 58.019154468106784,
        "ur2": 62.040008154963566,
        "ur3": 66.06086184182034,
        "standardDeviation": 4.020853686856781,
        "lowest": 33,
        "highest": 68
      },
      "500": {
        "ul3": 41.381123811475284,
        "ul2": 45.38897186390019,
        "ul1": 49.39681991632509,
        "mean": 53.40466796875,
        "median": 54,
        "mostFrequent": 54,
        "ur1": 57.4125160211749,
        "ur2": 61.420364073599806,
        "ur3": 65.4282121260247,
        "standardDeviation": 4.0078480524249045,
        "lowest": 32,
        "highest": 66
      },
      "750": {
        "ul3": 40.45953612427219,
        "ul2": 44.436204421389796,
        "ul1": 48.412872718507394,
        "mean": 52.389541015625,
        "median": 53,
        "mostFrequent": 53,
        "ur1": 56.366209312742605,
        "ur2": 60.3428776098602,
        "ur3": 64.31954590697781,
        "standardDeviation": 3.976668297117603,
        "lowest": 31,
        "highest": 65
      },
      "1000": {
        "ul3": 39.8380034142702,
        "ul2": 43.7730244115968,
        "ul1": 47.7080454089234,
        "mean": 51.64306640625,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 55.5780874035766,
        "ur2": 59.5131084009032,
        "ur3": 63.4481293982298,
        "standardDeviation": 3.935020997326601,
        "lowest": 29,
        "highest": 65
      },
      "2000": {
        "ul3": 38.304496096411135,
        "ul2": 42.19301367364909,
        "ul1": 46.081531250887046,
        "mean": 49.970048828125,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.85856640536296,
        "ur2": 57.74708398260091,
        "ur3": 61.63560155983887,
        "standardDeviation": 3.888517577237955,
        "lowest": 29,
        "highest": 64
      },
      "5000": {
        "ul3": 36.512463634254274,
        "ul2": 40.31477067804451,
        "ul1": 44.117077721834754,
        "mean": 47.919384765625,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.72169180941524,
        "ur2": 55.523998853205484,
        "ur3": 59.32630589699572,
        "standardDeviation": 3.8023070437902424,
        "lowest": 28,
        "highest": 63
      },
      "10000": {
        "ul3": 35.148677964356516,
        "ul2": 38.89915770540434,
        "ul1": 42.649637446452175,
        "mean": 46.4001171875,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 50.15059692854783,
        "ur2": 53.90107666959566,
        "ur3": 57.65155641064349,
        "standardDeviation": 3.750479741047829,
        "lowest": 25,
        "highest": 60
      }
    },
  },
  {
    name: 'Het Klaverblad',
    squares: [2,3,4,6,7,9,10,11,14,15,16,17,18,19,22],
    odds: {
      "25": {
        "ul3": 47.663428407815715,
        "ul2": 51.926947063543814,
        "ul1": 56.190465719271906,
        "mean": 60.453984375,
        "median": 61,
        "mostFrequent": 62,
        "ur1": 64.7175030307281,
        "ur2": 68.98102168645619,
        "ur3": 73.24454034218428,
        "standardDeviation": 4.263518655728093,
        "lowest": 30,
        "highest": 72
      },
      "50": {
        "ul3": 45.892693994249214,
        "ul2": 49.98164951179115,
        "ul1": 54.07060502933307,
        "mean": 58.159560546875,
        "median": 59,
        "mostFrequent": 59,
        "ur1": 62.248516064416926,
        "ur2": 66.33747158195885,
        "ur3": 70.42642709950078,
        "standardDeviation": 4.088955517541928,
        "lowest": 32,
        "highest": 68
      },
      "75": {
        "ul3": 44.868177023011285,
        "ul2": 48.85736541117419,
        "ul1": 52.846553799337094,
        "mean": 56.8357421875,
        "median": 57,
        "mostFrequent": 58,
        "ur1": 60.82493057566291,
        "ur2": 64.81411896382582,
        "ur3": 68.80330735198872,
        "standardDeviation": 3.9891883881629058,
        "lowest": 32,
        "highest": 67
      },
      "100": {
        "ul3": 44.099471010511664,
        "ul2": 48.03274629867444,
        "ul1": 51.96602158683722,
        "mean": 55.899296875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.83257216316277,
        "ur2": 63.765847451325556,
        "ur3": 67.69912273948833,
        "standardDeviation": 3.9332752881627786,
        "lowest": 29,
        "highest": 66
      },
      "125": {
        "ul3": 43.58800744318065,
        "ul2": 47.46652970170377,
        "ul1": 51.34505196022688,
        "mean": 55.22357421875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.10209647727312,
        "ur2": 62.980618735796234,
        "ur3": 66.85914099431935,
        "standardDeviation": 3.878522258523117,
        "lowest": 30,
        "highest": 66
      },
      "150": {
        "ul3": 43.12185159646799,
        "ul2": 46.96026760077033,
        "ul1": 50.79868360507266,
        "mean": 54.637099609375,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 58.47551561367734,
        "ur2": 62.31393161797967,
        "ur3": 66.15234762228201,
        "standardDeviation": 3.8384160043023354,
        "lowest": 30,
        "highest": 65
      },
      "200": {
        "ul3": 42.4010188749327,
        "ul2": 46.177323130163465,
        "ul1": 49.95362738539423,
        "mean": 53.729931640625,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 57.50623589585577,
        "ur2": 61.28254015108653,
        "ur3": 65.05884440631729,
        "standardDeviation": 3.776304255230766,
        "lowest": 29,
        "highest": 64
      },
      "250": {
        "ul3": 41.900484332391684,
        "ul2": 45.622848929927784,
        "ul1": 49.34521352746389,
        "mean": 53.067578125,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 56.789942722536104,
        "ur2": 60.51230732007221,
        "ur3": 64.23467191760831,
        "standardDeviation": 3.722364597536105,
        "lowest": 28,
        "highest": 63
      },
      "300": {
        "ul3": 41.53665770446416,
        "ul2": 45.20506672485111,
        "ul1": 48.87347574523805,
        "mean": 52.541884765625,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 56.210293786011945,
        "ur2": 59.87870280639889,
        "ur3": 63.54711182678584,
        "standardDeviation": 3.668409020386947,
        "lowest": 26,
        "highest": 62
      },
      "400": {
        "ul3": 40.9293515776806,
        "ul2": 44.528070322620394,
        "ul1": 48.126789067560196,
        "mean": 51.7255078125,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 55.3242265574398,
        "ur2": 58.9229453023796,
        "ur3": 62.5216640473194,
        "standardDeviation": 3.5987187449398004,
        "lowest": 27,
        "highest": 62
      },
      "500": {
        "ul3": 40.465790401345686,
        "ul2": 44.00322550193879,
        "ul1": 47.540660602531894,
        "mean": 51.078095703125,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 54.61553080371811,
        "ur2": 58.15296590431121,
        "ur3": 61.69040100490432,
        "standardDeviation": 3.5374351005931053,
        "lowest": 25,
        "highest": 61
      },
      "750": {
        "ul3": 39.541304330383525,
        "ul2": 42.99984090775568,
        "ul1": 46.45837748512784,
        "mean": 49.9169140625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.375450639872156,
        "ur2": 56.833987217244314,
        "ur3": 60.29252379461647,
        "standardDeviation": 3.4585365773721577,
        "lowest": 29,
        "highest": 60
      },
      "1000": {
        "ul3": 38.985507909984705,
        "ul2": 42.3678711587398,
        "ul1": 45.7502344074949,
        "mean": 49.13259765625,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 52.5149609050051,
        "ur2": 55.897324153760195,
        "ur3": 59.27968740251529,
        "standardDeviation": 3.382363248755098,
        "lowest": 29,
        "highest": 60
      },
      "2000": {
        "ul3": 37.50599174823937,
        "ul2": 40.765843457159576,
        "ul1": 44.02569516607979,
        "mean": 47.285546875,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 50.54539858392021,
        "ur2": 53.80525029284043,
        "ur3": 57.065102001760636,
        "standardDeviation": 3.2598517089202113,
        "lowest": 27,
        "highest": 57
      },
      "5000": {
        "ul3": 35.71532889605757,
        "ul2": 38.79931757133005,
        "ul1": 41.88330624660252,
        "mean": 44.967294921875,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 48.05128359714748,
        "ur2": 51.135272272419954,
        "ur3": 54.21926094769243,
        "standardDeviation": 3.0839886752724763,
        "lowest": 26,
        "highest": 55
      },
      "10000": {
        "ul3": 34.47470651383024,
        "ul2": 37.42319952484516,
        "ul1": 40.371692535860085,
        "mean": 43.320185546875,
        "median": 44,
        "mostFrequent": 44,
        "ur1": 46.26867855788992,
        "ur2": 49.217171568904845,
        "ur3": 52.16566457991976,
        "standardDeviation": 2.9484930110149197,
        "lowest": 24,
        "highest": 53
      }
    },
  },
  {
    name: 'De Diamand',
    squares: [3,7,8,9,11,12,13,14,16,17,18,22],
    odds: {
      "25": {
        "ul3": 41.324166554277774,
        "ul2": 46.63751077576852,
        "ul1": 51.950854997259256,
        "mean": 57.26419921875,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.577543440240746,
        "ur2": 67.89088766173148,
        "ur3": 73.20423188322223,
        "standardDeviation": 5.313344221490743,
        "lowest": 23,
        "highest": 71
      },
      "50": {
        "ul3": 39.130947927057825,
        "ul2": 44.273867628455214,
        "ul1": 49.41678732985261,
        "mean": 54.55970703125,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.70262673264739,
        "ur2": 64.84554643404478,
        "ur3": 69.98846613544217,
        "standardDeviation": 5.142919701397392,
        "lowest": 24,
        "highest": 70
      },
      "75": {
        "ul3": 37.89769518377405,
        "ul2": 42.92223949751603,
        "ul1": 47.946783811258015,
        "mean": 52.971328125,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.99587243874198,
        "ur2": 63.02041675248397,
        "ur3": 68.04496106622595,
        "standardDeviation": 5.024544313741984,
        "lowest": 21,
        "highest": 68
      },
      "100": {
        "ul3": 37.17114323995997,
        "ul2": 42.105583123514975,
        "ul1": 47.04002300706999,
        "mean": 51.974462890625,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.90890277418001,
        "ur2": 61.84334265773502,
        "ur3": 66.77778254129004,
        "standardDeviation": 4.934439883555011,
        "lowest": 23,
        "highest": 68
      },
      "125": {
        "ul3": 36.5307122031623,
        "ul2": 41.40828079169153,
        "ul1": 46.28584938022077,
        "mean": 51.16341796875,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 56.040986557279226,
        "ur2": 60.91855514580846,
        "ur3": 65.7961237343377,
        "standardDeviation": 4.877568588529232,
        "lowest": 25,
        "highest": 66
      },
      "150": {
        "ul3": 36.07086792398532,
        "ul2": 40.88441650661521,
        "ul1": 45.6979650892451,
        "mean": 50.511513671875,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.3250622545049,
        "ur2": 60.13861083713479,
        "ur3": 64.95215941976468,
        "standardDeviation": 4.813548582629895,
        "lowest": 21,
        "highest": 65
      },
      "200": {
        "ul3": 35.26779949372764,
        "ul2": 40.02925565206842,
        "ul1": 44.79071181040921,
        "mean": 49.55216796875,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.31362412709078,
        "ur2": 59.075080285431575,
        "ur3": 63.83653644377236,
        "standardDeviation": 4.7614561583407875,
        "lowest": 23,
        "highest": 64
      },
      "250": {
        "ul3": 34.73728152292504,
        "ul2": 39.41766684861669,
        "ul1": 44.09805217430835,
        "mean": 48.7784375,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.458822825691655,
        "ur2": 58.139208151383315,
        "ur3": 62.81959347707497,
        "standardDeviation": 4.680385325691655,
        "lowest": 21,
        "highest": 65
      },
      "300": {
        "ul3": 34.22845534379742,
        "ul2": 38.879178562531614,
        "ul1": 43.52990178126581,
        "mean": 48.180625,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.83134821873419,
        "ur2": 57.482071437468385,
        "ur3": 62.13279465620258,
        "standardDeviation": 4.650723218734193,
        "lowest": 19,
        "highest": 64
      },
      "400": {
        "ul3": 33.4495369135394,
        "ul2": 38.0431548173596,
        "ul1": 42.636772721179796,
        "mean": 47.230390625,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.8240085288202,
        "ur2": 56.4176264326404,
        "ur3": 61.0112443364606,
        "standardDeviation": 4.5936179038202,
        "lowest": 21,
        "highest": 64
      },
      "500": {
        "ul3": 32.90557602233212,
        "ul2": 37.440836488846415,
        "ul1": 41.9760969553607,
        "mean": 46.511357421875,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.04661788838929,
        "ur2": 55.58187835490358,
        "ur3": 60.117138821417875,
        "standardDeviation": 4.535260466514293,
        "lowest": 22,
        "highest": 63
      },
      "750": {
        "ul3": 31.868144757994557,
        "ul2": 36.3301726772047,
        "ul1": 40.79220059641485,
        "mean": 45.254228515625,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 49.71625643483515,
        "ur2": 54.1782843540453,
        "ur3": 58.64031227325545,
        "standardDeviation": 4.462027919210149,
        "lowest": 21,
        "highest": 62
      },
      "1000": {
        "ul3": 31.216408608642077,
        "ul2": 35.597573187011385,
        "ul1": 39.97873776538069,
        "mean": 44.35990234375,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 48.74106692211931,
        "ur2": 53.12223150048862,
        "ur3": 57.503396078857925,
        "standardDeviation": 4.381164578369308,
        "lowest": 22,
        "highest": 60
      },
      "2000": {
        "ul3": 29.661577181893072,
        "ul2": 33.904928407720384,
        "ul1": 38.14827963354769,
        "mean": 42.391630859375,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 46.63498208520231,
        "ur2": 50.878333311029614,
        "ur3": 55.121684536856925,
        "standardDeviation": 4.2433512258273085,
        "lowest": 22,
        "highest": 58
      },
      "5000": {
        "ul3": 27.67550178354221,
        "ul2": 31.75541264736147,
        "ul1": 35.83532351118073,
        "mean": 39.915234375,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.99514523881926,
        "ur2": 48.07505610263853,
        "ur3": 52.154966966457785,
        "standardDeviation": 4.079910863819263,
        "lowest": 17,
        "highest": 58
      },
      "10000": {
        "ul3": 26.237213564171775,
        "ul2": 30.211810995906184,
        "ul1": 34.18640842764059,
        "mean": 38.161005859375,
        "median": 38,
        "mostFrequent": 39,
        "ur1": 42.13560329110941,
        "ur2": 46.11020072284382,
        "ur3": 50.08479815457823,
        "standardDeviation": 3.9745974317344093,
        "lowest": 20,
        "highest": 57
      }
    },
  },
  {
    name: 'De Zeilboot',
    squares: [3,8,9,15,16,17,18,19,21,22,23],
    odds: {
      "25": {
        "ul3": 38.808369304394354,
        "ul2": 44.45133474459624,
        "ul1": 50.094300184798115,
        "mean": 55.737265625,
        "median": 56,
        "mostFrequent": 58,
        "ur1": 61.38023106520188,
        "ur2": 67.02319650540376,
        "ur3": 72.66616194560564,
        "standardDeviation": 5.642965440201882,
        "lowest": 24,
        "highest": 71
      },
      "50": {
        "ul3": 36.58212603931244,
        "ul2": 42.0157793387083,
        "ul1": 47.44943263810415,
        "mean": 52.8830859375,
        "median": 53,
        "mostFrequent": 55,
        "ur1": 58.31673923689585,
        "ur2": 63.7503925362917,
        "ur3": 69.18404583568756,
        "standardDeviation": 5.433653299395851,
        "lowest": 22,
        "highest": 70
      },
      "75": {
        "ul3": 35.23544059495514,
        "ul2": 40.565576933095095,
        "ul1": 45.895713271235046,
        "mean": 51.225849609375,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.55598594751495,
        "ur2": 61.8861222856549,
        "ur3": 67.21625862379486,
        "standardDeviation": 5.330136338139951,
        "lowest": 19,
        "highest": 68
      },
      "100": {
        "ul3": 34.426518787790755,
        "ul2": 39.662395988735504,
        "ul1": 44.89827318968025,
        "mean": 50.134150390625,
        "median": 51,
        "mostFrequent": 51,
        "ur1": 55.37002759156975,
        "ur2": 60.6059047925145,
        "ur3": 65.84178199345925,
        "standardDeviation": 5.23587720094475,
        "lowest": 21,
        "highest": 66
      },
      "125": {
        "ul3": 33.749433880701545,
        "ul2": 38.922448107967696,
        "ul1": 44.09546233523385,
        "mean": 49.2684765625,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 54.44149078976615,
        "ur2": 59.6145050170323,
        "ur3": 64.78751924429845,
        "standardDeviation": 5.17301422726615,
        "lowest": 21,
        "highest": 65
      },
      "150": {
        "ul3": 33.31097135778543,
        "ul2": 38.413355905190286,
        "ul1": 43.51574045259514,
        "mean": 48.618125,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.72050954740486,
        "ur2": 58.82289409480971,
        "ur3": 63.92527864221457,
        "standardDeviation": 5.102384547404858,
        "lowest": 20,
        "highest": 66
      },
      "200": {
        "ul3": 32.463113470738726,
        "ul2": 37.50421106382582,
        "ul1": 42.54530865691291,
        "mean": 47.58640625,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 52.6275038430871,
        "ur2": 57.668601436174185,
        "ur3": 62.70969902926128,
        "standardDeviation": 5.041097593087093,
        "lowest": 18,
        "highest": 64
      },
      "250": {
        "ul3": 31.906916951963538,
        "ul2": 36.870545546100686,
        "ul1": 41.83417414023784,
        "mean": 46.797802734375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.76143132851215,
        "ur2": 56.72505992264931,
        "ur3": 61.688688516786456,
        "standardDeviation": 4.963628594137154,
        "lowest": 20,
        "highest": 63
      },
      "300": {
        "ul3": 31.42714486029829,
        "ul2": 36.3274911047822,
        "ul1": 41.227837349266096,
        "mean": 46.12818359375,
        "median": 46,
        "mostFrequent": 48,
        "ur1": 51.02852983823391,
        "ur2": 55.92887608271781,
        "ur3": 60.82922232720171,
        "standardDeviation": 4.900346244483904,
        "lowest": 19,
        "highest": 64
      },
      "400": {
        "ul3": 30.607826419629,
        "ul2": 35.44976513912766,
        "ul1": 40.291703858626335,
        "mean": 45.133642578125,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.97558129762366,
        "ur2": 54.817520017122334,
        "ur3": 59.659458736621,
        "standardDeviation": 4.841938719498666,
        "lowest": 19,
        "highest": 64
      },
      "500": {
        "ul3": 30.135027397015005,
        "ul2": 34.900001988635005,
        "ul1": 39.664976580255,
        "mean": 44.429951171875,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 49.19492576349499,
        "ur2": 53.95990035511499,
        "ur3": 58.724874946734985,
        "standardDeviation": 4.764974591619997,
        "lowest": 17,
        "highest": 62
      },
      "750": {
        "ul3": 29.109449161771458,
        "ul2": 33.77613017034764,
        "ul1": 38.44281117892382,
        "mean": 43.1094921875,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 47.776173196076186,
        "ur2": 52.44285420465236,
        "ur3": 57.10953521322855,
        "standardDeviation": 4.666681008576182,
        "lowest": 18,
        "highest": 60
      },
      "1000": {
        "ul3": 28.39806435878939,
        "ul2": 33.00313665585959,
        "ul1": 37.6082089529298,
        "mean": 42.21328125,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 46.8183535470702,
        "ur2": 51.42342584414041,
        "ur3": 56.02849814121061,
        "standardDeviation": 4.605072297070203,
        "lowest": 21,
        "highest": 60
      },
      "2000": {
        "ul3": 26.79597373852701,
        "ul2": 31.243543039226342,
        "ul1": 35.691112339925674,
        "mean": 40.138681640625,
        "median": 40,
        "mostFrequent": 41,
        "ur1": 44.58625094132433,
        "ur2": 49.033820242023666,
        "ur3": 53.481389542722994,
        "standardDeviation": 4.44756930069933,
        "lowest": 17,
        "highest": 57
      },
      "5000": {
        "ul3": 24.786782665263317,
        "ul2": 29.052457974758877,
        "ul1": 33.318133284254436,
        "mean": 37.58380859375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 41.84948390324556,
        "ur2": 46.11515921274112,
        "ur3": 50.38083452223668,
        "standardDeviation": 4.2656753094955615,
        "lowest": 18,
        "highest": 57
      },
      "10000": {
        "ul3": 23.376781393976536,
        "ul2": 27.527333429317693,
        "ul1": 31.677885464658846,
        "mean": 35.8284375,
        "median": 36,
        "mostFrequent": 36,
        "ur1": 39.97898953534116,
        "ur2": 44.129541570682306,
        "ur3": 48.28009360602346,
        "standardDeviation": 4.150552035341154,
        "lowest": 17,
        "highest": 54
      }
    },
  },
  {
    name: '8',
    squares: [2,3,4,7,9,12,13,16,18,21,22,23],
    odds: {
      "25": {
        "ul3": 40.925265090568544,
        "ul2": 46.68261162287903,
        "ul1": 52.439958155189515,
        "mean": 58.1973046875,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 63.954651219810486,
        "ur2": 69.71199775212098,
        "ur3": 75.46934428443146,
        "standardDeviation": 5.757346532310486,
        "lowest": 22,
        "highest": 73
      },
      "50": {
        "ul3": 38.63370789072907,
        "ul2": 44.29541789069438,
        "ul1": 49.95712789065969,
        "mean": 55.618837890625,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 61.28054789059031,
        "ur2": 66.94225789055562,
        "ur3": 72.60396789052093,
        "standardDeviation": 5.6617099999653115,
        "lowest": 26,
        "highest": 71
      },
      "75": {
        "ul3": 37.350306945524046,
        "ul2": 42.97935502097437,
        "ul1": 48.60840309642468,
        "mean": 54.237451171875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.86649924732532,
        "ur2": 65.49554732277564,
        "ur3": 71.12459539822595,
        "standardDeviation": 5.629048075450318,
        "lowest": 24,
        "highest": 70
      },
      "100": {
        "ul3": 36.48629094420528,
        "ul2": 42.07577599405352,
        "ul1": 47.66526104390176,
        "mean": 53.25474609375,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 58.84423114359824,
        "ur2": 64.43371619344649,
        "ur3": 70.02320124329472,
        "standardDeviation": 5.58948504984824,
        "lowest": 25,
        "highest": 69
      },
      "125": {
        "ul3": 35.77289303677841,
        "ul2": 41.350633118268945,
        "ul1": 46.92837319975947,
        "mean": 52.50611328125,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 58.083853362740534,
        "ur2": 63.66159344423106,
        "ur3": 69.23933352572159,
        "standardDeviation": 5.577740081490529,
        "lowest": 24,
        "highest": 69
      },
      "150": {
        "ul3": 35.29321786667599,
        "ul2": 40.82267258820066,
        "ul1": 46.352127309725326,
        "mean": 51.88158203125,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 57.41103675277467,
        "ur2": 62.940491474299336,
        "ur3": 68.46994619582401,
        "standardDeviation": 5.52945472152467,
        "lowest": 20,
        "highest": 69
      },
      "200": {
        "ul3": 34.46501155341922,
        "ul2": 39.962647676237815,
        "ul1": 45.46028379905641,
        "mean": 50.957919921875,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 56.455556044693594,
        "ur2": 61.95319216751219,
        "ur3": 67.45082829033079,
        "standardDeviation": 5.497636122818594,
        "lowest": 23,
        "highest": 68
      },
      "250": {
        "ul3": 33.74841010737056,
        "ul2": 39.248233691372036,
        "ul1": 44.74805727537352,
        "mean": 50.247880859375,
        "median": 51,
        "mostFrequent": 51,
        "ur1": 55.747704443376485,
        "ur2": 61.24752802737797,
        "ur3": 66.74735161137944,
        "standardDeviation": 5.499823584001482,
        "lowest": 22,
        "highest": 68
      },
      "300": {
        "ul3": 33.3071181887498,
        "ul2": 38.7721738445832,
        "ul1": 44.2372295004166,
        "mean": 49.70228515625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 55.167340812083395,
        "ur2": 60.6323964679168,
        "ur3": 66.0974521237502,
        "standardDeviation": 5.4650556558334,
        "lowest": 21,
        "highest": 68
      },
      "400": {
        "ul3": 32.55919130559843,
        "ul2": 37.98073691206562,
        "ul1": 43.40228251853281,
        "mean": 48.823828125,
        "median": 49,
        "mostFrequent": 51,
        "ur1": 54.24537373146719,
        "ur2": 59.666919337934374,
        "ur3": 65.08846494440157,
        "standardDeviation": 5.4215456064671885,
        "lowest": 21,
        "highest": 65
      },
      "500": {
        "ul3": 32.040618844039635,
        "ul2": 37.42294836998475,
        "ul1": 42.805277895929876,
        "mean": 48.187607421875,
        "median": 48,
        "mostFrequent": 50,
        "ur1": 53.569936947820125,
        "ur2": 58.95226647376525,
        "ur3": 64.33459599971036,
        "standardDeviation": 5.3823295259451225,
        "lowest": 23,
        "highest": 65
      },
      "750": {
        "ul3": 30.951177515810002,
        "ul2": 36.30297902095667,
        "ul1": 41.65478052610333,
        "mean": 47.00658203125,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 52.358383536396666,
        "ur2": 57.710185041543326,
        "ur3": 63.061986546689994,
        "standardDeviation": 5.351801505146666,
        "lowest": 21,
        "highest": 66
      },
      "1000": {
        "ul3": 30.25192614750622,
        "ul2": 35.57450349937915,
        "ul1": 40.89708085125207,
        "mean": 46.219658203125,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 51.54223555499793,
        "ur2": 56.86481290687085,
        "ur3": 62.187390258743775,
        "standardDeviation": 5.322577351872926,
        "lowest": 21,
        "highest": 64
      },
      "2000": {
        "ul3": 28.624400426237944,
        "ul2": 33.86231512790863,
        "ul1": 39.10022982957932,
        "mean": 44.33814453125,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 49.576059232920684,
        "ur2": 54.813973934591374,
        "ur3": 60.05188863626206,
        "standardDeviation": 5.237914701670686,
        "lowest": 18,
        "highest": 63
      },
      "5000": {
        "ul3": 26.594750172652173,
        "ul2": 31.754286573434783,
        "ul1": 36.913822974217396,
        "mean": 42.073359375,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 47.23289577578261,
        "ur2": 52.39243217656522,
        "ur3": 57.551968577347836,
        "standardDeviation": 5.15953640078261,
        "lowest": 18,
        "highest": 61
      },
      "10000": {
        "ul3": 25.276120585795653,
        "ul2": 30.346931953030435,
        "ul1": 35.41774332026522,
        "mean": 40.4885546875,
        "median": 41,
        "mostFrequent": 41,
        "ur1": 45.55936605473478,
        "ur2": 50.63017742196956,
        "ur3": 55.700988789204345,
        "standardDeviation": 5.070811367234782,
        "lowest": 18,
        "highest": 59
      }
    },
  },
  {
    name: '10',
    squares: [1,3,4,5,6,8,10,11,14,15,17,19,20,22,23,24],
    odds: {
      "25": {
        "ul3": 48.91346131853963,
        "ul2": 53.27131470715142,
        "ul1": 57.629168095763205,
        "mean": 61.987021484375,
        "median": 63,
        "mostFrequent": 64,
        "ur1": 66.3448748729868,
        "ur2": 70.70272826159858,
        "ur3": 75.06058165021037,
        "standardDeviation": 4.35785338861179,
        "lowest": 36,
        "highest": 73
      },
      "50": {
        "ul3": 47.02783286202335,
        "ul2": 51.31775771530724,
        "ul1": 55.607682568591116,
        "mean": 59.897607421875,
        "median": 60,
        "mostFrequent": 61,
        "ur1": 64.18753227515889,
        "ur2": 68.47745712844277,
        "ur3": 72.76738198172666,
        "standardDeviation": 4.289924853283883,
        "lowest": 29,
        "highest": 71
      },
      "75": {
        "ul3": 45.921314182704805,
        "ul2": 50.17369513221987,
        "ul1": 54.426076081734934,
        "mean": 58.67845703125,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 62.93083798076506,
        "ur2": 67.18321893028013,
        "ur3": 71.43559987979519,
        "standardDeviation": 4.252380949515064,
        "lowest": 30,
        "highest": 70
      },
      "100": {
        "ul3": 45.17955096833936,
        "ul2": 49.41669608826791,
        "ul1": 53.653841208196454,
        "mean": 57.890986328125,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.12813144805355,
        "ur2": 66.3652765679821,
        "ur3": 70.60242168791063,
        "standardDeviation": 4.237145119928546,
        "lowest": 31,
        "highest": 70
      },
      "125": {
        "ul3": 44.67294151518967,
        "ul2": 48.87393692158478,
        "ul1": 53.074932327979894,
        "mean": 57.275927734375,
        "median": 58,
        "mostFrequent": 58,
        "ur1": 61.47692314077011,
        "ur2": 65.67791854716522,
        "ur3": 69.87891395356033,
        "standardDeviation": 4.200995406395111,
        "lowest": 33,
        "highest": 69
      },
      "150": {
        "ul3": 44.13776043664113,
        "ul2": 48.337582478594086,
        "ul1": 52.53740452054704,
        "mean": 56.7372265625,
        "median": 57,
        "mostFrequent": 58,
        "ur1": 60.937048604452954,
        "ur2": 65.13687064640591,
        "ur3": 69.33669268835887,
        "standardDeviation": 4.199822041952956,
        "lowest": 32,
        "highest": 69
      },
      "200": {
        "ul3": 43.488616770260286,
        "ul2": 47.64445219579852,
        "ul1": 51.800287621336764,
        "mean": 55.956123046875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 60.111958472413235,
        "ur2": 64.26779389795148,
        "ur3": 68.4236293234897,
        "standardDeviation": 4.155835425538237,
        "lowest": 33,
        "highest": 68
      },
      "250": {
        "ul3": 42.935188136356714,
        "ul2": 47.07090016382114,
        "ul1": 51.20661219128557,
        "mean": 55.34232421875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 59.47803624621443,
        "ur2": 63.61374827367886,
        "ur3": 67.74946030114329,
        "standardDeviation": 4.135712027464428,
        "lowest": 32,
        "highest": 67
      },
      "300": {
        "ul3": 42.570347800419846,
        "ul2": 46.6673933252799,
        "ul1": 50.76443885013995,
        "mean": 54.861484375,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 58.95852989986005,
        "ur2": 63.055575424720104,
        "ur3": 67.15262094958015,
        "standardDeviation": 4.097045524860052,
        "lowest": 30,
        "highest": 67
      },
      "400": {
        "ul3": 41.850889022241965,
        "ul2": 45.93684919191131,
        "ul1": 50.022809361580656,
        "mean": 54.10876953125,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 58.19472970091934,
        "ur2": 62.28068987058869,
        "ur3": 66.36665004025804,
        "standardDeviation": 4.085960169669344,
        "lowest": 28,
        "highest": 66
      },
      "500": {
        "ul3": 41.27098556581609,
        "ul2": 45.35493764283573,
        "ul1": 49.43888971985536,
        "mean": 53.522841796875,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 57.60679387389464,
        "ur2": 61.69074595091427,
        "ur3": 65.77469802793391,
        "standardDeviation": 4.083952077019636,
        "lowest": 27,
        "highest": 66
      },
      "750": {
        "ul3": 40.38771281080889,
        "ul2": 44.42028835824759,
        "ul1": 48.45286390568629,
        "mean": 52.485439453125,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 56.5180150005637,
        "ur2": 60.550590548002404,
        "ur3": 64.5831660954411,
        "standardDeviation": 4.032575547438703,
        "lowest": 27,
        "highest": 65
      },
      "1000": {
        "ul3": 39.75052516582394,
        "ul2": 43.76017432929929,
        "ul1": 47.76982349277465,
        "mean": 51.77947265625,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 55.78912181972535,
        "ur2": 59.79877098320071,
        "ur3": 63.80842014667606,
        "standardDeviation": 4.009649163475355,
        "lowest": 29,
        "highest": 65
      },
      "2000": {
        "ul3": 38.28561291081131,
        "ul2": 42.24094897179087,
        "ul1": 46.19628503277044,
        "mean": 50.15162109375,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.106957154729564,
        "ur2": 58.06229321570913,
        "ur3": 62.017629276688695,
        "standardDeviation": 3.9553360609795636,
        "lowest": 28,
        "highest": 63
      },
      "5000": {
        "ul3": 36.43507896428813,
        "ul2": 40.304154205358756,
        "ul1": 44.173229446429374,
        "mean": 48.0423046875,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.911379928570625,
        "ur2": 55.78045516964124,
        "ur3": 59.64953041071187,
        "standardDeviation": 3.869075241070622,
        "lowest": 26,
        "highest": 62
      },
      "10000": {
        "ul3": 35.0295161529221,
        "ul2": 38.86722300819807,
        "ul1": 42.704929863474035,
        "mean": 46.54263671875,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 50.38034357402597,
        "ur2": 54.21805042930194,
        "ur3": 58.05575728457791,
        "standardDeviation": 3.837706855275968,
        "lowest": 28,
        "highest": 60
      }
    },
  },
  {
    name: 'De Hoed',
    squares: [3,8,12,13,16,17,18,20,21,22,23,24], //12
    odds: {
      "25": {
        "ul3": 41.38442217088874,
        "ul2": 46.67543183788416,
        "ul1": 51.966441504879576,
        "mean": 57.257451171875,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.54846083887042,
        "ur2": 67.83947050586583,
        "ur3": 73.13048017286125,
        "standardDeviation": 5.29100966699542,
        "lowest": 25,
        "highest": 72
      },
      "50": {
        "ul3": 39.131229859819285,
        "ul2": 44.25586547946286,
        "ul1": 49.38050109910643,
        "mean": 54.50513671875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.62977233839357,
        "ur2": 64.75440795803715,
        "ur3": 69.87904357768072,
        "standardDeviation": 5.124635619643571,
        "lowest": 26,
        "highest": 69
      },
      "75": {
        "ul3": 38.0198779587264,
        "ul2": 43.0197298370676,
        "ul1": 48.019581715408805,
        "mean": 53.01943359375,
        "median": 54,
        "mostFrequent": 54,
        "ur1": 58.0192854720912,
        "ur2": 63.0191373504324,
        "ur3": 68.0189892287736,
        "standardDeviation": 4.9998518783412,
        "lowest": 24,
        "highest": 67
      },
      "100": {
        "ul3": 37.10107803288358,
        "ul2": 42.051630146922385,
        "ul1": 47.00218226096119,
        "mean": 51.952734375,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.903286489038805,
        "ur2": 61.85383860307761,
        "ur3": 66.80439071711642,
        "standardDeviation": 4.950552114038806,
        "lowest": 25,
        "highest": 68
      },
      "125": {
        "ul3": 36.56621638766369,
        "ul2": 41.43683957094246,
        "ul1": 46.30746275422123,
        "mean": 51.1780859375,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 56.048709120778774,
        "ur2": 60.91933230405754,
        "ur3": 65.78995548733631,
        "standardDeviation": 4.870623183278771,
        "lowest": 22,
        "highest": 66
      },
      "150": {
        "ul3": 36.05619781907541,
        "ul2": 40.87530700959194,
        "ul1": 45.69441620010847,
        "mean": 50.513525390625,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.33263458114153,
        "ur2": 60.151743771658055,
        "ur3": 64.97085296217459,
        "standardDeviation": 4.819109190516529,
        "lowest": 22,
        "highest": 65
      },
      "200": {
        "ul3": 35.1904801091741,
        "ul2": 39.96385522903273,
        "ul1": 44.737230348891366,
        "mean": 49.51060546875,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 54.283980588608635,
        "ur2": 59.05735570846727,
        "ur3": 63.8307308283259,
        "standardDeviation": 4.773375119858634,
        "lowest": 24,
        "highest": 65
      },
      "250": {
        "ul3": 34.72175661008011,
        "ul2": 39.41166260984507,
        "ul1": 44.101568609610034,
        "mean": 48.791474609375,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.48138060913997,
        "ur2": 58.171286608904936,
        "ur3": 62.861192608669896,
        "standardDeviation": 4.6899059997649655,
        "lowest": 21,
        "highest": 64
      },
      "300": {
        "ul3": 34.21892129042243,
        "ul2": 38.86682317798995,
        "ul1": 43.51472506555748,
        "mean": 48.162626953125,
        "median": 49,
        "mostFrequent": 49,
        "ur1": 52.81052884069252,
        "ur2": 57.45843072826005,
        "ur3": 62.10633261582757,
        "standardDeviation": 4.6479018875675235,
        "lowest": 22,
        "highest": 62
      },
      "400": {
        "ul3": 33.51455406487176,
        "ul2": 38.0817339599145,
        "ul1": 42.64891385495725,
        "mean": 47.21609375,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.78327364504275,
        "ur2": 56.350453540085496,
        "ur3": 60.91763343512824,
        "standardDeviation": 4.567179895042748,
        "lowest": 24,
        "highest": 62
      },
      "500": {
        "ul3": 32.950124320412456,
        "ul2": 37.47631985944164,
        "ul1": 42.00251539847082,
        "mean": 46.5287109375,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 51.05490647652918,
        "ur2": 55.581102015558365,
        "ur3": 60.10729755458755,
        "standardDeviation": 4.526195539029181,
        "lowest": 20,
        "highest": 63
      },
      "750": {
        "ul3": 31.901579579744823,
        "ul2": 36.35666177712155,
        "ul1": 40.81174397449828,
        "mean": 45.266826171875,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 49.72190836925173,
        "ur2": 54.176990566628454,
        "ur3": 58.63207276400519,
        "standardDeviation": 4.4550821973767265,
        "lowest": 21,
        "highest": 61
      },
      "1000": {
        "ul3": 31.192635025314885,
        "ul2": 35.58409066791826,
        "ul1": 39.97554631052163,
        "mean": 44.367001953125,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 48.758457595728366,
        "ur2": 53.149913238331735,
        "ur3": 57.54136888093511,
        "standardDeviation": 4.39145564260337,
        "lowest": 22,
        "highest": 61
      },
      "2000": {
        "ul3": 29.640881803095276,
        "ul2": 33.885526019771845,
        "ul1": 38.13017023644842,
        "mean": 42.374814453125,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 46.619458669801574,
        "ur2": 50.86410288647815,
        "ur3": 55.10874710315472,
        "standardDeviation": 4.2446442166765745,
        "lowest": 22,
        "highest": 57
      },
      "5000": {
        "ul3": 27.680296027966197,
        "ul2": 31.754917404060798,
        "ul1": 35.8295387801554,
        "mean": 39.90416015625,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.9787815323446,
        "ur2": 48.0534029084392,
        "ur3": 52.12802428453381,
        "standardDeviation": 4.074621376094601,
        "lowest": 18,
        "highest": 58
      },
      "10000": {
        "ul3": 26.27726890006391,
        "ul2": 30.242762600042603,
        "ul1": 34.208256300021304,
        "mean": 38.17375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 42.13924369997869,
        "ur2": 46.10473739995739,
        "ur3": 50.07023109993609,
        "standardDeviation": 3.965493699978697,
        "lowest": 21,
        "highest": 54
      }
    },
  },
  {
    name: 'De Libelle',
    squares: [1,2,3,4,5,7,8,9,11,14,17,22],
    odds: {
      "25": {
        "ul3": 41.48787790329853,
        "ul2": 46.68619269074068,
        "ul1": 51.88450747818284,
        "mean": 57.082822265625,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.281137053067155,
        "ur2": 67.47945184050931,
        "ur3": 72.67776662795147,
        "standardDeviation": 5.198314787442156,
        "lowest": 26,
        "highest": 72
      },
      "50": {
        "ul3": 39.329356811595616,
        "ul2": 44.33026456710541,
        "ul1": 49.3311723226152,
        "mean": 54.332080078125,
        "median": 55,
        "mostFrequent": 55,
        "ur1": 59.3329878336348,
        "ur2": 64.33389558914459,
        "ur3": 69.33480334465439,
        "standardDeviation": 5.000907755509797,
        "lowest": 23,
        "highest": 69
      },
      "75": {
        "ul3": 38.246501432059404,
        "ul2": 43.091472959914604,
        "ul1": 47.9364444877698,
        "mean": 52.781416015625,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.6263875434802,
        "ur2": 62.47135907133539,
        "ur3": 67.3163305991906,
        "standardDeviation": 4.844971527855198,
        "lowest": 26,
        "highest": 68
      },
      "100": {
        "ul3": 37.37576343678179,
        "ul2": 42.14661898389619,
        "ul1": 46.9174745310106,
        "mean": 51.688330078125,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.4591856252394,
        "ur2": 61.23004117235381,
        "ur3": 66.00089671946822,
        "standardDeviation": 4.770855547114404,
        "lowest": 24,
        "highest": 67
      },
      "125": {
        "ul3": 36.795254165293485,
        "ul2": 41.49408871436233,
        "ul1": 46.19292326343116,
        "mean": 50.8917578125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.590592361568845,
        "ur2": 60.28942691063768,
        "ur3": 64.98826145970652,
        "standardDeviation": 4.698834549068839,
        "lowest": 21,
        "highest": 66
      },
      "150": {
        "ul3": 36.27280269653078,
        "ul2": 40.92495440185385,
        "ul1": 45.57710610717693,
        "mean": 50.2292578125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 54.88140951782307,
        "ur2": 59.53356122314615,
        "ur3": 64.18571292846923,
        "standardDeviation": 4.652151705323074,
        "lowest": 24,
        "highest": 65
      },
      "200": {
        "ul3": 35.50542492770437,
        "ul2": 40.081754639302915,
        "ul1": 44.65808435090146,
        "mean": 49.2344140625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.81074377409854,
        "ur2": 58.38707348569709,
        "ur3": 62.963403197295634,
        "standardDeviation": 4.576329711598543,
        "lowest": 25,
        "highest": 65
      },
      "250": {
        "ul3": 34.926360339410984,
        "ul2": 39.43131704919065,
        "ul1": 43.93627375897033,
        "mean": 48.44123046875,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.94618717852967,
        "ur2": 57.451143888309346,
        "ur3": 61.956100598089016,
        "standardDeviation": 4.504956709779671,
        "lowest": 24,
        "highest": 64
      },
      "300": {
        "ul3": 34.53453139147232,
        "ul2": 38.97407887035655,
        "ul1": 43.41362634924077,
        "mean": 47.853173828125,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 52.29272130700923,
        "ur2": 56.73226878589345,
        "ur3": 61.17181626477768,
        "standardDeviation": 4.4395474788842275,
        "lowest": 18,
        "highest": 63
      },
      "400": {
        "ul3": 33.790802790091554,
        "ul2": 38.15748831839437,
        "ul1": 42.52417384669718,
        "mean": 46.890859375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.257544903302815,
        "ur2": 55.624230431605625,
        "ur3": 59.99091595990844,
        "standardDeviation": 4.366685528302814,
        "lowest": 19,
        "highest": 61
      },
      "500": {
        "ul3": 33.21372026668375,
        "ul2": 37.5290068704975,
        "ul1": 41.84429347431125,
        "mean": 46.159580078125,
        "median": 47,
        "mostFrequent": 47,
        "ur1": 50.47486668193876,
        "ur2": 54.79015328575251,
        "ur3": 59.105439889566256,
        "standardDeviation": 4.315286603813752,
        "lowest": 21,
        "highest": 61
      },
      "750": {
        "ul3": 32.23805444540128,
        "ul2": 36.445913250059185,
        "ul1": 40.653772054717095,
        "mean": 44.861630859375,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.0694896640329,
        "ur2": 53.27734846869081,
        "ur3": 57.48520727334871,
        "standardDeviation": 4.2078588046579055,
        "lowest": 22,
        "highest": 60
      },
      "1000": {
        "ul3": 31.59951371126079,
        "ul2": 35.73655406271553,
        "ul1": 39.87359441417026,
        "mean": 44.010634765625,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.147675117079736,
        "ur2": 52.28471546853447,
        "ur3": 56.42175581998921,
        "standardDeviation": 4.137040351454736,
        "lowest": 19,
        "highest": 60
      },
      "2000": {
        "ul3": 30.033454148903544,
        "ul2": 34.00638154197736,
        "ul1": 37.97930893505118,
        "mean": 41.952236328125,
        "median": 42,
        "mostFrequent": 43,
        "ur1": 45.925163721198814,
        "ur2": 49.89809111427263,
        "ur3": 53.87101850734645,
        "standardDeviation": 3.972927393073817,
        "lowest": 19,
        "highest": 59
      },
      "5000": {
        "ul3": 28.06970743438136,
        "ul2": 31.857539331254237,
        "ul1": 35.64537122812712,
        "mean": 39.433203125,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.22103502187288,
        "ur2": 47.00886691874576,
        "ur3": 50.79669881561864,
        "standardDeviation": 3.7878318968728797,
        "lowest": 20,
        "highest": 55
      },
      "10000": {
        "ul3": 26.685206221751386,
        "ul2": 30.348724720750926,
        "ul1": 34.01224321975047,
        "mean": 37.67576171875,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 41.33928021774954,
        "ur2": 45.00279871674908,
        "ur3": 48.66631721574862,
        "standardDeviation": 3.6635184989995384,
        "lowest": 20,
        "highest": 54
      }
    },
  },
  {
    name: 'De Tol',
    squares: [2,3,4,8,11,12,13,14,16,17,18,22], //12
    odds: {
      "25": {
        "ul3": 41.33132065039075,
        "ul2": 46.65605621484384,
        "ul1": 51.980791779296915,
        "mean": 57.30552734375,
        "median": 58,
        "mostFrequent": 59,
        "ur1": 62.630262908203086,
        "ur2": 67.95499847265617,
        "ur3": 73.27973403710925,
        "standardDeviation": 5.324735564453083,
        "lowest": 26,
        "highest": 72
      },
      "50": {
        "ul3": 39.24067904084008,
        "ul2": 44.33653016785172,
        "ul1": 49.43238129486336,
        "mean": 54.528232421875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 59.624083548886645,
        "ur2": 64.71993467589829,
        "ur3": 69.81578580290993,
        "standardDeviation": 5.095851127011641,
        "lowest": 26,
        "highest": 69
      },
      "75": {
        "ul3": 37.99139480990553,
        "ul2": 43.002091982645354,
        "ul1": 48.01278915538518,
        "mean": 53.023486328125,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 58.03418350086483,
        "ur2": 63.04488067360465,
        "ur3": 68.05557784634448,
        "standardDeviation": 5.010697172739824,
        "lowest": 23,
        "highest": 68
      },
      "100": {
        "ul3": 37.089155351503564,
        "ul2": 42.04867192704404,
        "ul1": 47.00818850258452,
        "mean": 51.967705078125,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.927221653665484,
        "ur2": 61.88673822920596,
        "ur3": 66.84625480474644,
        "standardDeviation": 4.95951657554048,
        "lowest": 25,
        "highest": 66
      },
      "125": {
        "ul3": 36.49565687337406,
        "ul2": 41.37765796766604,
        "ul1": 46.25965906195802,
        "mean": 51.14166015625,
        "median": 52,
        "mostFrequent": 52,
        "ur1": 56.02366125054198,
        "ur2": 60.90566234483396,
        "ur3": 65.78766343912594,
        "standardDeviation": 4.882001094291979,
        "lowest": 26,
        "highest": 66
      },
      "150": {
        "ul3": 36.09391486177841,
        "ul2": 40.90980066306061,
        "ul1": 45.7256864643428,
        "mean": 50.541572265625,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.3574580669072,
        "ur2": 60.17334386818939,
        "ur3": 64.9892296694716,
        "standardDeviation": 4.815885801282197,
        "lowest": 22,
        "highest": 65
      },
      "200": {
        "ul3": 35.305752775020686,
        "ul2": 40.05742242293046,
        "ul1": 44.80909207084023,
        "mean": 49.56076171875,
        "median": 50,
        "mostFrequent": 50,
        "ur1": 54.31243136665977,
        "ur2": 59.064101014569545,
        "ur3": 63.815770662479316,
        "standardDeviation": 4.751669647909772,
        "lowest": 23,
        "highest": 64
      },
      "250": {
        "ul3": 34.65412272426936,
        "ul2": 39.35703559222124,
        "ul1": 44.05994846017312,
        "mean": 48.762861328125,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.46577419607688,
        "ur2": 58.16868706402876,
        "ur3": 62.87159993198064,
        "standardDeviation": 4.702912867951881,
        "lowest": 20,
        "highest": 63
      },
      "300": {
        "ul3": 34.20217468540744,
        "ul2": 38.857143149646625,
        "ul1": 43.512111613885814,
        "mean": 48.167080078125,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.82204854236418,
        "ur2": 57.47701700660337,
        "ur3": 62.13198547084255,
        "standardDeviation": 4.654968464239185,
        "lowest": 22,
        "highest": 63
      },
      "400": {
        "ul3": 33.40566909105767,
        "ul2": 38.00791676383011,
        "ul1": 42.61016443660256,
        "mean": 47.212412109375,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.814659782147444,
        "ur2": 56.416907454919894,
        "ur3": 61.019155127692336,
        "standardDeviation": 4.602247672772444,
        "lowest": 19,
        "highest": 63
      },
      "500": {
        "ul3": 32.907016467699336,
        "ul2": 37.44303050971622,
        "ul1": 41.97904455173311,
        "mean": 46.51505859375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.051072635766886,
        "ur2": 55.58708667778377,
        "ur3": 60.12310071980066,
        "standardDeviation": 4.5360140420168875,
        "lowest": 22,
        "highest": 63
      },
      "750": {
        "ul3": 31.991052855401584,
        "ul2": 36.41510620047605,
        "ul1": 40.83915954555053,
        "mean": 45.263212890625,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 49.68726623569947,
        "ur2": 54.111319580773944,
        "ur3": 58.535372925848414,
        "standardDeviation": 4.424053345074472,
        "lowest": 20,
        "highest": 61
      },
      "1000": {
        "ul3": 31.269032605638046,
        "ul2": 35.64832967980036,
        "ul1": 40.027626753962686,
        "mean": 44.406923828125,
        "median": 45,
        "mostFrequent": 45,
        "ur1": 48.78622090228732,
        "ur2": 53.16551797644964,
        "ur3": 57.54481505061196,
        "standardDeviation": 4.379297074162319,
        "lowest": 21,
        "highest": 60
      },
      "2000": {
        "ul3": 29.67967827811034,
        "ul2": 33.91791051874023,
        "ul1": 38.15614275937011,
        "mean": 42.394375,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 46.63260724062988,
        "ur2": 50.870839481259765,
        "ur3": 55.10907172188965,
        "standardDeviation": 4.238232240629885,
        "lowest": 20,
        "highest": 61
      },
      "5000": {
        "ul3": 27.649653795924493,
        "ul2": 31.733991202491328,
        "ul1": 35.81832860905816,
        "mean": 39.902666015625,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.98700342219184,
        "ur2": 48.07134082875868,
        "ur3": 52.15567823532551,
        "standardDeviation": 4.084337406566837,
        "lowest": 20,
        "highest": 60
      },
      "10000": {
        "ul3": 26.316278949373082,
        "ul2": 30.27173479437372,
        "ul1": 34.227190639374356,
        "mean": 38.182646484375,
        "median": 38,
        "mostFrequent": 38,
        "ur1": 42.13810232937564,
        "ur2": 46.09355817437628,
        "ur3": 50.04901401937691,
        "standardDeviation": 3.955455845000639,
        "lowest": 19,
        "highest": 58
      }
    },
  },
  {
    name: 'De Vliegenmepper',
    squares: [1,2,3,6,7,8,11,12,16,21,24], //11
    odds: {
      "25": {
        "ul3": 38.42427182538976,
        "ul2": 44.359589420051506,
        "ul1": 50.29490701471325,
        "mean": 56.230224609375,
        "median": 57,
        "mostFrequent": 58,
        "ur1": 62.16554220403675,
        "ur2": 68.1008597986985,
        "ur3": 74.03617739336025,
        "standardDeviation": 5.935317594661747,
        "lowest": 25,
        "highest": 73
      },
      "50": {
        "ul3": 36.06062109946173,
        "ul2": 41.85969466526615,
        "ul1": 47.658768231070574,
        "mean": 53.457841796875,
        "median": 54,
        "mostFrequent": 56,
        "ur1": 59.25691536267943,
        "ur2": 65.05598892848386,
        "ur3": 70.85506249428828,
        "standardDeviation": 5.799073565804426,
        "lowest": 24,
        "highest": 71
      },
      "75": {
        "ul3": 34.888916853914125,
        "ul2": 40.56435602760942,
        "ul1": 46.23979520130471,
        "mean": 51.915234375,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 57.590673548695285,
        "ur2": 63.266112722390574,
        "ur3": 68.94155189608587,
        "standardDeviation": 5.67543917369529,
        "lowest": 22,
        "highest": 70
      },
      "100": {
        "ul3": 33.90629170594944,
        "ul2": 39.54575046021629,
        "ul1": 45.185209214483145,
        "mean": 50.82466796875,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 56.464126723016854,
        "ur2": 62.10358547728371,
        "ur3": 67.74304423155056,
        "standardDeviation": 5.639458754266854,
        "lowest": 22,
        "highest": 69
      },
      "125": {
        "ul3": 33.19495764499926,
        "ul2": 38.78453556541618,
        "ul1": 44.37411348583309,
        "mean": 49.96369140625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 55.55326932666691,
        "ur2": 61.14284724708382,
        "ur3": 66.73242516750074,
        "standardDeviation": 5.589577920416911,
        "lowest": 20,
        "highest": 67
      },
      "150": {
        "ul3": 32.679375671608824,
        "ul2": 38.23014042169755,
        "ul1": 43.78090517178627,
        "mean": 49.331669921875,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.88243467196372,
        "ur2": 60.433199422052446,
        "ur3": 65.98396417214117,
        "standardDeviation": 5.550764750088724,
        "lowest": 21,
        "highest": 67
      },
      "200": {
        "ul3": 31.871087231574833,
        "ul2": 37.36143771167489,
        "ul1": 42.85178819177494,
        "mean": 48.342138671875,
        "median": 49,
        "mostFrequent": 49,
        "ur1": 53.832489151975054,
        "ur2": 59.32283963207511,
        "ur3": 64.81319011217516,
        "standardDeviation": 5.490350480100055,
        "lowest": 20,
        "highest": 66
      },
      "250": {
        "ul3": 31.266980823536343,
        "ul2": 36.70919164277423,
        "ul1": 42.151402462012115,
        "mean": 47.59361328125,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 53.035824100487886,
        "ur2": 58.47803491972577,
        "ur3": 63.92024573896366,
        "standardDeviation": 5.442210819237886,
        "lowest": 19,
        "highest": 66
      },
      "300": {
        "ul3": 30.71267857307252,
        "ul2": 36.129048085173345,
        "ul1": 41.545417597274174,
        "mean": 46.961787109375,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 52.37815662147583,
        "ur2": 57.79452613357666,
        "ur3": 63.21089564567748,
        "standardDeviation": 5.416369512100828,
        "lowest": 19,
        "highest": 65
      },
      "400": {
        "ul3": 29.96003661403437,
        "ul2": 35.31454589373124,
        "ul1": 40.66905517342812,
        "mean": 46.023564453125,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 51.378073732821875,
        "ur2": 56.73258301251875,
        "ur3": 62.08709229221563,
        "standardDeviation": 5.3545092796968765,
        "lowest": 19,
        "highest": 64
      },
      "500": {
        "ul3": 29.33994077426157,
        "ul2": 34.66347614117438,
        "ul1": 39.98701150808719,
        "mean": 45.310546875,
        "median": 46,
        "mostFrequent": 46,
        "ur1": 50.63408224191281,
        "ur2": 55.95761760882562,
        "ur3": 61.28115297573843,
        "standardDeviation": 5.32353536691281,
        "lowest": 17,
        "highest": 65
      },
      "750": {
        "ul3": 28.24280762715123,
        "ul2": 33.50067383476748,
        "ul1": 38.75854004238374,
        "mean": 44.01640625,
        "median": 44,
        "mostFrequent": 44,
        "ur1": 49.27427245761626,
        "ur2": 54.53213866523252,
        "ur3": 59.790004872848776,
        "standardDeviation": 5.257866207616258,
        "lowest": 19,
        "highest": 64
      },
      "1000": {
        "ul3": 27.546999460411755,
        "ul2": 32.75400224444117,
        "ul1": 37.96100502847059,
        "mean": 43.1680078125,
        "median": 43,
        "mostFrequent": 43,
        "ur1": 48.375010596529414,
        "ur2": 53.582013380558834,
        "ur3": 58.78901616458825,
        "standardDeviation": 5.207002784029415,
        "lowest": 18,
        "highest": 63
      },
      "2000": {
        "ul3": 25.886456884759163,
        "ul2": 30.99569781900611,
        "ul1": 36.10493875325305,
        "mean": 41.2141796875,
        "median": 41,
        "mostFrequent": 42,
        "ur1": 46.32342062174695,
        "ur2": 51.43266155599389,
        "ur3": 56.54190249024084,
        "standardDeviation": 5.109240934246945,
        "lowest": 15,
        "highest": 63
      },
      "5000": {
        "ul3": 23.76303838804915,
        "ul2": 28.7615503316161,
        "ul1": 33.760062275183046,
        "mean": 38.75857421875,
        "median": 39,
        "mostFrequent": 39,
        "ur1": 43.75708616231695,
        "ur2": 48.7555981058839,
        "ur3": 53.754110049450844,
        "standardDeviation": 4.99851194356695,
        "lowest": 17,
        "highest": 60
      },
      "10000": {
        "ul3": 22.372208684370754,
        "ul2": 27.274682091663838,
        "ul1": 32.17715549895692,
        "mean": 37.07962890625,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 41.98210231354308,
        "ur2": 46.88457572083617,
        "ur3": 51.78704912812925,
        "standardDeviation": 4.902473407293082,
        "lowest": 17,
        "highest": 59
      }
    },
  },
  {
    name: 'De Schildpad',
    squares: [3,6,7,8,9,10,12,13,16,17,18,20,24], //13
    odds: {
      "25": {
        "ul3": 43.87877788266394,
        "ul2": 48.688215203025955,
        "ul1": 53.49765252338798,
        "mean": 58.30708984375,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 63.11652716411202,
        "ur2": 67.92596448447404,
        "ur3": 72.73540180483606,
        "standardDeviation": 4.809437320362021,
        "lowest": 27,
        "highest": 71
      },
      "50": {
        "ul3": 41.83354423118939,
        "ul2": 46.45367466975126,
        "ul1": 51.07380510831313,
        "mean": 55.693935546875,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 60.31406598543686,
        "ur2": 64.93419642399873,
        "ur3": 69.5543268625606,
        "standardDeviation": 4.620130438561868,
        "lowest": 28,
        "highest": 68
      },
      "75": {
        "ul3": 40.70851335765035,
        "ul2": 45.20137218635023,
        "ul1": 49.694231015050114,
        "mean": 54.18708984375,
        "median": 55,
        "mostFrequent": 55,
        "ur1": 58.67994867244989,
        "ur2": 63.17280750114978,
        "ur3": 67.66566632984966,
        "standardDeviation": 4.492858828699886,
        "lowest": 24,
        "highest": 66
      },
      "100": {
        "ul3": 40.02151895753819,
        "ul2": 44.42118190919213,
        "ul1": 48.820844860846066,
        "mean": 53.2205078125,
        "median": 54,
        "mostFrequent": 55,
        "ur1": 57.62017076415394,
        "ur2": 62.01983371580788,
        "ur3": 66.41949666746181,
        "standardDeviation": 4.399662951653937,
        "lowest": 28,
        "highest": 66
      },
      "125": {
        "ul3": 39.42855769749568,
        "ul2": 43.761323621247115,
        "ul1": 48.09408954499856,
        "mean": 52.42685546875,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 56.75962139250144,
        "ur2": 61.09238731625288,
        "ur3": 65.42515324000432,
        "standardDeviation": 4.33276592375144,
        "lowest": 25,
        "highest": 65
      },
      "150": {
        "ul3": 38.89744659679418,
        "ul2": 43.19126973640445,
        "ul1": 47.485092876014726,
        "mean": 51.778916015625,
        "median": 52,
        "mostFrequent": 53,
        "ur1": 56.072739155235276,
        "ur2": 60.36656229484555,
        "ur3": 64.66038543445582,
        "standardDeviation": 4.293823139610274,
        "lowest": 25,
        "highest": 65
      },
      "200": {
        "ul3": 38.225584375033094,
        "ul2": 42.4269716146054,
        "ul1": 46.6283588541777,
        "mean": 50.82974609375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 55.03113333332231,
        "ur2": 59.23252057289461,
        "ur3": 63.43390781246691,
        "standardDeviation": 4.201387239572303,
        "lowest": 26,
        "highest": 62
      },
      "250": {
        "ul3": 37.68199740892419,
        "ul2": 41.81145855907446,
        "ul1": 45.94091970922473,
        "mean": 50.070380859375,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 54.19984200952527,
        "ur2": 58.32930315967554,
        "ur3": 62.45876430982581,
        "standardDeviation": 4.129461150150271,
        "lowest": 22,
        "highest": 62
      },
      "300": {
        "ul3": 37.21863951034938,
        "ul2": 41.305824777732916,
        "ul1": 45.39301004511646,
        "mean": 49.4801953125,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 53.56738057988354,
        "ur2": 57.65456584726709,
        "ur3": 61.741751114650626,
        "standardDeviation": 4.087185267383542,
        "lowest": 26,
        "highest": 61
      },
      "400": {
        "ul3": 36.597286883624236,
        "ul2": 40.59333774012449,
        "ul1": 44.589388596624744,
        "mean": 48.585439453125,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 52.58149030962525,
        "ur2": 56.577541166125506,
        "ur3": 60.57359202262576,
        "standardDeviation": 3.9960508565002533,
        "lowest": 26,
        "highest": 60
      },
      "500": {
        "ul3": 36.0197831815418,
        "ul2": 39.97103383977787,
        "ul1": 43.92228449801393,
        "mean": 47.87353515625,
        "median": 48,
        "mostFrequent": 49,
        "ur1": 51.82478581448607,
        "ur2": 55.77603647272213,
        "ur3": 59.7272871309582,
        "standardDeviation": 3.9512506582360674,
        "lowest": 25,
        "highest": 60
      },
      "750": {
        "ul3": 35.040471850313075,
        "ul2": 38.89691938458372,
        "ul1": 42.753366918854354,
        "mean": 46.609814453125,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 50.46626198739564,
        "ur2": 54.322709521666276,
        "ur3": 58.17915705593692,
        "standardDeviation": 3.8564475342706404,
        "lowest": 23,
        "highest": 58
      },
      "1000": {
        "ul3": 34.47801718901402,
        "ul2": 38.246861719759345,
        "ul1": 42.01570625050467,
        "mean": 45.78455078125,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 49.553395311995324,
        "ur2": 53.32223984274065,
        "ur3": 57.09108437348598,
        "standardDeviation": 3.768844530745326,
        "lowest": 24,
        "highest": 57
      },
      "2000": {
        "ul3": 32.99691271119657,
        "ul2": 36.59583243246438,
        "ul1": 40.19475215373219,
        "mean": 43.793671875,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 47.39259159626781,
        "ur2": 50.991511317535625,
        "ur3": 54.59043103880343,
        "standardDeviation": 3.5989197212678112,
        "lowest": 24,
        "highest": 56
      },
      "5000": {
        "ul3": 31.086167641280642,
        "ul2": 34.496631292103764,
        "ul1": 37.90709494292688,
        "mean": 41.31755859375,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 44.72802224457312,
        "ur2": 48.13848589539624,
        "ur3": 51.54894954621936,
        "standardDeviation": 3.4104636508231194,
        "lowest": 23,
        "highest": 53
      },
      "10000": {
        "ul3": 29.78003520565219,
        "ul2": 33.04197334022646,
        "ul1": 36.30391147480073,
        "mean": 39.565849609375,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 42.82778774394927,
        "ur2": 46.08972587852354,
        "ur3": 49.35166401309781,
        "standardDeviation": 3.2619381345742693,
        "lowest": 19,
        "highest": 52
      }
    },
  },
  {
    name: 'Het Kasteel',
    squares: [11,14,15,16,17,18,19,20,21,23,24], // 11
    odds: {
      "25": {
        "ul3": 39.010986600940406,
        "ul2": 44.50912127562694,
        "ul1": 50.007255950313464,
        "mean": 55.505390625,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 61.00352529968653,
        "ur2": 66.50165997437306,
        "ur3": 71.9997946490596,
        "standardDeviation": 5.498134674686531,
        "lowest": 26,
        "highest": 71
      },
      "50": {
        "ul3": 36.81729495632306,
        "ul2": 42.07115562192371,
        "ul1": 47.32501628752435,
        "mean": 52.578876953125,
        "median": 53,
        "mostFrequent": 54,
        "ur1": 57.832737618725645,
        "ur2": 63.086598284326286,
        "ur3": 68.34045894992693,
        "standardDeviation": 5.253860665600645,
        "lowest": 23,
        "highest": 68
      },
      "75": {
        "ul3": 35.64948515422786,
        "ul2": 40.74035012886024,
        "ul1": 45.83121510349262,
        "mean": 50.922080078125,
        "median": 51,
        "mostFrequent": 52,
        "ur1": 56.01294505275738,
        "ur2": 61.103810027389756,
        "ur3": 66.19467500202214,
        "standardDeviation": 5.09086497463238,
        "lowest": 22,
        "highest": 65
      },
      "100": {
        "ul3": 34.80770359577657,
        "ul2": 39.80388898572605,
        "ul1": 44.80007437567553,
        "mean": 49.796259765625,
        "median": 50,
        "mostFrequent": 51,
        "ur1": 54.79244515557448,
        "ur2": 59.78863054552396,
        "ur3": 64.78481593547343,
        "standardDeviation": 4.996185389949476,
        "lowest": 23,
        "highest": 65
      },
      "125": {
        "ul3": 34.10300088639959,
        "ul2": 39.04458848155806,
        "ul1": 43.98617607671653,
        "mean": 48.927763671875,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.869351267033466,
        "ur2": 58.81093886219194,
        "ur3": 63.752526457350406,
        "standardDeviation": 4.941587595158469,
        "lowest": 20,
        "highest": 64
      },
      "150": {
        "ul3": 33.73293266046218,
        "ul2": 38.57031448197478,
        "ul1": 43.40769630348739,
        "mean": 48.245078125,
        "median": 49,
        "mostFrequent": 50,
        "ur1": 53.08245994651261,
        "ur2": 57.919841768025215,
        "ur3": 62.757223589537816,
        "standardDeviation": 4.837381821512607,
        "lowest": 21,
        "highest": 64
      },
      "200": {
        "ul3": 32.8703723466905,
        "ul2": 37.63289146029367,
        "ul1": 42.39541057389684,
        "mean": 47.1579296875,
        "median": 48,
        "mostFrequent": 48,
        "ur1": 51.92044880110316,
        "ur2": 56.68296791470633,
        "ur3": 61.4454870283095,
        "standardDeviation": 4.762519113603165,
        "lowest": 19,
        "highest": 61
      },
      "250": {
        "ul3": 32.36038361374704,
        "ul2": 37.02489441437302,
        "ul1": 41.68940521499901,
        "mean": 46.353916015625,
        "median": 47,
        "mostFrequent": 48,
        "ur1": 51.018426816250994,
        "ur2": 55.682937616876984,
        "ur3": 60.34744841750297,
        "standardDeviation": 4.6645108006259886,
        "lowest": 22,
        "highest": 61
      },
      "300": {
        "ul3": 31.82064396888669,
        "ul2": 36.44543582300779,
        "ul1": 41.0702276771289,
        "mean": 45.69501953125,
        "median": 46,
        "mostFrequent": 47,
        "ur1": 50.319811385371096,
        "ur2": 54.9446032394922,
        "ur3": 59.56939509361331,
        "standardDeviation": 4.624791854121103,
        "lowest": 20,
        "highest": 60
      },
      "400": {
        "ul3": 31.142963812763334,
        "ul2": 35.66146806267555,
        "ul1": 40.17997231258778,
        "mean": 44.6984765625,
        "median": 45,
        "mostFrequent": 46,
        "ur1": 49.21698081241222,
        "ur2": 53.73548506232444,
        "ur3": 58.25398931223666,
        "standardDeviation": 4.518504249912222,
        "lowest": 21,
        "highest": 61
      },
      "500": {
        "ul3": 30.56661587296289,
        "ul2": 35.02679664968359,
        "ul1": 39.486977426404295,
        "mean": 43.947158203125,
        "median": 44,
        "mostFrequent": 45,
        "ur1": 48.407338979845704,
        "ur2": 52.86751975656641,
        "ur3": 57.327700533287114,
        "standardDeviation": 4.460180776720704,
        "lowest": 19,
        "highest": 59
      },
      "750": {
        "ul3": 29.65162974511979,
        "ul2": 33.967587147788194,
        "ul1": 38.2835445504566,
        "mean": 42.599501953125,
        "median": 43,
        "mostFrequent": 44,
        "ur1": 46.9154593557934,
        "ur2": 51.231416758461805,
        "ur3": 55.54737416113021,
        "standardDeviation": 4.315957402668403,
        "lowest": 19,
        "highest": 58
      },
      "1000": {
        "ul3": 28.968964118230865,
        "ul2": 33.20595329236224,
        "ul1": 37.44294246649362,
        "mean": 41.679931640625,
        "median": 42,
        "mostFrequent": 42,
        "ur1": 45.91692081475638,
        "ur2": 50.15390998888776,
        "ur3": 54.390899163019135,
        "standardDeviation": 4.236989174131379,
        "lowest": 18,
        "highest": 56
      },
      "2000": {
        "ul3": 27.48048912965573,
        "ul2": 31.515778560395486,
        "ul1": 35.55106799113524,
        "mean": 39.586357421875,
        "median": 40,
        "mostFrequent": 40,
        "ur1": 43.62164685261476,
        "ur2": 47.65693628335451,
        "ur3": 51.69222571409427,
        "standardDeviation": 4.035289430739757,
        "lowest": 17,
        "highest": 54
      },
      "5000": {
        "ul3": 25.558887721988654,
        "ul2": 29.364743507367436,
        "ul1": 33.17059929274622,
        "mean": 36.976455078125,
        "median": 37,
        "mostFrequent": 37,
        "ur1": 40.78231086350378,
        "ur2": 44.58816664888256,
        "ur3": 48.394022434261345,
        "standardDeviation": 3.805855785378782,
        "lowest": 18,
        "highest": 51
      },
      "10000": {
        "ul3": 24.204201772300376,
        "ul2": 27.852244540908583,
        "ul1": 31.500287309516793,
        "mean": 35.148330078125,
        "median": 35,
        "mostFrequent": 36,
        "ur1": 38.796372846733206,
        "ur2": 42.44441561534141,
        "ur3": 46.09245838394962,
        "standardDeviation": 3.648042768608208,
        "lowest": 15,
        "highest": 51
      }
    },
  },
  {
    name: 'Volle Kaart',
    squares: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
    odds: {
      "25": {
        "ul3": 58.0449829822026,
        "ul2": 60.8207080558434,
        "ul1": 63.5964331294842,
        "mean": 66.372158203125,
        "median": 67,
        "mostFrequent": 67,
        "ur1": 69.1478832767658,
        "ur2": 71.9236083504066,
        "ur3": 74.6993334240474,
        "standardDeviation": 2.775725073640801,
        "lowest": 47,
        "highest": 73
      },
      "50": {
        "ul3": 56.748812402301496,
        "ul2": 59.462626236950996,
        "ul1": 62.176440071600496,
        "mean": 64.89025390625,
        "median": 65,
        "mostFrequent": 66,
        "ur1": 67.6040677408995,
        "ur2": 70.317881575549,
        "ur3": 73.0316954101985,
        "standardDeviation": 2.713813834649501,
        "lowest": 46,
        "highest": 72
      },
      "75": {
        "ul3": 56.012566783978805,
        "ul2": 58.68271053827753,
        "ul1": 61.35285429257627,
        "mean": 64.022998046875,
        "median": 64,
        "mostFrequent": 65,
        "ur1": 66.69314180117374,
        "ur2": 69.36328555547247,
        "ur3": 72.03342930977121,
        "standardDeviation": 2.670143754298733,
        "lowest": 46,
        "highest": 71
      },
      "100": {
        "ul3": 55.49519480752648,
        "ul2": 58.13987596543432,
        "ul1": 60.78455712334216,
        "mean": 63.42923828125,
        "median": 64,
        "mostFrequent": 64,
        "ur1": 66.07391943915785,
        "ur2": 68.71860059706569,
        "ur3": 71.36328175497353,
        "standardDeviation": 2.6446811579078404,
        "lowest": 45,
        "highest": 71
      },
      "125": {
        "ul3": 55.10045209974437,
        "ul2": 57.718345019621246,
        "ul1": 60.33623793949812,
        "mean": 62.954130859375,
        "median": 63,
        "mostFrequent": 64,
        "ur1": 65.57202377925188,
        "ur2": 68.18991669912876,
        "ur3": 70.80780961900562,
        "standardDeviation": 2.6178929198768763,
        "lowest": 45,
        "highest": 70
      },
      "150": {
        "ul3": 54.78312729121889,
        "ul2": 57.38683746497926,
        "ul1": 59.99054763873963,
        "mean": 62.5942578125,
        "median": 63,
        "mostFrequent": 64,
        "ur1": 65.19796798626037,
        "ur2": 67.80167816002074,
        "ur3": 70.4053883337811,
        "standardDeviation": 2.6037101737603696,
        "lowest": 44,
        "highest": 70
      },
      "200": {
        "ul3": 54.27336776678668,
        "ul2": 56.85097890181612,
        "ul1": 59.428590036845556,
        "mean": 62.006201171875,
        "median": 62,
        "mostFrequent": 63,
        "ur1": 64.58381230690443,
        "ur2": 67.16142344193388,
        "ur3": 69.73903457696332,
        "standardDeviation": 2.57761113502944,
        "lowest": 45,
        "highest": 69
      },
      "250": {
        "ul3": 53.86609315579467,
        "ul2": 56.42955689552978,
        "ul1": 58.99302063526489,
        "mean": 61.556484375,
        "median": 62,
        "mostFrequent": 63,
        "ur1": 64.11994811473511,
        "ur2": 66.68341185447021,
        "ur3": 69.24687559420532,
        "standardDeviation": 2.563463739735108,
        "lowest": 43,
        "highest": 68
      },
      "300": {
        "ul3": 53.53865757682243,
        "ul2": 56.090527577256616,
        "ul1": 58.64239757769081,
        "mean": 61.194267578125,
        "median": 62,
        "mostFrequent": 62,
        "ur1": 63.74613757855919,
        "ur2": 66.29800757899338,
        "ur3": 68.84987757942757,
        "standardDeviation": 2.551870000434191,
        "lowest": 43,
        "highest": 68
      },
      "400": {
        "ul3": 53.067155391530996,
        "ul2": 55.58343888081233,
        "ul1": 58.099722370093666,
        "mean": 60.616005859375,
        "median": 61,
        "mostFrequent": 62,
        "ur1": 63.132289348656336,
        "ur2": 65.64857283793766,
        "ur3": 68.164856327219,
        "standardDeviation": 2.516283489281334,
        "lowest": 40,
        "highest": 67
      },
      "500": {
        "ul3": 52.666250901529324,
        "ul2": 55.167451772894545,
        "ul1": 57.66865264425977,
        "mean": 60.169853515625,
        "median": 61,
        "mostFrequent": 61,
        "ur1": 62.67105438699023,
        "ur2": 65.17225525835545,
        "ur3": 67.67345612972068,
        "standardDeviation": 2.5012008713652265,
        "lowest": 44,
        "highest": 67
      },
      "750": {
        "ul3": 51.98040740111258,
        "ul2": 54.44891092365839,
        "ul1": 56.91741444620419,
        "mean": 59.38591796875,
        "median": 60,
        "mostFrequent": 60,
        "ur1": 61.85442149129581,
        "ur2": 64.32292501384161,
        "ur3": 66.79142853638743,
        "standardDeviation": 2.4685035225458076,
        "lowest": 44,
        "highest": 66
      },
      "1000": {
        "ul3": 51.522093092172994,
        "ul2": 53.96580294686533,
        "ul1": 56.40951280155766,
        "mean": 58.85322265625,
        "median": 59,
        "mostFrequent": 60,
        "ur1": 61.296932510942334,
        "ur2": 63.74064236563466,
        "ur3": 66.184352220327,
        "standardDeviation": 2.4437098546923335,
        "lowest": 43,
        "highest": 65
      },
      "2000": {
        "ul3": 50.4600964484462,
        "ul2": 52.82818929896413,
        "ul1": 55.196282149482066,
        "mean": 57.564375,
        "median": 58,
        "mostFrequent": 58,
        "ur1": 59.93246785051793,
        "ur2": 62.30056070103586,
        "ur3": 64.66865355155379,
        "standardDeviation": 2.3680928505179315,
        "lowest": 41,
        "highest": 65
      },
      "5000": {
        "ul3": 49.03080117900449,
        "ul2": 51.32765977037799,
        "ul1": 53.6245183617515,
        "mean": 55.921376953125,
        "median": 56,
        "mostFrequent": 57,
        "ur1": 58.2182355444985,
        "ur2": 60.515094135872005,
        "ur3": 62.811952727245504,
        "standardDeviation": 2.296858591373503,
        "lowest": 38,
        "highest": 63
      },
      "10000": {
        "ul3": 47.94322015710403,
        "ul2": 50.198123333902686,
        "ul1": 52.45302651070134,
        "mean": 54.7079296875,
        "median": 55,
        "mostFrequent": 56,
        "ur1": 56.962832864298655,
        "ur2": 59.21773604109731,
        "ur3": 61.47263921789597,
        "standardDeviation": 2.254903176798656,
        "lowest": 41,
        "highest": 62
      }
    },
  },
]
export default presetFigures;