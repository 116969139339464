/**
 *
 *
 *
 */

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import StyledBox from '../theme/styled-box'
import { useCallback, useContext, useState } from 'react'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Views } from '../routes/bingo'
import { BingoContext } from '../contexs/bingo-context'
import FigureSelector from './figure-selector'
import BingoGame from '../types/bingogame'
import SaveBingoButton from './save-bingo-button'
import { useParams } from 'react-router-dom'
import centerSquare from '../assets/bingo-default-center-square.png'
import infoScreenBackground from '../assets/pub-background.jpg'
import AttachmentsDialog from './attachments-dialog'

const mapTypeAndVariant = (bingo?: BingoGame) => {
  if (!bingo) {
    return 3
  }
  switch (true) {
    case bingo.variant === '3x3' && bingo.type === 'normal':
      return 2
    case bingo.variant === '3x3' && bingo.type === 'music':
      return 4
    case bingo.variant === '5x5' && bingo.type === 'normal':
      return 1
    case bingo.variant === '5x5' && bingo.type === 'music':
      return 3
    default:
      return 3
  }
}

const getLastParam = (str: string | undefined) => {
  if (str) {
    const arr = str.match(/[^/]+(?=\/$|$)/gm)
    if (arr && arr.length > 0) {
      return arr[0]
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export interface SettingsProps {
  setView: (view: Views) => void
}

export const Settings: React.FC<SettingsProps> = (props) => {
  const { setView } = props

  const { loadDefaultBingo, updateBingo, bingo } = useContext(BingoContext)

  const { deviceHash } = useParams()

  // states
  const [name, setName] = useState<string>(
    bingo?.name || `${new Date().toDateString()} Bingo`
  )
  const [variant, setVariant] = useState<number>(mapTypeAndVariant(bingo))
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>('default')

  const [cardBg, setCardBg] = useState<undefined | string>(
    getLastParam(bingo?.cardDesign?.backgroundImage)
  )
  const [centerSquared, setCenterSquared] = useState<undefined | string>(
    getLastParam(bingo?.cardDesign?.centerImage)
  )
  const [bgInfoScreen, setBgInfoScreen] = useState<undefined | string>(
    getLastParam(bingo?.infoScreenDesign?.backgroundImage)
  )

  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false)
  const [attachmentDialogOpen2, setAttachmentDialogOpen2] = useState(false)
  const [attachmentDialogOpen3, setAttachmentDialogOpen3] = useState(false)

  // handlers
  const handleNameChange = useCallback(
    (newName: string) => {
      setName(newName)
      if (!newName) {
        newName = `Bingo op ${new Date().toLocaleDateString()}`
      }

      updateBingo({ name: newName })
    },
    [updateBingo]
  )

  const handleVariantChange = useCallback(
    (variant: number) => {
      if (!variant) {
        return
      }
      // update textfield
      setVariant(variant)

      // load bingo
      switch (variant) {
        case 1:
          loadDefaultBingo('5x5', 'normal')
          break
        case 2:
          loadDefaultBingo('3x3', 'normal')
          break
        case 4:
          loadDefaultBingo('3x3', 'music')
          break
        case 3:
        default:
          loadDefaultBingo('5x5', 'music')
          break
      }
    },
    [loadDefaultBingo]
  )

  return (
    <Grid
      container
      alignItems={'flex-start'}
      justifyContent={'center'}
      spacing={2}
    >
      <Grid item xs={1} />
      <Grid item xs={10}>
        <StyledBox>
          <Grid container alignItems={'center'}>
            <Grid item>
              {/* back button */}
              <IconButton color="secondary" onClick={() => setView('home')}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              {/* header */}
              <Typography
                variant="h5"
                alignSelf={'center'}
                onClick={() => setView('home')}
                sx={{ cursor: 'pointer' }}
              >
                Start
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <SaveBingoButton />
            </Grid>
            <Grid item>
              {/* next step */}
              <Typography
                variant="h5"
                alignSelf={'center'}
                onClick={() => setView('cards')}
                sx={{ cursor: 'pointer' }}
              >
                Bingo Kaarten Beheren
              </Typography>
            </Grid>
            <Grid item>
              {/* back button */}
              <IconButton color="secondary" onClick={() => setView('cards')}>
                <ArrowForward />
              </IconButton>
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={5}>
        <StyledBox>
          {/* variant selector */}
          <TextField
            label={'Variant'}
            color="secondary"
            select
            fullWidth
            sx={{ marginBottom: 4 }}
            value={variant}
            onChange={(e) => handleVariantChange(Number(e.target.value))}
            helperText={
              'Als u van variant wijzigd, worden alle kaarten, resultaten en instellingen verwijderd'
            }
          >
            <MenuItem value={1}>Nummer Bingo (met 5x5 kaarten)</MenuItem>
            <MenuItem value={2}>Nummer Bingo (met 3x3 kaarten)</MenuItem>
            <MenuItem value={3}>Muziek Bingo (met 5x5 kaarten)</MenuItem>
            <MenuItem value={4}>Muziek Bingo (met 3x3 kaarten)</MenuItem>
          </TextField>

          <Divider />

          {/* name input */}
          <TextField
            label={'Naam'}
            color="secondary"
            fullWidth
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
            helperText={'Kies een naam voor de bingo'}
            sx={{ marginBottom: 4 }}
          />

          {/* Playlist/Genre selector */}
          {variant > 2 && (
            <TextField
              label={'Selecteer Genre'}
              select
              fullWidth
              color="secondary"
              value={selectedPlaylist}
              onChange={(e) => setSelectedPlaylist(e.target.value)}
              helperText={'Selecteer een lijst met muziek per categorie'}
            >
              <MenuItem value="default">Playlist 01 - 5x5 Bingo Test</MenuItem>
            </TextField>
          )}
        </StyledBox>
      </Grid>
      <Grid item xs={5}>
        <StyledBox>
          <Typography
            color="secondary.main"
            variant="caption"
            sx={{
              marginBottom: 1,
              display: 'block',
            }}
          >
            Bingokaart stijlen
          </Typography>

          {/* bingo card background image */}
          <Stack
            direction={'row'}
            id="testing"
            spacing={3}
            alignItems={'center'}
          >
            {bingo?.cardDesign.backgroundImage ? (
              <Box
                component={'img'}
                src={bingo.cardDesign.backgroundImage}
                alt={'Achtergrond bingokaart'}
                sx={{
                  maxWidth: '50%',
                  minWidth: 50,
                  maxHeight: 100,
                  aspectRatio: '1 / 1.414',
                  background: 'white',
                  border: 1,
                }}
              />
            ) : (
              <Box
                sx={{
                  maxWidth: '30%',
                  minWidth: 50,
                  backgroundColor: 'lightgrey',
                  aspectRatio: '1 / 1.414',
                }}
              />
            )}
            <Box>
              <Typography variant="subtitle2">Achtergrond</Typography>
              <AttachmentsDialog
                open={attachmentDialogOpen3}
                onClose={() => setAttachmentDialogOpen3(false)}
                onSelect={(selectedFile) => {
                  setCardBg(selectedFile)
                  if (selectedFile) {
                    updateBingo({
                      cardDesign: {
                        backgroundImage: `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingo?.id || 'xxx'}/attachments/${selectedFile}`,
                      },
                    })
                  } else {
                    updateBingo({
                      cardDesign: {
                        backgroundImage: '',
                      },
                    })
                  }
                }}
                selectedItem={cardBg}
                bingoId={bingo?.id || 'xxx'}
              />
              <Button
                onClick={() => setAttachmentDialogOpen3(true)}
                variant="contained"
                color="secondary"
              >
                Selecteer Afbeelding
              </Button>
            </Box>
          </Stack>

          <Divider sx={{ margin: 1 }} />

          {/* center bingo square image */}
          <Stack
            direction={'row'}
            id="testing"
            spacing={3}
            sx={{ marginTop: 1 }}
            alignItems={'center'}
          >
            <Box
              component={'img'}
              src={bingo?.cardDesign.centerImage || centerSquare}
              alt={'Achtergrond bingokaart'}
              sx={{ width: 75, border: 1, background: 'white' }}
            />
            <Box>
              <Typography variant="subtitle2">Middelste Vierkant</Typography>
              <AttachmentsDialog
                open={attachmentDialogOpen2}
                onClose={() => setAttachmentDialogOpen2(false)}
                onSelect={(selectedFile) => {
                  setCenterSquared(selectedFile)
                  if (selectedFile) {
                    updateBingo({
                      cardDesign: {
                        centerImage: `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingo?.id || 'xxx'}/attachments/${selectedFile}`,
                      },
                    })
                  } else {
                    updateBingo({
                      cardDesign: {
                        centerImage: '',
                      },
                    })
                  }
                }}
                selectedItem={centerSquared}
                bingoId={bingo?.id || 'xxx'}
              />
              <Button
                onClick={() => setAttachmentDialogOpen2(true)}
                variant="contained"
                color="secondary"
              >
                Selecteer Afbeelding
              </Button>
            </Box>
          </Stack>

          <Divider sx={{ margin: 1 }} />

          <Typography
            color="secondary.main"
            variant="caption"
            sx={{
              marginBottom: 1,
              display: 'block',
            }}
          >
            2de scherm stijl
          </Typography>

          {/* InfoScreen background image */}
          <Stack
            direction={'row'}
            id="testing"
            spacing={3}
            sx={{ marginTop: 1 }}
            alignItems={'center'}
          >
            <Box
              component={'img'}
              src={
                bingo?.infoScreenDesign?.backgroundImage || infoScreenBackground
              }
              alt={'Achtergrond'}
              sx={{
                height: 60,
                border: 1,
                aspectRatio: '1.414 / 1',
                background: 'white',
              }}
            />
            <Box>
              <Typography variant="subtitle2">Achtergrond</Typography>
              <AttachmentsDialog
                open={attachmentDialogOpen}
                onClose={() => setAttachmentDialogOpen(false)}
                onSelect={(selectedFile) => {
                  setBgInfoScreen(selectedFile)
                  if (selectedFile) {
                    updateBingo({
                      infoScreenDesign: {
                        backgroundImage: `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingo?.id || 'xxx'}/attachments/${selectedFile}`,
                      },
                    })
                  } else {
                    updateBingo({
                      infoScreenDesign: {
                        backgroundImage: '',
                      },
                    })
                  }
                }}
                selectedItem={bgInfoScreen}
                bingoId={bingo?.id || 'xxx'}
              />
              <Button
                onClick={() => setAttachmentDialogOpen(true)}
                variant="contained"
                color="secondary"
              >
                Selecteer Afbeelding
              </Button>
            </Box>
          </Stack>
        </StyledBox>
      </Grid>
      <Grid item xs={1} />

      {/* Select bingo figure to play */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <StyledBox>
          <FigureSelector />
        </StyledBox>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}
export default Settings
