import { BingoGame } from '../types/bingogame'

export const defaultBingo: BingoGame = {
  id: 'default-3x3-bingo',
  name: '3x3 Bingo',
  cardDesign: {
    centerImage: '',
    backgroundImage: '',
  },
  variant: '3x3',
  type: 'normal',
  currentFigure: 1,
  figures: {
    1: {
      order: 1,
      name: 'Volle Kaart',
      squares: [1, 2, 3, 4, 5, 6, 7, 8],
      drawnNumbers: [],
    },
  },
  numbers: {
    '1': {
      number: 1,
    },
    '2': {
      number: 2,
    },
    '3': {
      number: 3,
    },
    '4': {
      number: 4,
    },
    '5': {
      number: 5,
    },
    '6': {
      number: 6,
    },
    '7': {
      number: 7,
    },
    '8': {
      number: 8,
    },
    '9': {
      number: 9,
    },
    '10': {
      number: 10,
    },
    '11': {
      number: 11,
    },
    '12': {
      number: 12,
    },
    '13': {
      number: 13,
    },
    '14': {
      number: 14,
    },
    '15': {
      number: 15,
    },
    '16': {
      number: 16,
    },
    '17': {
      number: 17,
    },
    '18': {
      number: 18,
    },
    '19': {
      number: 19,
    },
    '20': {
      number: 20,
    },
    '21': {
      number: 21,
    },
    '22': {
      number: 22,
    },
    '23': {
      number: 23,
    },
    '24': {
      number: 24,
    },
    '25': {
      number: 25,
    },
    '26': {
      number: 26,
    },
    '27': {
      number: 27,
    },
  },
  numbersDrawn: [],
  cards: {},
  winningCards: [],
}
export default defaultBingo
