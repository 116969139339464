/**
 *
 *
 *
 */

import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

/**
 * Validates the given deviceHash
 */
export const useValidateToken = () => {
  const { deviceHash } = useParams()

  const [validToken, setValidToken] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const validateDeviceHash = useCallback(async () => {
    try {
      setLoading(true)

      const response = await fetch(
        `https://stream3.kijk-mee.nl/bingo/${deviceHash}`,
        { method: 'HEAD' }
      )

      if (response && response.ok) {
        setValidToken(true)
      } else {
        setValidToken(false)
      }

      setLoading(false)
    } catch (err) {
      console.error(err)

      setValidToken(false)

      setLoading(false)
    }
  }, [deviceHash])

  useEffect(() => {
    if (deviceHash) {
      validateDeviceHash()
    } else {
      setValidToken(false)
    }
  }, [deviceHash, validateDeviceHash])

  return {
    loading,
    validToken,
    validateDeviceHash,
  }
}
export default useValidateToken
