import { BingoGame } from '../types/bingogame'

export const defaultNormalBingo: BingoGame = {
  id: 'default-normal-bingo',
  name: 'Normale Standaard Bingo',
  cardDesign: {
    centerImage: '',
    backgroundImage: '',
  },
  variant: '5x5',
  type: 'normal',
  currentFigure: 1,
  figures: {
    1: {
      order: 1,
      name: 'Volle Kaart',
      squares: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
      drawnNumbers: [],
    },
  },
  numbers: {
    '1': {
      number: 1,
    },
    '2': {
      number: 2,
    },
    '3': {
      number: 3,
    },
    '4': {
      number: 4,
    },
    '5': {
      number: 5,
    },
    '6': {
      number: 6,
    },
    '7': {
      number: 7,
    },
    '8': {
      number: 8,
    },
    '9': {
      number: 9,
    },
    '10': {
      number: 10,
    },
    '11': {
      number: 11,
    },
    '12': {
      number: 12,
    },
    '13': {
      number: 13,
    },
    '14': {
      number: 14,
    },
    '15': {
      number: 15,
    },
    '16': {
      number: 16,
    },
    '17': {
      number: 17,
    },
    '18': {
      number: 18,
    },
    '19': {
      number: 19,
    },
    '20': {
      number: 20,
    },
    '21': {
      number: 21,
    },
    '22': {
      number: 22,
    },
    '23': {
      number: 23,
    },
    '24': {
      number: 24,
    },
    '25': {
      number: 25,
    },
    '26': {
      number: 26,
    },
    '27': {
      number: 27,
    },
    '28': {
      number: 28,
    },
    '29': {
      number: 29,
    },
    '30': {
      number: 30,
    },
    '31': {
      number: 31,
    },
    '32': {
      number: 32,
    },
    '33': {
      number: 33,
    },
    '34': {
      number: 34,
    },
    '35': {
      number: 35,
    },
    '36': {
      number: 36,
    },
    '37': {
      number: 37,
    },
    '38': {
      number: 38,
    },
    '39': {
      number: 39,
    },
    '40': {
      number: 40,
    },
    '41': {
      number: 41,
    },
    '42': {
      number: 42,
    },
    '43': {
      number: 43,
    },
    '44': {
      number: 44,
    },
    '45': {
      number: 45,
    },
    '46': {
      number: 46,
    },
    '47': {
      number: 47,
    },
    '48': {
      number: 48,
    },
    '49': {
      number: 49,
    },
    '50': {
      number: 50,
    },
    '51': {
      number: 51,
    },
    '52': {
      number: 52,
    },
    '53': {
      number: 53,
    },
    '54': {
      number: 54,
    },
    '55': {
      number: 55,
    },
    '56': {
      number: 56,
    },
    '57': {
      number: 57,
    },
    '58': {
      number: 58,
    },
    '59': {
      number: 59,
    },
    '60': {
      number: 60,
    },
    '61': {
      number: 61,
    },
    '62': {
      number: 62,
    },
    '63': {
      number: 63,
    },
    '64': {
      number: 64,
    },
    '65': {
      number: 65,
    },
    '66': {
      number: 66,
    },
    '67': {
      number: 67,
    },
    '68': {
      number: 68,
    },
    '69': {
      number: 69,
    },
    '70': {
      number: 70,
    },
    '71': {
      number: 71,
    },
    '72': {
      number: 72,
    },
    '73': {
      number: 73,
    },
    '74': {
      number: 74,
    },
    '75': {
      number: 75,
    },
  },
  numbersDrawn: [],
  cards: {},
  winningCards: [],
}
export default defaultNormalBingo
