/**
 *
 *
 *
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from './use-local-storage'
import BingoGame, {
  BingoCard,
  BingoCards,
  BingoFigure,
  BingoFigures,
  WinningCard,
} from '../types/bingogame'
import { cloneDeep, merge } from 'lodash'

// default bingogames
import default3x3Normal from '../default-bingos/3x3-normal'
import default3x3Music from '../default-bingos/3x3-music'
import default5x5Normal from '../default-bingos/5x5-normal'
import default5x5Music from '../default-bingos/5x5-music'
import { uniqueRandomNumber, uuid } from '../utils/numbers'
import { crc32 } from '../utils/hash'
import { useParams } from 'react-router-dom'

export const useBingo = () => {
  const browserStorage = useLocalStorage()

  const { deviceHash } = useParams()

  if (!deviceHash) {
    throw new Error('useListBingos() hook is missing the deviceHash url param')
  }

  const [loading, setLoading] = useState(false)

  const [bingo, setBingo] = useState<undefined | BingoGame>(undefined)

  const [showCardId, setShowCardId] = useState<string | undefined>(undefined)

  const [bingoInLocalstorage, setBingoInLocalstorage] = useState<boolean>(
    () => {
      if (browserStorage.load('eazis-bingo')) {
        return true
      }
      return false
    }
  )

  // bingo handlers
  const createBingo = useCallback(() => {
    const newBingo = default5x5Music
    newBingo.id = uuid()
    setBingo(newBingo)
  }, [])

  const loadBingoFromLocalstorage = useCallback(() => {
    let b = browserStorage.load('eazis-bingo')
    if (b) {
      setBingo(JSON.parse(b))
      return true
    }
    return false
  }, [browserStorage])

  const saveBingoToLocalstorage = useCallback(() => {
    if (bingo) {
      try {
        browserStorage.save('eazis-bingo', JSON.stringify(bingo))
        setBingoInLocalstorage(true)
        return true
      } catch (err) {
        console.error(err)
        return false
      }
    }
    return false
  }, [bingo, browserStorage])

  const clearBingoFromLocalstorage = useCallback(() => {
    browserStorage.remove('eazis-bingo')
    setBingoInLocalstorage(false)
  }, [browserStorage])

  const updateBingo = useCallback(
    (game: Partial<BingoGame>) => {
      try {
        setLoading(true)
        const newBingo = merge({}, bingo, game) as BingoGame
        setBingo(newBingo)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    },
    [bingo]
  )

  const saveBingo = useCallback(async () => {
    try {
      if (!bingo || !deviceHash) {
        return false
      }

      setLoading(true)

      const bingoAsString = JSON.stringify({ bingo: bingo })

      const response = await fetch(
        `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingo.id}`,
        {
          method: 'POST',
          body: bingoAsString,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.status !== 204) {
        setLoading(false)
        return false
      }

      setLoading(false)
      return true
    } catch (err) {
      console.error(err)
      setLoading(false)
      throw err
    }

    // save locally
    // var blob = new Blob([bingoAsString], {
    //   type: 'application/json;charset=utf-8',
    // })
    // var url = URL.createObjectURL(blob)
    // var elem = document.createElement('a')
    // elem.href = url
    // elem.download =
    //   bingo.name.replace(/\s+/gi, '-') + '-' + Date.now() + '.bingo'
    // if (filename) {
    //   elem.download = filename + '.bingo'
    // }
    // document.body.appendChild(elem)
    // elem.click()
    // document.body.removeChild(elem)
  }, [bingo, deviceHash])

  const loadBingo = useCallback(
    async (bingoId: string) => {
      try {
        setLoading(true)

        const response = await fetch(
          `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingoId}`
        )

        if (response.status !== 200) {
          // console.error('loadBingo() error: non 200 response from api')
          // return false
          throw new Error(
            'Deze bingo bestaat niet of er gaat iets fout tijdens het laden'
          )
        }

        const data = await response.json()

        setBingo(data.bingo)

        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
        throw err
      }

      // if (!file) {
      //   return
      // }

      // setLoading(true)

      // const fileReader = new FileReader()

      // fileReader.addEventListener('loadend', () => {
      //   if (!fileReader.result) {
      //     setLoading(false)
      //     return
      //   }
      //   try {
      //     const loadedBingo = JSON.parse(String(fileReader.result))
      //     setBingo(loadedBingo)
      //     setLoading(false)
      //   } catch (e) {
      //     console.error(e)
      //     setLoading(false)
      //   }
      // })

      // fileReader.addEventListener('error', (err) => {
      //   console.error(err)
      //   setLoading(false)
      // })

      // fileReader.readAsText(file)
    },
    [deviceHash]
  )

  const deleteBingo = useCallback(
    async (bingoId: string) => {
      try {
        setLoading(true)
        const response = await fetch(
          `https://stream3.kijk-mee.nl/bingo/${deviceHash}/${bingoId}`,
          {
            method: 'DELETE',
          }
        )

        if (response.status !== 204) {
          console.error('loadBingo() error: non 204 response from api')
          setLoading(false)
          return false
        }

        setLoading(false)
        return true
      } catch (err) {
        console.error(err)
        setLoading(false)
        throw err
      }
    },
    [deviceHash]
  )

  const loadDefaultBingo = useCallback(
    (
      variant: BingoGame['variant'] = '5x5',
      type: BingoGame['type'] = 'normal'
    ) => {
      // TODO: Add are you sure check

      // overwrite bingo
      switch (true) {
        case variant === '3x3' && type === 'normal':
          setBingo(default3x3Normal)
          break
        case variant === '3x3' && type === 'music':
          setBingo(default3x3Music)
          break
        case variant === '5x5' && type === 'music':
          setBingo(default5x5Music)
          break
        case variant === '5x5' && type === 'normal':
        default:
          setBingo(default5x5Normal)
          break
      }
    },
    []
  )

  const resetGame = useCallback(async () => {
    try {
      if (!bingo) {
        return
      }

      let newBingo: BingoGame = cloneDeep(bingo)

      // archived game partially
      // if (!newBingo.previousGames) {
      //   newBingo.previousGames = []
      // }
      // newBingo.previousGames.push({
      //   timestamp: Date.now(),
      //   numbers: bingo.numbers,
      //   comments: bingo.comments,
      //   figures: bingo.figures,
      //   numbersDrawn: bingo.numbersDrawn,
      //   winningCards: winningCards,
      // })

      // reset
      newBingo.currentFigure = 1
      newBingo.numbersDrawn = []
      newBingo.winningCards = []
      // newBingo.comments = "";
      if (!newBingo.figures) {
        newBingo.figures = {}
      }
      Object.keys(newBingo.figures).forEach((figure) => {
        newBingo.figures[parseInt(figure)].drawnNumbers = []
      })
      setBingo(newBingo)
    } catch (e) {
      console.error(e)
    }
  }, [bingo])

  // bingo settings
  const addFigure = useCallback(
    (newFigure: Omit<BingoFigure, 'drawnNumbers'>) => {
      if (!bingo) {
        return
      }
      setLoading(true)
      const newFigures: BingoFigures = {}
      const figuresCount = Object.keys(bingo.figures).length

      // figure already in the list?
      let alreadyExists = false
      for (let i = 1; i <= figuresCount; i++) {
        if (bingo.figures[i].name === newFigure.name) {
          alreadyExists = true
          break
        }
      }
      if (alreadyExists) {
        setLoading(false)
        return
      }

      // shift objectkeys and insert new
      for (let i = 1; i <= figuresCount; i++) {
        if (i < newFigure.order) {
          continue
        }
        if (i === newFigure.order) {
          newFigures[i] = {
            ...newFigure,
            drawnNumbers: [],
          }
        }
        if (i >= newFigure.order) {
          newFigures[i + 1] = {
            ...bingo.figures[i],
            order: i + 1,
          }
        }
      }

      // update bingo
      const newBingo = cloneDeep(bingo)
      newBingo.figures = newFigures
      setBingo(newBingo)
      setLoading(false)
    },
    [bingo]
  )

  const removeFigure = useCallback(
    (order: BingoFigure['order']) => {
      if (!bingo) {
        return
      }

      const newFigures: BingoFigures = {}
      const figuresKeys = Object.keys(bingo.figures)
      const figuresCount = figuresKeys.length

      // create new figures object
      figuresKeys.forEach((key) => {
        const orderKey = parseInt(key)
        // everything under the deletion remains the same
        if (orderKey < order) {
          newFigures[orderKey] = bingo.figures[orderKey]
          return
        }

        // shift everything one down, except the last one
        if (orderKey >= order && orderKey < figuresCount) {
          newFigures[orderKey] = {
            ...bingo.figures[orderKey + 1],
            order: orderKey,
          }
        }
      })

      // update bingo
      const newBingo = cloneDeep(bingo)
      newBingo.figures = newFigures
      setBingo(newBingo)
    },
    [bingo]
  )

  const moveFigure = useCallback(
    (position: number, direction: 'up' | 'down') => {
      if (!bingo || !position || !direction) {
        return
      }

      // clone bingo
      const newBingo = cloneDeep(bingo)

      // move up
      if (
        direction === 'up' &&
        position in newBingo.figures &&
        position - 1 in newBingo.figures
      ) {
        const top = newBingo.figures[position]
        const above = newBingo.figures[position - 1]
        newBingo.figures[position] = { ...above, order: position }
        newBingo.figures[position - 1] = { ...top, order: position - 1 }
      }

      // move down
      if (
        direction === 'down' &&
        position in newBingo.figures &&
        position + 1 in newBingo.figures
      ) {
        const top = newBingo.figures[position]
        const bottom = newBingo.figures[position + 1]
        newBingo.figures[position] = { ...bottom, order: position }
        newBingo.figures[position + 1] = { ...top, order: position + 1 }
      }

      // update bingo
      setBingo(newBingo)
    },
    [bingo]
  )

  // bingo cards
  const addCards = useCallback(
    async (
      amount: number = 1,
      owner: BingoCard['owner'] = '',
      variant: BingoGame['variant'] = '5x5'
    ) => {
      try {
        if (!bingo) {
          return
        }

        setLoading(true)

        // delay the process just enough to render a loader and then lock react with massive calculation
        if (amount > 10) {
          await new Promise((r) => setTimeout(r, 50))
        }

        // load already generated cards
        const cards: BingoCards = { ...bingo.cards }

        const collisions: string[] = []

        if (variant === '3x3') {
          // generate 3x3
          for (let i = 0; i < amount; i++) {
            // track generated numbers in column for unique-ness within column
            const Bcolumn: number[] = []
            const Icolumn: number[] = []
            const Ncolumn: number[] = []

            // available options in each column
            const Boptions = [1, 2, 3, 4, 5, 6, 7, 8, 9]
            const Ioptions = [10, 11, 12, 13, 14, 15, 16, 17, 18]
            const Noptions = [19, 20, 21, 22, 23, 24, 25, 26, 27]

            // get number for column
            const Num = (options: number[], column: number[]) => {
              const num = uniqueRandomNumber(options, column)
              column.push(num)
              return num
            }

            // generate 5x5 squares
            const squares: BingoCard['squares'] = {
              1: Num(Boptions, Bcolumn),
              2: Num(Ioptions, Icolumn),
              3: Num(Noptions, Ncolumn),
              4: Num(Boptions, Bcolumn),
              /*xxxxxxxxxxxxxxxxxxxxxxx*/ 5: Num(Noptions, Ncolumn),
              6: Num(Boptions, Bcolumn),
              7: Num(Ioptions, Icolumn),
              8: Num(Noptions, Ncolumn),
            }

            // generate id
            const string = JSON.stringify(squares)
            const id = crc32(string).toString(16)

            // hash collision?
            if (id in cards) {
              i -= 1 // redo this loop
              collisions.push(id)
              continue
            }

            // generate & add card object
            cards[id] = {
              id: id,
              owner: owner,
              squares: squares,
            }
          }
        } else {
          // generate 5x5 cards
          for (let i = 0; i < amount; i++) {
            // track generated numbers in column for unique-ness within column
            const Bcolumn: number[] = []
            const Icolumn: number[] = []
            const Ncolumn: number[] = []
            const Gcolumn: number[] = []
            const Ocolumn: number[] = []

            // available options in each column
            const Boptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            const Ioptions = [
              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            ]
            const Noptions = [
              31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
            ]
            const Goptions = [
              46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            ]
            const Ooptions = [
              61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
            ]

            // get number for column
            const Num = (options: number[], column: number[]) => {
              const num = uniqueRandomNumber(options, column)
              column.push(num)
              return num
            }

            // generate 5x5 squares
            const squares: BingoCard['squares'] = {
              1: Num(Boptions, Bcolumn),
              2: Num(Ioptions, Icolumn),
              3: Num(Noptions, Ncolumn),
              4: Num(Goptions, Gcolumn),
              5: Num(Ooptions, Ocolumn),
              6: Num(Boptions, Bcolumn),
              7: Num(Ioptions, Icolumn),
              8: Num(Noptions, Ncolumn),
              9: Num(Goptions, Gcolumn),
              10: Num(Ooptions, Ocolumn),
              11: Num(Boptions, Bcolumn),
              12: Num(Ioptions, Icolumn),
              /*  xxxxxxxxxxxxxxxxxxxx */ 13: Num(Goptions, Gcolumn),
              14: Num(Ooptions, Ocolumn),
              15: Num(Boptions, Bcolumn),
              16: Num(Ioptions, Icolumn),
              17: Num(Noptions, Ncolumn),
              18: Num(Goptions, Gcolumn),
              19: Num(Ooptions, Ocolumn),
              20: Num(Boptions, Bcolumn),
              21: Num(Ioptions, Icolumn),
              22: Num(Noptions, Ncolumn),
              23: Num(Goptions, Gcolumn),
              24: Num(Ooptions, Ocolumn),
            }

            // generate id
            const string = JSON.stringify(squares)
            const id = crc32(string).toString(16)

            // hash collision?
            if (id in cards) {
              i -= 1 // redo this loop
              collisions.push(id)
              continue
            }

            // generate & add card object
            cards[id] = {
              id: id,
              owner: owner,
              squares: squares,
            }
          }
        }

        const newBingo = cloneDeep(bingo)
        newBingo.cards = cards
        setBingo(newBingo)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    },
    [bingo]
  )

  const removeCard = useCallback(
    (id: keyof BingoCards) => {
      if (!id) {
        return
      }

      // id in cards list?
      if (bingo && id in bingo.cards) {
        const newBingo = cloneDeep(bingo)
        delete newBingo.cards[id]
        setBingo(newBingo)
      }
    },
    [bingo]
  )

  const removeAllCards = useCallback(async () => {
    if (!window.confirm('Alle kaarten verwijderen?')) {
      return
    }
    const newBingo = cloneDeep(bingo) as BingoGame
    newBingo.cards = {}
    setBingo(newBingo)
  }, [bingo])

  const updateCard = useCallback(
    (id: keyof BingoCards, owner: BingoCard['owner'] = '') => {
      // just checking
      if (!id || !bingo) {
        console.warn('Cannot update card, no info to work with')
        return
      }

      // does the card exist in the game
      if (!(id in bingo.cards)) {
        console.warn('cannot update a card that is not in this game')
        return
      }

      // update bingo
      const newBingo = cloneDeep(bingo)
      newBingo.cards[id].owner = owner || ''
      setBingo(newBingo)
    },
    [bingo]
  )

  // play bingo
  const drawNumber = useCallback(
    async (numbers: number[]) => {
      try {
        // do we have everything we need
        if (
          !numbers ||
          Array.isArray(numbers) === false ||
          numbers.length < 1 ||
          !bingo ||
          !bingo.currentFigure
        ) {
          return
        }

        // any cards in the game (cannot play without any cards)
        const cardsAmount = Object.keys(bingo.cards || {}).length
        if (cardsAmount < 1) {
          window.alert('Er zijn (nog) geen kaarten in het spel')
          return
        }

        // tell the world we busy
        setLoading(true)

        // delay the rest of the function, so if you lock up the browser, we are showing a loader
        await new Promise((r) => setTimeout(r, 75))

        // clone Bingo
        const newBingo = cloneDeep(bingo)

        // add new numbers
        newBingo.numbersDrawn = [...newBingo.numbersDrawn, ...numbers]

        // update all figure drawn number except the figure(s) already played
        Object.keys(newBingo.figures).forEach((figKey) => {
          const figKeyed = parseInt(figKey)
          // skipped already played figures
          if (figKeyed < newBingo.currentFigure) {
            return
          }
          newBingo.figures[figKeyed].drawnNumbers = [
            ...newBingo.figures[figKeyed].drawnNumbers,
            ...numbers,
          ]
        })

        setBingo(newBingo)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    },
    [bingo]
  )

  const nextFigure = useCallback(async () => {
    try {
      if (!bingo) {
        return
      }
      if (Object.keys(bingo.cards).length < 1) {
        window.alert('Er zijn (nog) geen kaarten in het spel')
        return
      }
      setLoading(true)
      const newBingo = cloneDeep(bingo)
      const newFigure = newBingo.currentFigure + 1

      if (newFigure in newBingo.figures) {
        newBingo.currentFigure = newFigure
        newBingo.figures[newFigure].drawnNumbers = [...newBingo.numbersDrawn]
        setBingo(newBingo)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }, [bingo])

  // calc winning cards
  const possibleWinningCards = useMemo(() => {
    const winners: {
      amountDrawn: number
      card: BingoCard
      figure: BingoGame['currentFigure']
    }[] = []

    // no bingo?
    if (!bingo) {
      return winners
    }

    // rounds object empty?
    if (!bingo.figures || Object.keys(bingo.figures).length < 1) {
      return winners
    }

    // get the object keys from all the cards === card.id
    const cardKeys = Object.keys(bingo.cards)
    const cardKeysLength = cardKeys.length

    if (cardKeys.length < 1) {
      return winners
    }

    // figures to be played
    const figureKeys = Object.keys(bingo.figures)
    const figureKeysLength = figureKeys.length

    // check all figures for winning cards
    for (let f = 0; f < figureKeysLength; f++) {
      const figureKey = parseInt(figureKeys[f])

      // current figure
      const figureSquares = bingo.figures[figureKey].squares
      const figureDrawnNumbers = bingo.figures[figureKey].drawnNumbers
      const figureSquaresLength = figureSquares.length

      // not enough numbers drawn to fill the figure, so no need to check all cards
      if (figureSquaresLength > bingo.numbersDrawn.length) {
        continue
      }

      // hide figures already played
      if (figureKey < bingo.currentFigure) {
        continue
      }

      // check all cards
      for (let i = 0; i < cardKeysLength; i++) {
        const card = bingo.cards[cardKeys[i]]

        // check if all values match drawn numbers on figure
        const matches = []
        for (let key = 0; key < figureSquaresLength; key++) {
          const figureSquareNumber = figureSquares[key]

          // get value from square
          const cardSquareValue = card.squares[figureSquareNumber]

          // in any of the figuresquares?
          if (figureDrawnNumbers.includes(cardSquareValue)) {
            matches.push(cardSquareValue)
          }
        }

        // push the winning card if amount of matches matches the amount of figuresquares
        if (matches.length === figureSquaresLength) {
          winners.push({
            card: card,
            figure: figureKey,
            amountDrawn: bingo.figures[figureKey].drawnNumbers.length,
          })
        }
      }
    }

    // return sorted by amount of drawn numbers (latest figure first)
    const s = (a: any, b: any) => {
      if (a.amountDrawn > b.amountDrawn) {
        return -1
      }
      if (a.amountDrawn < b.amountDrawn) {
        return 1
      }
      return 0
    }
    return winners.sort(s) // ((a,b) => a.amountDrawn + b.amountDrawn);
  }, [bingo])

  // add a card to the winning cards list
  const addToWinningCards = useCallback(
    async (wc: WinningCard) => {
      if (!bingo) {
        return
      }

      // add new entry to list
      const newWinners: WinningCard[] = [wc]

      // filter duplicates
      bingo.winningCards.forEach((wcard) => {
        const found = newWinners.find((nw) => nw.card.id === wcard.card.id)
        if (!found) {
          newWinners.push(wcard)
        }
      })

      // save the winners
      updateBingo({ winningCards: newWinners })
    },
    [bingo, updateBingo]
  )

  // update localstorage on bingo update
  useEffect(() => {
    if (bingo) {
      try {
        if (Object.keys(bingo.cards).length < 20000) {
          browserStorage.save('eazis-bingo', JSON.stringify(bingo))
          setBingoInLocalstorage(true)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [bingo, browserStorage, saveBingo])

  // returning
  return {
    bingo,
    loading,

    createBingo,
    updateBingo,
    saveBingo,
    loadBingo,
    deleteBingo,
    loadDefaultBingo,
    resetGame,

    bingoInLocalstorage,
    loadBingoFromLocalstorage,
    saveBingoToLocalstorage,
    clearBingoFromLocalstorage,

    addFigure,
    removeFigure,
    moveFigure,

    addCards,
    updateCard,
    removeCard,
    removeAllCards,

    drawNumber,
    nextFigure,

    possibleWinningCards,

    addToWinningCards,

    showCardId,
    setShowCardId,
  }
}
export default useBingo
