/**
 *
 *
 *
 */

import { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BingoContext } from '../contexs/bingo-context'

export const useFiles = (token?: string) => {
  const { deviceHash } = useParams()
  const { bingo } = useContext(BingoContext)

  token = token || deviceHash

  const [files, setFiles] = useState<undefined | string[]>(undefined)

  const [loading, setLoading] = useState(false)

  /**
   * lists all files for a user/deviceHash
   */
  const listFiles = useCallback(async () => {
    if (!bingo) {
      return []
    }

    try {
      setLoading(true)

      // do request
      const response = await fetch(
        `https://stream3.kijk-mee.nl/bingo/${token}/${bingo?.id}/attachments`
      )

      // non 200 response
      if (response.ok === false) {
        setFiles([])
        setLoading(false)
        return []
      }

      // let's get that json
      const data = await response.json()

      // files provided?
      if ('files' in data) {
        setFiles(data.files)
        setLoading(false)
        return data.files
      }

      setLoading(false)
      return []
    } catch (err) {
      setLoading(false)
      setFiles([])
    }
  }, [bingo, token])

  /**
   * uploads a file for this user/deviceHash
   */
  const uploadFile = useCallback(
    async (file: File) => {
      if (!bingo || !file) {
        return undefined
      }

      try {
        setLoading(true)

        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(
          `https://stream3.kijk-mee.nl/bingo/${token}/${bingo.id}/attachments`,
          {
            method: 'POST',
            body: formData,
          }
        )

        if (response.ok === false) {
          throw new Error('Non 200 response from server')
        }

        setLoading(false)
        return file.name
      } catch (err) {
        setLoading(false)
        return undefined
      }
    },
    [bingo, token]
  )

  /**
   * deletes a file from a user
   */
  const deleteFile = useCallback(
    async (filename: string) => {
      if (!filename || !bingo) {
        return false
      }

      try {
        setLoading(true)

        const response = await fetch(
          `https://stream3.kijk-mee.nl/bingo/${token}/${bingo.id}/attachments/${filename}`,
          { method: 'DELETE' }
        )

        // no success
        if (response.ok === false) {
          setLoading(false)
          return false
        }

        setLoading(false)
        return true
      } catch (err) {
        setLoading(false)
        return false
      }
    },
    [bingo, token]
  )

  return {
    files,
    loading,
    listFiles,
    // loadFile,
    uploadFile,
    deleteFile,
  }
}
export default useFiles
