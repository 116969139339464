/**
 *
 *
 *
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import BingoCard from './bingo-card'

export interface BingoShowCardDialogProps {
  open: boolean
  onClose: () => void
  cardId?: string
}

export const BingoShowCardDialog: React.FC<BingoShowCardDialogProps> = (
  props
) => {
  const { open, onClose, cardId } = props

  return (
    <Dialog open={open} maxWidth="sm" fullWidth sx={{ zIndex: 9999999 }}>
      <DialogTitle>Kaart</DialogTitle>
      <DialogContent>
        <BingoCard cardId={cardId} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ m: 2 }}
          color="secondary"
        >
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default BingoShowCardDialog
