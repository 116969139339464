/**
 * 
 * 
 * 
*/

import { Box, Typography } from "@mui/material";
import eazisLogo from '../assets/logo.webp';

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = (props) => {

  return (
    <Box display={"grid"} alignItems={'center'} justifyItems={'center'} height={"100vh"} width={"100vw"}>
      <Box component={"img"} src={eazisLogo} alt="Eazis Logo" />
      <Typography variant="caption">Please visit your device url directly</Typography>
    </Box>
  )
}
export default NotFound;