/**
 *
 *
 *
 */

// convert seconds => 0:00 format
export const useFormatSeconds = () => (seconds: number) => {
  if (Number.isNaN(seconds)) {
    return '0:00'
  }

  let sec: number | string = Math.floor(seconds || 0)
  let min: number | string = Math.floor((seconds || 0) / 60)

  sec = Math.floor(seconds % 60)
  if (sec < 10) {
    sec = '0' + sec.toString()
  }

  return min.toString() + ':' + sec.toString()
}
export default useFormatSeconds
