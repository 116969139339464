/**
 *
 *
 *
 */

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'

export interface AddBingoCardDialogProps {
  open: boolean
  onClose: () => void
}

const maxCardsToAdd = 100000

export const AddBingoCardDialog: React.FC<AddBingoCardDialogProps> = (
  props
) => {
  const { open, onClose } = props

  const { addCards, loading, bingo } = useContext(BingoContext)

  const [owner, setOwner] = useState('')
  const [amount, setAmount] = useState('1')
  const [message, setMessage] = useState('')

  const handleAddCards = useCallback(
    async (e: any) => {
      if (e) {
        e.preventDefault()
      }
      if (!amount || !bingo) {
        return
      }

      const amountN = parseInt(amount)
      const ownerN = owner

      if (Number.isNaN(amountN)) {
        return
      }

      setMessage(`Kaarten worden gecreerd...`)

      await new Promise((r) => setTimeout(() => r(true), 50))

      await addCards(amountN, ownerN, bingo.variant)

      setOwner('')
      setMessage(`${amountN} kaarten gegenereerd en toegevoegd`)
    },
    [amount, owner, addCards, bingo]
  )

  const amountNumber = parseInt(amount)

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Genereer bingokaarten</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleAddCards(e)}
        >
          <TextField
            label={'Aantal kaarten te genereren'}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type={'number'}
            color="secondary"
            fullWidth
            helperText={
              amountNumber > maxCardsToAdd
                ? 'Dat zijn wel erg veel kaarten, je browser wordt traag bij 100.000+ kaarten in 1 spel'
                : undefined
            }
            sx={{ mt: 1, mb: 1 }}
          />
          <TextField
            label={'Eigenaar/Opmerking/Info'}
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            color="secondary"
            fullWidth
            sx={{ mt: 1, mb: 1 }}
          />
        </Box>
        {message && <Alert color="info">{message}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddCards}
          sx={{ m: 2 }}
          disabled={loading || amountNumber > maxCardsToAdd}
        >
          Kaarten Toevoegen
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ m: 2 }}
          color="secondary"
        >
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AddBingoCardDialog
