/**
 *
 *
 *
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useContext, useMemo } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import { WinningCard } from '../types/bingogame'
import BingoWinningCards from './bingo-winning-cards'
import FigureDisplay from './figure-display'

export interface BingoWinnersDialogProps {
  open: boolean
  onClose: () => void
  winningCard?: WinningCard
}

export const BingoAddWinnerDialog: React.FC<BingoWinnersDialogProps> = (
  props
) => {
  const { open, onClose, winningCard } = props

  const { addToWinningCards, nextFigure, loading, bingo } =
    useContext(BingoContext)

  const noNextFigure = useMemo(() => {
    if (loading || !bingo) {
      return true
    }

    const amountOfFigs = Object.keys(bingo.figures).length

    const disable = amountOfFigs === bingo.currentFigure
    return disable
  }, [loading, bingo])

  const handleAdd = (wc?: WinningCard) => {
    if (!wc) {
      return
    }
    addToWinningCards(wc)
  }

  const handleNextFigure = () => {
    nextFigure()
    onClose()
  }

  if (!bingo) {
    return null
  }

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>Winnaar Toevoegen</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            Huidige Winnaars:
          </Typography>
          <BingoWinningCards border height={150} />
          {winningCard && (
            <>
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                Kaart om toe te voegen:
              </Typography>
              <List>
                <ListItem
                  sx={{
                    border: 1,
                    borderColor: 'primary.light',
                    paddingLeft: 0,
                    paddingRight: 1,
                  }}
                >
                  <ListItemAvatar>
                    <FigureDisplay
                      squares={bingo.figures[winningCard.figure]?.squares}
                      maxWidth={48}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Kaart: ${winningCard.card.id}`}
                    secondary={
                      winningCard.card.owner
                        ? 'Eigenaar: ' + winningCard.card.owner
                        : 'Figuur: ' + bingo.figures[winningCard.figure].name
                    }
                  />
                </ListItem>
              </List>
            </>
          )}
          <Button
            variant="contained"
            onClick={() => handleAdd(winningCard)}
            color="secondary"
            fullWidth
            disabled={loading}
            sx={{ marginBottom: 4 }}
          >
            Toevoegen aan winnaars
          </Button>
          <Typography>
            Winnaar toegevoegd? Speel de bingo verder met het volgende figuur.
          </Typography>
          <Button
            variant="contained"
            disabled={noNextFigure}
            onClick={() => handleNextFigure()}
            color="secondary"
            fullWidth
            size="large"
          >
            Start met het volgende figuur
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ m: 2 }}
            color="secondary"
          >
            Terug
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default BingoAddWinnerDialog
