/**
 *
 *
 *
 */

import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { FixedSizeList } from 'react-window'
import presetFigures from '../default-figures/preset-figures'
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Delete,
} from '@mui/icons-material'
import FigureDisplay from './figure-display'
import { BingoFigure } from '../types/bingogame'
import { useContext } from 'react'
import { BingoContext } from '../contexs/bingo-context'

// --------------
// select a figure
export interface FigureSelectorProps {
  disableAddButton?: boolean
}

export const FigureSelector: React.FC<FigureSelectorProps> = (props) => {
  const { disableAddButton } = props

  const { bingo, moveFigure, removeFigure, addFigure } =
    useContext(BingoContext)

  // render a bingo figure row
  const BingoFigureRow: React.FC<{
    index: number
    style: any
    data: any
  }> = (props) => {
    const { index, style, data } = props
    const item = data[index]
    return (
      <ListItem
        key={index}
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
        }}
        style={style}
        divider
        secondaryAction={
          <Tooltip title="Voeg het figuur toe aan het spel">
            <span>
              <IconButton
                onClick={() =>
                  addFigure({
                    name: item.name,
                    squares: item.squares,
                    order: 1,
                  })
                }
                disabled={disableAddButton}
                color="secondary"
                sx={{
                  boxShadow: 2,
                }}
                size="large"
                edge="end"
              >
                <ArrowForward color="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        }
      >
        <ListItemAvatar>
          <FigureDisplay
            squares={item.squares}
            noMargin
            normalCenterSquare={true}
          />
        </ListItemAvatar>
        <ListItemText sx={{ ml: 2 }} primary={item.name} />
      </ListItem>
    )
  }

  const SelectedFigureRow: React.FC<{
    index: number
    item: BingoFigure
  }> = ({ index, item }) => {
    if (!bingo) {
      return null
    }

    return (
      <ListItem key={index} divider sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Typography variant="h6" sx={{ mr: 2, ml: 2 }}>
          {item.order}
        </Typography>
        <ListItemAvatar>
          <FigureDisplay
            squares={item.squares}
            noMargin
            normalCenterSquare={true}
          />
        </ListItemAvatar>
        <ListItemText sx={{ ml: 2 }} primary={item.name} />
        {((bingo.variant === '5x5' && item.squares.length < 24) ||
          (bingo.variant === '3x3' && item.squares.length < 8)) && (
          <ListItemSecondaryAction>
            <IconButton
              color="primary"
              onClick={() => moveFigure(item.order, 'up')}
              disabled={item.order === 1}
              sx={{ boxShadow: 2, mr: 1 }}
            >
              <ArrowUpward color="inherit" />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => moveFigure(item.order, 'down')}
              disabled={item.order === Object.keys(bingo.figures).length - 1}
              sx={{ boxShadow: 2, mr: 1 }}
            >
              <ArrowDownward color="inherit" />
            </IconButton>
            <IconButton
              onClick={() => removeFigure(item.order)}
              disabled={bingo.numbersDrawn.length > 0}
              sx={{ boxShadow: 2 }}
            >
              <Delete color="inherit" />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }

  if (!bingo || bingo.variant === '3x3') {
    return null
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12} sm={6}>
        <Typography
          color="secondary.main"
          variant="caption"
          sx={{
            marginBottom: 1,
            display: 'block',
          }}
        >
          Beschikbare figuren
        </Typography>
        <FixedSizeList
          height={330}
          itemCount={presetFigures.length}
          itemData={presetFigures}
          itemSize={73}
          itemKey={(index, data) => data[index].name}
          width={'100%'}
          style={{
            border: '1px solid rgba(255,255,255,0.2)',
            borderRadius: '4px',
          }}
        >
          {BingoFigureRow}
        </FixedSizeList>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          color="secondary.main"
          variant="caption"
          sx={{
            marginBottom: 1,
            display: 'block',
          }}
        >
          Figuren in het spel
        </Typography>
        <List
          sx={{
            overflow: 'auto',
            height: 330,
            border: '1px solid rgba(255,255,255,0.2)',
            borderRadius: 1,
          }}
          disablePadding
        >
          {Object.values(bingo.figures).map((item, index) => (
            <SelectedFigureRow key={index} item={item} index={index} />
          ))}
        </List>
      </Grid>
    </Grid>
  )
}
export default FigureSelector
