/**
 *
 *
 *
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import BingoWinningCards from './bingo-winning-cards'

export interface BingoWinnersDialogProps {
  open: boolean
  onClose: () => void
}

export const BingoWinnersDialog: React.FC<BingoWinnersDialogProps> = (
  props
) => {
  const { open, onClose } = props

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>Bingo Winnaars</DialogTitle>
        <DialogContent>
          <BingoWinningCards />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ m: 2 }}
            color="secondary"
          >
            Sluiten
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default BingoWinnersDialog
