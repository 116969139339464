/**
 *
 *
 *
 */

import { createBrowserRouter } from 'react-router-dom'
import NotFound from '../components/not-found'
import Bingo from './bingo'
import InfoScreen from './info-screen'
import PlayerCard from './player'

// all routes
export const routesConfig = createBrowserRouter([
  // secondary bingo info screen
  {
    path: '/:deviceHash/info-screen',
    element: <InfoScreen />,
  },

  // play cards
  {
    path: '/:deviceHash/play/:bingoId/:cardId?',
    element: <PlayerCard />,
  },

  // main bingo routes
  {
    path: '/:deviceHash',
    element: <Bingo />,
  },

  // everything else / not found
  {
    path: '*',
    element: <NotFound />,
  },
])
export default routesConfig
