/**
 *
 *
 *
 */

import {
  FastForward,
  Pause,
  PlayArrow,
  VolumeDown,
  VolumeUp,
} from '@mui/icons-material'
import { Box, Button, Slider, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import useFormatSeconds from '../hooks/use-format-time'

export interface PlaylistAudioPlayerProps {
  playlist?: string[]
}

export const PlaylistAudioPlayer: React.FC<PlaylistAudioPlayerProps> = (
  props
) => {
  const { playlist } = props

  // html element <audio>
  const playerRef = useRef<null | HTMLAudioElement>(null)

  // hooks
  const formatSeconds = useFormatSeconds()

  // states
  const [musicToPlay, setMusicToPlay] = useState<string[]>([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentSongTrackTimes, setCurrentSongTrackTimes] = useState<
    undefined | { duration: string; currentTime: string }
  >({
    duration: '0:00',
    currentTime: '0:00',
  })
  const [volumeLevel, setVolumeLevel] = useState<number>(0.95)

  // functions
  const playNext = useCallback(() => {
    const player = playerRef.current
    if (player) {
      const list = [...musicToPlay]
      const newSong = list.shift()
      if (newSong) {
        player.src = newSong
      } else {
        player.pause()
        player.src = ''
      }
      setMusicToPlay(list)
    }
  }, [musicToPlay])

  const playPause = useCallback(() => {
    const player = playerRef.current
    if (player) {
      if (player.paused) {
        // if you do no set the player's src... it will default to the current url
        if (!player.src || player.src === window.location.href) {
          playNext()
        } else {
          player.play()
        }
      } else {
        player.pause()
      }
    }
  }, [playNext])

  // volume control
  useEffect(() => {
    const p = playerRef.current
    if (p) {
      p.volume = volumeLevel
    }
  }, [volumeLevel])

  // load songs from playlist into musicToPlay list
  useEffect(() => {
    if (playlist && playlist.length > 0) {
      setMusicToPlay((musicToPlay) => [...musicToPlay, ...playlist])
    } else {
      setMusicToPlay([])
    }
  }, [playlist])

  // set callback's from player events
  useEffect(() => {
    const p = playerRef.current
    const setPlay = () => setIsPlaying(true)
    const setPause = () => setIsPlaying(false)
    const setTrackTime = () => {
      if (!p) return
      setCurrentSongTrackTimes({
        duration: formatSeconds(p.duration),
        currentTime: formatSeconds(p.currentTime),
      })
    }

    // player loaded
    if (p) {
      p.addEventListener('play', setPlay)
      p.addEventListener('pause', setPause)
      p.addEventListener('timeupdate', setTrackTime)
      p.addEventListener('ended', playNext)
    }
    // cleanup
    return () => {
      if (p) {
        p.removeEventListener('play', setPlay)
        p.removeEventListener('pause', setPause)
        p.removeEventListener('timeupdate', setTrackTime)
        p.removeEventListener('ended', playNext)
      }
    }
  }, [formatSeconds, playNext])

  return (
    <Box sx={{ marginTop: 1 }}>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <Button
          variant="outlined"
          size="large"
          onClick={playPause}
          startIcon={
            <>
              {isPlaying && <Pause fontSize="inherit" />}
              {!isPlaying && <PlayArrow fontSize="inherit" />}
            </>
          }
        >
          Play/Pause
        </Button>
        <Button
          variant="outlined"
          size="large"
          onClick={playNext}
          startIcon={<FastForward fontSize="inherit" />}
        >
          Play Volgende Nummer
        </Button>
      </Stack>
      <Stack
        spacing={4}
        direction="row"
        sx={{ p: 1, pb: 0 }}
        alignItems="center"
        justifyContent={'justify'}
      >
        <Typography>
          {currentSongTrackTimes?.currentTime || ''}
          {' - '}
          {currentSongTrackTimes?.duration || ''}
        </Typography>
        <Typography>{musicToPlay.length || '0'} in de wachtrij</Typography>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <VolumeDown />
          <Slider
            sx={{ minWidth: 100 }}
            min={0}
            max={1}
            step={0.01}
            value={volumeLevel}
            onChange={(e, val) => setVolumeLevel(val as number)}
            valueLabelDisplay="auto"
            valueLabelFormat={(x) => `${x ? x * 100 : 0}%`}
          />
          <VolumeUp />
        </Stack>
      </Stack>
      <Box
        ref={playerRef}
        component={'audio'}
        preload="metadata"
        sx={{ display: 'none' }}
        src={undefined}
        autoPlay
      />
    </Box>
  )
}
export default PlaylistAudioPlayer
