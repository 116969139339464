const numbers = []
const numbers3x3 = []
for (let i = 1; i < 76; i++) {
  numbers.push(i)
}
for (let i = 1; i < 28; i++) {
  numbers3x3.push(i)
}
export const bingo5x5Numbers = numbers
export const bingo3x3Numbers = numbers3x3

// pick unique random number
/*

  @params
  listToPickFrom: number[] to pick a random number from
  alreadyPicked: number[] the list to be unique

  @returns 
    number
*/
export const uniqueRandomNumber = (
  listToPickFrom: number[] = bingo5x5Numbers,
  alreadyPicked: number[] = []
) => {
  // pick random number from list
  let randNum =
    listToPickFrom[Math.floor(Math.random() * listToPickFrom.length)]

  // anything supplied to check uniqueness
  if (Array.isArray(alreadyPicked) && alreadyPicked.length > 0) {
    // recursion protection counter
    let count = 0

    // as long a the number exists in the array, pick random number
    while (alreadyPicked.includes(randNum)) {
      randNum =
        listToPickFrom[Math.floor(Math.random() * listToPickFrom.length)]

      // simple recursion protection
      if (count > 100) {
        throw new Error(
          'Too much retries when generating a unique number, is your picklist smaller than the amount needed?'
        )
      }
      count += 1
    }
  }
  return randNum
}

export const leadingZeros = (num: number | string, size: number) => {
  num = num.toString()
  while (num.length < size) num = '0' + num
  return num
}

/**
 * generates a UUID (v4)
 */
export const uuid = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  )
}
