/**
 *
 *
 *
 */

import { Button, Grid, Typography } from '@mui/material'
import { StyledBox } from '../theme/styled-box'
import { Add, FileOpen, Source } from '@mui/icons-material'
import { Views } from '../routes/bingo'
import { useContext, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import BingoLoadDialog from './bingo-load-dialog'

export interface StartBingoProps {
  setView: (view: Views) => void
}

export const StartBingo: React.FC<StartBingoProps> = (props) => {
  const { createBingo, bingoInLocalstorage, loadBingoFromLocalstorage } =
    useContext(BingoContext)

  const [loadDialogOpen, setLoadDialogOpen] = useState(false)

  const handleCreate = () => {
    createBingo()
    props.setView('settings')
  }

  const handleLocalLoad = () => {
    loadBingoFromLocalstorage()
    props.setView('settings')
  }

  const handleBingoAfterLoad = () => {
    setLoadDialogOpen(false)
    props.setView('settings')
  }

  const handleLoad = () => {
    setLoadDialogOpen(true)
  }

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      height={'80vh'}
    >
      <Grid item>
        <StyledBox sx={{ width: 400, maxWidth: '100%', textAlign: 'center' }}>
          <Typography variant="h6">Maak uw keuze</Typography>
          <br />
          <Button
            color="secondary"
            startIcon={<FileOpen />}
            size={'large'}
            variant="contained"
            sx={{
              marginBottom: 2,
              height: 60,
            }}
            fullWidth
            onClick={handleLocalLoad}
            disabled={!bingoInLocalstorage}
          >
            Laatste Bingo Verder Spelen
          </Button>

          <br />
          <Button
            color="secondary"
            startIcon={<Add />}
            size={'large'}
            variant="contained"
            onClick={handleCreate}
            sx={{
              marginBottom: 2,
              height: 60,
            }}
            fullWidth
          >
            Nieuwe Bingo
          </Button>
          <br />
          <Button
            color="secondary"
            startIcon={<Source />}
            size={'large'}
            variant="contained"
            onClick={handleLoad}
            fullWidth
            sx={{
              height: 60,
            }}
          >
            Mijn bingo's
          </Button>
          <BingoLoadDialog
            open={loadDialogOpen}
            onClose={() => setLoadDialogOpen(false)}
            onLoad={handleBingoAfterLoad}
          />
        </StyledBox>
      </Grid>
    </Grid>
  )
}
export default StartBingo
