/**
 * 
 * 
 * 
*/


// ripped from : http://crc32.nichabi.com/javascript-function.php
export const crc32 = (function () {
    const table: any[] = []
    for (let i = 0; i < 256; i++) {
        let c = i
        for (let j = 0; j < 8; j++) {
            c = (c & 1) ? (0xedb88320 ^ (c >>> 1)) : (c >>> 1)
        }
        table.push(c);
    }

    return function (str:string, crc?: number) {
        str = unescape(encodeURIComponent(str))
        if (!crc) crc = 0
        crc = crc ^ (-1)
        for (let i = 0; i < str.length; i++) {
            const y: any = (crc ^ str.charCodeAt(i)) & 0xff
            crc = (crc >>> 8) ^ table[y]
        }
        crc = crc ^ (-1)
        return crc >>> 0
    }
})()