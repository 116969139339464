/*



*/

import { useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { BingoGame, BingoNumber } from '../types/bingogame'
import { Typography } from '@mui/material'

export interface PreviewBingoCardCellProps {
  children?: React.ReactNode
  numberData?: BingoNumber
  print?: boolean
  checked?: boolean
  variant?: BingoGame['variant']
}

export const BingoCardCell: React.FC<PreviewBingoCardCellProps> = (props) => {
  const { children, numberData, print, checked, variant } = props

  const parentRef = useRef<null | any>(null)

  const [highlight, setHighlight] = useState<boolean>(false)

  // select square
  const handleSquareClick = useCallback(
    (e: any) => {
      if (checked) {
        return
      }
      setHighlight(!highlight)
    },
    [checked, highlight]
  )

  // fit text
  useEffect(() => {
    if (parentRef && parentRef.current) {
      // set maxHeight (make it a square)
      parentRef.current.style.maxHeight = parentRef.current.offsetWidth + 'px'

      // set fontsize based on room in container
      let size = 1.3
      parentRef.current.style.fontSize = `${size}rem`
      while (
        parentRef.current.scrollHeight > parentRef.current.offsetHeight ||
        parentRef.current.scrollWidth > parentRef.current.offsetWidth
      ) {
        // each itteration makes the text smaller and test again
        size = size - 0.05
        parentRef.current.style.fontSize = `${size}rem`
        if (size < 0.3) break // just in case
      }
    }
  }, [numberData, children])

  // selected / hightlighted
  useEffect(() => {
    if (checked && !print) {
      setHighlight(true)
    } else {
      setHighlight(false)
    }
  }, [checked, print, children, numberData])

  return (
    <Grid item xs={variant === '3x3' ? 4 : 2.4} sx={{ position: 'relative' }}>
      <Box
        ref={parentRef}
        sx={{
          width: '96%',
          height: '94.2%',
          margin: '2.5% 2%',
          aspectRatio: '1 / 1',
          backgroundColor:
            !print && highlight ? 'secondary.light' : 'rgba(255,255,255,.9)',
          color: !print && highlight ? 'secondary.contrastText' : 'black',
          textAlign: 'center',
          cursor: 'pointer',
          border: '2px solid black',
          borderRadius: 2,
          boxShadow: 2,
        }}
        onClick={handleSquareClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {numberData && !children && (
          <Box sx={{ lineHeight: 1, fontSize: 'inherit' }}>
            <Box sx={{ p: 1, transition: 'height 1s linear' }}>
              {numberData.artist && numberData.title && (
                <>
                  {numberData.title}
                  <br />-<br />
                  {numberData.artist}
                </>
              )}
              {!numberData.artist && !numberData.title && numberData.text && (
                <>{numberData.text}</>
              )}
              {!numberData.artist && !numberData.title && !numberData.text && (
                <Typography
                  sx={{
                    fontSize: { xs: '25px', md: '50px' },
                    fontWeight: 600,
                  }}
                >
                  {numberData.number}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {children}
      </Box>
    </Grid>
  )
}
export default BingoCardCell
