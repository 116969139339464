/**
 *
 *
 *
 */

import { useCallback, useState } from 'react'
import BingoGame from '../types/bingogame'
import { useParams } from 'react-router-dom'

export const useListBingos = (token?: string) => {
  const { deviceHash } = useParams()

  if (!deviceHash && !token) {
    throw new Error('useListBingos() hook is missing the deviceHash url param')
  }

  // no token given, use the url parameter.
  token = token || deviceHash

  const [bingos, setBingos] = useState<BingoGame[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const listBingos = useCallback(async () => {
    try {
      if (!token) {
        return
      }

      setLoading(true)

      const response = await fetch(`https://stream3.kijk-mee.nl/bingo/${token}`)

      // success
      if (response.status === 200) {
        const data = await response.json()
        if (data.bingos) {
          setBingos(data.bingos)
          setLoading(false)
          return
        }
      }

      setLoading(false)
      setBingos([])
    } catch (err) {
      console.error('useListBingos hook, listBingos() error', err)

      setLoading(false)
      setBingos([])
    }
  }, [token])

  return {
    loading,
    bingos,
    listBingos,
  }
}
export default useListBingos
