/**
 *
 *
 *
 */

import { Button, Stack, TextField } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import {
  bingo3x3Numbers,
  bingo5x5Numbers,
  uniqueRandomNumber,
} from '../utils/numbers'

export interface NextBingoNumberProps {}

export const NextBingoNumber: React.FC<NextBingoNumberProps> = (props) => {
  const { bingo, drawNumber, loading } = useContext(BingoContext)

  // states
  const [amountToDraw, setAmountToDraw] = useState<number>(1)

  // handlers
  const handleDrawNumber = useCallback(async () => {
    if (!bingo || !bingo.numbersDrawn || amountToDraw < 1 /*|| !drawNumber */) {
      return
    }

    const newNumbers: number[] = []
    for (let i = 0; i < amountToDraw; i++) {
      let n = uniqueRandomNumber(bingo5x5Numbers)
      if (bingo.variant === '3x3') {
        n = uniqueRandomNumber(bingo3x3Numbers)
      }
      let count = 1
      while (bingo.numbersDrawn.includes(n) || newNumbers.includes(n)) {
        n = uniqueRandomNumber(bingo5x5Numbers)
        if (bingo.variant === '3x3') {
          n = uniqueRandomNumber(bingo3x3Numbers)
        }
        if (bingo.variant === '5x5' && count > 75) return
        if (bingo.variant === '3x3' && count > 27) return
        count += 1
      }
      newNumbers.push(n)
    }
    await drawNumber(newNumbers)
  }, [bingo, drawNumber, amountToDraw])

  const handleAmountSelect = useCallback((numb: string | number) => {
    numb = Number(numb)
    if (Number.isNaN(numb)) {
      setAmountToDraw(1)
      return
    }
    if (numb > 8) {
      setAmountToDraw(8)
      return
    }
    if (numb < 1) {
      setAmountToDraw(1)
      return
    }
    setAmountToDraw(numb)
  }, [])

  let buttonLabel = `Trek Volgende Nummer`
  if (bingo?.type === 'music') {
    buttonLabel = `Trek and Queue Nummers`
  }

  if (!bingo) {
    return null
  }

  if (bingo.type === 'music') {
    return (
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <TextField
          label="Aantal nummers tegelijkertijd trekken"
          value={amountToDraw.toString()}
          onChange={(e) => handleAmountSelect(e.target.value)}
          type={'number'}
          sx={{ maxWidth: 100 }}
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleDrawNumber}
          disabled={
            loading ||
            bingo.numbersDrawn.length > 74 ||
            Object.keys(bingo.cards).length < 1
          }
        >
          {buttonLabel}
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={handleDrawNumber}
        disabled={
          loading ||
          bingo.numbersDrawn.length > 74 ||
          Object.keys(bingo.cards).length < 1
        }
      >
        {buttonLabel}
      </Button>
    </>
  )
}
export default NextBingoNumber
