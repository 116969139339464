/**
 *
 *
 *
 */

import {
  Backdrop,
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BingoContext } from '../contexs/bingo-context'
import useFilteredBingoCards from '../hooks/use-filtered-bingo-cards'
import BingoCardSquares from './bingo-card-squares'

export interface PlayBingoCardProps {}

export const PlayBingoCard: React.FC<PlayBingoCardProps> = (props) => {
  const { bingoId, cardId: cardIdParam } = useParams()

  const { loadBingo, bingo, loading } = useContext(BingoContext)
  const [selectedCardId, setSelectedCardId] = useState<undefined | string>(
    () => {
      if (bingo && cardIdParam && cardIdParam in bingo.cards) {
        return cardIdParam
      } else {
        return undefined
      }
    }
  )

  const bingoCards = useFilteredBingoCards(bingo)

  const cards = useMemo(() => {
    return bingoCards.sort((a, b) =>
      a.id.toString().localeCompare(b.id.toString())
    )
  }, [bingoCards])

  useEffect(() => {
    if (!bingo && bingoId) {
      loadBingo(bingoId).catch(console.error)
    }
  }, [bingo, loadBingo, bingoId])

  return (
    <Box sx={{ padding: 0, paddingTop: 3 }}>
      {/* we do not have a bingo loaded */}
      {!bingo && !loading && <>No Bingo</>}

      {/* loading bingo */}
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* we have a loaded bingo */}
      {bingo && (
        <Stack spacing={2} alignItems={'center'}>
          <Box sx={{ maxWidth: 500, minWidth: 300 }}>
            <TextField
              label={'Selecteer uw Kaart'}
              select
              fullWidth
              value={selectedCardId}
            >
              {cards.map((card) => (
                <MenuItem
                  key={card.id}
                  value={card.id}
                  onClick={() => setSelectedCardId(card.id.toString())}
                >
                  {card.owner ? card.id + ' - ' + card.owner : card.id}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${bingo?.cardDesign?.backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              backgroundOrigin: 'border-box',
              aspectRatio: '1 / 1.414',
            }}
          >
            <BingoCardSquares cardId={selectedCardId} />
          </Box>
        </Stack>
      )}
    </Box>
  )
}
export default PlayBingoCard
