/**
 *
 *
 *
 */

import { Button, CircularProgress, styled } from '@mui/material'
import { useCallback } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import useFiles from '../hooks/use-files'

export interface FileUploadButtonProps {
  buttonLabel?: string
  uploadUrl: string
  onSuccess: (filename: string) => void
  onError: (e: unknown) => void
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
  const { buttonLabel, uploadUrl, onSuccess, onError } = props

  const { loading, uploadFile } = useFiles()

  const handleUpload = useCallback(
    async (event: any) => {
      if (
        !event ||
        !event.target ||
        !event.target.files ||
        event.target.files.length < 1 ||
        !uploadUrl
      ) {
        console.warn('nothing to upload')
        return
      }

      // get file
      const file = event.target.files[0]

      if (!file) {
        console.warn('no file to upload', file)
        return
      }

      try {
        await uploadFile(file)
        onSuccess && onSuccess(file.name)
      } catch (err) {
        onError && onError(err)
      }
    },
    [uploadFile, uploadUrl, onSuccess, onError]
  )

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<UploadFileIcon />}
      disabled={loading}
      color={'secondary'}
    >
      {loading ? 'Uploaden...' : buttonLabel || 'Bestand uploaden'}
      <VisuallyHiddenInput
        id="upload-input"
        type="file"
        onChange={handleUpload}
      />
    </Button>
  )
}
export default FileUploadButton

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
