/**
 *
 *
 *
 */

import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import { Views } from '../routes/bingo'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import StyledBox from '../theme/styled-box'
import {
  Add,
  ArrowBack,
  ArrowForward,
  Delete,
  Menu as MenuIcon,
} from '@mui/icons-material'
import BingoGame, { BingoCards as BingoCardsType } from '../types/bingogame'
import { FixedSizeList } from 'react-window'
import useFilteredBingoCards from '../hooks/use-filtered-bingo-cards'
import AddBingoCardDialog from './add-bingo-card-dialog'
import { formatNumber } from '../utils/strings'
import BingoCard from './bingo-card'
import ConfirmDialog from './confirm-dialog'
import SaveBingoButton from './save-bingo-button'

export interface CardsProps {
  setView: (view: Views) => void
}

export const BingoCards: React.FC<CardsProps> = (props) => {
  // props
  const { setView } = props

  // contexts
  const { bingo, removeCard, removeAllCards } = useContext(BingoContext)

  // states
  const [showCardId, setShowCardId] = useState<
    undefined | keyof BingoCardsType
  >(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [showAddCardDialog, setShowAddCardDialog] = useState<boolean>(false)
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<
    undefined | string
  >(undefined)

  // hooks
  const filteredCards = useFilteredBingoCards(bingo, searchTerm)

  // handlers
  const handleDeleteCard = useCallback(
    (cardId: keyof BingoGame['cards'] | undefined) => {
      if (!cardId) {
        return
      }
      if (showCardId === cardId) {
        setShowCardId(undefined)
      }
      removeCard(cardId)
      setShowDeleteConfirmDialog(undefined)
    },
    [removeCard, showCardId]
  )

  const handleDeleteAllCards = useCallback(() => {
    setShowCardId(undefined)
    setMenuAnchorEl(null)
    removeAllCards()
  }, [removeAllCards])

  // ref
  const cardListRef = useRef<null | FixedSizeList>(null)

  // sub components: a bingocard row
  const CardRow: React.FC<{ index: number; style: any; data: any }> = (
    props
  ) => {
    const { index, style, data } = props
    const item = data[index]
    return (
      <ListItemButton
        style={style}
        key={index}
        divider
        component={'div'}
        onClick={() => setShowCardId(item.id)}
        sx={{
          fontWeight: item.id === showCardId ? 600 : undefined,
        }}
        selected={item.id === showCardId}
      >
        <ListItemText
          primary={`Nummer: ${item.id}`}
          secondary={item.owner ? `Eigenaar: ${item.owner}` : undefined}
        />
        <ListItemSecondaryAction>
          <IconButton
            //onClick={(e) => handleDeleteCard(e, item.id)}
            onClick={() => setShowDeleteConfirmDialog(item.id)}
            color="secondary"
            sx={{ boxShadow: 1 }}
            edge={'end'}
            disabled={bingo?.numbersDrawn && bingo.numbersDrawn.length > 0}
          >
            <Delete sx={{ fontSize: '1.2rem' }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    )
  }

  // if external selected card id updates move list to item
  useEffect(() => {
    if (showCardId && bingo && Object.keys(bingo.cards).length > 0) {
      const index = Object.keys(bingo.cards).findIndex(
        (key) => key === showCardId
      )
      if (index > -1 && cardListRef.current) {
        cardListRef.current.scrollToItem(index)
      }
    }
  }, [showCardId, bingo])

  return (
    <Grid
      container
      alignItems={'flex-start'}
      justifyContent={'center'}
      spacing={2}
    >
      {/* delete card confirmation dialog */}
      <ConfirmDialog
        open={Boolean(showDeleteConfirmDialog)}
        onCancel={() => setShowDeleteConfirmDialog(undefined)}
        onConfirm={() => handleDeleteCard(showDeleteConfirmDialog)}
        text={`Kaart ${showDeleteConfirmDialog} verwijderen?`}
      />

      {/* titleblock */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <StyledBox>
          <Grid container alignItems={'center'}>
            <Grid item>
              {/* back button */}
              <IconButton color="secondary" onClick={() => setView('settings')}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              {/* header */}
              <Typography
                variant="h5"
                alignSelf={'center'}
                onClick={() => setView('settings')}
                sx={{ cursor: 'pointer' }}
              >
                Instellingen
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <SaveBingoButton />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                alignSelf={'center'}
                onClick={() => setView('play')}
                sx={{ cursor: 'pointer' }}
              >
                Start Bingo
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color="secondary" onClick={() => setView('play')}>
                <ArrowForward />
              </IconButton>
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
      <Grid item xs={1} />

      {/* cards block */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <StyledBox>
          <Grid
            container
            alignItems={'flex-start'}
            justifyContent={'stretch'}
            spacing={2}
          >
            {/* card list */}
            <Grid item xs={12} sm={4}>
              <Typography color="secondary.main" variant="caption">
                Opties
              </Typography>
              <Divider
                sx={{
                  marginBottom: 1,
                  marginTop: 1,
                }}
              />
              {/* add card button */}
              <Button
                color="secondary"
                startIcon={<Add />}
                onClick={() => setShowAddCardDialog(true)}
                variant="contained"
                sx={{ width: '48%', marginRight: '2%' }}
              >
                Kaarten Toevoegen
              </Button>

              {/* add cards dialog */}
              <AddBingoCardDialog
                open={showAddCardDialog}
                onClose={() => setShowAddCardDialog(false)}
              />

              {/* more options button */}
              <Button
                color="secondary"
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                startIcon={<MenuIcon />}
                variant="contained"
                sx={{ width: '48%', marginLeft: '2%' }}
              >
                Overige Opties
              </Button>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
              >
                <MenuItem onClick={(e) => handleDeleteAllCards()}>
                  Verwijder alle kaarten
                </MenuItem>
                <MenuItem>Reset kaartontwerp</MenuItem>
              </Menu>

              <Typography
                color="secondary.main"
                variant="caption"
                sx={{ marginTop: 2, display: 'block' }}
              >
                Kaarten in het spel
              </Typography>
              <Divider
                sx={{
                  marginBottom: 1,
                  marginTop: 1,
                }}
              />
              {/* searchfield */}
              <TextField
                label={'Zoek kaart nummer'}
                fullWidth
                color="secondary"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                sx={{ marginBottom: 1 }}
              />

              <Divider />

              {/* list of cards */}
              <FixedSizeList
                ref={cardListRef}
                height={500}
                itemCount={filteredCards.length}
                itemData={filteredCards}
                itemSize={56}
                itemKey={(index, data) => data[index].id}
                width={'100%'}
              >
                {CardRow}
              </FixedSizeList>

              <Divider />

              {/* total cards */}
              <Box sx={{ width: '100%', textAlign: 'center', marginTop: 0.5 }}>
                <Typography variant="caption">
                  {formatNumber(filteredCards.length)} van{' '}
                  {formatNumber(Object.keys(bingo?.cards || {}).length)} kaarten
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="secondary.main" variant="caption">
                Geselecteerde kaart
              </Typography>
              <Divider
                sx={{
                  marginBottom: 1,
                  marginTop: 1,
                }}
              />
              <BingoCard cardId={showCardId} />
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}
export default BingoCards
