import { BingoGame } from '../types/bingogame'

export const defaultBingo: BingoGame = {
  id: 'default-3x3-music-bingo',
  name: '3x3 Muziekbingo',
  cardDesign: {
    centerImage: '',
    backgroundImage: '',
  },
  variant: '3x3',
  type: 'music',
  currentFigure: 1,
  figures: {
    1: {
      order: 1,
      name: 'Volle Kaart',
      squares: [1, 2, 3, 4, 5, 6, 7, 8],
      drawnNumbers: [],
    },
  },
  numbers: {
    '1': {
      number: 1,
      title: 'Barbie Girl',
      artist: 'Aqua',
    },
    '2': {
      number: 2,
      title: 'Kind van de Duivel',
      artist: 'Je Broer',
    },
    '3': {
      number: 3,
      title: 'Macarena',
      artist: 'Los del Rio',
    },
    '4': {
      number: 4,
      title: 'A Sky Full of Starts',
      artist: 'Coldplay',
    },
    '5': {
      number: 5,
      title: 'Wie liet hem?',
      artist: 'Kinderen voor Kinderen',
    },
    '6': {
      number: 6,
      title: 'Mamma Mial',
      artist: 'Abba',
    },
    '7': {
      number: 7,
      title: 'Prada',
      artist: 'Casso Raye D-Blocked',
    },
    '8': {
      number: 8,
      title: 'Firework',
      artist: 'Katy Perry',
    },
    '9': {
      number: 9,
      title: 'Be the One',
      artist: 'Dua Lippa',
    },
    '10': {
      number: 10,
      title: 'Zeven Heksen',
      artist: 'Sesamstraat',
    },
    '11': {
      number: 11,
      title: 'Vluchtstrook',
      artist: 'Kris Kros Amsterdam',
    },
    '12': {
      number: 12,
      title: 'As it Was',
      artist: 'Harry Styles',
    },
    '13': {
      number: 13,
      title: 'Cowboys & Indianen',
      artist: 'Dikdakkers',
    },
    '14': {
      number: 14,
      title: 'Ik ben Kachel',
      artist: 'OBZ',
    },
    '15': {
      number: 15,
      title: 'Oops I did it Again',
      artist: 'Britney Spears',
    },
    '16': {
      number: 16,
      title: 'Blue (da BE Dee)',
      artist: 'Eiffel 65, Gabry Ponte',
    },
    '17': {
      number: 17,
      title: 'The Ketchup Song',
      artist: 'Las Ketchup',
    },
    '18': {
      number: 18,
      title: 'Raise Your Glass',
      artist: 'Pink',
    },
    '19': {
      number: 19,
      title: 'I Like to Move it',
      artist: 'Will.I.Am',
    },
    '20': {
      number: 20,
      title: 'Happy "Dispicable me"',
      artist: 'Pharrell Williams',
    },
    '21': {
      number: 21,
      title: 'Waka Waka',
      artist: 'Shakira',
    },
    '22': {
      number: 22,
      title: 'Cheerleader',
      artist: 'OMI Felix Jaaehn',
    },
    '23': {
      number: 23,
      title: 'Despacito',
      artist: 'Louis Fonsi',
    },
    '24': {
      number: 24,
      title: 'Shotgun',
      artist: 'George Esra',
    },
    '25': {
      number: 25,
      title: 'Iko Iko (my Bestie)',
      artist: 'Justin Wellington, Small Jam',
    },
    '26': {
      number: 26,
      title: 'Sorry',
      artist: 'Justin Bieber',
    },
    '27': {
      number: 27,
      title: 'Sugar',
      artist: 'Robin Schultz',
    },
  },
  numbersDrawn: [],
  cards: {},
  winningCards: [],
}
export default defaultBingo
