/**
 *
 *
 *
 */

import { createTheme } from '@mui/material'

// the main theme
export const themeConfig = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#27282a',
      paper: '#27282a',
    },
    text: {
      primary: '#eeeeee',
      secondary: '#70B615',
    },
    primary: {
      main: '#eeeeee',
    },
    secondary: {
      main: '#70B615',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
  },
})
export default themeConfig
