/*



*/

import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { BingoContext } from '../contexs/bingo-context'
import BingoGame from '../types/bingogame'

export interface FigureDisplayProps {
  squares: number[]
  noMargin?: boolean
  maxWidth?: number | string
  onChange?: (square: number) => void
  bingo?: BingoGame
  normalCenterSquare?: boolean
}

const templateRows: { [key in BingoGame['variant']]: string } = {
  '3x3': '1fr 1fr 1fr',
  '5x5': '1fr 1fr 1fr 1fr 1fr',
}
const totalSquares: { [key in BingoGame['variant']]: number[] } = {
  '3x3': [1, 2, 3, 4, 3000, 5, 6, 7, 8],
  '5x5': [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 3000, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24,
  ],
}

export const FigureDisplay: React.FC<FigureDisplayProps> = (props) => {
  const {
    squares,
    noMargin,
    maxWidth,
    onChange,
    bingo: bingoProp,
    normalCenterSquare,
  } = props

  const { bingo: bingoContext } = useContext(BingoContext)

  let bingo = bingoProp || bingoContext

  const h = (square: number) => onChange && onChange(square)

  if (!squares) {
    return null
  }

  return (
    <Box
      component={'div'}
      sx={{
        display: 'grid',
        aspectRatio: '1 / 1',
        gridTemplateRows: templateRows[bingo?.variant || '5x5'],
        gridTemplateColumns: templateRows[bingo?.variant || '5x5'],
        border: '1px solid #333',
        ml: noMargin ? 0 : 1,
        mr: noMargin ? 0 : 1,
        maxWidth: maxWidth || '100%',
        gap: '2%',
        backgroundColor: '#333',
      }}
    >
      {totalSquares[bingo?.variant || '5x5'].map((i) => (
        <Box
          key={i}
          component={'div'}
          sx={{
            display: 'flex',
            aspectRatio: '1 / 1',
            backgroundColor:
              i !== 3000
                ? squares.includes(i)
                  ? 'secondary.main'
                  : 'background.default'
                : normalCenterSquare
                  ? 'secondary.main'
                  : '#333',
            cursor: onChange && i !== 3000 ? 'pointer' : undefined,
          }}
          onClick={() => h(i)}
        />
      ))}
    </Box>
  )
}
export default FigureDisplay
