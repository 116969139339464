/**
 *
 *
 *
 */

import { Fragment, useCallback, useContext } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import BingoGame, { BingoCard as BingoCardType } from '../types/bingogame'
import { Box, Grid, Typography } from '@mui/material'
import BingoCardCell from './bingo-card-cell'
import centerBingo from '../assets/bingo-default-center-square.png'

export interface BingoCardProps {
  cardId?: BingoCardType['id']
  print?: boolean
}

export const BingoCard: React.FC<BingoCardProps> = (props) => {
  const { cardId, print } = props

  // bingo
  const { bingo } = useContext(BingoContext)

  // match the square number with a bingo number object
  const matchNumber = useCallback(
    (number: keyof BingoGame['numbers']) => {
      if (bingo && number in bingo.numbers) {
        return bingo.numbers[number]
      }
      // fallback to number if no text/artist/number is set
      return {
        number,
      }
    },
    [bingo]
  )

  if (!bingo || !cardId) {
    return (
      <Box
        sx={{
          m: 'auto',
          width: '100%',
          maxWidth: '210mm',
          maxHeight: '297mm',
          aspectRatio: '1 / 1.414',
          overflow: 'hidden',
          boxSizing: 'border-box',
          background: 'rgba(255,255,255,0.1)',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='rgba(0,0,0,0.5)' /><path d='M0 99 L99 0 L100 1 L1 100' fill='rgba(0,0,0,0.5)' /></svg>")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '100% 100%, auto',
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      // ref={ref}
      sx={{
        m: 'auto',
        width: '100%',
        maxWidth: '210mm',
        maxHeight: '297mm',
        aspectRatio: '1 / 1.414',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: 'primary.dark',
          color: 'primary.contrastText',
          backgroundImage: bingo.cardDesign.backgroundImage
            ? `url(${bingo.cardDesign.backgroundImage})`
            : `linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          backgroundOrigin: 'border-box',
          borderRadius: 1,
          padding: 2,
          boxShadow: print ? undefined : 2,
          maxHeight: '297mm',
          aspectRatio: '1 / 1.414',
          width: '100%',
        }}
        alignItems={'flex-start'}
      >
        <Grid
          item
          xs={12}
          container
          spacing={0.5}
          sx={{ padding: 3, aspectRatio: '1 / 1' }}
        >
          {Object.keys(bingo.cards[cardId].squares).map((squareKey) => {
            // lookup data
            const square = parseInt(squareKey)
            const cardData = matchNumber(bingo.cards[cardId].squares[square])

            // add middle square
            if (
              (bingo.variant === '5x5' && square === 12) ||
              (bingo.variant === '3x3' && square === 4)
            ) {
              return (
                <Fragment key={square}>
                  <BingoCardCell
                    numberData={cardData}
                    print={print}
                    checked={
                      !print &&
                      bingo.numbersDrawn.includes(
                        bingo.cards[cardId].squares[square]
                      )
                    }
                    variant={bingo.variant}
                  />
                  <BingoCardCell
                    print={print}
                    checked={false}
                    variant={bingo.variant}
                  >
                    <Box
                      component="img"
                      src={bingo?.cardDesign?.centerImage || centerBingo}
                      alt="Bingo Center Square"
                      sx={{
                        maxWidth: '80%',
                      }}
                    />
                  </BingoCardCell>
                </Fragment>
              )
            }

            // normal square
            else {
              return (
                <Fragment key={square}>
                  <BingoCardCell
                    numberData={cardData}
                    print={print}
                    checked={
                      !print &&
                      bingo.numbersDrawn.includes(
                        bingo.cards[cardId].squares[square]
                      )
                    }
                    variant={bingo.variant}
                  />
                </Fragment>
              )
            }
          })}
        </Grid>
        <Grid item xs={12} sx={{ padding: 3 }}>
          <Box
            sx={{
              width: '100%',
              border: 2,
              borderRadius: 2,
              padding: 2,
              textAlign: 'center',
              backgroundColor: 'common.white',
              boxShadow: 2,
            }}
          >
            <Typography variant="h6">
              Kaart: {bingo.cards[cardId].id}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default BingoCard
