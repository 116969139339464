/**
 *
 *
 *
 */

import { useContext } from 'react'
import { BingoContext } from '../contexs/bingo-context'
import {
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import FigureDisplay from './figure-display'
import { FixedSizeList } from 'react-window'

export interface BingoWinningCardsProps {
  border?: boolean
  height?: number
}

export const BingoWinningCards: React.FC<BingoWinningCardsProps> = (props) => {
  const { bingo, showCardId, setShowCardId } = useContext(BingoContext)

  const { border, height } = props

  // render a bingocard row
  const RenderBingoCardRow: React.FC<{
    index: number
    style: any
    data: any
  }> = (props) => {
    const { index, style, data } = props
    const item = data[index]

    if (!bingo) {
      return null
    }

    return (
      <ListItemButton
        key={index}
        style={style}
        sx={{
          borderBottom: 1,
          borderColor: 'primary.light',
          paddingLeft: 0,
          paddingRight: 1,
        }}
        onClick={() => setShowCardId(item.card.id)}
        selected={showCardId === item.card.id}
      >
        <ListItemAvatar>
          <FigureDisplay
            squares={bingo.figures[item.figure]?.squares}
            maxWidth={48}
          />
        </ListItemAvatar>
        <ListItemText
          primary={`Kaart: ${item.card.id}`}
          secondary={
            item.card.owner
              ? 'Eigenaar: ' + item.card.owner
              : 'Figuur: ' + bingo.figures[item.figure].name
          }
        />
      </ListItemButton>
    )
  }

  const borderSet = typeof border !== 'undefined' ? 1 : 0

  if (!bingo) {
    return null
  }

  return (
    <Box
      sx={{
        border: borderSet,
        borderRadius: borderSet,
        paddingLeft: borderSet,
        paddingRight: borderSet,
      }}
    >
      {bingo.winningCards.length < 1 && (
        <Typography variant="overline">
          U heeft nog geen winnaars toegevoegd
        </Typography>
      )}
      <FixedSizeList
        height={height || 250}
        itemCount={bingo.winningCards.length}
        itemData={bingo.winningCards}
        itemSize={56}
        itemKey={(index, data) => data[index].id}
        width={'100%'}
      >
        {RenderBingoCardRow}
      </FixedSizeList>
    </Box>
  )
}
export default BingoWinningCards
