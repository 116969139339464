import BingoGame from '../types/bingogame'

export const defaultBingo: BingoGame = {
  id: 'xmas-roc-bingo',
  name: 'Eazis 5x5 Muziek Bingo',
  cardDesign: {
    centerImage: '',
    backgroundImage: '',
  },
  variant: '5x5',
  type: 'music',
  currentFigure: 1,
  figures: {
    1: {
      order: 1,
      name: 'Volle Kaart',
      squares: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
      drawnNumbers: [],
    },
  },
  numbers: {
    '1': {
      number: 1,
      title: 'Der Kommissar',
      artist: 'Falco',
      fileUrl: '1.ogg',
    },
    '2': {
      number: 2,
      title: 'De Soldaat',
      artist: 'Nick & Simon',
      fileUrl: '2.ogg',
    },
    '3': {
      number: 3,
      title: 'Destination Calabria',
      artist: 'Alex Gaudino & Crystal Waters',
      fileUrl: '3.ogg',
    },
    '4': {
      number: 4,
      title: 'De Toreador',
      artist: 'Opgeblazen Ft. Wilbert Pigmans',
      fileUrl: '4.ogg',
    },
    '5': {
      number: 5,
      title: 'Devil Went Down To Georgia',
      artist: 'Charlie Daniels Band',
      fileUrl: '5.ogg',
    },
    '6': {
      number: 6,
      title: 'Devil Woman',
      artist: 'Cliff Richard',
      fileUrl: '6.ogg',
    },
    '7': {
      number: 7,
      title: 'De Woonboot',
      artist: 'Stef Ekkel',
      fileUrl: '7.ogg',
    },
    '8': {
      number: 8,
      title: 'Diana',
      artist: 'Therapy',
      fileUrl: '8.ogg',
    },
    '9': {
      number: 9,
      title: 'Die Immer Lacht',
      artist: 'Stereoact Ft. Kirstin Ott',
      fileUrl: '9.ogg',
    },
    '10': {
      number: 10,
      title: 'Diggi Loo Diggi Lee',
      artist: 'Herreys',
      fileUrl: '10.ogg',
    },
    '11': {
      number: 11,
      title: 'Ding A Dong',
      artist: 'Teach In',
      fileUrl: '11.ogg',
    },
    '12': {
      number: 12,
      title: "Dirty Ol' Man",
      artist: 'Three Degrees',
      fileUrl: '12.ogg',
    },
    '13': {
      number: 13,
      title: 'Disco Inferno',
      artist: 'Trammps',
      fileUrl: '13.ogg',
    },
    '14': {
      number: 14,
      title: 'Diva (English)',
      artist: 'Dana International',
      fileUrl: '14.ogg',
    },
    '15': {
      number: 15,
      title: 'Dock Of The Day',
      artist: 'Otis Redding',
      fileUrl: '15.ogg',
    },
    '16': {
      number: 16,
      title: 'Witch doctor',
      artist: 'Def Rhymz',
      fileUrl: '16.ogg',
    },
    '17': {
      number: 17,
      title: 'Do I Do',
      artist: 'Stevie Wonder',
      fileUrl: '17.ogg',
    },
    '18': {
      number: 18,
      title: 'Dokter Bernard',
      artist: 'Bonnie St. Claire',
      fileUrl: '18.ogg',
    },
    '19': {
      number: 19,
      title: 'Dolce Vita',
      artist: 'Ryan Paris',
      fileUrl: '19.ogg',
    },
    '20': {
      number: 20,
      title: "Don't Cha",
      artist: 'The Pussycat Dolls Ft. Busta Rhymes',
      fileUrl: '20.ogg',
    },
    '21': {
      number: 21,
      title: "Don't Cry For Me Argentina",
      artist: 'Julie Covington',
      fileUrl: '21.ogg',
    },
    '22': {
      number: 22,
      title: "Don't Fear The Reaper",
      artist: 'Blue Oyster Cult',
      fileUrl: '22.ogg',
    },
    '23': {
      number: 23,
      title: "Don't Go Breaking My Heart",
      artist: 'Elton John & Kiki Dee',
      fileUrl: '23.ogg',
    },
    '24': {
      number: 24,
      title: "Don't Go",
      artist: 'Yazoo',
      fileUrl: '24.ogg',
    },
    '25': {
      number: 25,
      title: "Don't Leave Me This Way",
      artist: 'Communards',
      fileUrl: '25.ogg',
    },
    '26': {
      number: 26,
      title: "Don't Let Go (Love)",
      artist: 'En Vogue',
      fileUrl: '26.ogg',
    },
    '27': {
      number: 27,
      title: "Don't Let Me Be Misunderstood",
      artist: 'Santa Esmeralda & Leroy Gomez',
      fileUrl: '27.ogg',
    },
    '28': {
      number: 28,
      title: "Don't Look Any Further",
      artist: 'Dennis Edwards & Siedah Garrett',
      fileUrl: '28.ogg',
    },
    '29': {
      number: 29,
      title: "Don't Look Back",
      artist: 'Mick Jagger & Peter Tosh',
      fileUrl: '29.ogg',
    },
    '30': {
      number: 30,
      title: "Don't Sit Under The Apple Tree",
      artist: 'Andrews Sisters',
      fileUrl: '30.ogg',
    },
    '31': {
      number: 31,
      title: "Don't Stop The Music",
      artist: 'Yarbrough & Peoples',
      fileUrl: '31.ogg',
    },
    '32': {
      number: 32,
      title: "Don't Stop 'till You Get Enough",
      artist: 'Michael Jackson',
      fileUrl: '32.ogg',
    },
    '33': {
      number: 33,
      title: "Don't Take Away The Music",
      artist: 'Tavares',
      fileUrl: '33.ogg',
    },
    '34': {
      number: 34,
      title: "Don't Talk Just Kiss",
      artist: 'Right Said Fred',
      fileUrl: '34.ogg',
    },
    '35': {
      number: 35,
      title: "Don't Waste My Time",
      artist: 'Paul Hardcastle & Carol Kenyan',
      fileUrl: '35.ogg',
    },
    '36': {
      number: 36,
      title: "Don't You (Forget About Me)",
      artist: 'Simple Minds',
      fileUrl: '36.ogg',
    },
    '37': {
      number: 37,
      title: "Don't You Want Me",
      artist: 'Human League',
      fileUrl: '37.ogg',
    },
    '38': {
      number: 38,
      title: 'Doris Day',
      artist: 'Doe Maar',
      fileUrl: '38.ogg',
    },
    '39': {
      number: 39,
      title: 'Het Dorp',
      artist: 'Wim Sonneveld',
      fileUrl: '39.ogg',
    },
    '40': {
      number: 40,
      title: 'Do That To Me One More Time',
      artist: 'Captian & Tennille',
      fileUrl: '40.ogg',
    },
    '41': {
      number: 41,
      title: 'Down In The Silvermine',
      artist: 'Diesel',
      fileUrl: '41.ogg',
    },
    '42': {
      number: 42,
      title: 'Down With The King',
      artist: 'Run DMC',
      fileUrl: '42.ogg',
    },
    '43': {
      number: 43,
      title: 'Do You Believe In Love',
      artist: 'Huey Lewis & The News',
      fileUrl: '43.ogg',
    },
    '44': {
      number: 44,
      title: 'Do You Love Me',
      artist: 'Contours',
      fileUrl: '44.ogg',
    },
    '45': {
      number: 45,
      title: 'Do You Wanna Funk',
      artist: 'Patrick Cowley & Sylvester',
      fileUrl: '45.ogg',
    },
    '46': {
      number: 46,
      title: 'Do You Wanna Hold Me',
      artist: 'Bow Wow Wow',
      fileUrl: '46.ogg',
    },
    '47': {
      number: 47,
      title: 'Dracula',
      artist: 'ZZ & De Maskers',
      fileUrl: '47.ogg',
    },
    '48': {
      number: 48,
      title: 'Dragostea Din Tei',
      artist: 'O Zone',
      fileUrl: '48.ogg',
    },
    '49': {
      number: 49,
      title: 'Dreaklock Holiday',
      artist: '10 CC',
      fileUrl: '49.ogg',
    },
    '50': {
      number: 50,
      title: 'Dromendans',
      artist: 'Vinzzent',
      fileUrl: '50.ogg',
    },
    '51': {
      number: 51,
      title: 'Dromen Zijn Bedrog',
      artist: 'Marco Borsato',
      fileUrl: '51.ogg',
    },
    '52': {
      number: 52,
      title: 'Droomland',
      artist: 'Andre Hazes & Paul De Leeuw',
      fileUrl: '52.ogg',
    },
    '53': {
      number: 53,
      title: 'Dschinighis Khan',
      artist: 'Dschinighis Khan',
      fileUrl: '53.ogg',
    },
    '54': {
      number: 54,
      title: 'Du Hast Mich Tausendmal Belogen',
      artist: 'Andrea Berg',
      fileUrl: '54.ogg',
    },
    '55': {
      number: 55,
      title: 'Du Hast',
      artist: 'Rammstein',
      fileUrl: '55.ogg',
    },
    '56': {
      number: 56,
      title: 'Du',
      artist: 'Peter Maffay',
      fileUrl: '56.ogg',
    },
    '57': {
      number: 57,
      title: 'Duurt Te Lang',
      artist: 'Davina Michelle',
      fileUrl: '57.ogg',
    },
    '58': {
      number: 58,
      title: 'Ease On Down The Road',
      artist: 'Wiz Stars',
      fileUrl: '58.ogg',
    },
    '59': {
      number: 59,
      title: 'Easy Lover',
      artist: 'Phil Collins & Philip Bailey',
      fileUrl: '59.ogg',
    },
    '60': {
      number: 60,
      title: 'Echame La Culpa',
      artist: 'Luis Fonsi & Demi Lovato',
      fileUrl: '60.ogg',
    },
    '61': {
      number: 61,
      title: 'Ecuador',
      artist: 'Sash',
      fileUrl: '61.ogg',
    },
    '62': {
      number: 62,
      title: 'Een Beetje Meer',
      artist: 'Jannes',
      fileUrl: '62.ogg',
    },
    '63': {
      number: 63,
      title: 'Een Beetje',
      artist: 'Teddy Scholten',
      fileUrl: '63.ogg',
    },
    '64': {
      number: 64,
      title: 'Een Bom',
      artist: 'Henk Dissel',
      fileUrl: '64.ogg',
    },
    '65': {
      number: 65,
      title: 'Een Reisje Langs De Rijn',
      artist: 'Willy Alberti',
      fileUrl: '65.ogg',
    },
    '66': {
      number: 66,
      title: 'Een Speeldoos',
      artist: 'Annie Palmen',
      fileUrl: '66.ogg',
    },
    '67': {
      number: 67,
      title: 'De Eerste Keer',
      artist: 'Maxine & Franklin Brown',
      fileUrl: '67.ogg',
    },
    '68': {
      number: 68,
      title: 'Ein Bett im KornFeld',
      artist: 'Juergen Drews',
      fileUrl: '68.ogg',
    },
    '69': {
      number: 69,
      title: 'Ein Bisschen Frieden',
      artist: 'Nicole',
      fileUrl: '69.ogg',
    },
    '70': {
      number: 70,
      title: 'Ein Festival Der Liebe',
      artist: 'Jurgen Marcus',
      fileUrl: '70.ogg',
    },
    '71': {
      number: 71,
      title: 'Je Hoeft Niet Naar Huis Vannacht',
      artist: 'Marco Borsato',
      fileUrl: '71.ogg',
    },
    '72': {
      number: 72,
      title: 'Jij Bent Zo',
      artist: 'Jeroen Van Der Boom',
      fileUrl: '72.ogg',
    },
    '73': {
      number: 73,
      title: 'Jolene',
      artist: 'Dolly Parton',
      fileUrl: '73.ogg',
    },
    '74': {
      number: 74,
      title: 'Jump',
      artist: 'Kris Kross',
      fileUrl: '74.ogg',
    },
    '75': {
      number: 75,
      title: 'Killing Me Softly',
      artist: 'Fugees',
      fileUrl: '75.ogg',
    },
  },
  numbersDrawn: [],
  cards: {},
  winningCards: [],
}
export default defaultBingo
