/**
 *
 *
 *
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

export interface ConfirmDialogProps {
  onCancel?: () => void
  onConfirm?: () => void
  open?: boolean
  title?: string
  text?: string
  cancelButtonText?: string
  confirmButtonText?: string
  disabled?: boolean
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    open,
    onCancel,
    onConfirm,
    title,
    text,
    cancelButtonText,
    confirmButtonText,
    disabled,
  } = props

  return (
    <Dialog
      open={open ? true : false}
      onClose={() => onCancel && onCancel()}
      maxWidth="xs"
      fullWidth
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text || "prop={'your text here'} is missing"}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onConfirm && onConfirm()}
          sx={{ m: 2 }}
          disabled={disabled}
        >
          {confirmButtonText || 'Ok'}
        </Button>
        <Button
          variant="contained"
          onClick={() => onCancel && onCancel()}
          sx={{ m: 2 }}
          color="secondary"
          disabled={disabled}
        >
          {cancelButtonText || 'Annuleren'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog
