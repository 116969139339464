/**
 *
 *
 *
 */

import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BingoContext } from '../contexs/bingo-context'
import { FixedSizeList } from 'react-window'
import BingoBall from '../assets/bingo-ball.jpeg'
import { leadingZeros } from '../utils/numbers'
import { Pause, PlayArrow } from '@mui/icons-material'
import BingoGame from '../types/bingogame'

export interface NumberHistoryProps {
  hideArtists?: boolean
  hidePlaybackButton?: boolean
  showHideArtistsSwitch?: boolean
  bingo?: BingoGame
  hideLast?: number
}

export const NumberHistory: React.FC<NumberHistoryProps> = (props) => {
  const {
    hideArtists: hideArtistsProp,
    showHideArtistsSwitch: showHideArtistsSwitchProp,
    bingo: bingoProp,
    hideLast,
    hidePlaybackButton,
  } = props

  const { bingo: bingoContext } = useContext(BingoContext)

  const playerRef = useRef<null | HTMLAudioElement>(null)

  const [hideArtists, setHideArtists] = useState<boolean>(false)

  let bingo = bingoProp || bingoContext

  const [delayedBingo, setDelayedBingo] = useState<undefined | BingoGame>(bingo)

  const drawnNumbersRef = useRef<FixedSizeList<any> | null>(null)

  const drawnNumbers = useMemo(
    () => delayedBingo?.numbersDrawn || [],
    [delayedBingo?.numbersDrawn]
  )

  const playBingoNumberAudio = useCallback((url: string) => {
    const pl = playerRef.current
    if (pl && url) {
      if (pl.paused) {
        pl.src = url
        pl.play()
      } else {
        pl.pause()
      }
    }
  }, [])

  useEffect(() => {
    if (bingo && hideLast && hideLast > 0) {
      const multilatedBingo = bingo
      multilatedBingo.numbersDrawn =
        multilatedBingo.numbersDrawn.slice(0, 0 - hideLast) || []
      setDelayedBingo(multilatedBingo)
    } else {
      setDelayedBingo(bingo)
    }
  }, [bingo, hideLast])

  useEffect(() => {
    if (drawnNumbersRef && drawnNumbersRef.current) {
      const itemNumber = delayedBingo?.numbersDrawn.length || 0
      if (itemNumber > 0) {
        drawnNumbersRef.current.scrollToItem(itemNumber || 1, 'end')
      }
    }
  }, [delayedBingo?.numbersDrawn])

  const DrawnNumberRow: React.FC<{
    index: number
    style: any
    data: number[]
  }> = (props) => {
    const { index, style, data } = props
    const item = data[index]

    const [isPlaying, setIsPlaying] = useState(false)

    const handlePlayPause = useCallback(
      (number: any) => {
        if (!number) {
          return
        } else {
          number = number.toString()
        }

        // let url = bingo?.numbers[number].fileUrl || ''
        let url = delayedBingo?.numbers[number].fileUrl || ''

        if (!url) {
          return
        }

        url = `/music/playlist-01/${url}`

        playBingoNumberAudio(url)
        setIsPlaying(!isPlaying)
      },
      [isPlaying]
    )

    return (
      <ListItem
        key={index}
        style={style}
        component="div"
        sx={{
          borderBottom: 1,
          borderColor: 'secondary.main',
        }}
      >
        <Typography
          sx={{
            fontSize: '1.0rem',
            color:
              (delayedBingo?.numbersDrawn.length || 0) - 1 === index
                ? 'unset'
                : 'secondary.light',
          }}
        >
          {leadingZeros(index + 1, 2)}
        </Typography>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'black',
              boxShadow: 1,
              background: 'yellow',
              backgroundImage: `url(${BingoBall})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              ml: 2,
              mr: 1,
            }}
          >
            {item.toString()}
          </Avatar>
        </ListItemAvatar>
        {delayedBingo?.type === 'music' && !hidePlaybackButton && (
          <IconButton
            sx={{ mr: 1 }}
            size="large"
            onClick={() => handlePlayPause(item)}
          >
            {!isPlaying && <PlayArrow color="inherit" />}
            {isPlaying && <Pause color="inherit" />}
          </IconButton>
        )}
        {!hideArtistsProp && !hideArtists && (
          <ListItemText
            primary={
              (delayedBingo?.numbers[item].artist ||
                delayedBingo?.numbers[item].text ||
                '') +
              (delayedBingo?.numbers[item].title
                ? ' - ' + delayedBingo.numbers[item].title
                : '')
            }
          />
        )}
      </ListItem>
    )
  }

  return (
    <Box
      sx={{
        boxShadow: 5,
        borderRadius: 1,
        position: 'relative',
      }}
    >
      {drawnNumbers.length < 1 && (
        <Typography
          variant="overline"
          sx={{ position: 'absolute', top: 20, left: 20 }}
        >
          Er zijn nog geen nummers getrokken.
        </Typography>
      )}
      <FixedSizeList
        ref={drawnNumbersRef}
        height={500}
        itemCount={drawnNumbers.length}
        itemData={drawnNumbers}
        itemSize={56}
        itemKey={(index, data) => data[index].number}
        width={'100%'}
      >
        {DrawnNumberRow}
      </FixedSizeList>
      {!hideArtistsProp && showHideArtistsSwitchProp && (
        <FormControlLabel
          color="secondary"
          sx={{
            borderRadius: 1,
            padding: 0.5,
            margin: 0,
          }}
          control={
            <Switch
              color="secondary"
              checked={hideArtists}
              onChange={(e) => setHideArtists(e.currentTarget.checked)}
            />
          }
          label="Verberg Artiest/Titel"
        />
      )}
      <Box component={'audio'} ref={playerRef} />
    </Box>
  )
}
export default NumberHistory
