/**
 *
 *
 *
 */

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import useListBingos from '../hooks/use-list-bingos'
import { BingoContext } from '../contexs/bingo-context'
import BingoGame from '../types/bingogame'
import { Delete } from '@mui/icons-material'

export interface BingoLoadDialogProps {
  open: boolean
  onClose: () => void
  onLoad: () => void
}

/**
 * Dialog content, needed for updating / loading external content and not redraw / open the full dialog
 */
export const BingoLoadDialogContent: React.FC<BingoLoadDialogProps> = (
  props
) => {
  const { onClose, onLoad } = props

  const { loading, bingos, listBingos } = useListBingos()
  const { loadBingo, deleteBingo } = useContext(BingoContext)

  const [selectedBingo, setSelectedBingo] = useState<undefined | string>(
    undefined
  )

  const handleLoadBingo = useCallback(async () => {
    if (!selectedBingo) {
      console.warn('no bingo selected')
      return
    }

    await loadBingo(selectedBingo)

    onLoad && onLoad()
  }, [selectedBingo, loadBingo, onLoad])

  const handleDeleteBingo = useCallback(
    async (bingoId: BingoGame['id'], e: any) => {
      if (!bingoId) {
        console.warn('no bingo selected')
        return
      }

      // if provided an click or other event
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      await deleteBingo(bingoId)

      await listBingos()

      // unselect selection
      if (bingoId === selectedBingo) {
        setSelectedBingo(undefined)
      }
    },
    [deleteBingo, listBingos, selectedBingo]
  )

  useEffect(() => {
    if (!bingos) {
      listBingos()
    }
  }, [listBingos, bingos])

  return (
    <>
      <DialogContent>
        {loading && (
          <Alert severity="info" sx={{ mb: 2 }}>
            We zijn je bingo's aan het ophalen, momentje
          </Alert>
        )}
        <Box
          component="form"
          id="select-bingo-from-storage"
          name="select-bingo-from-storage"
          noValidate
          autoComplete="off"
          border={1}
        >
          <List>
            {bingos &&
              bingos.map((b) => (
                <ListItemButton
                  selected={b.id === selectedBingo}
                  onClick={() => setSelectedBingo(b.id)}
                  key={b.id}
                  divider
                >
                  <ListItemText>{b.name}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton onClick={(e) => handleDeleteBingo(b.id, e)}>
                      <Delete color="inherit" fontSize="inherit" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            {(!bingos || bingos.length < 1) && (
              <ListItem>
                <ListItemText>U heeft nog geen bingos opgeslagen</ListItemText>
              </ListItem>
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLoadBingo}
          sx={{ m: 2 }}
          disabled={typeof selectedBingo === 'undefined'}
        >
          Laad Bingo
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ m: 2 }}
          color="secondary"
        >
          Sluiten
        </Button>
      </DialogActions>
    </>
  )
}

/**
 * Container for the Dialog
 */
export const BingoLoadDialog: React.FC<BingoLoadDialogProps> = (props) => {
  const { open, onClose } = props
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Selecteer Bingo</DialogTitle>
      <BingoLoadDialogContent {...props} />
    </Dialog>
  )
}
export default BingoLoadDialog
