/**
 * 
 * 
 * 
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';


// material-ui font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// routes
import routesConfig from './routes/routes-config';
import { CssBaseline, ThemeProvider } from '@mui/material';
import themeConfig from './theme/theme-config';

// create root from div html element
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// render app
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <RouterProvider router={routesConfig} />
    </ThemeProvider>
  </React.StrictMode>
);
