/**
 *
 *
 *
 */

import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'root',
})(({ theme }) => ({
  padding: theme.spacing(3, 3),
  backgroundColor: 'rgba(0,0,0,0.95)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  color: theme.palette.text.primary,
  position: 'relative',
}))
export default StyledBox
