/**
 *
 *
 *
 */

import BingoGame from '../types/bingogame'

export const useFilteredBingoCards = (
  bingo?: BingoGame,
  searchTerm: string = ''
) => {
  if (!bingo) {
    return []
  }
  // caseinsensative searching
  const searchLowerCase = searchTerm.toLowerCase()

  // filter cards by searchterm
  const foundCards = Object.values(bingo.cards).filter((card) => {
    if (card.id.toString().toLowerCase().includes(searchLowerCase)) {
      return card
    }
    if (card.owner && card.owner.toLowerCase().includes(searchLowerCase)) {
      return card
    }
    return undefined
  })

  // return the found cards
  return foundCards
}
export default useFilteredBingoCards
