/**
 *
 *
 *
 */

import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import QRCodeGenerator from 'qrcode'

export interface QRCodeProps {
  text: string
}

export const QRCode: React.FC<QRCodeProps> = (props) => {
  const { text } = props

  const canvasRef = useRef<null | HTMLCanvasElement>(null)

  useEffect(() => {
    const c = canvasRef.current
    if (c) {
      QRCodeGenerator.toCanvas(c, text, (err) => {
        if (err) {
          console.error(err)
        }
      })
    }
  }, [text])

  return (
    <Box>
      <Box ref={canvasRef} component={'canvas'} />
    </Box>
  )
}
export default QRCode
