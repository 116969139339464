/**
 *
 *
 *
 */

import { Button } from '@mui/material'
import ConfirmDialog from './confirm-dialog'
import { useCallback, useContext, useState } from 'react'
import { BingoContext } from '../contexs/bingo-context'

export interface SaveBingoButtonProps {}

export const SaveBingoButton: React.FC<SaveBingoButtonProps> = (props) => {
  const { saveBingo: save, loading } = useContext(BingoContext)

  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined)

  const handleSave = useCallback(async () => {
    try {
      await save()
      setErrorMsg(undefined)
    } catch (err) {
      setErrorMsg(
        'Er is iets fout gegaan tijdens het opslaan, probeer het nog een keer'
      )
    }
  }, [save])

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSave}
        sx={{ marginRight: 2 }}
        disabled={loading}
      >
        Spel Opslaan
      </Button>
      <ConfirmDialog
        open={Boolean(errorMsg)}
        title="Save Error"
        text={errorMsg}
        onConfirm={handleSave}
        onCancel={() => setErrorMsg(undefined)}
        confirmButtonText="Opnieuw Proberen"
        disabled={loading}
      />
    </>
  )
}
export default SaveBingoButton
