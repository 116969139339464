/**
 *
 *
 *
 */
import { BingoContextProvider } from '../contexs/bingo-context'
import PlayBingoCard from '../components/play-bingo-card'

export interface PlayerProps {}

export const Player: React.FC<PlayerProps> = (props) => {
  return (
    <BingoContextProvider>
      <PlayBingoCard />
    </BingoContextProvider>
  )
}
export default Player
